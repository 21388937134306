import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMimeTypeConstantsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable } from "rxjs";

import { HttpHeaderConstantsEnum } from "../../core/enums/http-header-constants.enum";

import { IRequestPhoneAuth } from "../../core/interfaces/request-phone-auth-rnec.interface";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PhoneAuthRemoteService {
  constructor(private http: HttpClient) {}

  postUpdatePhoneAuth(
    request: IRequestPhoneAuth,
    token: string,
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set(
        HttpHeaderConstantsEnum.CONTENT_TYPE,
        HttpMimeTypeConstantsEnum.APPLICATION_JSON,
      )
      .set(HttpHeaderConstantsEnum.AUTHORIZATION, token);
    return this.http.post(
      environment.serverUrlSalaryLoans + "api/phone/auth",
      request,
      { headers },
    );
  }
}
