import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { RnecCodeDirectiveValidate } from "../../../core/directives/rnec-code.directive";
import { ValidationMethodEnum } from "../../../core/enums/validation-method.enum";

import { ICustomer } from "../../../core/interfaces/customer.interface";

import { CustomerService } from "../../../services/local/customer.service";

@Component({
  selector: "app-form-popup-rnec",
  templateUrl: "./form-popup-rnec.component.html",
})
export class FormPopupRnecComponent implements OnInit {
  @Input("popUpRnec")
  set setPopUpRnec(popUpRnec: boolean) {
    this.popUpRnec = popUpRnec;
  }

  @Input("urlNavigate")
  set setUrlNavigate(urlNavigate: string) {
    this.urlNavigate = urlNavigate;
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<boolean>();

  title: string;
  description: string;
  captionButton: string;
  loadingButtonSendCodeRnec: boolean;
  popUpRnec: boolean;
  urlNavigate: string;

  customer: ICustomer;
  formGroup: UntypedFormGroup;

  constructor(
    private customerService: CustomerService,
    private router: Router,
  ) {
    this.title = "Ingresa el código Certihuella";
    this.description = "Recuerda, son 12 caracteres";
    this.captionButton = "Enviar Código";

    this.customer = this.customerService.getCustomer();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});

    const textRnecCode: UntypedFormControl = new UntypedFormControl();
    this.formGroup.addControl(
      "textRnecCode",
      new UntypedFormControl("", RnecCodeDirectiveValidate(textRnecCode)),
    );
  }

  closePopupAndRedirect(redirect: boolean) {
    if (!redirect) {
      this.closePopupAndRedirectEvent.emit(redirect);
    } else if (redirect && this.formGroup.valid) {
      this.customer.idCaseRnec = this.formGroup.controls.textRnecCode.value;
      this.customer.validationMethod = ValidationMethodEnum.CERTIHUELLA;
      this.loadingButtonSendCodeRnec = true;

      this.closePopupAndRedirectEvent.emit(redirect);

      if (this.urlNavigate) {
        this.router.navigate([this.urlNavigate]);
      }
    }
  }
}
