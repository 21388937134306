import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { TableColumnTypeEnum } from "../../../core/enums/table-column-type.enum";
import { TableTypeEnum } from "../../../core/enums/table-type.enum";

import { MapTableService } from "../../../services/local/map-table.service";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @Output() commandEvent = new EventEmitter<any>();
  @Output() dataListEvent = new EventEmitter<any>();

  @Input() arrColorColumn: any;
  @Input() cssClass: string = "table-scrollbar";

  set tableColumnTypeEnum(tableColumnTypeEnum) {}
  get tableColumnTypeEnum() {
    return TableColumnTypeEnum;
  }

  @Input("changeTableType")
  public set setChangeTableType(changeTableType: any) {
    this.titles = [];
    this.fieldsData = [];
    this.fieldsListData = [];
    this.mapData = this.mapTableService.getMapTableData(changeTableType);

    this.setTitles();
  }

  @Input("dataTable")
  set setDataTable(dataTable: any[]) {
    this.dataTable = [];
    this.dataListTable = [];
    if (dataTable) {
      dataTable.forEach((data) => {
        this.dataTable.push(this.fieldsData.map((field) => data[field]));
      });
      dataTable.forEach((data) => {
        this.dataListTable.push(
          this.fieldsListData.map((field) => data[field]),
        );
      });
    }
  }

  @Input() tableType: TableTypeEnum;

  dataTable: any[] = [];
  dataListTable: any[] = [];
  fieldsData: any[] = [];
  fieldsListData: any[] = [];
  mapData: any[];
  titles: any[] = [];

  DOCUMENTSTATE = 0;

  constructor(private mapTableService: MapTableService) {}

  ngOnInit(): void {
    this.mapData = this.mapTableService.getMapTableData(this.tableType);
    this.setTitles();
  }

  setTitles() {
    this.mapData.forEach((map) => {
      this.titles.push({
        title: map.title,
      });
      this.fieldsData.push(map.field);
      this.fieldsListData.push(map.fieldList);
    });
  }

  setColorColumn(label: string) {
    if (!this.arrColorColumn || !label) {
      return null;
    }

    const findPoint = label.indexOf(".");

    const fieldName = findPoint !== -1 ? label.slice(findPoint + 1) : label;

    const colorParameter = this.arrColorColumn.find(
      (res: any) => res.label === fieldName,
    );

    if (colorParameter) {
      return colorParameter;
    } else {
      return {
        label: label,
        icon: "./assets/images/onbase_load/check.svg",
        class: "btn btn-link-green",
      };
    }
  }

  onClick(data: any) {
    this.commandEvent.emit(data);
  }

  onClickList(data: any, dataList: any) {
    this.dataListEvent.emit({
      data,
      dataList,
    });
  }
}
