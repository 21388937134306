import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

import { AwsSignConstantsEnum } from "../../core/enums/aws-sign-constants.enum";
import { HttpHeaderConstantsEnum } from "../../core/enums/http-header-constants.enum";

import { AdvisorService } from "./advisor.service";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AwsSignV4Service {
  accessKey: string;
  currentDate: Date;
  sessionToken: string;
  secretKey: string;

  get getCurrentDate() {
    return this.currentDate;
  }

  set setAccessKey(accessKey: string) {
    this.accessKey = accessKey;
  }

  set setCurrentDate(date: Date) {
    this.currentDate = date;
  }

  set setSecretKey(secretKey: string) {
    this.secretKey = secretKey;
  }

  set setSessionToken(sessionToken: string) {
    this.sessionToken = sessionToken;
  }

  constructor(private advisorService: AdvisorService) {}

  build(req: HttpRequest<any>): any {
    let request = req.clone();

    if (
      req.headers.get(HttpHeaderConstantsEnum.X_AWS_SIGNATURE_V4) === "true"
    ) {
      request = request.clone({
        headers: request.headers
          .delete(HttpHeaderConstantsEnum.X_AWS_SIGNATURE_V4)
          .delete(HttpHeaderConstantsEnum.AUTHORIZATION),
      });
      this.setCurrentDate = new Date();
      this.setAccessKey = this.advisorService.getAdvisor().accessKey;
      this.setSessionToken = this.advisorService.getAdvisor().sessionToken;
      this.setSecretKey = this.advisorService.getAdvisor().secretKey;
      const currentDateStamp = this.getCurrentDate;
      const { signedHeaders, canonicalRequest } = this.createCanonicalRequest(
        request,
        currentDateStamp,
      );
      const { algorithm, credential, sign } = this.createSign(
        currentDateStamp,
        canonicalRequest,
      );
      const signature = this.calculateSignature(currentDateStamp, sign);
      request = request.clone({
        headers: request.headers
          .set(
            HttpHeaderConstantsEnum.AUTHORIZATION,
            `${algorithm} Credential=${credential},SignedHeaders=${signedHeaders},Signature=${signature}`,
          )
          .set(HttpHeaderConstantsEnum.X_AMZ_SECURITY_TOKEN, this.sessionToken)
          .set(
            HttpHeaderConstantsEnum.X_AMZ_DATE,
            currentDateStamp.toISOString().replace(/-|:|\..{3}/g, ""),
          )
          .set(
            HttpHeaderConstantsEnum.X_AMZ_CONTENT_SHA256,
            CryptoJS.SHA256(JSON.stringify(req.body)).toString(),
          ),
      });
    }

    return request;
  }

  private appendHeaders(req: HttpRequest<any>, date: Date): HttpRequest<any> {
    const amzDate: string = date.toISOString().replace(/-|:|\..{3}/g, "");
    const url = new URL(req.url);

    return req.clone({
      headers: req.headers
        .set(HttpHeaderConstantsEnum.X_AMZ_DATE, amzDate)
        .set(HttpHeaderConstantsEnum.HOST, url.host)
        .set(HttpHeaderConstantsEnum.X_AMZ_SECURITY_TOKEN, this.sessionToken)
        .set(
          HttpHeaderConstantsEnum.X_AMZ_CONTENT_SHA256,
          CryptoJS.SHA256(JSON.stringify(req.body)).toString(),
        ),
    });
  }

  private calculateSignature(date: Date, sign: string): string {
    const dateStamp = date
      .toISOString()
      .replace(/-|:|\..{3}/g, "")
      .substring(0, 8);
    const region = environment.awsCognitoData.region;
    const service = environment.awsCognitoData.service;
    const kDate = CryptoJS.HmacSHA256(
      dateStamp,
      AwsSignConstantsEnum.AWS4 + this.secretKey,
    );
    const kRegion = CryptoJS.HmacSHA256(region, kDate);
    const kService = CryptoJS.HmacSHA256(service, kRegion);
    const kSigning = CryptoJS.HmacSHA256(
      AwsSignConstantsEnum.AWS4_REQUEST,
      kService,
    );

    return CryptoJS.HmacSHA256(sign, kSigning).toString();
  }

  private calculeSortHeader(firstWord: string, lastWord: string): number {
    if (firstWord < lastWord) {
      return -1;
    }
    if (firstWord > lastWord) {
      return 1;
    }
    return 0;
  }

  private createCanonicalRequest(
    request: HttpRequest<any>,
    date: Date,
  ): { signedHeaders: string; canonicalRequest: string } {
    request = this.appendHeaders(request, date);
    const url = new URL(request.url);
    const HTTPMethod = request.method;
    const canonicalUri = url.pathname;
    const canonicalQueryString = "";
    const canonicalHeaders = request.headers
      .keys()
      .map((headerKey) => headerKey.toLowerCase())
      .sort(this.calculeSortHeader)
      .map((headerKey) => {
        return `${headerKey.toLowerCase()}:${request.headers
          .get(headerKey)
          .trim()}`;
      })
      .join("\n");

    const signedHeaders = request.headers
      .keys()
      .map((headerKey) => headerKey.toLowerCase())
      .sort(this.calculeSortHeader)
      .join(";");
    const hashedPayload =
      typeof request.body === "object"
        ? CryptoJS.SHA256(JSON.stringify(request.body))
        : CryptoJS.SHA256(request.body).toString();
    const canonicalRequest = `${HTTPMethod}\n${canonicalUri}\n${canonicalQueryString}\n${canonicalHeaders}\n\n${signedHeaders}\n${hashedPayload}`;

    return { signedHeaders, canonicalRequest };
  }

  private createSign(
    date: Date,
    canonicalRequest: string,
  ): { algorithm: AwsSignConstantsEnum; credential: string; sign: string } {
    const region = environment.awsCognitoData.region;
    const service = environment.awsCognitoData.service;
    const algorithm = AwsSignConstantsEnum.AWS4_HMAC_SHA256;
    const requestDateTime = date.toISOString().replace(/-|:|\..{3}/g, "");
    const credentialScope = `${requestDateTime.substring(
      0,
      8,
    )}/${region}/${service}/aws4_request`;
    const hashedCanonicalRequest = CryptoJS.SHA256(canonicalRequest);
    const sign = `${algorithm}\n${requestDateTime}\n${credentialScope}\n${hashedCanonicalRequest}`;

    return {
      algorithm,
      credential: `${this.accessKey}/${credentialScope}`,
      sign,
    };
  }
}
