import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FreshChatRemoteService {
  constructor(private httpClient: HttpClient) {}

  getIdHistory(clientIdDocument: string, accessToken: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    return this.httpClient.post<any>(
      `${environment.serverUrlPublicResources}freshchat-history`,
      { clientIdDocument },
      { headers },
    );
  }

  saveIdHistory(
    clientIdDocument: string,
    restoreId: string,
    accessToken: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    return this.httpClient.post<any>(
      `${environment.serverUrlPublicResources}freshchat-history`,
      { clientIdDocument, restoreId },
      { headers },
    );
  }
}
