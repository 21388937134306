import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMimeTypeConstantsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { IRequestValidateRnec } from "../../core/interfaces/request-validate-rnec.interface";
import { IResponseValidateRnec } from "../../core/interfaces/response-validate-rnec.interface";
import { AwsSignV4RemoteService } from "./aws-sign-v4-remote.service";

@Injectable({
  providedIn: "root",
})
export class RnecRemoteService {
  constructor(
    private awsSignV4RemoteService: AwsSignV4RemoteService,
    private http: HttpClient,
  ) {}

  postValidateRnec(
    request: IRequestValidateRnec,
  ): Observable<IResponseValidateRnec> {
    const headers = this.awsSignV4RemoteService.getHeadersAwsSignV4(
      HttpMimeTypeConstantsEnum.APPLICATION_JSON,
    );
    return this.http.post(
      environment.serverUrlBackendSecurity + "auth/certihuella",
      request,
      { headers },
    );
  }
}
