import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { ChannelTypeEnum } from "../../core/enums/channel-type.enum";
import { FileExtensionEnum } from "../../core/enums/file-extension.enum";
import { GoogleAnalyticsEventsEnum } from "../../core/enums/google-analytics-events.enum";

import { FileManagerService } from "./file-manager.service";
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";

@Injectable({
  providedIn: "root",
})
export class PayrollUploadFileService {
  private providersId: any = [];

  file: File;
  updateProviderId: any = [];

  constructor(
    private fileManagerService: FileManagerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private router: Router,
  ) {}

  isValidFile(file: File) {
    return this.fileManagerService.validateFile(
      file,
      ApplicationConstantsEnum.MAXIMUM_ONBASE_FILE_SIZE,
      [FileExtensionEnum.PDF],
    );
  }

  upload(file: File, channelType: ChannelTypeEnum) {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.UPLOAD_DOCUMENTS_CLICK_CONTINUE,
    );
    this.file = file;

    return this.fileManagerService.sendFile(file, channelType).pipe(
      catchError((error: any) => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_ERROR,
        );

        this.router.navigate(["overflowerror"]);
        return throwError(error);
      }),
    );
  }

  uploadWithPDFValidations(file: File, channelType: ChannelTypeEnum) {
    if (
      !this.fileManagerService.isValidExtension(file, [FileExtensionEnum.PDF])
    ) {
      throw new Error("FileExtensionError");
    }
    if (
      !this.fileManagerService.isValidSize(
        file,
        ApplicationConstantsEnum.MAXIMUM_ONBASE_FILE_SIZE,
      )
    ) {
      throw new Error("FileSizeError");
    }
    return this.upload(file, channelType);
  }
}
