<div id="main">
  <div
    id="groupcarddebit"
    [ngClass]="entry ? 'group-card-debit-in' : 'group-card-debit-out'"
  ></div>
  <div
    id="carddebit"
    [ngClass]="entry ? 'card-debit-in' : 'card-debit-out'"
  ></div>
  <div
    id="textcarddebit"
    class="d-flex justify-content-center"
    [ngClass]="entry ? 'text-card-debit-in' : 'text-card-debit-out'"
  >
    <adl-text-html
      [text]="
        '<p class=text-justify><b>Código de seguridad</b> (los 6 dígitos pequeños en la parte frontal de la tarjeta)</p>'
      "
    >
    </adl-text-html>
  </div>
</div>
