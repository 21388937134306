import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";

import { ApplicationConstantsEnum } from "./core/enums/applications-constants.enum";
import { MessageEnum } from "./core/enums/message.enum";
import { OverflowErrorsEnum } from "./core/enums/overflow-errors.enum";

import { environment } from "../environments/environment";

import { AdvisorService } from "./services/local/advisor.service";
import { CognitoService } from "./services/local/cognito.service";
import { FederatedAuthService } from "./services/local/federated-auth.service";
import { GoogleAnalyticsEventsService } from "./services/local/google-analytics-events.service";
import { OverflowErrorsService } from "./services/local/overflow-errors.service";
import { RedirectService } from "./services/local/redirect.service";

import { TealiumUtagService } from "./tealium/utag.service";

import { FormPopupMessageBoxComponent } from "./design/structures/form-popup-message-box/form-popup-message-box.component";

declare let ga: Function;

@Component({
  selector: "app-root",
  providers: [TealiumUtagService],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(FormPopupMessageBoxComponent, { static: false })
  formPopupMessageBoxComponent: FormPopupMessageBoxComponent;

  userActivity: any;
  userInactive: Subject<any> = new Subject();
  title = "bpop-digital-libranzas-front";

  constructor(
    private advisorService: AdvisorService,
    private cognitoService: CognitoService,
    private elementRef: ElementRef,
    private federatedAuthService: FederatedAuthService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private overflowErrorsService: OverflowErrorsService,
    private tealium: TealiumUtagService,
    private redirectService: RedirectService,
    private router: Router,
  ) {
    this.setTimeout();
    this.createCodeGoogleAnalytics();
    this.setPageGoogleAnalytics();

    this.tealium.setConfig({
      account: "adl",
      profile: "popular",
      environment: "prod",
    });
  }

  ngAfterViewInit(): void {
    this.cognitoService.isFailToken().subscribe((fail) => {
      if (fail) {
        this.showMessageOverral();
      }
    });
  }

  async ngOnInit() {
    this.elementRef.nativeElement.removeAttribute("ng-version");

    this.userInactive.subscribe(() => {
      this.overflowErrorsService.setOverflowError(
        OverflowErrorsEnum.ERROR_TIME_LIMIT,
      );

      this.federatedAuthService.federatedAuthEnabled()
        ? this.redirectService.redirectToFederatedAuthLogout()
        : this.setLogout();
    });

    this.tealium.view({
      event_name: "init",
      page_path: document.location.pathname,
    });
  }

  closeMessageBox() {
    window.location.reload();
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(),
      ApplicationConstantsEnum.TIME_OUT_APP,
    );
  }

  setLogout() {
    this.advisorService.setLogout().subscribe(
      () => {
        this.router.navigate(["overflowerror"]);
      },
      (error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    );
  }

  @HostListener("click") refreshTimeInactivity() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  private createCodeGoogleAnalytics() {
    const scriptHeadUA = document.createElement("script");
    const scriptHead = document.createElement("script");
    const scriptBody = document.createElement("iframe");

    scriptHeadUA.innerHTML = `
        (function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r]
        || function () { (i[r].q = i[r].q || []).push(arguments) },
        i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1;
        a.src = g; m.parentNode.insertBefore(a, m) })
        (window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        ga('create', '${environment.codeUAGoogleAnalytics}', 'auto');
      `;

    document.head.appendChild(scriptHeadUA);

    scriptHead.innerHTML = `
        window.onload = function() {
          if (document.readyState === 'complete') {
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer' ? '&l='+l : '';
              j.async = true;
              j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=${environment.codeGoogleAnalytics}&gtm_cookies_win=x';
              f.parentNode.insertBefore(j, f);
            })
            (window, document, 'script', 'dataLayer', 'GTM-5B9FZ2N');
          }
        };
      `;

    document.head.appendChild(scriptHead);

    scriptBody.src = `https://www.googletagmanager.com/ns.html?id=GTM-5B9FZ2N&gtm_auth=${environment.codeGoogleAnalytics}&gtm_cookies_win=x`;
    scriptBody.style.display = "none";
    scriptBody.style.visibility = "hidden";
    scriptBody.style.height = "0";
    scriptBody.style.width = "0";

    document.body.appendChild(scriptBody);
  }

  private setPageGoogleAnalytics() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.split("/").length === 2) {
          this.googleAnalyticsEventsService.emitEventNavigate(event);
        }
      }
    });
  }

  private showMessageOverral() {
    this.formPopupMessageBoxComponent.setMessageEnum =
      MessageEnum.ERROR_CRASHED_OVERALL_SERVICES;
    this.formPopupMessageBoxComponent.setShowMessageBox = true;
  }
}
