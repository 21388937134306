import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingComponent } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { EventTopic } from "../../../core/enums/event-topic.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ModalityTypeEnum } from "../../../core/enums/modality-type.enum";

import { IMessage } from "../../../core/interfaces/message.interface";
import { IPreloan } from "../../../core/interfaces/pre-loan.interface";

import { EventManagerService } from "../../../services/local/event-manager.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { LoanService } from "../../../services/local/loan.service";
import { RepricingCampaignService } from "../../../services/local/repricing-campaign.service";
import { SalaryLoanActionFactoryService } from "../../../services/local/factory/salary-loan-action-factory.service";

import { FormPopupMessageBoxComponent } from "../form-popup-message-box/form-popup-message-box.component";
import { FormPopupSygnusLinkComponent } from "../form-popup-sygnus-link/form-popup-sygnus-link.component";
import { FormPopupUploadPaymentReceiptComponent } from "../form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";
import { PopupPayrollcheckUploadResponseComponent } from "../popup-payrollcheck-upload-response/popup-payrollcheck-upload-response.component";

@Component({
  selector: "app-salary-loan-view",
  styleUrls: ["./salary-loan-view.component.scss"],
  templateUrl: "./salary-loan-view.component.html",
})
export class SalaryLoanViewComponent implements OnInit, OnDestroy {
  @ViewChild(LoadingComponent, { static: false })
  loading: LoadingComponent;

  @ViewChild(FormPopupMessageBoxComponent, { static: false })
  formPopupMessageBoxComponent: FormPopupMessageBoxComponent;

  @ViewChild(FormPopupSygnusLinkComponent, { static: false })
  formPopupSygnusLinkComponent: FormPopupSygnusLinkComponent;

  @ViewChild(FormPopupUploadPaymentReceiptComponent, { static: false })
  formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent;

  @ViewChild("popupUploadResponse", { static: false })
  popupUploadResponseRef: PopupPayrollcheckUploadResponseComponent;

  isNotificationVisible = false;
  loadingCss: string;
  loadingDescription: string;
  loadPercentage: number;
  showLoading: boolean;
  customerLoans: IPreloan[];
  message: IMessage;
  messageEnum: MessageEnum;
  notificationDescription: string;
  notificationTitle: string;
  uploadResponse: IMessage;
  attemptsUpload = 0;

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private repricingService: RepricingCampaignService,
    private router: Router,
    private salaryLoanActionFactoryService: SalaryLoanActionFactoryService,
    private eventManagerService: EventManagerService,
  ) {}

  ngOnDestroy(): void {
    this.eventManagerService.unsubscribe(EventTopic.OPEN_POPUP_UPLOAD);
    this.eventManagerService.unsubscribe(
      EventTopic.ACTION_BUTTON_POPUP_RESPONSE,
    );
  }

  ngOnInit(): void {
    this.notificationTitle = "Consulta en ALALA";
    this.notificationDescription =
      "Asegúrate de realizar la consulta en la plataforma ALALA para las pagadurías que aplique antes de continuar con el proceso.";

    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.SALARY_LOAN_INIT_PAGE,
    );

    this.getCustomerLoans();
    this.eventManagerService
      .on(EventTopic.OPEN_POPUP_UPLOAD)
      .subscribe((data) => {
        const { message, attempt } = data;
        this.popupUploadResponseRef.show();
        this.uploadResponse = message;
        this.attemptsUpload = attempt;
      });
  }

  retryUploadDocument() {
    this.eventManagerService.publish(
      EventTopic.ACTION_BUTTON_POPUP_RESPONSE,
      this.uploadResponse,
    );
  }

  clickButtonPayer(selectedLoan: IPreloan, loanFlowType: string) {
    const account = this.repricingService.getAccount(
      selectedLoan.payerUniqueName,
    );
    if (account) {
      selectedLoan.accountNumber = account.accountNumber;
      selectedLoan.accountType = account.accountType;
    }
    selectedLoan.modalityType = ModalityTypeEnum.ORDINARY;
    this.loanService.setPreloan(selectedLoan);
    this.salaryLoanActionFactoryService.salaryLoanAction(
      this,
      loanFlowType as LoanFlowEnum,
    );
  }

  closeMessageBox(message: IMessage) {
    this.formPopupMessageBoxComponent.setShowMessageBox = false;
    this.message = message;

    if (
      this.messageEnum === MessageEnum.ERROR_SAME_FEE_IN_SALARY_LOANS ||
      this.message.messageId === MessageEnum.ERROR_QUOTA_GREATER_AVAILABLE
    ) {
      this.getCustomerLoans();
    } else if (this.message.actionButton === MessageActionEnum.GO_INIT) {
      this.router.navigate(["loginadvisor"]);
    }
  }

  salaryLoanResolve(event: any) {
    this.salaryLoanActionFactoryService.salaryLoanResolve(event);
  }

  closeNotification() {
    this.isNotificationVisible = false;
  }

  private getCustomerLoans() {
    this.showLoading = true;

    this.loanService.getCustomerLoans().subscribe(
      (dataCustomerLoans: IPreloan[]) => {
        this.customerLoans = dataCustomerLoans;
        this.showLoading = false;
        this.isNotificationVisible = true;
      },
      (message: any) => {
        this.formPopupMessageBoxComponent.setShowMessageBox = true;
        this.showLoading = false;

        if (message.messageId === MessageEnum.ERROR_SAME_FEE_IN_SALARY_LOANS) {
          this.messageEnum = message.messageId;
        } else {
          this.message = message;
        }
      },
    );
  }
}
