import { IMessageLoan } from "../../../core/interfaces/message-loan.interface";
import { IMessageLoanStrategy } from "./message-loan-strategy.interface";

import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";

export class MessageLoanOperationPayerrollPlatform extends IMessageLoanStrategy {
  constructor(dataLoanStatus: ILoanStatus) {
    super(dataLoanStatus);
  }

  getMessageId(): string {
    return this.dataLoanStatus.customerInBureauTrail
      ? "PAYERROLL_PLATFORM_ACTIVE_WITH_IN_BUREAU_TRAIL"
      : "PAYERROLL_PLATFORM_ACTIVE_WITHOUT_IN_BUREAU_TRAIL";
  }

  getMessageLoan(): IMessageLoan {
    return super.getMessageLoan();
  }
}
