import { Injectable } from "@angular/core";

import { ExtractorRemoteService } from "../remote/extractor-remote.service";

import { IdGeneratorService } from "./id-generator.service";

import {
  concatMap,
  delay,
  repeatWhen,
  retry,
  takeWhile,
  tap,
} from "rxjs/operators";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IExtractorRequest } from "../../core/interfaces/extractor-request.interface";
import { IExtractionResponse } from "../../core/interfaces/extraction-response.interface";
import { IPreloan } from "../../core/interfaces/pre-loan.interface";

import { ProcessStatusEnum } from "../../core/enums/process-status.enum";

@Injectable({
  providedIn: "root",
})
export class ExtractorService {
  private executeId: string;
  private extractionAttempts = 0;

  constructor(
    private extractorRemoteService: ExtractorRemoteService,
    private idGeneratorService: IdGeneratorService,
  ) {}

  get isMaxAttempts() {
    return this.extractionAttempts > 1;
  }

  startExtraction(body: IExtractorRequest) {
    return this.extractorRemoteService.startExtraction(body);
  }

  getExtractionResult(executeId: string) {
    return this.extractorRemoteService.getExtractionResult(executeId);
  }

  pollingExtraction(advisor: IAdvisor, loan: IPreloan, customer: ICustomer) {
    this.executeId = this.idGeneratorService.getUuid();

    const body = this.buildBodyStartExtraction(advisor, loan, customer);

    return this.startExtraction(body).pipe(
      concatMap(() => {
        return this.getExtractionResult(this.executeId).pipe(
          repeatWhen((obs) => obs.pipe(delay(9000))),
          takeWhile((result) => result.statusCode === "RUNNING", true),
        );
      }),
      tap((result) => {
        if (this.hasStatusFail(result)) {
          this.increaseExtractionAttempts();
        }
        if (this.isMaxAttempts && this.hasStatusFail(result)) {
          result.response.processStatus = ProcessStatusEnum.ALLOW_UPLOAD;
          this.resetExtractionAttempts();
        }
      }),
      retry(1),
    );
  }

  increaseExtractionAttempts() {
    return (this.extractionAttempts += 1);
  }

  resetExtractionAttempts() {
    this.extractionAttempts = 0;
  }

  private buildBodyStartExtraction(
    advisor: IAdvisor,
    loan: IPreloan,
    customer: ICustomer,
  ): IExtractorRequest {
    return {
      data: {
        clientDocumentType: customer.documentType,
        clientDocumentNumber: String(customer.documentId),
        clientName: `${customer.name} ${customer.lastName}`,
        flowType: loan.loanFlow,
        payerNit: loan.payerNit,
        payerName: loan.payerUniqueName,
        sectorCode: String(loan.sectorNumber),
        advisorId: String(advisor.sellerIdNumber),
        executeId: this.executeId,
        journeyId: advisor.advisorJourneyId,
        obligationId: loan.obligationId,
        officeId: String(advisor.office.idoffice),
      },
      executeId: this.executeId,
    };
  }

  hasStatusFail(result: IExtractionResponse) {
    const processStatus = result.response.processStatus ?? result.statusCode;
    const fails = [
      ProcessStatusEnum.EXTRACT_FAIL,
      ProcessStatusEnum.EXTRACT_EXCEPTION,
      ProcessStatusEnum.INTERPRETER_FAIL,
      "FAILED",
    ];
    return fails.some((fail) => fail === processStatus);
  }

  getExecuteId(): string {
    return this.executeId;
  }
}
