<div id="main" class="d-flex flex-column justify-content-center">
  <form [formGroup]="formGroup">
    <app-document-upload-notification></app-document-upload-notification>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <adl-tab
          id="tabs-onbase-load"
          icon="./assets/images/onbase_load/information_success.svg"
          [title]="title"
          [tabs]="tabs"
          [initialIndex]="initialIndexTab"
          [description]="description"
          (emitSelectedTab)="selectedTab($event)"
        ></adl-tab>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85 list-document">
            <adl-form-file-list-document
              [documents]="tab.documents"
              [parentForm]="formGroup"
              (fileUploadEvent)="fileUpload($event)"
              (documentEvent)="setDocument($event)"
              (deleteDocumentEvent)="deleteDocument($event)"
              (fileExistEvent)="printfileErrorMessage($event)"
            >
            </adl-form-file-list-document>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center bottom-upload">
          <adl-button
            id="adl-button_button-upload-onbase"
            cssClass="buttonMedium buttonTextMedium  {{
              disabled ? 'buttonSilver' : ''
            }} "
            [mandatory]="true"
            [disabled]="disabled"
            [text]="'Enviar a aprobación'"
            [parentForm]="formGroup"
            (clickEvent)="uploadDocuments()"
          ></adl-button>
        </div>

        <div class="w-100 d-flex justify-content-center button-cancel">
          <adl-text-link
            id="cancel-button"
            text="Cancelar"
            (click)="goToDecision()"
          ></adl-text-link>
        </div>
      </div>
    </div>
  </form>
</div>

<adl-loading
  [show]="showLoading"
  [loadingCss]="loadingCss"
  [loadingDescription]="loadingDescription"
  [textLoading]="textLoading"
  [loadPercentage]="loadPercentage"
></adl-loading>
