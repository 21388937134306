import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-authentication-card-debit",
  template: "<app-two-column></app-two-column>",
})
export class AuthenticationCardDebitComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "twocolumn",
        {
          outlets: {
            "part-left": ["formauthenticationcarddebit"],
            "part-right": ["authenticationcarddebitimage"],
          },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
