import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-otp-image",
  templateUrl: "./otp-image.component.html",
  styleUrls: ["./otp-image.component.scss"],
})
export class OtpImageComponent implements OnInit {
  @Input() entry: boolean;

  ngOnInit() {
    this.entry = true;
  }
}
