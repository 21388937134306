<div class="animation-notification" *ngIf="isNotificationVisible">
  <adl-notification-message
    [isClose]="true"
    [title]="notificationTitle"
    severity="info"
    [message]="notificationDescription"
    (clickClose)="closeNotification()"
  ></adl-notification-message>
</div>
<div id="main" class="d-flex flex-column justify-content-center">
  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 content-message">
        <adl-content-message
          [icon]=""
          [title]="title"
          [description]="description"
        >
        </adl-content-message>

        <div class="h-20-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <adl-form-select
              [fieldControl]="formGroup.controls['textSector']"
              [valueControl]="formGroup.controls['valueSector']"
              [lengthInput]="'25'"
              [listSelect]="listSector"
              [parentForm]="formGroup"
              [text]="'Sector'"
              (selectItemEvent)="setSelectPayer()"
              id="app-form-select_sector-list"
            ></adl-form-select>
          </div>
        </div>

        <div class="h-20-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <adl-form-select
              [fieldControl]="formGroup.controls['textPayer']"
              [valueControl]="formGroup.controls['valuePayer']"
              [lengthInput]="'25'"
              [listSelect]="listPayer"
              [parentForm]="formGroup"
              [text]="'Pagaduría'"
              [disabled]="payerDisabled"
              (selectItemEvent)="canShowDoublePensionButton()"
              id="app-form-select_payer-list"
            ></adl-form-select>
          </div>
        </div>

        <div class="h-14-px"></div>

        <ng-container *ngIf="loanByPhoneCall && !unificationFlowActive">
          <div class="w-100 d-flex justify-content-center">
            <div class="w-85">
              <adl-form-warning
                [text]="
                  'Indícale al cliente que debe subir el desprendible de pago, envía el enlace a su correo electrónico y celular para subir el documento'
                "
                [title]="'¡Antes de continuar!'"
                [titleColor]="'#009a48'"
                [urlIcon]="'./assets/images/icons/essential-like.png'"
              ></adl-form-warning>
            </div>
          </div>
          <div
            class="w-100 d-flex justify-content-center text-center mt-1 mb-0"
            *ngIf="loan.showDoublePensionControl"
          >
            <div class="w-85">
              <adl-text-label
                [cssClass]="'font-weight-normal'"
                style="font-size: 14px;"
                [text]="'¿Tu cliente cuenta con doble pension?'"
              >
              </adl-text-label>
              <div class="d-flex justify-content-center">
                <adl-radio-group
                  [parentForm]="formGroup"
                  [nameRadioGroup]="'doublePensionRadioGroup'"
                  [radioButtonData]="radioButtonData"
                ></adl-radio-group>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="h-14-px"></div>

        <div
          *ngIf="!unificationFlowActive"
          class="w-100 d-flex flex-column px-5 py-3"
        >
          <adl-button
            id="adl-button_button-click"
            [cssClass]="'w-100 buttonTextMedium'"
            [loading]="loadingButton"
            [mandatory]="true"
            [parentForm]="formGroup"
            [text]="captionButton"
            (clickEvent)="continue()"
          ></adl-button>
        </div>

        <div
          class="w-100 d-flex flex-column px-5 py-1"
          *ngIf="unificationFlowActive"
        >
          <ng-container *ngIf="loan.showDoublePensionControl">
            <div class="w-100 d-flex justify-content-center text-center pb-4">
              <div class="w-85">
                <adl-text-label
                  [cssClass]="'font-weight-normal'"
                  style="font-size: 14px;"
                  [text]="'¿Tu cliente cuenta con doble pension?'"
                >
                </adl-text-label>
                <div class="d-flex justify-content-center">
                  <adl-radio-group
                    [parentForm]="formGroup"
                    [nameRadioGroup]="'doublePensionRadioGroup'"
                    [radioButtonData]="radioButtonData"
                  ></adl-radio-group>
                </div>
              </div>
            </div>
          </ng-container>

          <adl-button
            id="adl-button_button-upload"
            [cssClass]="'w-100 buttonTextMedium mb-2'"
            [loading]="loadingButton"
            [mandatory]="true"
            [parentForm]="formGroup"
            text="Subir documentos"
            (clickEvent)="onClickLoadDocuments()"
          ></adl-button>

          <div *ngIf="loanByPhoneCall">
            <adl-text-label
              [cssClass]="'font-weight-normal text-center '"
              style="font-size: 14px; line-height: 24px;"
              text="
              El cliente debe subir su desprendible de pago. Le enviaremos un
            enlace a su email y celular registrados dónde podrá cargarlo."
            >
            </adl-text-label>

            <adl-button
              id="adl-button_send_link"
              [cssClass]="'w-100 buttonTextOrange mt-0'"
              [mandatory]="true"
              [parentForm]="formGroup"
              text="Enviar enlace"
              (clickEvent)="onClickSendLink()"
            ></adl-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<adl-loading
  #loading
  [loadingCss]="loadingCss"
  [loadingDescription]="loadingDescription"
  [loadPercentage]="loadPercentage"
  [show]="showLoading"
></adl-loading>

<app-form-popup-upload-payment-receipt
  #formPopupUploadPaymentReceipt
  [isFromNewLoanComponent]="true"
  (resolveUploadDocumentsEvent)="salaryLoanResolve($event)"
></app-form-popup-upload-payment-receipt>

<app-popup-payrollcheck-upload-response
  #popupUploadResponse
  [message]="uploadResponse"
  [attemptMax]="3"
  [attempt]="attemptsUpload"
  (clickButtonOk)="retryUploadDocument()"
></app-popup-payrollcheck-upload-response>

<app-popup-document-upload-tips> </app-popup-document-upload-tips>

<app-form-popup-sygnus-link
  #formPopupSygnusLink
  (resolveSygnusEvent)="salaryLoanResolveSygnus($event)"
></app-form-popup-sygnus-link>
