import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-login-customer",
  template: "<app-two-column></app-two-column>",
})
export class LoginCustomerComponent implements OnInit {
  decision: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.decision = params.decision;
    });

    this.router.navigate(
      [
        "twocolumn",
        {
          outlets: {
            "part-left": ["formlogincustomer", this.decision],
            "part-right": ["loginimage"],
          },
        },
      ],
      { skipLocationChange: true },
    );
  }
}
