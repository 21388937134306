import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoanRemoteService {
  constructor(private httpClient: HttpClient) {}

  getCustomerLoans(
    documentType: string,
    documentId: number,
  ): Observable<object> {
    return this.httpClient.get(
      environment.serverUrlSalaryLoans +
        "accounts/customers/loans/" +
        documentType +
        "/" +
        documentId,
    );
  }

  getLoansStatus(
    documentType: string,
    documentId: number,
    isFirstCall: boolean,
  ): Observable<object> {
    const url =
      environment.serverUrlSalaryLoans +
      "accounts/customers/" +
      documentType +
      "/" +
      documentId +
      "/loans/status";

    if (isFirstCall) {
      environment.encryptUrls.push(url);
    }

    return this.httpClient.get(url);
  }

  validateAgeRestriction(payload) {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "customer-age",
      payload,
    );
  }
}
