import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { MenuRemoteService } from "../remote/menu-remote.service";

import { FormPopupViabilityQueryComponent } from "../../design/structures/form-popup-viability-query/form-popup-viability-query.component";

import { RepricingCampaignService } from "./repricing-campaign.service";
import { ToggleService } from "./toggle.service";

import { ToggleEnum } from "../../core/enums/toggle.enum";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  popupViability: FormPopupViabilityQueryComponent;

  constructor(
    private menuRemoteService: MenuRemoteService,
    private repricingService: RepricingCampaignService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  getMenu() {
    return this.menuRemoteService.getMenu().map((item) => {
      const requiredVisualization = item.paramsRequiredVisualization;
      item.visible = this.visibilityDecision(requiredVisualization);

      if (
        this.isRepricing(requiredVisualization?.repricing) ||
        this.isUnification(requiredVisualization?.unification) ||
        this.isRepricingV2(requiredVisualization?.repricingV2)
      ) {
        item.label =
          item.label === "Tramitar una libranza telefónica"
            ? "Tramitar una libranza"
            : item.label;
      }

      return item;
    });
  }

  getMenuTelephonic() {
    return this.menuRemoteService.getMenuTelephonic();
  }

  private visibilityDecision(requiredVisualization) {
    const isRepricing = this.isRepricing(requiredVisualization?.repricing)
      ? requiredVisualization?.repricing === this.repricingService.isEnable()
      : true;

    const isRepricingV2 = this.isRepricingV2(requiredVisualization?.repricingV2)
      ? requiredVisualization?.repricingV2 ===
        this.repricingService.isEnableV2()
      : true;

    const isUnification =
      requiredVisualization?.unification !== undefined &&
      this.isToggleEnabled(ToggleEnum.UNIFICATION_FLOW_TOOGLE)
        ? this.isUnification(requiredVisualization?.unification)
        : true;

    const toggleEnable = requiredVisualization?.toggleEnable
      ? this.isToggleEnabled(requiredVisualization?.toggleEnable)
      : true;

    return toggleEnable && isRepricing && isUnification && isRepricingV2;
  }

  private isToggleEnabled(toggleEnable) {
    return this.toggleService.getToggleEnabledById(toggleEnable as ToggleEnum);
  }
  private isRepricing(repricing) {
    return repricing !== undefined && this.repricingService.isEnable();
  }

  private isUnification(unification) {
    return (
      this.isToggleEnabled(ToggleEnum.UNIFICATION_FLOW_TOOGLE) === unification
    );
  }
  private isRepricingV2(repricingV2) {
    return repricingV2 !== undefined && this.repricingService.isEnableV2();
  }

  private get popupDecision() {
    return {
      SendViabilityQuery: this.popupViability,
    };
  }

  private getParams(decisionItem) {
    if (decisionItem.params.length) {
      return decisionItem.params.join("/");
    }
    return [];
  }

  private get queryParams() {
    return {
      SendStrongAuthenticationCustomerInfo: {
        r: this.toggleService.getToggleEnabledById(
          ToggleEnum.CERTIHUELLA_PHONE_FUNCTION,
        ),
        d: this.toggleService.getToggleEnabledById(
          ToggleEnum.DECRIM_PHONE_FUNCTION,
        ),
      },
    };
  }

  private getQueryParams(decisionItem) {
    if (decisionItem.queryParams) {
      decisionItem.queryParams = this.queryParams[decisionItem.decision];
      return { queryParams: decisionItem.queryParams };
    }
    return undefined;
  }

  select(decisionItem) {
    if (!decisionItem.route) {
      const popup = this.popupDecision[decisionItem.decision];
      popup.setShowPopUpViabilityQuery = true;
      return;
    }

    const extras = this.getQueryParams(decisionItem);
    const params = this.getParams(decisionItem);
    this.router.navigate([decisionItem.route, params], extras);
  }

  setPopupViability(popup: FormPopupViabilityQueryComponent) {
    this.popupViability = popup;
  }
}
