import { Pipe, PipeTransform } from "@angular/core";
import { ApplicationConstantsEnum } from "../enums/applications-constants.enum";

@Pipe({
  name: "phoneNumberObfuscate",
})
export class PhoneNumberObfuscatePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    let obfuscatedPrefix = "";

    if (
      !phoneNumber ||
      phoneNumber.length <= ApplicationConstantsEnum.OFUSCATE_PREFIX
    ) {
      return phoneNumber;
    }

    const suffix = phoneNumber.substring(
      phoneNumber.length - ApplicationConstantsEnum.OFUSCATE_PREFIX,
    );
    const prefixLength =
      phoneNumber.length - ApplicationConstantsEnum.OFUSCATE_PREFIX;

    for (let i = 0; i < prefixLength; i++) {
      obfuscatedPrefix += ApplicationConstantsEnum.OFUSCATE;
    }

    return obfuscatedPrefix + suffix;
  }
}
