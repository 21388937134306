<div *ngIf="popUpRnecWithCustomerInfo">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopupAndRedirect(false)"
      />
    </div>

    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img src="./assets/images/validate-identity.svg" alt="Icon Rnec" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 text-center">
        <adl-text-description [text]="description"></adl-text-description>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 h-90-px text-justify">
        <adl-form-field
          [lengthInput]="'12'"
          [fieldControl]="formGroup.controls['textRnecCode']"
          [parentForm]="formGroup"
          [text]="'Código RNEC'"
          [textError]="'Código no válido'"
          id="app-form-field_rnec_code"
        ></adl-form-field>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center m-b-5-px">
      <div class="w-85 h-70-px text-justify">
        <adl-form-select
          [fieldControl]="formGroup.controls['textDocumentType']"
          [valueControl]="formGroup.controls['valueDocumentType']"
          [lengthInput]="'25'"
          [listSelect]="listDocumentType"
          [parentForm]="formGroup"
          [text]="'Tipo de documento'"
          id="app-form-select_advisor-login-offices"
        ></adl-form-select>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center m-b-5-px">
      <div class="w-85 h-70-px text-justify">
        <adl-form-field
          [lengthInput]="'15'"
          [fieldControl]="formGroup.controls['textDocumentCustomer']"
          [parentForm]="formGroup"
          [text]="'Número de documento'"
          id="app-form-field_customer-login-document"
          appOnlyNumber
        ></adl-form-field>
      </div>
    </div>

    <div class="w-100 h-75-px d-flex justify-content-center">
      <div class="w-85 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <adl-button
            [cssClass]="'w-100 buttonTextMedium'"
            [loading]="loadingButtonSendCodeRnec"
            [mandatory]="true"
            [parentForm]="formGroup"
            [text]="captionButton"
            (click)="closePopupAndRedirect(true)"
            id="adl-button_button-send-code-rnec"
          ></adl-button>
        </div>
      </div>
    </div>
  </form>
</div>
