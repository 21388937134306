import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InterceptorService } from "@avaldigitallabs/bpop-security-frontend-lib";
import { Observable } from "rxjs";
import { tap } from "rxjs/internal/operators";

import { environment } from "../../../environments/environment";

import { AdvisorService } from "../../services/local/advisor.service";
import { LoggerService } from "../../services/local/logger-service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private advisorService: AdvisorService,
    private interceptorService: InterceptorService,
    private loggerService: LoggerService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.loggerService.setHttpPreRequest(req);
    if (this.shouldEncryptUrl(req)) {
      return this.interceptorService
        .handlerInterceptor(this.buildAdditionalParams(req), next)
        .pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) {
                this.loggerService.setHttpResponse(event);
              }
            },
          }),
        );
    } else {
      return next.handle(this.buildAdditionalParams(req)).pipe(
        tap(
          (event: any) => {
            if (event instanceof HttpResponse) {
              this.loggerService.setHttpResponse(event);
              if (event.status === 206) {
                // eslint-disable-next-line no-console
                console.log(event.status);
              }
            }
          },
          (error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401 || error.status === 403) {
                // eslint-disable-next-line no-console
                console.log(error.status);
              }
            }
          },
        ),
      );
    }
  }

  private buildAdditionalParams(req: HttpRequest<any>): any {
    if (!req.headers.get("X-Amz-Security-Token")) {
      let accessToken = "";
      const advisorInfo = this.advisorService.getAdvisor();

      if (req.url.includes("/oauth2/token")) {
        return req.clone({
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        });
      }

      if (advisorInfo && advisorInfo.accessToken) {
        accessToken = advisorInfo.accessToken.replace(",", "");
      }

      if (req.url.substring(0, 35) === "https://s3.us-east-2.amazonaws.com/") {
        return req;
      } else {
        if (req.headers.get("Authorization")) {
          return req;
        }

        return req.clone({
          headers: req.headers.append("Authorization", accessToken),
        });
      }
    } else {
      return req;
    }
  }

  private shouldEncryptUrl(req: HttpRequest<any>): boolean {
    return (
      environment.encryptUrls.filter(
        (encryptUrl: any) => encryptUrl === req.url,
      ).length !== 0
    );
  }
}
