import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ICustomer } from "../../../core/interfaces/customer.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";

@Component({
  selector: "app-close-session",
  templateUrl: "./close-session.component.html",
  styleUrls: ["./close-session.component.scss"],
})
export class CloseSessionComponent implements OnInit {
  authenticationDone: boolean;
  customer: ICustomer;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customer = this.customerService.getCustomer();
    this.authenticationDone = this.customer
      ? this.customer.authenticationDone
      : false;
  }

  closeSession() {
    this.advisorService.setAdvisor(undefined);
    this.customerService.setCustomer(undefined);
    this.router.navigate(["loginadvisor"]);
  }
}
