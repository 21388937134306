import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMimeTypeConstantsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { environment } from "../../../environments/environment";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { ChannelTypeEnum } from "../../core/enums/channel-type.enum";
import { FileExtensionEnum } from "../../core/enums/file-extension.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";

import { AwsSignatureRemoteService } from "./aws-signature-remote.service";

@Injectable({
  providedIn: "root",
})
export class FileManagerRemoteService {
  constructor(
    private httpClient: HttpClient,
    private awsSignatureRemoteService: AwsSignatureRemoteService,
  ) {}

  getSignedUrl(advisor: IAdvisor, fileName: string, channel: ChannelTypeEnum) {
    const headers = this.awsSignatureRemoteService.setHeaders(
      advisor,
      environment.awsCognitoData.hostBackendSecurity,
      "GET",
      `/security-manager/file/url/${fileName}/${channel}`,
      "application/x-www-form-urlencoded",
    );

    return this.httpClient.get(
      environment.serverUrlBackendSecurity + `file/url/${fileName}/${channel}`,
      { headers },
    );
  }

  uploadFile(
    advisor: IAdvisor,
    fileName: string,
    fileType: string,
    file: File,
    channel: ChannelTypeEnum,
  ) {
    return this.getSignedUrl(advisor, fileName, channel).pipe(
      mergeMap((data: any) => this.putFile(data.url, file, fileType)),
    );
  }

  putFileWithProgress(
    fileUrl: string,
    file: File,
    fileType: string,
  ): Observable<number> {
    const fileBytes = new Blob([file]);
    const contentType = (fileType === FileExtensionEnum.PDF
      ? HttpMimeTypeConstantsEnum.APPLICATION_PDF
      : ApplicationConstantsEnum.IMAGE_JPEG) as string;

    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", contentType);

    const httpRequest = new HttpRequest("PUT", fileUrl, fileBytes, {
      headers,
      reportProgress: true,
    });

    return this.httpClient.request(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          return Math.round(100 * (event.loaded / event.total));
        } else {
          return 0;
        }
      }),
      catchError((error) => {
        return throwError([]);
      }),
    );
  }

  uploadOnbaseDocumentsFile(
    advisor: IAdvisor,
    fileName: string,
    factoryFileId: string,
    numberOfCopy: number,
  ) {
    return this.getOnbaseUploadDocumentsUrl(
      advisor,
      fileName,
      numberOfCopy,
      factoryFileId,
    ).pipe(
      mergeMap((data: any) =>
        of({
          url: data.url,
          urlDate: data.urlDate,
        }),
      ),
    );
  }

  private getOnbaseUploadDocumentsUrl(
    advisor: IAdvisor,
    fileName: string,
    numberOfCopy: number,
    factoryFileId: string,
  ) {
    const headers = this.awsSignatureRemoteService.setHeaders(
      advisor,
      environment.awsCognitoData.hostBackendSecurity,
      "GET",
      `/security-manager/file/url/onbase/${factoryFileId}/${fileName}/${numberOfCopy}`,
      "application/x-www-form-urlencoded",
    );

    return this.httpClient.get(
      environment.serverUrlBackendSecurity +
        `file/url/onbase/${factoryFileId}/${fileName}/${numberOfCopy}`,
      { headers },
    );
  }

  private putFile(fileUrl: string, file: File, fileType: string) {
    const fileBytes = new Blob([file]);
    const contentType = (fileType === FileExtensionEnum.PDF
      ? HttpMimeTypeConstantsEnum.APPLICATION_PDF
      : ApplicationConstantsEnum.IMAGE_JPEG) as string;

    return this.httpClient.put(fileUrl, fileBytes, {
      headers: { "Content-Type": contentType },
    });
  }
}
