import { Router } from "@angular/router";

import { IMessage } from "../../../core/interfaces/message.interface";
import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { SalesProcessTypeEnum } from "../../../core/enums/sales-process-type.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../advisor.service";
import { CustomerService } from "../customer.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { LoanService } from "../loan.service";
import { MessageService } from "../message.service";
import { PayerService } from "../payer.service";
import { PayrollService } from "../payroll.service";
import { RedirectService } from "../redirect.service";
import { SalaryLoanActionFactoryService } from "./salary-loan-action-factory.service";
import { ToggleService } from "../toggle.service";

import { FormPopupSygnusLinkComponent } from "../../../design/structures/form-popup-sygnus-link/form-popup-sygnus-link.component";

export class SalaryLoanActionSygnus implements ISalaryLoanActionFactory {
  formPopupSygnusLink: FormPopupSygnusLinkComponent;
  private loan: any;
  private factory: any;
  isPaystubsSygnusRequired = false;
  private valueBindings: string;
  readonly singleBindingList = 1;

  constructor(
    private advisorService: AdvisorService,
    private eventAnalyticsService: EventAnalyticsService,
    formPopupSygnusLink: FormPopupSygnusLinkComponent,
    private loanService: LoanService,
    private messageService: MessageService,
    private payerService: PayerService,
    private redirectService: RedirectService,
    private router: Router,
    private customerService: CustomerService,
    private form: any,
    factory: SalaryLoanActionFactoryService,
    private toggleService: ToggleService,
    private payrollService: PayrollService,
  ) {
    this.formPopupSygnusLink = formPopupSygnusLink;
    this.loan = this.loanService.getPreloan();
    this.factory = factory;
    this.valueBindings = null;
    this.isPaystubsSygnusRequired = this.toggleService.getToggleEnabledById(
      ToggleEnum.PAYSTUBS_SYGNUS_REQUIRED,
    );
  }

  get advisor() {
    return this.advisorService.getAdvisor();
  }

  salaryLoanAction(): void {
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      this.loanService.getPayerName(),
    );
    this.initFormPopupSygnusLink();
  }

  salaryLoanResolve(event?: any) {
    if (event === MessageActionEnum.CHOOSE_OTHER_BINDING_SYGNUS) {
      this.setInitialMessage();
    } else if (event === MessageActionEnum.PROCESS_SYGNUS) {
      this.prosessSygnus(null);
    } else if (event === MessageActionEnum.PROCESS_SYGNUS_WITH_PAYROLL) {
      const providerId = this.payrollService.getProvidersId()[0];
      this.prosessSygnus(providerId);
    } else {
      this.sendSygnusLink();
    }
  }

  private initFormPopupSygnusLink() {
    this.payerService.getBindingsPlatform().subscribe(
      (dataBindings: any) => {
        this.setInitialMessage();
        this.formPopupSygnusLink.listBindings = dataBindings;
        if (
          this.isPaystubsSygnusRequired &&
          dataBindings.length === this.singleBindingList
        ) {
          this.formPopupSygnusLink.presetValues(dataBindings[0].text);
          this.valueBindings = dataBindings[0].value;
          this.prosessSygnus(null);
        } else {
          this.formPopupSygnusLink.setShowPopUpSygnusLink = true;
        }
      },
      (errorBindings: any) => {
        if (errorBindings) {
          this.formPopupSygnusLink.setMessage = errorBindings;
          this.formPopupSygnusLink.setShowPopUpSygnusLink = true;
          this.formPopupSygnusLink.clearTextBindingsValidator();
        } else {
          this.router.navigate(["overflowerror"]);
        }
      },
    );
  }

  private prosessSygnus(payrollCheckId: string) {
    if (this.isPaystubsSygnusRequired && payrollCheckId === null) {
      this.redirectSygnusToPayroll();
    } else {
      this.makeOfferSygnus(payrollCheckId);
    }
  }

  private redirectSygnusToPayroll() {
    this.loanService.setPlatform("Sygnus");
    this.formPopupSygnusLink.setShowPopUpSygnusLink = false;
    this.factory.previousAction = this.factory.salaryLoanActionFactory;
    this.factory.salaryLoanAction(this.form, LoanFlowEnum.ANYFEE);
  }

  private makeOfferSygnus(payrollCheckId: string) {
    if (this.isPaystubsSygnusRequired) {
      const loadingMessage: IMessage = this.messageService.getMessageById(
        MessageEnum.LOADING_INFORMATION,
      );
      if (loadingMessage) {
        this.formPopupSygnusLink.setMessage = loadingMessage;
        this.formPopupSygnusLink.setShowPopUpSygnusLink = true;
      }
    }
    this.formPopupSygnusLink.loadingButtonSendSygnusLink = true;
    let finalBindings =
      this.valueBindings === null
        ? this.formPopupSygnusLink.formGroup.controls.valueBindings.value.value
        : this.valueBindings;
    this.payerService
      .generatePlatformOffer(finalBindings, payrollCheckId)
      .subscribe(
        () => {
          this.loanService.setLoanFlow(LoanFlowEnum.ANYFEE);
          this.loanService.setSalesProcessType(
            this.advisorService.isLoanByPhoneCall()
              ? SalesProcessTypeEnum.TELEFONIC_SALE_TC
              : SalesProcessTypeEnum.PRESENTIAL_SALE_TC,
          );
          this.redirectService.redirectToFrontOldToPep();
        },
        (errorPlatformOffer: any) => {
          this.formPopupSygnusLink.loadingButtonSendSygnusLink = false;
          if (errorPlatformOffer) {
            this.formPopupSygnusLink.setMessage = errorPlatformOffer;
          } else {
            this.router.navigate(["overflowerror"]);
          }
        },
      );
  }

  private sendSygnusLink() {
    window.open(
      this.loanService.getPreloan().platformAuthorizationUrl,
      "_blank",
    );
  }

  private setInitialMessage() {
    this.formPopupSygnusLink.setMessage = this.messageService.getMessageById(
      MessageEnum.FORM_POPUP_SYGNUS_LINK_CONFIGURATION,
    );
  }
}
