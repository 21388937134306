import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  INavPill,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { AdvisorTypeEnum } from "../../../core/enums/advisor-type.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";
import { ValidationMethodEnum } from "../../../core/enums/validation-method.enum";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";
import { IMessage } from "../../../core/interfaces/message.interface";
import { IPreloan } from "../../../core/interfaces/pre-loan.interface";
import { IResponseValidateDecrimQuery } from "../../../core/interfaces/response-validate-decrim-query.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { RedirectService } from "../../../services/local/redirect.service";
import { ToggleService } from "../../../services/local/toggle.service";

import { FormPopupMessageBoxComponent } from "../form-popup-message-box/form-popup-message-box.component";

@Component({
  selector: "app-validation-method-view",
  templateUrl: "./validation-method-view.component.html",
  styleUrls: ["./validation-method-view.component.scss"],
})
export class ValidationMethodViewComponent implements OnInit {
  @ViewChild(FormPopupMessageBoxComponent, { static: true })
  formPopupMessageBoxComponent: FormPopupMessageBoxComponent;

  advisor: IAdvisor;
  advisorType: AdvisorTypeEnum;
  captionButton: string;
  customer: ICustomer;
  customerInfo: any;
  dataValidationQueryDecrim: IResponseValidateDecrimQuery[] | MessageEnum;
  decision: string;
  decisionStrongAuthentication: any;
  description: string;
  formGroup: UntypedFormGroup;
  icon: string;
  isWithCustomerInfo = false;
  messageEnum: MessageEnum;
  pills: INavPill[];
  popUpDecrim: boolean;
  popUpDecrimWithCustomerInfo: boolean;
  popUpRnec: boolean;
  popUpRnecWithCustomerInfo: boolean;
  preloan: IPreloan;
  showPopUpValidationQueryDecrim: boolean;
  title: string;

  private casesMessage: { [x: string]: () => void };
  private message: IMessage;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private initialNavPillsService: InitialNavPillsService,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
    private router: Router,
    private toggleService: ToggleService,
  ) {
    this.customerInfo = this.customerService.getCustomerInfo();
  }

  ngOnInit() {
    this.addPills();
    this.initForm();
  }

  initForm() {
    this.casesMessage = {
      [MessageEnum.DECRIM_CASE_AUTHORIZED]: () => {
        this.navigateToDecision();
      },
      [MessageEnum.RNEC_CASE_AUTHORIZED]: () => {
        this.navigateToLoginAdvisor();
      },
      [MessageEnum.SecurityManager0021_Decrim]: () => {
        this.navigateToDecision();
      },
    };

    this.description =
      "Selecciona como quieres validar la identidad de tu cliente.";
    this.icon = "./assets/images/decision/icon-validation.svg";
    this.title = "Método de validación";
    this.captionButton = "Continuar";
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl("valuePill", new UntypedFormControl());
  }

  goToValidation() {
    switch (this.formGroup.controls.valuePill.value) {
      case ValidationMethodEnum.CERTIHUELLA:
        this.popUpRnec = true;
        break;
      case ValidationMethodEnum.CERTIHUELLA_WITH_CUSTOMER_INFO:
        this.popUpRnecWithCustomerInfo = true;
        break;
      case ValidationMethodEnum.DECRIM:
        this.popUpDecrim = true;
        this.messageEnum = MessageEnum.INFO_UPLOAD_FILE_DECRIM;
        break;
      case ValidationMethodEnum.DECRIM_WITH_CUSTOMER_INFO:
        this.popUpDecrimWithCustomerInfo = true;
        break;
    }
  }

  closeMessageBox() {
    this.formPopupMessageBoxComponent.setShowMessageBox = false;
    if (this.casesMessage[this.message.messageId]) {
      this.casesMessage[this.message.messageId]();
    }
  }

  async closePopupDecrim(redirect: boolean) {
    this.popUpDecrim = false;

    if (redirect) {
      this.redirectService.redirectByCustomerType();
    }
  }

  closePopupDecrimWithCustomerInfo(
    message?: IResponseValidateDecrimQuery[] | MessageEnum,
  ) {
    this.popUpDecrimWithCustomerInfo = false;

    if (message) {
      this.dataValidationQueryDecrim = message;
      this.showPopUpValidationQueryDecrim = true;
    }
  }

  closePopupFileDecrimWithCustomerInfo(message: MessageEnum) {
    this.popUpDecrim = false;
    this.showMessagePopUp(message);
  }

  async closePopupRnec(redirect: boolean) {
    this.popUpRnec = false;

    if (redirect) {
      this.redirectService.redirectByCustomerType();
    }
  }

  closePopupRnecWithCustomerInfo(messageEnum?: MessageEnum) {
    this.popUpRnecWithCustomerInfo = false;

    this.showMessagePopUp(messageEnum);
  }

  closePopupValidationQueryDecrim(messageEnum?: MessageEnum) {
    this.showPopUpValidationQueryDecrim = false;
    if (MessageEnum.INFO_UPLOAD_FILE_DECRIM === messageEnum) {
      this.isWithCustomerInfo = true;
      this.messageEnum = MessageEnum.INFO_UPLOAD_FILE_DECRIM;
      this.popUpDecrim = true;
      return;
    }
    if (MessageEnum.CLIENT_HAVE_PENDING_ONBASE_SALES === messageEnum) {
      this.navigateToQueryUploadDocument();
      return;
    }
    if (MessageEnum.CLIENT_HAVE_SUCCESS_AUTHENTICATION_SALES === messageEnum) {
      this.navigateToDecision();
    }
  }

  private navigateToDecision(): void {
    this.router.navigate(["decision"]);
  }

  private navigateToQueryUploadDocument(): void {
    this.router.navigate(["queryuploaddocument"]);
  }

  private navigateToLoginAdvisor(): void {
    this.router.navigate(["loginadvisor"]);
  }

  private addPills() {
    this.advisor = this.advisorService.getAdvisor();
    this.decisionStrongAuthentication = {
      rnec: this.route.snapshot.queryParams.r === "true",
      decrim: this.route.snapshot.queryParams.d === "true",
    };

    if (
      this.decisionStrongAuthentication.rnec ||
      this.decisionStrongAuthentication.decrim
    ) {
      this.pills = this.initialNavPillsService.getInitialNavPills(
        this.decisionStrongAuthentication.rnec &&
          this.decisionStrongAuthentication.decrim
          ? InitialNavPillsEnum.VALIDATION_METHOD_WITH_CUSTOMER_INFO
          : this.decisionStrongAuthentication.rnec
          ? InitialNavPillsEnum.VALIDATION_METHOD_RNEC_WITH_CUSTOMER_INFO
          : InitialNavPillsEnum.VALIDATION_METHOD_DECRIM_WITH_CUSTOMER_INFO,
      );
    } else {
      this.pills = this.initialNavPillsService.getInitialNavPills(
        this.advisor.advisorType === AdvisorTypeEnum.OFFICE_SALES &&
          this.toggleService.getToggleEnabledById(
            ToggleEnum.CERTIHUELLA_FUNCTION,
          )
          ? InitialNavPillsEnum.VALIDATION_METHOD
          : InitialNavPillsEnum.VALIDATION_METHOD_DECRIM,
      );
    }
  }

  private showMessagePopUp(messageEnum?: MessageEnum) {
    if (messageEnum) {
      this.formPopupMessageBoxComponent.setMessageEnum = messageEnum;
      this.formPopupMessageBoxComponent.setShowMessageBox = true;
      this.message = this.formPopupMessageBoxComponent.message;
    }
  }
}
