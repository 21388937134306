import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { LoanStatusEnum } from "../../../core/enums/loan-status.enum";
import { QueryUploadDocumentsTypeEnum } from "../../../core/enums/query-upload-documents-type.enum";
import { TableTypeEnum } from "../../../core/enums/table-type.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { OnbaseLoadService } from "../../../services/local/onbase-load.service";

@Component({
  selector: "app-form-query-upload-documents",
  styleUrls: ["./form-query-upload-documents.component.scss"],
  templateUrl: "./form-query-upload-documents.component.html",
})
export class FormQueryUploadDocumentsComponent implements OnInit {
  set tableTypeEnum(tableTypeEnum) {}
  get tableTypeEnum() {
    return TableTypeEnum;
  }

  documentStatus = LoanStatusEnum;
  advisor: IAdvisor;
  descriptionTable1: string;
  descriptionTable2: string;
  error: any;
  isSearch: boolean = false;
  loadingCss: string;
  loadingDescription: string;
  loadPercentage: number;
  showLoading: boolean;
  textLoading: string;
  tittle: string;

  arrColorColumn: any;
  clientDocuments: any;
  clientDocumentsSearch: any = [];
  currentfactoryPayersPreload: any;
  uploadDocumentsList: any;

  constructor(
    private advisorService: AdvisorService,
    private onbaseLoadService: OnbaseLoadService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.showLoading = true;
    this.advisor = this.advisorService.getAdvisor();
    this.arrColorColumn = this.onbaseLoadService.getOnbaseLoadDocumentStates();
    this.descriptionTable1 = "Clientes pendientes por cargue de documentación";
    this.descriptionTable2 =
      "Puedes consultar el proceso de los clientes que ya cuentan con documentación cargada";

    this.initCurrentPayerLoanDocuments();
    this.searchDocumentsOnload();
  }

  initCurrentPayerLoanDocuments() {
    this.onbaseLoadService.currentfactoryPayersPreload.subscribe(
      (currentfactoryPayersPreload) => {
        this.currentfactoryPayersPreload = currentfactoryPayersPreload;
      },
    );
  }

  searchDocumentsOnload() {
    this.onbaseLoadService
      .getConsultDocumentsOnbaseLoad(this.advisor.sellerIdNumber)
      .subscribe(
        (data: any) => {
          this.uploadDocumentsList = this.findDocumentsCharge(data.documents);
          this.searchDocumentsStateOnload();
        },
        (error: any) =>
          // eslint-disable-next-line no-console
          console.log(
            `FormQueryUploadDocumentsComponent - searchDocumentsOnload -  Error`,
            JSON.stringify(error),
          ),
      );
  }

  onClickContinue(document: any, isSearch: boolean) {
    let documentList = this.uploadDocumentsList;
    let documentType = document[QueryUploadDocumentsTypeEnum.DOCUMENT_TYPE];
    let documentNumber = document[QueryUploadDocumentsTypeEnum.DOCUMENT_NUMBER];

    if (isSearch) {
      documentList = this.clientDocumentsSearch;
      documentType = document.documentType;
      documentNumber = document.documentNumber;
    }

    const customer = this.findCustomer(
      documentType,
      documentNumber,
      documentList,
    );

    this.onbaseLoadService.setClientDocumentsUpload({
      ...customer[0],
      payerSelected: document.payer || null,
    });

    let service = this.onbaseLoadService.getSearchDocumentsUploadClient(
      customer[0].documentType,
      customer[0].documentNumber,
    );

    if (this.statusFailedToUpload().includes(document.statusId)) {
      service = this.onbaseLoadService.getSearchDocumentsFailedUploadClient(
        document.factoryFileId,
      );
    }
    service.subscribe(
      () => {
        this.router.navigate(["uploaddocumentsonbase"]);
      },
      (error: any) => (this.error = error),
    );
  }

  onClickContinueStates(document: any) {
    if (
      document.data[QueryUploadDocumentsTypeEnum.STATE].includes(
        UploadDocumentsStatesEnum.RELOAD_DOCUMENTS,
      ) ||
      document.data[QueryUploadDocumentsTypeEnum.STATE].includes(
        UploadDocumentsStatesEnum.VALIDATE_INCORRECT,
      )
    ) {
      const customer = this.clientDocuments.find((item: any) => {
        return (
          item.salaryLoans[QueryUploadDocumentsTypeEnum.CUSTOMER_FILTER]
            .factoryFileId ===
          document.dataList[QueryUploadDocumentsTypeEnum.CUSTOMER_FILTER]
            .factoryFileId
        );
      });

      this.onbaseLoadService.setClientDocumentsUpload(customer);

      this.onbaseLoadService
        .getSearchDocumentsFailedUploadClient(
          document.dataList[QueryUploadDocumentsTypeEnum.CUSTOMER_FILTER]
            .factoryFileId,
        )
        .subscribe(
          () => {
            this.router.navigate(["uploaddocumentsonbase"]);
          },
          (error: any) => (this.error = error),
        );
    }
  }

  onClickCustomerDocumentsFinder(documentNumber: any) {
    this.onbaseLoadService.getCustomerDocumentFinder(documentNumber).subscribe(
      (data: any) => {
        this.isSearch = true;
        data.userLoans = this.changeNameLoanDocuments(data.userLoans);
        this.clientDocumentsSearch = this.findDocumentsCharge(data.userLoans);

        this.clientDocumentsSearch[0]?.salaryLoans.forEach((loan: any) => {
          loan.canAllowUpload = this.canAllowUpload(loan.statusId);
        });
      },
      (error: any) => (this.error = error),
    );
  }

  onChangeCustomerDocument() {
    this.clientDocumentsSearch = null;
    this.isSearch = false;
  }

  searchDocumentsStateOnload() {
    this.onbaseLoadService
      .getClientConsultDocumentStateList(this.advisor.sellerIdNumber)
      .subscribe(
        (data: any) => {
          this.onbaseLoadService.setDeleteDocumentLoads(data.documents);
          this.clientDocuments = this.findDocumentsCharge(data.documents);
          this.clientDocuments = this.clientDocuments.map((item) => {
            if (item.documentStatus === "Envío a fábrica") {
              item.documentStatus = "Proceso exitoso";
            }
            return item;
          });
          this.showLoading = false;
        },
        (error: any) =>
          // eslint-disable-next-line no-console
          console.log(
            `FormQueryUploadDocumentsComponent - searchDocumentsStateOnload -  Error`,
            JSON.stringify(error),
          ),
      );
  }

  findDocumentsCharge(documentList: any) {
    if (this.currentfactoryPayersPreload) {
      for (const current of this.currentfactoryPayersPreload) {
        documentList = documentList.map((item: any) => {
          const indexFactoryFile = item.salaryLoans.findIndex(
            (index: any) => index.factoryFileId === current.factoryFileId,
          );

          if (indexFactoryFile > ApplicationConstantsEnum.INDEX_EMPTY) {
            item.salaryLoans.splice(indexFactoryFile, 1);
          }
          return item;
        });
      }
    }

    return documentList.filter((item: any) => item.salaryLoans.length > 0);
  }

  private canAllowUpload(statusId: any) {
    const statusFailedToUpload = this.statusFailedToUpload();
    return (
      statusFailedToUpload.includes(statusId) ||
      statusId === LoanStatusEnum.DOCUMENTS_TO_LOAD
    );
  }

  private statusFailedToUpload() {
    return [LoanStatusEnum.VALIDATE_INCORRECT, LoanStatusEnum.RELOAD_DOCUMENTS];
  }

  private findCustomer(
    documentType: string,
    documentNumber: string,
    documents: any,
  ) {
    return documents.filter(
      (customer: any) =>
        customer.documentType === documentType &&
        customer.documentNumber === documentNumber,
    );
  }

  private changeNameLoanDocuments(documentList: any) {
    for (const document of documentList) {
      document.salaryLoans = document.loanDocuments;
      delete document.loanDocuments;
    }
    return documentList;
  }
}
