import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  INavPill,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { GoogleRecaptchaService } from "../../../services/local/google-recaptcha.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { OtpService } from "../../../services/local/otp.service";
import { ToggleService } from "src/app/services/local/toggle.service";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ToggleEnum } from "src/app/core/enums/toggle.enum";

import { PhoneNumberObfuscatePipe } from "../../../core/pipes/phone-number-obfuscate.pipe";

import { TealiumUtagService } from "../../../tealium/utag.service";

@Component({
  selector: "app-form-authentication-code",
  styleUrls: ["./form-authentication-code.component.scss"],
  templateUrl: "./form-authentication-code.component.html",
})
export class FormAuthenticationCodeComponent implements OnInit, AfterViewInit {
  cellPhone: string;
  description: string;
  editCellPhone: boolean;
  editEmail: boolean;
  isAdvisorDecisionSendViabilityQuery: boolean;
  loadingButtonGenerate: boolean;
  showMessageBox: boolean;
  title: string;

  advisor: IAdvisor;
  decision = AdvisorDecisionTypeEnum;
  customer: ICustomer;
  formGroup: UntypedFormGroup;
  messageEnum: MessageEnum;
  pills: INavPill[];
  unificationFlowActive: boolean;
  isPreviabilitySimulatorFlow: boolean;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private googleRecaptchaService: GoogleRecaptchaService,
    private initialNavPillsService: InitialNavPillsService,
    private otpService: OtpService,
    private router: Router,
    private toggleService: ToggleService,
    private tealiumUtagService: TealiumUtagService,
  ) {
    this.unificationFlowActive = this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_GENERATE_OTP_INIT_PAGE,
    );

    this.advisor = this.advisorService.getAdvisor();
    this.customer = this.customerService.getCustomer();

    this.addPills();
    this.initForm();

    this.isPreviabilitySimulatorFlow = this.checkIfPreviabilitySimulatorFlow();
  }

  private checkIfPreviabilitySimulatorFlow(): boolean {
    return this.advisorService.isAdvisorDecisionSendViabilityQuery();
  }

  ngAfterViewInit(): void {
    this.tealiumUtagService.view({
      tealium_event: "page_view",
      page_path: document.location.pathname,
    });
  }

  closeMessageBox() {
    this.showMessageBox = false;
  }

  editTextCellPhone() {
    this.editCellPhone =
      this.advisor.decisionType ===
        AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL ||
      this.unificationFlowActive;

    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "autenticacion_otp",
      event_label: "edit_celular",
    });

    this.formGroup.addControl(
      "textCellPhone",
      new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
    );
  }

  editTextEmail() {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    this.editEmail = true;

    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "autenticacion_otp",
      event_label: "edit_correo",
    });

    this.formGroup.addControl(
      "textEmail",
      new UntypedFormControl("", Validators.pattern(emailPattern)),
    );
  }

  async generateCodeOtp() {
    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "autenticacion_otp",
      event_label: "continuar",
    });

    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE,
    );

    this.loadingButtonGenerate = true;
    this.googleRecaptchaService
      .generateTokenRecaptcha()
      .subscribe((token: any) => {
        this.updateCellphoneAndEmail()
          .then(() => {
            const decisionType =
              this.advisor.decisionType ===
              AdvisorDecisionTypeEnum.SEND_VIABILITY_QUERY
                ? this.customer.cellphone
                : undefined;

            this.otpService.generateOtp({
              advisorDecisionType: this.advisor.decisionType,
              advisorDocumentNumber: this.advisor.sellerIdNumber.toString(),
              advisorJourneyId: this.advisor.advisorJourneyId,
              cellphoneNumber: this.editCellPhone
                ? this.formGroup.controls.textCellPhone.value
                : decisionType,
              documentNumber: this.customer.documentId.toString(),
              documentType: this.customer.documentType,
              email: this.editEmail
                ? this.formGroup.controls.textEmail.value
                : undefined,
              officeId: this.advisor.office.idoffice.toString(),
              officeName: this.advisor.office.name_office,
              recaptchaResponse: token,
            });
          })
          .then(() => {
            this.router.navigate(["otpauthentication"]);
          })
          .catch(() => this.generateCodeOtpError());
      });
  }

  generateCodeOtpError() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE_ERROR,
    );

    this.loadingButtonGenerate = false;
    this.showMessageBox = true;
    this.messageEnum = MessageEnum.ERROR_GENERATE_OTP;
  }

  initForm() {
    this.isAdvisorDecisionSendViabilityQuery = this.advisorService.isAdvisorDecisionSendViabilityQuery();
    this.title = this.isAdvisorDecisionSendViabilityQuery
      ? "Método de autenticación"
      : "¡Listo " +
        this.customer.name.trim() +
        (" " + this.customer.lastName.trim()) +
        "!";
    this.description = "Ahora selecciona como quieres autenticar tus datos";
    this.cellPhone = new PhoneNumberObfuscatePipe().transform(
      this.customer.cellphone,
    );

    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl("valuePill", new UntypedFormControl());
  }

  noEditTextCellPhone() {
    this.editCellPhone = false;
    this.formGroup.removeControl("textCellPhone");
  }

  noEditTextEmail() {
    this.editEmail = false;
    this.formGroup.removeControl("textEmail");
  }

  showButtonEditCellPhoneOrEmail(): string {
    return this.advisor.decisionType ===
      AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL ||
      this.unificationFlowActive
      ? ApplicationConstantsEnum.COL_10
      : ApplicationConstantsEnum.COL_12;
  }

  async updateCellphoneAndEmail() {
    if (this.editCellPhone) {
      const newCellphone = this.formGroup.controls.textCellPhone.value;

      if (!this.isPreviabilitySimulatorFlow) {
        await this.customerService.setCustomerCellphone(newCellphone);
      } else {
        this.customerService.getCustomer().cellphone = newCellphone;
      }
    }

    if (this.editEmail) {
      await this.customerService.setCustomerEmail(
        this.formGroup.controls.textEmail.value,
      );
    }
  }

  private addPills() {
    this.pills = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.AUTHENTICATION_CODE,
    );
  }
}
