import { Injectable } from "@angular/core";

import { IAccountCustomer } from "../../core/interfaces/account-customer.interface";

import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";

import { AccountCustomerRemoteService } from "../remote/account-customer-remote.service";
import { OverflowErrorsService } from "./overflow-errors.service";

const BASE_NUMBER_ACCOUNTS = Number(OverflowErrorsEnum.BASE_NUMBER_ACCOUNTS);

@Injectable({
  providedIn: "root",
})
export class AccountCustomer {
  data: IAccountCustomer;

  constructor(
    private accountCustomerRemoteService: AccountCustomerRemoteService,
    private overflowErrorsService: OverflowErrorsService,
  ) {}

  getFlexcubeOwnership(
    identificationType: string,
    identificationNumber: string,
    reverse: boolean,
  ) {
    return this.accountCustomerRemoteService
      .listFlexcubeOwnership(identificationType, identificationNumber, reverse)
      .then((resp: IAccountCustomer) => {
        this.data = resp;
        if (resp.isThereTechnicalError) {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.FAILFLEXCUBEOWNERship002,
          );
          return false;
        }

        const {
          numberOfDualOwnershipAccounts,
          numberOfSeizedAccounts,
          numberOfBlockedAccounts,
        } = resp.context;

        if (numberOfDualOwnershipAccounts > BASE_NUMBER_ACCOUNTS) {
          return this.handleDualOwnershipAccounts(
            numberOfSeizedAccounts,
            numberOfBlockedAccounts,
          );
        }

        if (numberOfSeizedAccounts > BASE_NUMBER_ACCOUNTS) {
          return this.handleSeizedAccounts(numberOfBlockedAccounts);
        }

        if (numberOfBlockedAccounts > BASE_NUMBER_ACCOUNTS) {
          this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship007);
          return false;
        }

        if (
          this.data.technicalErrorDto.code ===
          OverflowErrorsEnum.FAILFLEXCUBEOWNERship003
        ) {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.FAILFLEXCUBEOWNERship003,
          );
          return false;
        }

        if (
          this.data.technicalErrorDto.code ===
          OverflowErrorsEnum.FAILFLEXCUBEOWNERship004
        ) {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.FAILFLEXCUBEOWNERship004,
          );
          return false;
        }

        if (resp.isThereAccounts) {
          return true;
        }
        return true;
      });
  }

  private handleDualOwnershipAccounts(
    numberOfSeizedAccounts: number,
    numberOfBlockedAccounts: number,
  ): boolean {
    if (numberOfSeizedAccounts > BASE_NUMBER_ACCOUNTS) {
      if (numberOfBlockedAccounts > BASE_NUMBER_ACCOUNTS) {
        this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship001);
      } else {
        this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship008);
      }
      return false;
    }

    if (numberOfBlockedAccounts > BASE_NUMBER_ACCOUNTS) {
      this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship009);
      return false;
    }

    this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship005);
    return false;
  }

  private handleSeizedAccounts(numberOfBlockedAccounts: number): boolean {
    if (numberOfBlockedAccounts > BASE_NUMBER_ACCOUNTS) {
      this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship0010);
    } else {
      this.setOverflowError(OverflowErrorsEnum.FAILFLEXCUBEOWNERship006);
    }
    return false;
  }
  private setOverflowError(error: OverflowErrorsEnum) {
    this.overflowErrorsService.setOverflowError(error);
  }
}
