import { Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-main-image",
  templateUrl: "./main-image.component.html",
  styleUrls: ["./main-image.component.scss"],
})
export class MainImageComponent implements OnInit, OnDestroy {
  @Input() entry: boolean;

  ngOnInit() {
    this.entry = true;
  }

  ngOnDestroy() {
    this.entry = false;
  }
}
