export enum MessageLoanEnum {
  INVALID_PAYMENT_TYPE = "INVALID_PAYMENT_TYPE",
  LOAN_NOT_GENERATE_TEN_PERCENTAGE_GROWTH = "LOAN_NOT_GENERATE_TEN_PERCENTAGE_GROWTH",
  MESSAGE_LOAN_WITH_ERRORS = "MESSAGE_LOAN_WITH_ERRORS",
  NOT_APPROVE_ACCOUNT_ISSUE = "NOT_APPROVE_ACCOUNT_ISSUE",
  NOT_APPROVE_BURO_DENEGATION = "NOT_APPROVE_BURO_DENEGATION",
  NOT_APPROVE_BUSINESS_RULES = "NOT_APPROVE_BUSINESS_RULES",
  NOT_APPROVE_EXTERNAL_VIABILITY = "NOT_APPROVE_EXTERNAL_VIABILITY",
  NOT_APPROVE_MAX_AGE_EXCEEDED = "NOT_APPROVE_MAX_AGE_EXCEEDED",
  NOT_APPROVE_MODALITY_OR_SEGMENT_NOT_SOPPORTED_ADL = "NOT_APPROVE_MODALITY_OR_SEGMENT_NOT_SOPPORTED_ADL",
  NOT_APPROVE_OTHER_LOAN_IN_PROCESS = "NOT_APPROVE_OTHER_LOAN_IN_PROCESS",
  NOT_APPROVE_PAYER_NOT_SOPPORTED_ADL = "NOT_APPROVE_PAYER_NOT_SOPPORTED_ADL",
  NORMAL_CONDITIONS = "NORMAL_CONDITIONS",
  PAYERROLL_PLATFORM_ACTIVE = "PAYERROLL_PLATFORM_ACTIVE",
  PAYER_WITH_EXTRACTOR = "PAYER_WITH_EXTRACTOR",
  SIMULATE_SAME_FEE_ERROR = "SIMULATE_SAME_FEE_ERROR",
}
