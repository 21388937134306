import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, retry } from "rxjs/operators";

import { EventCodeEnum } from "../../core/enums/event-code.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IEventAnalytics } from "../../core/interfaces/event-analytics.interface";
import { IPreloan } from "../../core/interfaces/pre-loan.interface";
import { ITransactionAudit } from "../../core/interfaces/transaction-audit.interface";

import { AdvisorService } from "./advisor.service";
import { CognitoService } from "./cognito.service";
import { CustomerService } from "./customer.service";
import { LoanService } from "./loan.service";

import { EventAnalyticsRemote } from "../remote/event-analytics-remote.service";
import { SessionSecurityEventService } from "../remote/session-security-event-remote.service";
import { AdvisorDecisionTypeEnum } from "../../core/enums/advisor-decision-type.enum";

@Injectable({
  providedIn: "root",
})
export class EventAnalyticsService {
  advisor: IAdvisor;
  customer: ICustomer;
  preloan: IPreloan;
  transactionAudit: ITransactionAudit;
  customerInfo: any;

  constructor(
    private advisorService: AdvisorService,
    private cognitoService: CognitoService,
    private customerService: CustomerService,
    private eventAnalyticsRemoteService: EventAnalyticsRemote,
    private loanService: LoanService,
    private sessionSecurityEventService: SessionSecurityEventService,
  ) {
    this.advisor = this.advisorService.getAdvisor();
    this.customer = this.customerService.getCustomer();
    this.preloan = this.loanService.getPreloan();
    this.customerInfo = this.customerService.getCustomerInfo();
  }

  async sendEventAnalytics(
    eventcode: EventCodeEnum,
    descriptionEvent: string,
    sendMainCauseError?: string,
  ) {
    this.advisor = this.advisorService.getAdvisor();
    this.customer = this.customerService.getCustomer();
    this.preloan = this.loanService.getPreloan();
    let salesModelValue: AdvisorDecisionTypeEnum = this.customerInfo?.salesModel
      ? this.customerInfo.salesModel
      : null;
    if (salesModelValue === null) {
      salesModelValue = this.advisor ? this.advisor.decisionType : null;
    }

    if (salesModelValue === "SendStrongAuthenticationCustomerInfo") {
      salesModelValue = AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL;
    }

    let currentBalanceSend = undefined;
    if (this.preloan && this.preloan.obligationBalance) {
      currentBalanceSend = this.preloan.obligationBalance;
    }
    if (this.preloan && this.preloan.currentDeb) {
      currentBalanceSend = this.preloan.currentDeb;
    }

    this.transactionAudit = {
      acceptance: null,
      businessAdvisorNumber: this.advisor
        ? this.advisor.sellerIdNumber.toString()
        : undefined,
      currentBalance: currentBalanceSend,
      currentQuotaAmount: this.preloan
        ? this.preloan.currentQuotaAmount
        : undefined,
      description: descriptionEvent,
      documentType: this.customer.documentType,
      documentNumber: this.customer.documentId.toString(),
      eventCode: eventcode,
      loanAmount: this.preloan ? this.preloan.loanAmount : undefined,
      loanQuotas: this.preloan ? this.preloan.loanQuota : undefined,
      missingLoanQuotas: this.preloan
        ? this.preloan.missingLoanQuotas
        : undefined,
      officeId: this.advisor
        ? this.advisor.office.idoffice.toString()
        : undefined,
      payerName: this.preloan ? this.preloan.payerName : undefined,
      payerNit: this.preloan ? this.preloan.payerNit : undefined,
      sector: this.preloan ? this.preloan.sectorName : undefined,
      salesModel: salesModelValue,
      sendMainCauseError,
    };

    await this.eventAnalyticsRemoteService.sendEventAnalytics(
      this.transactionAudit,
    );
  }

  sendNewEventAnalytics({
    documentType,
    documentNumber,
    eventCode,
    eventContent,
  }): Observable<any> {
    const body: IEventAnalytics = {
      officeId: this.advisor.office.idoffice.toString(),
      businessAdvisorNumber: this.advisor.sellerIdNumber.toString(),
      documentType,
      documentNumber,
      eventCode,
      eventContent,
    };
    return this.eventAnalyticsRemoteService
      .sendNewEventAnalytics(body, this.cognitoService.getTokenNew())
      .pipe(
        retry(3),
        catchError((error) => {
          throw error;
        }),
      );
  }

  initSessionSecurityAudit(documentType, documentNumber): Observable<any> {
    const body: any = {
      documentNumber: documentNumber.toString(),
      documentType: documentType,
      officeCode: this.advisor.office.idoffice.toString(),
      businessAdvisorDocument: this.advisor.sellerIdNumber.toString(),
      administrativeOfficeCode:
        this.advisor.administrativeOffice?.code.toString() ?? "",
      officeName: this.advisor.office.name_office,
      administrativeOfficeName: this.advisor.administrativeOffice?.name ?? "",
      advisorJourneyId: this.advisor.advisorJourneyId,
      salesModel: this.advisor.decisionType ?? "",
    };
    return this.sessionSecurityEventService
      .initSessionAudit(body, this.cognitoService.getTokenLegacy())
      .pipe(
        retry(3),
        catchError((error) => {
          throw error;
        }),
      );
  }
}
