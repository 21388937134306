<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title [text]="title" [level]="2"></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="description"
      ></adl-text-description>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-description">
      <adl-nav-pills
        id="app-nav-pills_select-nationality"
        [checkMark]="true"
        [pills]="pills"
        [valueControl]="formGroup.controls['valuePill']"
        [parentForm]="formGroup"
      ></adl-nav-pills>
    </div>

    <div class="h-40-px"></div>

    <div class="animation-text-document">
      <adl-form-field
        id="app-form-field_customer-login-document"
        (keyup.enter)="searchCustomer()"
        [lengthInput]="'15'"
        [fieldControl]="formGroup.controls['textDocumentCustomer']"
        [obfuscate]="true"
        [parentForm]="formGroup"
        [text]="'Escribe el número sin puntos'"
        appOnlyNumber
      ></adl-form-field>
    </div>

    <div class="h-40-px"></div>

    <div class="animation-button">
      <adl-button
        id="adl-button_customer-login"
        [cssClass]="'w-50'"
        [loading]="loadingButtonCustomer"
        [mandatory]="true"
        [parentForm]="formGroup"
        [text]="'Continuar'"
        (clickEvent)="searchCustomer()"
      ></adl-button>
    </div>
  </form>
</div>

<app-form-popup-message-box
  #formPopupMessageBox
  [showMessageBox]="showMessageBox"
  [messageEnum]="messageEnum"
  (closePopupEvent)="closeMessageBox()"
></app-form-popup-message-box>

<app-form-popup-short-flow-repricing
  #formPopupShortFlowReprecing
  (closePopupEvent)="closeShortFlowRepricing($event)"
>
</app-form-popup-short-flow-repricing>
