import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../advisor.service";
import { ClientEducationalService } from "../client-educational.service";
import { CustomerService } from "../customer.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { ExtractorService } from "../extractor.service";
import { GoogleAnalyticsEventsService } from "../google-analytics-events.service";
import { LoanService } from "../loan.service";
import { MessageService } from "../message.service";
import { OverflowErrorsService } from "../overflow-errors.service";
import { PayerService } from "../payer.service";
import { PayrollService } from "../payroll.service";
import { RedirectService } from "../redirect.service";
import { ToggleService } from "../toggle.service";

import { SalaryLoanActionAnyFee } from "./salary-loan-action-any-fee";
import { SalaryLoanActionAnyFeeOld } from "./salary-loan-action-any-fee-old";
import { SalaryLoanActionSameFee } from "./salary-loan-action-same-fee";
import { SalaryLoanActionSygnus } from "./salary-loan-action-sygnus";
import { SalaryLoanActionExtractor } from "./salary-loan-action-extractor";
import { EventManagerService } from "../event-manager.service";

@Injectable({
  providedIn: "root",
})
export class SalaryLoanActionFactoryService {
  salaryLoanActionFactory: ISalaryLoanActionFactory;
  previousAction: ISalaryLoanActionFactory;
  form: any;

  constructor(
    private advisorService: AdvisorService,
    private clientEducationalService: ClientEducationalService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private extractorService: ExtractorService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private payerService: PayerService,
    private payrollService: PayrollService,
    private redirectService: RedirectService,
    private router: Router,
    private toggleService: ToggleService,
    private eventManagerService: EventManagerService,
  ) {}

  salaryLoanAction(form: any, loanFlowEnum: LoanFlowEnum) {
    this.form = form;
    if (loanFlowEnum === LoanFlowEnum.SAMEFEE) {
      this.salaryLoanActionFactory = new SalaryLoanActionSameFee(
        this.advisorService,
        this.eventAnalyticsService,
        this.googleAnalyticsEventsService,
        this.loanService,
        this.redirectService,
        this.router,
      );
    } else if (loanFlowEnum === LoanFlowEnum.ANYFEE) {
      this.salaryLoanActionAnyFee(form);
    } else if (loanFlowEnum === LoanFlowEnum.SYGNUS) {
      this.salaryLoanActionFactory = new SalaryLoanActionSygnus(
        this.advisorService,
        this.eventAnalyticsService,
        form.formPopupSygnusLinkComponent,
        this.loanService,
        this.messageService,
        this.payerService,
        this.redirectService,
        this.router,
        this.customerService,
        form,
        this,
        this.toggleService,
        this.payrollService,
      );
    } else if (loanFlowEnum === LoanFlowEnum.EXTRACTOR) {
      this.salaryLoanActionFactory = new SalaryLoanActionExtractor(
        this.advisorService,
        this.clientEducationalService,
        this.customerService,
        this.eventAnalyticsService,
        this.extractorService,
        this.googleAnalyticsEventsService,
        this.loanService,
        this.messageService,
        this.overflowErrorsService,
        this.payrollService,
        this.router,
        this,
        form,
      );
    }

    this.salaryLoanActionFactory.salaryLoanAction();
  }

  salaryLoanResolve(event?: any) {
    this.salaryLoanActionFactory.salaryLoanResolve(event);
  }

  private salaryLoanActionAnyFee(form: any) {
    if (
      this.clientEducationalService.getToggleEducational() ||
      this.toggleService.getToggleEnabledById(
        ToggleEnum.PAYROLL_CHECK_OPTIMIZATION_II,
      ) ||
      this.toggleService.getToggleEnabledById(
        ToggleEnum.EXTRACTION_AND_INTERPRETER,
      )
    ) {
      this.salaryLoanActionFactory = new SalaryLoanActionAnyFee(
        this.advisorService,
        this.clientEducationalService,
        this.customerService,
        this.eventAnalyticsService,
        form.formPopupUploadPaymentReceiptComponent,
        this.googleAnalyticsEventsService,
        this.loanService,
        form.loading,
        this.messageService,
        this.overflowErrorsService,
        this.payrollService,
        this.router,
        this.toggleService,
        this.eventManagerService,
      );
    } else {
      this.salaryLoanActionFactory = new SalaryLoanActionAnyFeeOld(
        this.advisorService,
        this.customerService,
        this.eventAnalyticsService,
        form.formPopupUploadPaymentReceiptComponent,
        this.googleAnalyticsEventsService,
        this.loanService,
        form.loading,
        this.messageService,
        this.overflowErrorsService,
        this.payrollService,
        this.router,
      );
    }
  }
}
