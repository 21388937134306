import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import {
  IListSelect,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IMessage } from "../../../core/interfaces/message.interface";

import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { CustomerService } from "../../../services/local/customer.service";
import { EventAnalyticsService } from "../../../services/local/event-analytics.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { MessageService } from "../../../services/local/message.service";
import { ViabilityQueryService } from "../../../services/local/viability-query.service";

@Component({
  selector: "app-form-popup-viability-query",
  templateUrl: "./form-popup-viability-query.component.html",
})
export class FormPopupViabilityQueryComponent implements OnInit {
  isInProgressEvent = false;

  sectors: IListSelect[];

  @Input("showPopUpViabilityQuery")
  set setShowPopUpViabilityQuery(showPopUpViabilityQuery: boolean) {
    this.showPopUpViabilityQuery = showPopUpViabilityQuery;
    if (showPopUpViabilityQuery) {
      this.setSelectSector();
    }
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  listDocumentType: any[];
  listSector: any;
  message: IMessage;
  showPopUpViabilityQuery: boolean;

  constructor(
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private initialNavPillsService: InitialNavPillsService,
    private messageService: MessageService,
    private router: Router,
    private viabilityQueryService: ViabilityQueryService,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  closePopupAndRedirect(redirect: boolean) {
    this.closePopupAndRedirectEvent.emit(redirect);
  }

  initData() {
    this.message = this.messageService.getMessageById(
      MessageEnum.VIABILITY_QUERY,
    );
  }

  initForm() {
    this.listDocumentType = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
    );

    this.formGroup = new UntypedFormGroup({
      textDocumentType: new UntypedFormControl("", Validators.required),
      valueDocumentType: new UntypedFormControl(),
      textDocumentNumber: new UntypedFormControl("", Validators.required),
      textSector: new UntypedFormControl("", Validators.required),
      valueSector: new UntypedFormControl(),
      textPhoneNumber: new UntypedFormControl("", Validators.required),
    });
  }

  redirectToFormAuthorizationData() {
    this.isInProgressEvent = true;
    const infoCustomer = {
      documentType: this.formGroup.controls.valueDocumentType.value.value,
      documentTypeText: this.formGroup.controls.textDocumentType.value,
      documentId: this.formGroup.controls.textDocumentNumber.value,
      cellphone: this.formGroup.controls.textPhoneNumber.value,
      sectorRisk: this.formGroup.controls.valueSector.value.value,
      sectorRiskText: this.formGroup.value.valueSector.text,
    };
    this.customerService.setCustomer(infoCustomer);

    this.eventAnalyticsService
      .initSessionSecurityAudit(
        infoCustomer.documentType,
        infoCustomer.documentId,
      )
      .subscribe();

    this.eventAnalyticsService
      .sendNewEventAnalytics({
        documentType: infoCustomer.documentType,
        documentNumber: infoCustomer.documentId,
        eventCode: EventCodeEnum.PREVIABILITY_SIMULATOR_LOGIN,
        eventContent: { description: JSON.stringify(infoCustomer) },
      })
      .subscribe(
        () => {
          this.router.navigate(["/authorizationdata"]);
          this.isInProgressEvent = false;
        },
        () => {
          this.router.navigate(["/authorizationdata"]);
        },
      );
  }

  setSelectSector(): void {
    this.viabilityQueryService
      .getDeclaratedSectorRisk()
      .subscribe((sectors) => {
        this.sectors = sectors;
      });
  }
}
