<div id="main" class="d-flex flex-column justify-content-center">
  <div class="row d-flex justify-content-center">
    <div class="col-11 col-md-10 col-lg-4 content-message content-tab-left">
      <adl-text-title
        [text]="title"
        [level]="2"
        id="app-text-title_title-salary-loan-success"
      ></adl-text-title>

      <div class="h-14-px"></div>

      <adl-text-html [text]="description"></adl-text-html>

      <div class="h-14-px"></div>

      <adl-text-description
        [cssClass]="'font-weight-bold'"
        [text]="'Celular: *******118 '"
        [icon]="'phone'"
        id="app-text-description_description-cell-phone"
      >
      </adl-text-description>

      <div class="h-14-px"></div>

      <adl-text-description
        [cssClass]="'font-weight-bold'"
        [text]="'Correo: lau***************@gmail.com'"
        [icon]="'at'"
        id="app-text-description_description-email"
      >
      </adl-text-description>

      <div class="h-26-px"></div>
    </div>
    <div class="col-11 col-md-10 col-lg-6 content-message content-tab-right">
      <div>
        <img [src]="icon" alt="Icon Salary Loan Succes" />
      </div>

      <adl-text-title
        [text]="summaryTitle"
        [level]="5"
        id="app-text-title_summary-title-salary-loan-success"
      ></adl-text-title>

      <div class="h-14-px"></div>

      <div class="container-border-green w-90">
        <div class="row row-margin">
          <div class="col-lg-8 col-sm-12 content-section-border-between">
            <div class="d-flex justify-content-center w-100">
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Monto máximo pre aprobado'"
              ></adl-text-label>
            </div>
            <div class="d-flex justify-content-center w-100">
              <div class="m-r-20-px">
                <adl-icon
                  [urlIcon]="iconLoanAmount"
                  altIcon="Icon loan amount"
                ></adl-icon>
              </div>
              <adl-text-label
                [cssClass]="'text-extra-large font-weight-bold text-nowrap'"
                [text]="'$ 70.000.000'"
              ></adl-text-label>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12 content-left">
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Plazo:'"
              ></adl-text-label>
            </div>

            <div>
              <adl-text-title
                [text]="'12 meses'"
                [level]="4"
                id="app-text-title_term"
              ></adl-text-title>
            </div>
          </div>
        </div>

        <div class="h-25-px separator"></div>

        <div class="row row-margin">
          <div
            class="col-lg-6 col-sm-12 content-right content-section-border-between"
          >
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Neto a desembolsar'"
              ></adl-text-label>
            </div>
            <div>
              <adl-text-title
                [text]="'$ 64.000.000'"
                [level]="4"
                id="app-text-title_total_debt"
              ></adl-text-title>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 content-left">
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Deducciones'"
              ></adl-text-label>
            </div>
            <div>
              <adl-text-title
                [text]="'$ 6.000.000*'"
                [level]="4"
                id="adl-text-title-discounts"
              ></adl-text-title>
            </div>
          </div>
        </div>

        <div class="h-25-px separator"></div>

        <div class="row row-margin">
          <div
            class="col-lg-4 col-sm-12 content-left content-section-border-between"
          >
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Cuota mensual:'"
              ></adl-text-label>
            </div>
            <div>
              <adl-text-title
                [text]="'$ 1.289.000*'"
                [level]="4"
                id="adl-text-title-fee-amount"
              ></adl-text-title>
            </div>
          </div>
          <div
            class="col-lg-4 col-6 content-left content-section-border-between hidden-border-bottom"
          >
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Tasa E.A'"
              ></adl-text-label>
            </div>
            <div>
              <adl-text-title
                [text]="'0.8% E.A'"
                [level]="4"
                id="adl-text-title-anual-rate"
              ></adl-text-title>
            </div>
          </div>
          <div class="col-lg-4 col-6 content-left hidden-border-bottom">
            <div>
              <adl-text-label
                [cssClass]="'text-minimal-small'"
                [text]="'Tasa nominal:'"
              ></adl-text-label>
            </div>
            <div>
              <adl-text-title
                [text]="'2.54% M.V'"
                [level]="4"
                id="app-text-title_nominalRate"
              ></adl-text-title>
            </div>
          </div>
        </div>
      </div>

      <div class="h-25-px"></div>

      <div class="row d-flex justify-content-center">
        <div class="w-191-px h-75-px">
          <adl-button
            id="adl-button_button-upload-decrim"
            [mandatory]="true"
            [text]="captionButton"
            [parentForm]="formGroup"
          ></adl-button>
        </div>
      </div>

      <div class="h-2-px"></div>
    </div>
  </div>

  <div class="h-8-px"></div>

  <div
    class="text-minimal-small d-flex align-items-center justify-content-center"
  >
    <adl-text-html [text]="footerText"></adl-text-html>
  </div>
</div>
