import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SessionSecurityEventService {
  constructor(private http: HttpClient) {}

  initSessionAudit(customerInfo: object, tokenLegacy: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: tokenLegacy,
    });
    return this.http.post(
      environment.serverUrlBackendSecurity + "session/audit",
      customerInfo,
      { headers },
    );
  }
}
