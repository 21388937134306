import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { FileExtensionEnum } from "../../../core/enums/file-extension.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

import { IOnBaseLoad } from "../../../core/interfaces/onbase-load.interface";

import { FileManagerService } from "../../../services/local/file-manager.service";
import { OnbaseLoadService } from "../../../services/local/onbase-load.service";
import { OnbaseNotificationService } from "../../../services/local/onbase-notification.service";
import { OnbaseUploadDocumentsService } from "../../../services/local/onbase-upload-documents.service";

@Component({
  selector: "app-form-upload-documents-onbase",
  templateUrl: "./form-upload-documents-onbase.component.html",
  styleUrls: ["./form-upload-documents-onbase.component.scss"],
})
export class FormUploadDocumentsOnbaseComponent implements OnInit {
  cssButton: string;
  currentfactoryPayersPreload: any;
  disabled: boolean = true;
  description: string;
  document: any;
  documents: any;
  documentUpload: string;
  files: any = [];
  filesUpload: any[];
  loadingCss: string;
  loadingDescription: string;
  loadPercentage: number;
  showLoading: boolean;
  tabs: any[];
  tab: any;
  textLoading: string;
  title: string;

  customerOnBaseLoad: IOnBaseLoad;
  formGroup: UntypedFormGroup;

  initialIndexTab = 0;

  constructor(
    private fileManagerService: FileManagerService,
    private onbaseLoadService: OnbaseLoadService,
    private onbaseNotificationService: OnbaseNotificationService,
    private onbaseUploadDocumentsService: OnbaseUploadDocumentsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customerOnBaseLoad = this.onbaseLoadService.getClientDocumentsUpload();
    this.documents = this.onbaseLoadService.getDocumentsUploadToShow();

    this.initFormUploadDocuments();
    this.initCurrentFactoryPayersPreload();
    this.initForm();
    this.initFormTab();
    this.initNotificationService();
    this.initOnbaseUploadServices();
  }

  deleteDocument(document: any) {
    document.documentUpload = "";
    document.reasons = [];
    document.file = null;
    this.onbaseNotificationService.deleteExistingItem(document);
    this.onbaseNotificationService.documentHasFile();
  }

  fileUpload(file: File) {
    let isInvalidFile = false;
    this.document.file = file;
    this.document.reasons = [];

    if (
      !this.fileManagerService.validateFile(
        file,
        ApplicationConstantsEnum.MAXIMUM_ONBASE_FILE_SIZE,
        [FileExtensionEnum.PDF, FileExtensionEnum.JPG, FileExtensionEnum.JPGE],
      )
    ) {
      isInvalidFile = true;
      this.document.reasons = [UploadDocumentsStatesEnum.BIG_ONBASE_FILE_ERROR];
    }

    if (this.filePreUpload(file)) {
      isInvalidFile = true;
      this.document.reasons = [UploadDocumentsStatesEnum.FILE_EXISTS];
    }

    this.onbaseLoadService.setSelectedFile(
      this.tab.payer,
      this.document.name,
      file,
    );
    this.onbaseNotificationService.changeNotification(
      this.document,
      isInvalidFile,
    );
  }

  goToDecision() {
    this.onbaseNotificationService.removeNotifications();
    this.router.navigate(["decision"]);
  }

  printfileErrorMessage(isFile: boolean) {
    if (!isFile && !this.document.file) {
      this.document.reasons = [UploadDocumentsStatesEnum.FILE_EMPTY];
    }
  }

  selectedTab(tab: any) {
    this.disabled = true;
    this.tab = this.documents.loanDocuments.find((item: any) => {
      return item.payer === tab;
    });

    this.description = `Adjunta los siguientes documentos, los cuales no se encuentran cargados en Onbase para el
      desembolso del cliente <b class = "text-capitalize">${this.customerOnBaseLoad.name.toLowerCase()}</b>
      - <span class="form-label-green">${this.tab.sector}</span>`;

    this.onbaseNotificationService.setDocumentFiles(this.tab.documents);
    this.onbaseNotificationService.removeNotifications();
    this.onbaseNotificationService.documentHasFile();
  }

  setDocument(document: any) {
    this.document = document;
  }

  uploadDocuments() {
    this.showLoading = true;

    this.files = this.tab.documents.filter((file: any) => file.file);
    this.onbaseUploadDocumentsService.setFiles(this.files);
    this.onbaseUploadDocumentsService
      .uploadFiles(this.tab.documents, this.tab.factoryFileId, this.tab.payer)
      .then(() => {
        setTimeout(() => {
          this.uploadFilesSuccess();
        }, 4000);
      })
      .catch(() => {
        this.infoBarErrorUploadDocuments();
      });
  }

  private filePreUpload(file: File) {
    const files = this.tab.documents.filter((document: any) => {
      return (
        document.file &&
        document.file.name === file.name &&
        document.name !== this.document.name
      );
    });

    return files.length > 0;
  }

  private infoBarCompleteUploadDocuments() {
    this.loadingDescription = "Documentación Cargada";

    setTimeout(() => {
      this.showLoading = false;
      this.goToDecision();
    }, 5000);
  }

  private infoBarErrorUploadDocuments() {
    setTimeout(() => {
      this.textLoading = "Error al cargar";
      this.loadingCss = ApplicationConstantsEnum.TEXT_RED_BOLD;
    }, 3000);

    const documentsError = this.tab.documents.filter(
      (data: any) => data.reasons.length > ApplicationConstantsEnum.EMPTY_ARRAY,
    );

    for (const documentError of documentsError) {
      this.onbaseNotificationService.changeNotification(documentError, true);
    }

    this.showLoading = false;
  }

  private initFormUploadDocuments() {
    this.onbaseLoadService.currentfactoryPayersPreload.subscribe(
      (currentDocumentsUpload) => {
        this.currentfactoryPayersPreload = currentDocumentsUpload;
      },
    );
  }

  private initCurrentFactoryPayersPreload() {
    if (this.currentfactoryPayersPreload) {
      for (const current of this.currentfactoryPayersPreload) {
        this.documents.loanDocuments = this.documents.loanDocuments.filter(
          (item: any) => item.factoryFileId !== current.factoryFileId,
        );
      }
    }
  }

  private initNotificationService() {
    this.onbaseNotificationService.currentNotification.subscribe(
      (filesUpload) => {
        this.filesUpload = filesUpload;
      },
    );

    this.onbaseNotificationService.currentDisabledBotton.subscribe(
      (notificacion) => {
        this.disabled = notificacion;
      },
    );
  }

  private initOnbaseUploadServices() {
    let numberFilesUpload = 0;

    this.onbaseUploadDocumentsService.loadPercentage.subscribe(
      (loadPercentage: number) => {
        const numberFiles = this.tab ? this.tab.documents.length : 0;

        this.loadPercentage = loadPercentage;
        if (loadPercentage === ApplicationConstantsEnum.LOADINGCOMPLETE) {
          numberFilesUpload += 1;
          this.loadingCss = ApplicationConstantsEnum.TEXT_WHITE_BOLD;
          this.textLoading = `Cargando ${numberFilesUpload.toString()} de ${numberFiles.toString()}`;
        }
      },
    );
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  private initFormTab() {
    this.loadingDescription = "Estamos cargando la documentación";
    this.tabs = this.documents.loanDocuments.map((item: any) => item.payer);
    const index = this.tabs.findIndex(
      (item) => item === this.customerOnBaseLoad.payerSelected,
    );
    this.initialIndexTab = index !== -1 ? index : 0;
    this.title = "Cargue de documentos";
  }

  private uploadFilesSuccess() {
    this.onbaseUploadDocumentsService.setPreviouslyUploadedDocuments(
      this.tab.payer,
    );

    this.onbaseUploadDocumentsService
      .saveUrlQueues()
      .then(() => {
        this.infoBarCompleteUploadDocuments();
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(
          `FormUploadDocumentsOnbase - saveUrlQueues - Error`,
          JSON.stringify(error),
        );
      });
  }
}
