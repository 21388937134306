<div id="main" class="d-flex flex-column justify-content-center">
  <div
    class="row d-flex align-items-start justify-content-center progress-step-bar"
  >
    <div class="col-12 col-sm-7">
      <adl-progress-step-bar [listSteps]="listSteps"></adl-progress-step-bar>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-7 content-message">
      <adl-content-message
        [cssClass]="'text-center'"
        [title]="payrollLoanStatus.title"
        [description]="payrollLoanStatus.description"
      >
      </adl-content-message>

      <adl-content-message
        *ngIf="showAuthenticationCellphone()"
        [description]="authenticationCellphoneText"
      >
      </adl-content-message>

      <div class="w-100 h-110-px d-flex justify-content-center">
        <div class="container-button">
          <div class="container-left-button">
            <adl-button
              id="adl-button_button-click-consult-other-payroll"
              [cssClass]="'w-100 buttonSilver buttonTextMedium'"
              [mandatory]="false"
              [text]="captionButtonConsultOtherPayroll"
              [parentForm]="formGroup"
              (clickEvent)="goToGetPayrollLoanStatus()"
            ></adl-button>
          </div>
          <div class="container-right-button">
            <adl-button
              id="adl-button_button-click"
              [cssClass]="'w-100 buttonTextMedium'"
              [mandatory]="true"
              [text]="captionButtonGoToInit"
              [parentForm]="formGroup"
              (clickEvent)="goToLoginAdvisor()"
            ></adl-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
