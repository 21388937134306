import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadFileScriptService {
  loadFileScriptSync(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!document.querySelector(`script[src="${filePath}"]`)) {
        const script = document.createElement("script");
        script.src = filePath;
        script.id = filePath;
        script.type = "text/javascript";
        script.onload = () => {
          script.setAttribute("data-loaded", "true");
          resolve();
        };
        script.onerror = () =>
          reject(new Error(`Error al cargar el script: ${filePath}`));
        document.head.appendChild(script);
      } else {
        resolve();
      }
    });
  }
}
