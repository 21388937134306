import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-authentication-code-image",
  styleUrls: ["./authentication-code-image.component.scss"],
  templateUrl: "./authentication-code-image.component.html",
})
export class AuthenticationCodeImageComponent implements OnInit {
  @Input() entry: boolean;

  ngOnInit() {
    this.entry = true;
  }
}
