import { Injectable } from "@angular/core";

import { IGoogleAnalyticEvent } from "../../core/interfaces/google-analytic-event.interface";
import { GoogleAnalyticsEventsRemoteService } from "../remote/google-analytics-events-remote.service";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsEventsService {
  googleAnalyticsEvent: IGoogleAnalyticEvent;

  constructor(
    private googleAnalyticsEventsRemoteService: GoogleAnalyticsEventsRemoteService,
  ) {}

  emitEvent(eventId: string, saveClientId?: boolean) {
    this.googleAnalyticsEventsRemoteService.sendEvent(
      this.googleAnalyticsEventsRemoteService.searchGoogleAnalyticEventData(
        eventId,
        window.location.href,
      ),
    );

    if (saveClientId) {
      this.googleAnalyticsEventsRemoteService.saveClientId();
    }
  }

  emitEventNavigate(event: any) {
    this.googleAnalyticsEventsRemoteService.sendEventNavigate(event);
  }

  getClientId() {
    return this.googleAnalyticsEventsRemoteService.getClientId();
  }
}
