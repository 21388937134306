import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IBindingRequest } from "../../core/interfaces/binding-request.interface";
import { IPlatformOfferRequest } from "../../core/interfaces/platform-offer-request.interface";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PayerRemoteService {
  constructor(private httpClient: HttpClient) {}

  generatePlatformOffer(
    platformOffer: IPlatformOfferRequest,
  ): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "payer/platform/offer",
      platformOffer,
    );
  }

  getBindingsPlatform(requestBindings: IBindingRequest): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "payer/platform/bindings",
      requestBindings,
    );
  }
}
