export enum GoogleAnalyticsEventsEnum {
  LOGIN_ADVISOR_INIT_PAGE = "LOGIN_ADVISOR_INIT_PAGE",
  LOGIN_ADVISOR_SEARCH = "LOGIN_ADVISOR_SEARCH",
  LOGIN_ADVISOR_SEARCH_ERROR = "LOGIN_ADVISOR_SEARCH_ERROR",
  LOGIN_ADVISOR_CLICK_CONTINUE = "LOGIN_ADVISOR_CLICK_CONTINUE",
  DECISION_INIT_PAGE = "DECISION_INIT_PAGE",
  DECISION_CLICK_NEW_PAYROLL_LOAN = "DECISION_CLICK_NEW_PAYROLL_LOAN",
  DECISION_CLICK_NEW_PAYROLL_LOAN_BY_PHONE_CALL = "DECISION_CLICK_NEW_PAYROLL_LOAN_BY_PHONE_CALL",
  DECICION_CLICK_GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL = "DECICION_CLICK_GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL",
  DECISION_CLICK_SEND_STRONG_AUTHENTICATION_CUSTOMER_INFO = "DECISION_CLICK_SEND_STRONG_AUTHENTICATION_CUSTOMER_INFO",
  DECISION_CLICK_GET_PAYROLL_LOAN_STATUS = "DECISION_CLICK_GET_PAYROLL_LOAN_STATUS",
  DECISION_CLICK_UPLOAD_DOCUMENTS_ONBASE = "DECISION_CLICK_UPLOAD_DOCUMENTS_ONBASE",
  LOGIN_CUSTOMER_INIT_PAGE = "LOGIN_CUSTOMER_INIT_PAGE",
  LOGIN_CUSTOMER_SEARCH_NATIONAL = "LOGIN_CUSTOMER_SEARCH_NATIONAL",
  LOGIN_CUSTOMER_SEARCH_FOREIGN = "LOGIN_CUSTOMER_SEARCH_FOREIGN",
  LOGIN_CUSTOMER_SEARCH_NATIONAL_ERROR = "LOGIN_CUSTOMER_SEARCH_NATIONAL_ERROR",
  LOGIN_CUSTOMER_SEARCH_FOREIGN_ERROR = "LOGIN_CUSTOMER_SEARCH_FOREIGN_ERROR",
  QUERY_CUSTOMER_STATUS_INIT_PAGE = "QUERY_CUSTOMER_STATUS_INIT_PAGE",
  QUERY_CUSTOMER_STATUS_SEARCH_NATIONAL = "QUERY_CUSTOMER_STATUS_SEARCH_NATIONAL",
  QUERY_CUSTOMER_STATUS_SEARCH_FOREIGN = "QUERY_CUSTOMER_STATUS_SEARCH_FOREIGN",
  QUERY_CUSTOMER_STATUS_SEARCH_NATIONAL_ERROR = "QUERY_CUSTOMER_STATUS_SEARCH_NATIONAL_ERROR",
  QUERY_CUSTOMER_STATUS_SEARCH_FOREIGN_ERROR = "QUERY_CUSTOMER_STATUS_SEARCH_FOREIGN_ERROR",
  DATA_AUTHORIZATION_INIT_PAGE = "DATA_AUTHORIZATION_INIT_PAGE",
  DATA_AUTHORIZATION_CLICK_ACCEPT_DATA = "DATA_AUTHORIZATION_CLICK_ACCEPT_DATA",
  DATA_AUTHORIZATION_CLICK_NO_ACCEPT_DATA = "DATA_AUTHORIZATION_CLICK_NO_ACCEPT_DATA",
  AUTHENTICATION_GENERATE_OTP_INIT_PAGE = "AUTHENTICATION_GENERATE_OTP_INIT_PAGE",
  AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE = "AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE",
  AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE_ERROR = "AUTHENTICATION_GENERATE_OTP_CLICK_CONTINUE_ERROR",
  AUTHENTICATION_VALIDATE_OTP_INIT_PAGE = "AUTHENTICATION_VALIDATE_OTP_INIT_PAGE",
  AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE = "AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE",
  AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE_ERROR = "AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE_ERROR",
  AUTHENTICATION_VALIDATE_OTP_EXPIRED_TIME = "AUTHENTICATION_VALIDATE_OTP_EXPIRED_TIME",
  AUTHENTICATION_VALIDATE_OTP_CLICK_LINK_CALL_OTP = "AUTHENTICATION_VALIDATE_OTP_CLICK_LINK_CALL_OTP",
  AUTHENTICATION_VALIDATE_OTP_CLICK_GENERATE_OTHER_OTP = "AUTHENTICATION_VALIDATE_OTP_CLICK_GENERATE_OTHER_OTP",
  AUTHENTICATION_VALIDATE_OTP_GENERATE_OTHER_OTP_ERROR = "AUTHENTICATION_VALIDATE_OTP_GENERATE_OTHER_OTP_ERROR",
  AUTHENTICATION_VALIDATE_OTP_UPLOAD_FILES_DECRIM = "AUTHENTICATION_VALIDATE_OTP_UPLOAD_FILES_DECRIM",
  AUTHENTICATION_VALIDATE_OTP_UPLOAD_FILES_DECRIM_ERROR = "AUTHENTICATION_VALIDATE_OTP_UPLOAD_FILES_DECRIM_ERROR",
  CUSTOMER_DECISION_INIT_PAGE = "CUSTOMER_DECISION_INIT_PAGE",
  CUSTOMER_DECISION_CLICK_NEW_PAYROLL_LOAN = "CUSTOMER_DECISION_CLICK_NEW_PAYROLL_LOAN",
  CUSTOMER_DECISION_CLICK_EXTEND_PAYROLL_LOAN = "CUSTOMER_DECISION_CLICK_EXTEND_PAYROLL_LOAN",
  NEW_LOAN_INIT_PAGE = "NEW_LOAN_INIT_PAGE",
  NEW_LOAN_INIT_PAGE_CLICK_CONTINUE = "NEW_LOAN_INIT_PAGE_CLICK_CONTINUE",
  NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_SUCCESS = "NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_SUCCESS",
  NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_ERROR = "NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_ERROR",
  UPLOAD_DOCUMENTS_INIT_PAGE = "UPLOAD_DOCUMENTS_INIT_PAGE",
  UPLOAD_DOCUMENTS_CLICK_CONTINUE = "UPLOAD_DOCUMENTS_CLICK_CONTINUE",
  UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_SUCCESS = "UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_SUCCESS",
  UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_ERROR = "UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_ERROR",
  UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_INVALID = "UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_INVALID",
  SALARY_LOAN_INIT_PAGE = "SALARY_LOAN_INIT_PAGE",
  SALARY_LOAN_CLICK_ANY_FEE = "SALARY_LOAN_CLICK_ANY_FEE",
  SALARY_LOAN_CLICK_SAME_FEE = "SALARY_LOAN_CLICK_SAME_FEE",
  PEP_INIT_PAGE = "PEP_INIT_PAGE",
}
