import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent {
  data;
  @Output() selectDecision = new EventEmitter<any>();
  @Input("data") set setData(data) {
    data.imageUrl = `assets/images/decision/${data.image}.svg`;
    this.data = data;
  }

  handleClick(item) {
    this.selectDecision.emit(item);
  }
}
