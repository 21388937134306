import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ValidationQueryDecrimResponseStatusEnum } from "../../../core/enums/validation-query-decrim-response-status.enum";

import { IMessage } from "../../../core/interfaces/message.interface";
import { IResponseValidateDecrimQuery } from "./../../../core/interfaces/response-validate-decrim-query.interface";

import { CustomerService } from "../../../services/local/customer.service";
import { MessageService } from "../../../services/local/message.service";

@Component({
  selector: "app-form-popup-validation-query-decrim",
  templateUrl: "./form-popup-validation-query-decrim.component.html",
  styleUrls: ["./form-popup-validation-query-decrim.component.scss"],
})
export class FormPopupValidationQueryDecrimComponent
  implements OnInit, OnChanges {
  @Input("showPopUpValidationQueryDecrim")
  set setShowPopUpValidationQueryDecrim(
    showPopUpValidationQueryDecrim: boolean,
  ) {
    this.showPopUpValidationQueryDecrim = showPopUpValidationQueryDecrim;
  }

  @Input("dataValidationQueryDecrim")
  set setDataValidationQueryDecrim(
    dataValidationQueryDecrim: IResponseValidateDecrimQuery[] | MessageEnum,
  ) {
    this.dataValidationQueryDecrim = dataValidationQueryDecrim;
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<MessageEnum>();

  responseValidateDecrimQuery: IResponseValidateDecrimQuery[];
  completeName: string;
  dataValidationQueryDecrim: IResponseValidateDecrimQuery[] | MessageEnum = [];
  descriptionContent: string;
  documentId: number;
  formGroup: UntypedFormGroup;
  message: IMessage;
  showPopUpValidationQueryDecrim: boolean = true;

  constructor(
    private customerService: CustomerService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  closePopupAndRedirect() {
    this.closePopupAndRedirectEvent.emit();
  }

  goToAction() {
    switch (this.message.actionButton) {
      case MessageActionEnum.CLOSE_MESSAGE:
        this.closePopupAndRedirectEvent.emit();
        break;
      case MessageActionEnum.CLIENT_HAVE_PENDING_ONBASE_SALES:
        this.closePopupAndRedirectEvent.emit(
          MessageEnum.CLIENT_HAVE_PENDING_ONBASE_SALES,
        );
        break;
      case MessageActionEnum.INFO_UPLOAD_FILE_DECRIM:
        this.closePopupAndRedirectEvent.emit(
          MessageEnum.INFO_UPLOAD_FILE_DECRIM,
        );
        break;
      case MessageActionEnum.GO_DECISION:
        this.closePopupAndRedirectEvent.emit(
          MessageEnum.CLIENT_HAVE_SUCCESS_AUTHENTICATION_SALES,
        );
        break;
    }
  }

  initData() {
    this.message = this.messageService.getMessageById(
      MessageEnum.VALIDATION_QUERY_DECRIM,
    );
  }

  initForm(): void {
    this.formGroup = new UntypedFormGroup({});
  }

  ngOnChanges() {
    this.showInfo();
  }

  showInfo() {
    if (this.showPopUpValidationQueryDecrim) {
      this.setClientInformation();
      this.message = this.messageService.getMessageById(
        this.dataValidationQueryDecrim as MessageEnum,
      );

      if (this.message && !this.message.messageId) {
        this.initData();
        this.responseValidateDecrimQuery = this
          .dataValidationQueryDecrim as IResponseValidateDecrimQuery[];
        this.validateSuccessStateSale();
      }
    }
  }

  private validateSuccessStateSale() {
    const validationAuthStatus = this.responseValidateDecrimQuery.every(
      (responseValidateDecrimQuery) =>
        responseValidateDecrimQuery.authStatus ===
          ValidationQueryDecrimResponseStatusEnum.SUCCESS ||
        responseValidateDecrimQuery.authStatus ===
          ValidationQueryDecrimResponseStatusEnum.ASSIGNED,
    );

    if (validationAuthStatus) {
      this.message.actionButton = MessageActionEnum.GO_DECISION;
    }
  }

  private setClientInformation() {
    this.documentId = this.customerService.getCustomer().documentId;
    this.completeName = this.customerService.getCustomer().completeName;
  }
}
