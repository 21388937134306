import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { IMessage } from "../../../core/interfaces/message.interface";

import { MessageEnum } from "../../../core/enums/message.enum";

import { MessageService } from "../../../services/local/message.service";

@Component({
  selector: "app-form-popup-message-box",
  templateUrl: "./form-popup-message-box.component.html",
})
export class FormPopupMessageBoxComponent implements OnInit {
  @Input("showMessageBox")
  set setShowMessageBox(showMessageBox: boolean) {
    this.showMessageBox = showMessageBox;
  }

  @Input("message")
  set setMessage(message: IMessage) {
    this.message = message;
  }

  @Input("messageEnum")
  set setMessageEnum(messageEnum: MessageEnum) {
    this.message = this.messageService.getMessageById(messageEnum);
  }

  @Output() closePopupEvent = new EventEmitter<IMessage>();

  showMessageBox: boolean;

  formGroup: UntypedFormGroup;
  message: IMessage;

  constructor(private messageService: MessageService, private router: Router) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  closePopup() {
    this.closePopupEvent.emit(this.message);

    if (this.message.urlNavigate) {
      this.router.navigate([this.message.urlNavigate]);
    }
  }
}
