import { Component, OnInit } from "@angular/core";
import { ITip } from "../../../core/interfaces/tip.interface";

@Component({
  selector: "app-popup-document-upload-tips",
  templateUrl: "./popup-document-upload-tips.component.html",
  styleUrls: ["./popup-document-upload-tips.component.scss"],
})
export class PopupDocumentUploadTipsComponent implements OnInit {
  tips: ITip[] = [];
  show = false;

  ngOnInit(): void {
    this.tips = this.initTips();
    this.show = this.canShowPopup();
  }

  canShowPopup() {
    const min = 1;
    const max = 11;
    const probability = 6;
    if (this.randomNumber(min, max) <= probability) {
      return true;
    }
    return false;
  }

  initTips() {
    return [
      {
        icon: "assets/images/icons/orange-upload.svg",
        title: "Tamaño del documento",
        message: "Recuerda que el desprendible no debe pesar más de 1MB.",
      },
      {
        icon: "assets/images/icons/orange-letter.svg",
        title: "Legibilidad del documento",
        message:
          "Verifica que el documento sea visible y que los textos se puedan leer.",
      },
      {
        icon: "assets/images/icons/orange-calendar.svg",
        title: "Vencimiento del documento",
        message: "Revisa que el documento no supere la fecha permitida.",
      },
      {
        icon: "assets/images/icons/orange-spotlight.svg",
        title: "Datos que coincidan ",
        message:
          "Ningún reflejo o sombra debe cubrir la información o la foto de tu documento. ",
      },
    ];
  }

  randomNumber(min: number, max: number) {
    const crypto = window.crypto || (window as any).msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    const random = array[0] / (0xffffffff + 1);
    return Math.floor(random * (max - min) + min);
  }
}
