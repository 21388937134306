import { Injectable } from "@angular/core";

import { TableTypeEnum } from "../../core/enums/table-type.enum";

import { MapTableRemoteService } from "../remote/map-table-remote.service";

@Injectable({
  providedIn: "root",
})
export class MapTableService {
  constructor(private mapTableRemoteService: MapTableRemoteService) {}

  getMapTableData(tableTypeEnum: TableTypeEnum): any[] {
    return this.mapTableRemoteService.getMapTableData(tableTypeEnum);
  }
}
