import { Injectable } from "@angular/core";

import * as menuItem from "../data/menu-decision.json";
import * as menuTelephonic from "../data/menu-telephonic-decision.json";

@Injectable({
  providedIn: "root",
})
export class MenuRemoteService {
  getMenu() {
    return (menuItem as any).default;
  }

  getMenuTelephonic() {
    return (menuTelephonic as any).default;
  }
}
