import { Injectable } from "@angular/core";

import { IPayrollLoanStatus } from "../../core/interfaces/payroll-loan-status.interface";

import * as payrollLoanStatusCausalData from "../data/payroll-loan-status-causal-data.json";

@Injectable({
  providedIn: "root",
})
export class PayrollLoanStatusCausalRemoteService {
  public searchPayrollLoanStatusCausalData(
    statusId: string,
    payerName: string,
    toDo: string,
  ): IPayrollLoanStatus {
    return this.mapPayrollLoanStatusCausal(statusId, payerName, toDo);
  }

  private mapPayrollLoanStatusCausal(
    statusId: string,
    payerName: string,
    toDo: string,
  ): IPayrollLoanStatus {
    const payrollLoanStatusCausal: IPayrollLoanStatus = {
      ...(payrollLoanStatusCausalData as any).default.find(
        (payrollLoanCausal: any) => payrollLoanCausal.statusId === statusId,
      ),
    };

    if (payrollLoanStatusCausal.description) {
      payrollLoanStatusCausal.description = payrollLoanStatusCausal.description
        .replace(/{payerName}/, payerName)
        .replace(/{toDo}/, toDo);
    }

    return payrollLoanStatusCausal;
  }
}
