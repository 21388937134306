<div
  id="main"
  class="d-flex flex-column align-items-center justify-content-center"
>
  <form
    class="form-autorization-data d-flex flex-column align-items-center justify-content-center"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="text-tittle text-center animation-title-bold">
      <adl-text-title
        [text]="title | CapitalCasePipe"
        [level]="2"
      ></adl-text-title>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-text-offices">
      <adl-form-checkbox
        id="app-form-checkbox_data-treatment"
        [parentForm]="formGroup"
        [fieldControl]="formGroup.controls['checkDataTreatment']"
        [typeMessageBox]="messageEnum.PERSONAL_DATA_INFO"
        [text]="
          'El consumidor financiero autoriza al BANCO POPULAR a realizar el tratamiento de sus datos personales'
        "
        [textSeeMore]="'Ver mas...'"
        (seeMoreEvent)="seeMoreDataTreatment()"
      >
      </adl-form-checkbox>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-text-offices">
      <adl-form-checkbox
        id="app-form-checkbox_credit-reporting"
        [parentForm]="formGroup"
        [fieldControl]="formGroup.controls['checkCreditReporting']"
        [typeMessageBox]="messageEnum.RISK_CENTERS_INFO"
        [text]="
          'El consumidor financiero autoriza al BANCO a realizar la consulta y reporte en centrales de información, además realiza las siguientes declaraciones'
        "
        [textSeeMore]="'Ver mas...'"
        (seeMoreEvent)="seeMoreCreditReporting()"
      >
      </adl-form-checkbox>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-button w-100">
      <adl-button
        id="adl-button_accept-data"
        [cssClass]="'w-100 buttonTextMedium'"
        [loading]="loadingButtonAuthorization"
        [mandatory]="true"
        [text]="'Si, acepto las consultas'"
        [parentForm]="formGroup"
        (clickEvent)="goToAuthentication()"
      ></adl-button>
    </div>

    <div class="h-20-px"></div>

    <div class="animation-button w-100">
      <adl-button
        id="adl-button_no-accept-data"
        [cssClass]="'w-100 buttonOutline buttonTextMedium'"
        [loading]="loadingButtonNoAuthorization"
        [mandatory]="false"
        [text]="'No acepto las consultas'"
        [parentForm]="formGroup"
        (clickEvent)="cancelAuthorization()"
      ></adl-button>
    </div>
  </form>
</div>

<app-form-popup-view-more
  #formPopupViewMore
  [hidden]="hiddenViewMore"
  (closePopupEvent)="closeSeeMore()"
  (closeSavePopupEvent)="closeSeeMore()"
></app-form-popup-view-more>
