import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "TermQuotas",
})
export class TermQuotas implements PipeTransform {
  transform(numberQuotas: number): string {
    return `${numberQuotas}` + (numberQuotas === 1 ? " mes" : " meses");
  }
}
