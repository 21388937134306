<div *ngIf="showPopupShortFlow">
  <adl-popup-simple
    [icon]="popupIcon"
    [title]="popupTitle"
    [message]="popupMessage"
    [textOkButton]="popuptextOkButton"
    [disabledOkButton]="popupdisabledtOkButton"
    (clickOkButton)="continueFlowReprecing()"
  >
    <form
      [formGroup]="formGroup"
      novalidate
      class="d-flex justify-content-center"
    >
      <div class="d-flex content-radio-buttons">
        <adl-radio-button
          *ngFor="let radioButton of radioButtons; let i = index"
          [parentForm]="formGroup"
          [fieldControl]="formGroup.controls['shortFlow']"
          [radioId]="i + 1"
          [radioButtonInfo]="radioButton"
          (clickRadioButton)="selectedValue()"
        >
        </adl-radio-button>
      </div>
    </form>
  </adl-popup-simple>
</div>
