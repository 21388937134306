import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-validation-method",
  template: "<app-one-column></app-one-column>",
})
export class ValidationMethodComponent implements OnInit {
  decision: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.decision = params.decision;
    });
    this.router.navigate(
      [
        "onecolumn",
        { outlets: { "part-center": ["validationmethodview", this.decision] } },
      ],
      {
        skipLocationChange: true,
        queryParams: this.route.snapshot.queryParams,
      },
    );
  }
}
