import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidateResponseService {
  validateSearchAdvisor(response: any) {
    let validate = true;

    if (
      response.identificationNumber === undefined ||
      response.name === undefined ||
      response.accessKey === undefined ||
      response.secretKey === undefined ||
      response.sessionToken === undefined
    ) {
      validate = false;
    }

    return validate;
  }

  validateGenerateOtp(response: any) {
    let validate = true;

    if (
      response.code === undefined ||
      response.message === undefined ||
      response.requestUID === undefined
    ) {
      validate = false;
    }

    return validate;
  }

  validateAuthenticationOtp(response: any) {
    let validate = true;

    if (
      response.completeName === undefined ||
      response.token === undefined ||
      response.generatedId === undefined ||
      response.returnCode === undefined
    ) {
      validate = false;
    }

    return validate;
  }
}
