import { Injectable } from "@angular/core";
import {
  IStep,
  ProgressBarStepTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import * as stepData from "../data/step-data.json";

@Injectable({
  providedIn: "root",
})
export class StepRemoteService {
  getStepByEnum(progressBarStepTypeEnum: ProgressBarStepTypeEnum): IStep[] {
    return (stepData as any).default.filter(
      (step: IStep) => step.id === progressBarStepTypeEnum,
    );
  }
}
