import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { IEventAnalytics } from "../../core/interfaces/event-analytics.interface";
import { ITransactionAudit } from "../../core/interfaces/transaction-audit.interface";

@Injectable({
  providedIn: "root",
})
export class EventAnalyticsRemote {
  constructor(private httpClient: HttpClient) {}

  async sendEventAnalytics(transactionAudit: ITransactionAudit) {
    return this.httpClient
      .post(
        environment.serverUrlSalaryLoans + "transaction-audit-analytics/",
        transactionAudit,
      )
      .toPromise();
  }

  sendNewEventAnalytics(
    eventAnalitics: IEventAnalytics,
    accessToken: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    return this.httpClient.post(
      `${environment.serverUrlPublicResources}analytic-events/send`,
      eventAnalitics,
      { headers },
    );
  }
}
