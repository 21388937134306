import { Injectable } from "@angular/core";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";

import { PayrollCheckCaptureData } from "../../core/interfaces/payroll-check-capture-data.interface";

@Injectable({
  providedIn: "root",
})
export class PayrollCheckCaptureDataService {
  constructor() {}

  getIncorrectValuesString(
    captureData: PayrollCheckCaptureData,
    formValuesCheck: any,
  ) {
    let dataIncorrect = {};
    for (const key in captureData) {
      if (formValuesCheck[key]) {
        dataIncorrect = {
          ...dataIncorrect,
          [this.mapPropertyNames()[key]]: captureData[key],
        };
      }
    }
    const dataString = JSON.stringify(dataIncorrect);
    return dataString === ApplicationConstantsEnum.EMPTY_OBJECT
      ? null
      : dataString;
  }

  transformEmptyProperties(data: PayrollCheckCaptureData) {
    data.totalPayment = data.totalIncome - data.totalDiscount;
    for (const key in data) {
      data[key] =
        data[key] === null ? ApplicationConstantsEnum.NOT_APPLY : data[key];
    }
    return data;
  }

  private mapPropertyNames() {
    return {
      affiliationNumber: "Número de afiliacion",
      bonuses: "Bonificaciones",
      customerName: "Nombre cliente",
      documentNumber: "Número documento",
      extraPayment: "Primas",
      healthDiscount: "Salud",
      honorarium: "Honorarios",
      otherDiscount1: "Descuento 1",
      otherDiscount2: "Descuento 2",
      otherDiscount3: "Descuento 3",
      otherLawDiscount: "Otros descuentos de ley",
      overtime: "Horas extras",
      payerName: "Nombre pagaduria",
      payrollCheckDate: "Fecha de Nomina del desprendible",
      pensionDiscount: "Pensión",
      position: "Cargo",
      positionLevel: "Nivel",
      salary: "Sueldo",
      totalOtherIncome: "Otros ingresos",
    };
  }
}
