export enum ToggleEnum {
  ACCOUNTS_WITH_STATES = "ACCOUNTS_WITH_STATES",
  ADL_TEAM_CLIENT_VALIDATION = "ADL_TEAM_CLIENT_VALIDATION",
  AUTHENTICATION_ACTIVE_DIRECTORY = "AUTHENTICATION_ACTIVE_DIRECTORY",
  BANKING_INSURANCE = "BANKING_INSURANCE",
  CERTIHUELLA_FUNCTION = "CERTIHUELLA_FUNCTION",
  CERTIHUELLA_PHONE_FUNCTION = "CERTIHUELLA_PHONE_FUNCTION",
  COMPARE_RATE_TOOGLE = "COMPARE_RATE_TOOGLE",
  DECRIM_PHONE_FUNCTION = "DECRIM_PHONE_FUNCTION",
  EXTRACTION_AND_INTERPRETER = "EXTRACTION_AND_INTERPRETER",
  FEDERATED_AUTHENTICATION = "FEDERATED_AUTHENTICATION",
  FRONT_TRANSVERSAL_ASISTIDO = "FRONT_TRANSVERSAL_ASISTIDO",
  LOYALTY_SAME_FEE = "LOYALTY_SAME_FEE",
  NEW_CLIENT_ACCESS = "NEW_CLIENT_ACCESS",
  NEW_CLIENT_EDUCATIONAL_SECTOR_TOGGLE = "NEW_CLIENT_EDUCATIONAL_SECTOR_TOGGLE",
  ONBASE_LOAD_DOCUMENT_FEATURE_TOGGLE = "ONBASE_LOAD_DOCUMENT_FEATURE_TOGGLE",
  ORGANIZATION_LAMBDAS_FEATURE_TOGGLE = "ORGANIZATION_LAMBDAS_FEATURE_TOGGLE",
  OWNERSHIP_REFACTOR_TOGGLE = "OWNERSHIP_REFACTOR_TOGGLE",
  PAYROLL_CHECK_OPTIMIZATION = "PAYROLL_CHECK_OPTIMIZATION",
  PAYROLL_CHECK_OPTIMIZATION_II = "PAYROLL_CHECK_OPTIMIZATION_II",
  PAYSTUBS_SYGNUS_REQUIRED = "PAYSTUBS_SYGNUS_REQUIRED",
  PREVALIDATION_SIMULATION = "PREVALIDATION_SIMULATION",
  RECAPTCHA = "RECAPTCHA",
  SIM_VALIDATION = "SIM_VALIDATION",
  SHORT_FLOW_CALL_CENTER_REPRICING_TOGGLE = "SHORT_FLOW_CALL_CENTER_REPRICING_TOGGLE",
  TELEPHONIC_MODEL = "TELEPHONIC_MODEL",
  TWILIO_SMS_AND_CALL = "TWILIO_SMS_AND_CALL",
  UNIFICATION_FLOW_TOOGLE = "UNIFICATION_FLOW_TOOGLE",
  UPLOAD_FACIAL_IDENTIFICATION_DECRIM_TOGGLE = "UPLOAD_FACIAL_IDENTIFICATION_DECRIM_TOGGLE",
  USE_FOOTPRINTS_NEW_SERVICE_TOGGLE = "USE_FOOTPRINTS_NEW_SERVICE_TOGGLE",
  USE_INTERNAL_BANK_ODM = "USE_INTERNAL_BANK_ODM",
  USE_NEW_GROWTH_POLICY = "USE_NEW_GROWTH_POLICY",
  USE_NEW_SIMULATION_SERVICE = "USE_NEW_SIMULATION_SERVICE",
  USE_NEW_SIMULATION_SERVICE_SAME_FEE = "USE_NEW_SIMULATION_SERVICE_SAME_FEE",
  SHORT_FLOW_REPRICING_V2 = "SHORT_FLOW_REPRICING_V2",
  SHORT_FLOW_REPRICING_V3 = "SHORT_FLOW_REPRICING_V3",
}
