import { IMapTableFabric } from "./map-table-fabric.interface";

import { TableColumnTypeEnum } from "../../core/enums/table-column-type.enum";

export class UploadDocumentsMap implements IMapTableFabric {
  getMapTableData(): any[] {
    return [
      {
        field: "name",
        fieldList: "salaryLoans",
        fieldListView: "payerName",
        title: "Información del cliente",
        columnType: TableColumnTypeEnum.TEXT_WITH_LIST,
      },
      {
        field: "documentType",
        title: "",
        columnType: TableColumnTypeEnum.TEXT,
      },
      {
        field: "documentNumber",
        title: "",
        columnType: TableColumnTypeEnum.TEXT,
      },
      {
        field: "documentNumber",
        title: "",
        columnType: TableColumnTypeEnum.UPLOAD,
      },
    ];
  }
}
