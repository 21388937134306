import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { IAdvisor } from "./core/interfaces/advisor.interface";

import { AdvisorDecisionTypeEnum } from "./core/enums/advisor-decision-type.enum";

import { AdvisorService } from "./services/local/advisor.service";
import { CustomerService } from "./services/local/customer.service";
import { LoanService } from "./services/local/loan.service";

@Injectable({
  providedIn: "root",
})
export class AppAccessGuard implements CanActivate {
  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private loanService: LoanService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (window.name !== "") {
      const userObject = JSON.parse(atob(window.name));
      this.advisorService.setExternalAdvisor(userObject);
      this.customerService.setExternalCustomer(userObject);
      this.loanService.setLoanFlowChanged(userObject.creditFlowTypeChanged);
      this.setAdvisorDecision();
      window.name = "";
    }

    const currentAdvisor: IAdvisor = this.advisorService.getAdvisor();

    if (
      currentAdvisor !== undefined &&
      currentAdvisor.federatedAuthToken !== undefined
    ) {
      return true;
    }

    this.router.navigate(["loginadvisor"]);

    return false;
  }

  private setAdvisorDecision() {
    if (this.advisorService.getAdvisor().isUnification) {
      this.advisorService.setAdvisor({
        ...this.advisorService.getAdvisor(),
        decisionType: AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
      });
    }
  }
}
