import { Injectable } from "@angular/core";

import { IGoogleAnalyticEvent } from "../../core/interfaces/google-analytic-event.interface";

import * as googleAnalyticsEventsData from "../data/google-analytics-events-data.json";

declare let ga: Function;
declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsEventsRemoteService {
  private clientId: string;

  sendEvent(googleAnalyticsEvent: IGoogleAnalyticEvent) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "event",
      eventCategory: googleAnalyticsEvent.eventCategory,
      eventAction: googleAnalyticsEvent.eventAction,
      eventLabel: googleAnalyticsEvent.eventLabel,
    });
  }

  sendEventNavigate(event: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Pageview",
      pagePath: event.url,
      pageTitle: "",
    });
  }

  saveClientId() {
    const $that = this;

    ga((tracker: any) => {
      $that.clientId = tracker.get("clientId");
    });
  }

  getClientId() {
    return this.clientId;
  }

  searchGoogleAnalyticEventData(
    eventId: string,
    url: string,
  ): IGoogleAnalyticEvent {
    return this.mapSearchGoogleAnalyticEventData(eventId, url);
  }

  private mapSearchGoogleAnalyticEventData(
    eventId: string,
    url: string,
  ): IGoogleAnalyticEvent {
    const googleAnalyticsEvents: IGoogleAnalyticEvent = {
      ...(googleAnalyticsEventsData as any).default.find(
        (event: any) => event.eventId === eventId,
      ),
    };

    if (googleAnalyticsEvents.eventLabel) {
      googleAnalyticsEvents.eventLabel = googleAnalyticsEvents.eventLabel.replace(
        /{url}/,
        url,
      );

      googleAnalyticsEvents.eventValue = googleAnalyticsEvents.eventValue.replace(
        /{dateNow}/,
        Date.now(),
      ) as number;
    }

    return googleAnalyticsEvents;
  }
}
