import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { find, findIndex, propEq } from "ramda";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { UploadDocumentsStatesEnum } from "../../core/enums/upload-documents-states.enum";

import { OnbaseNotificationRemoteService } from "../remote/onbase-notification-remote.service";

@Injectable({
  providedIn: "root",
})
export class OnbaseNotificationService {
  disableButton = new BehaviorSubject<boolean>(true);
  currentDisabledBotton = this.disableButton.asObservable();

  notification: BehaviorSubject<any> = new BehaviorSubject([]);
  currentNotification = this.notification.asObservable();

  private documentFiles: any[];
  private fileNumber: number = 0;
  private updateData: any[] = [];

  constructor(
    private onbaseNotificationRemoteService: OnbaseNotificationRemoteService,
  ) {}

  changeNotification(notification: any, isInvalidFile: boolean) {
    if (this.notification.value) {
      const notificationName = find(propEq(notification.name, "name"))(
        this.notification.value,
      );

      notification.status = false;
      notification.documentComplete = false;
      notification.documentUpload =
        UploadDocumentsStatesEnum.DOCUMENT_LOAD_VALIDATE;

      this.updateData = this.notification.value;

      if (isInvalidFile) {
        notification.documentUpload =
          UploadDocumentsStatesEnum.DOCUMENTS_LOAD_EMPTY;
      }
      if (!notificationName) {
        this.updateData.push(notification);
        this.fileNumber += 1;
      }
    }
    this.notification.next(this.updateData);
  }

  deleteExistingItem(notification: any) {
    const index = findIndex(propEq(notification.name, "name"))(this.updateData);

    if (index >= 0) {
      if (this.updateData.length === 1) {
        this.updateData = [];
      } else {
        this.updateData.splice(index, 1);
      }

      this.fileNumber = this.fileNumber < 0 ? 0 : this.fileNumber - 1;
    }
    this.notification.next(this.updateData);
  }

  documentHasFile() {
    let isDisable: boolean;
    const documents: any[] =
      this.notification.value.length > ApplicationConstantsEnum.EMPTY_ARRAY
        ? this.notification.value
        : this.documentFiles;

    if (this.fileNumber === this.documentFiles.length) {
      const documentWithFile = documents.findIndex((item: any) => {
        return item.documentUpload !== UploadDocumentsStatesEnum.DOCUMENTS_LOAD;
      });

      isDisable = documentWithFile >= 0;
    } else {
      isDisable = true;
    }
    this.disableButton.next(isDisable);
  }

  getDocumentFiles() {
    return this.documentFiles;
  }

  notificationDetails(notification: any) {
    if (notification.reasons.length === 0) {
      switch (notification.status) {
        case true:
          return this.notificationSearch("success");
        case false:
          return this.notificationSearch("upload");
        default:
          return this.notificationSearch("none");
      }
    } else {
      return this.notificationSearch("error");
    }
  }

  removeNotifications() {
    this.notification.next([]);
    this.disableButton.next(true);
  }

  setDocumentFiles(documentFiles: any[]) {
    const documents = documentFiles.filter((item: any) => {
      return item.documentUpload === UploadDocumentsStatesEnum.DOCUMENTS_LOAD;
    });

    this.fileNumber = documents.length;
    this.documentFiles = documentFiles;
  }

  private notificationSearch(notificationId: string) {
    return this.onbaseNotificationRemoteService.notificationSearch(
      notificationId,
    );
  }
}
