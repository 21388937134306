<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title [text]="'Bienvenido'" [level]="1"></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description [text]="description"></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-text-document">
      <adl-form-field
        #searchInput
        (focusTextEvent)="focusAdvisorDocument()"
        (keyup.enter)="search()"
        [lengthInput]="'15'"
        [fieldControl]="formGroup.controls['textDocumentAdvisor']"
        [loading]="loadingDocumentAdvisor"
        [parentForm]="formGroup"
        [leaveFocus]="leaveFocusTextAdvisor"
        [text]="'Ingresa cédula'"
        type="search"
        id="app-form-field_advisor-login-document"
        appOnlyNumber
      ></adl-form-field>
    </div>

    <div class="h-25-px"></div>

    <ng-container *ngIf="showSearchAdvisorSuccess">
      <div class="animation-text-offices">
        <adl-form-select
          [fieldControl]="formGroup.controls['textOffices']"
          [valueControl]="formGroup.controls['valueOffices']"
          [lengthInput]="'25'"
          [listSelect]="listOffices"
          [parentForm]="formGroup"
          [text]="'Selecciona la oficina a la que perteneces'"
          id="app-form-select_advisor-login-offices"
        ></adl-form-select>
      </div>

      <div class="h-25-px"></div>

      <div class="animation-text-offices" *ngIf="showAdministrativeOffice">
        <adl-form-select
          [fieldControl]="formGroup.controls['textAdministrativeOffices']"
          [valueControl]="formGroup.controls['valueAdministrativeOffices']"
          [lengthInput]="'25'"
          [listSelect]="listAdministrativeOffices"
          [parentForm]="formGroup"
          [text]="'Selecciona la sala de éxito a la que perteneces'"
          id="app-form-select_advisor-login-administrative-offices"
        ></adl-form-select>
      </div>

      <div class="h-25-px"></div>

      <div class="animation-button">
        <adl-button
          [cssClass]="'w-50'"
          [loading]="loadingButtonAdvisor"
          [mandatory]="true"
          [parentForm]="formGroup"
          [text]="'Continuar'"
          (clickEvent)="goToDecision()"
          id="adl-button_advisor-login"
        ></adl-button>
      </div>
    </ng-container>
  </form>
</div>

<app-form-popup-message-box
  #formPopupMessageBox
  (closePopupEvent)="closeMessageBox()"
></app-form-popup-message-box>
