import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMimeTypeConstantsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { HttpHeaderConstantsEnum } from "../../core/enums/http-header-constants.enum";

@Injectable({
  providedIn: "root",
})
export class AwsSignV4RemoteService {
  getHeadersAwsSignV4(contentType: HttpMimeTypeConstantsEnum): HttpHeaders {
    return new HttpHeaders()
      .set(HttpHeaderConstantsEnum.X_AWS_SIGNATURE_V4, "true")
      .set(HttpHeaderConstantsEnum.CONTENT_TYPE, contentType);
  }
}
