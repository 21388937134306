import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IMessage } from "src/app/core/interfaces/message.interface";

@Component({
  selector: "app-popup-payrollcheck-upload-response",
  templateUrl: "./popup-payrollcheck-upload-response.component.html",
  styleUrls: ["./popup-payrollcheck-upload-response.component.scss"],
})
export class PopupPayrollcheckUploadResponseComponent {
  @Input() message: IMessage;
  @Input() attempt: number;
  @Input() attemptMax: number;
  formGroup = new FormGroup({});
  protected canShow = false;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() clickButtonOk = new EventEmitter<boolean>();

  show() {
    this.canShow = true;
  }

  close() {
    this.canShow = false;
  }

  onClickButtonOk() {
    this.close();
    this.clickButtonOk.emit(true);
  }
}
