import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";

import { MenuService } from "src/app/services/local/menu.service";

@Component({
  selector: "app-form-popup-strong-authentication",
  templateUrl: "./form-popup-strong-authentication.component.html",
  styleUrls: ["./form-popup-strong-authentication.component.scss"],
})
export class FormPopupStrongAuthenticationComponent implements OnInit {
  menu = [];

  @Output() closePopupEvent = new EventEmitter<string>();
  @Output() goToDecisionEvent = new EventEmitter<any>();

  @Input("popupStrongAuthentication")
  set setPopupStrongAuthentication(popupStrongAuthentication: boolean) {
    this.popupStrongAuthentication = popupStrongAuthentication;
  }

  constructor(private menuService: MenuService) {}

  get advisorDecisionTypeEnum() {
    return AdvisorDecisionTypeEnum;
  }

  decisionType: AdvisorDecisionTypeEnum;
  description: string;
  icon: string;
  popupStrongAuthentication: boolean;

  formGroup: UntypedFormGroup;

  ngOnInit() {
    this.menu = this.menuService.getMenuTelephonic();
    this.description =
      "¡Selecciona la acción de Libranzas Telefónica que necesites!";
    this.icon = "../../../../assets/images/decision/icon-warning.svg";

    this.initForm();
  }

  closePopup() {
    this.closePopupEvent.emit();
  }

  goToDecision(decisionTypeEnum) {
    this.goToDecisionEvent.emit(decisionTypeEnum);
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({});
  }
}
