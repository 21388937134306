<adl-background-black></adl-background-black>
<form class="form-popup" [formGroup]="formGroup" novalidate>
  <div class="w-100 h-50-px d-flex justify-content-center">
    <div class="w-90 d-flex align-items-center justify-content-end">
      <adl-button-close (click)="closePopup()"></adl-button-close>
    </div>
  </div>
  <div class="w-100 h-90-px d-flex justify-content-center">
    <div class="w-85 text-center">
      <adl-text-title [level]="5" [text]="title"></adl-text-title>
    </div>
  </div>
  <div class="w-100 d-flex align-items-center justify-content-center">
    <div class="w-85">
      <adl-form-text-overflow-y
        [cssClass]="'h-260-px'"
        [cssClassDiv]="'h-260-px'"
        [text]="contentText"
      ></adl-form-text-overflow-y>
    </div>
  </div>
  <div class="w-100 h-110-px d-flex align-items-center justify-content-center">
    <div class="w-85">
      <adl-button
        id="adl-button_button-close"
        [cssClass]="'w-100 buttonTextMedium'"
        [mandatory]="true"
        [text]="'Continuar'"
        [parentForm]="formGroup"
        (clickEvent)="closeSavePopup()"
      ></adl-button>
    </div>
  </div>
</form>
