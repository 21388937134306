import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

import { ApplicationConstantsEnum } from "../enums/applications-constants.enum";

export function RnecCodeDirectiveValidate(rnecCodeDirectiveValidate: {
  value: boolean;
}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let answer: any = {
      rnecCodeDirectiveValidate: false,
    };

    if (
      control.value.length ===
      ApplicationConstantsEnum.NUMBER_MAX_LENGTH_CODE_RNEC
    ) {
      const regex = /[ °¬!¡@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/¿?¨´~]/g;

      if (
        control.value.indexOf(ApplicationConstantsEnum.CHARACTER_SPACE) ===
        ApplicationConstantsEnum.CHARACTER_NOT_FOUND
      ) {
        if (!regex.test(control.value)) {
          answer = null;
        }
      }
    }

    return answer;
  };
}
