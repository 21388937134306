<div id="main" class="d-flex flex-column justify-content-center">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-7 col-lg-6 content-message">
      <div class="d-flex justify-content-center">
        <img
          src="./assets/images/onbase_load/information_success.svg"
          alt="Icon information success"
        />
      </div>
      <div class="w-100 d-flex justify-content-center">
        <div class="w-85 d-flex align-items-center">
          <adl-text-title [text]="'Cargue de documentos'" [level]="2">
          </adl-text-title>
        </div>
      </div>

      <adl-content-message
        [icon]=""
        [title]=""
        [description]="descriptionTable1"
      ></adl-content-message>

      <div class="h-20-px"></div>

      <div class="w-100 d-flex justify-content-center">
        <div class="w-85">
          <app-table
            [dataTable]="uploadDocumentsList"
            [tableType]="tableTypeEnum.UPLOAD_DOCUMENTS"
            (commandEvent)="onClickContinue($event, false)"
          ></app-table>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-7 col-lg-6 content-message mt-3">
      <adl-form-finder
        icon="./assets/images/onbase_load/search.svg"
        description="Busca tu cliente por número de cédula"
        (searchEvent)="onClickCustomerDocumentsFinder($event)"
        (searchChangeEvent)="onChangeCustomerDocument()"
      ></adl-form-finder>

      <div class="w-100 d-flex justify-content-center">
        <div
          class="w-85 mb-4 overflow-auto border container-table"
          *ngIf="isSearch"
        >
          <table
            id="table"
            aria-label="loans find"
            *ngIf="clientDocumentsSearch?.length"
            class="table table-hover table-sm"
          >
            <thead>
              <tr *ngFor="let item of clientDocumentsSearch; let i = index">
                <th scope="col" id="title-{{ i }}">
                  {{ item.name | titlecase }}
                </th>
                <th scope="col">
                  <span class="mr-2">{{ item.documentType }}</span>
                  <span>{{ item.documentNumber }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of clientDocumentsSearch">
                <tr *ngFor="let loan of item.salaryLoans; let i = index">
                  <td class="w-70">
                    {{ loan.payer | titlecase }}
                  </td>
                  <td class="pr-2 w-30">
                    <span
                      *ngIf="!loan.canAllowUpload"
                      id="upload-response-{{ i }}"
                      >{{ loan.statusName }}</span
                    >
                    <div
                      *ngIf="loan.canAllowUpload"
                      class="d-flex cursor-pointer"
                      id="upload-{{ i }}"
                      (click)="
                        onClickContinue(
                          {
                            documentType: item.documentType,
                            documentNumber: item.documentNumber,
                            factoryFileId: loan.factoryFileId,
                            statusId: loan.statusId,
                            payer: loan.payer
                          },
                          true
                        )
                      "
                    >
                      <span
                        class="mr-1"
                        *ngIf="
                          loan.statusId === documentStatus.DOCUMENTS_TO_LOAD
                        "
                      >
                        Cargar ahora
                      </span>
                      <span
                        class="mr-1"
                        *ngIf="
                          loan.statusId !== documentStatus.DOCUMENTS_TO_LOAD
                        "
                      >
                        {{ loan.statusName }}
                      </span>
                      <img
                        src="./assets/images/onbase_load/add.svg"
                        alt="Icon add"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div
            class="d-flex justify-content-center align-items-center h-100"
            *ngIf="!clientDocumentsSearch?.length"
          >
            <span>El cliente no tiene documentos por cargar</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-7 col-lg-6 content-message mt-3">
      <div class="w-100 d-flex justify-content-center">
        <div class="w-85 d-flex align-items-center">
          <adl-text-title
            [text]="'Consulta (' + (clientDocuments?.length || '') + ')'"
            [level]="2"
          >
          </adl-text-title>
        </div>
      </div>

      <adl-content-message
        [icon]=""
        [title]=""
        [description]="descriptionTable2"
      ></adl-content-message>

      <div class="h-20-px"></div>

      <div class="w-100 d-flex justify-content-center">
        <div class="w-85">
          <adl-notification-message
            severity="info"
            message="Recuerda comprobar si tu cliente necesita autenticación fuerte"
          >
          </adl-notification-message>

          <div class="h-20-px"></div>
          <app-table
            [dataTable]="clientDocuments"
            [tableType]="tableTypeEnum.UPLOAD_DOCUMENTS_STATE"
            [arrColorColumn]="arrColorColumn"
            (dataListEvent)="onClickContinueStates($event)"
          ></app-table>
        </div>
      </div>
      <div class="h-14-px"></div>
    </div>
  </div>
</div>

<adl-loading
  [show]="showLoading"
  [loadingCss]="loadingCss"
  [loadingDescription]="loadingDescription"
  [textLoading]="textLoading"
  [loadPercentage]="loadPercentage"
></adl-loading>
