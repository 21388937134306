export enum AdvisorDecisionTypeEnum {
  GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL = "GetNewPayrollLoanByPhoneCall",
  GET_PAYROLL_LOAN_STATUS = "GetPayrollLoanStatus",
  NEW_PAYROLL_LOAN = "NewPayrollLoan",
  NEW_PAYROLL_LOAN_UNIFICATION = "NewPayrollLoanUnification",
  NEW_PAYROLL_LOAN_BY_PHONE_CALL = "NewPayrollLoanByPhoneCall",
  SEND_STRONG_AUTHENTICATION_CUSTOMER_INFO = "SendStrongAuthenticationCustomerInfo",
  SEND_VIABILITY_QUERY = "SendViabilityQuery",
  UPLOAD_DOCUMENTS_ONBASE = "UploadDocumentsOnbase",
  PAYROLL_LOAN_REPRICING = "PayrollLoanRepricing",
}
