<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title [text]="title" [level]="2"></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="description"
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-nav-pills
        [pills]="pills"
        [checkMark]="false"
        [valueControl]="formGroup.controls['valuePill']"
        [parentForm]="formGroup"
      ></adl-nav-pills>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="
          'Ingresa con el código de seguridad de tu tarjeta débito y clave de cajero'
        "
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-form-field
        [lengthInput]="'6'"
        [fieldControl]="formGroup.controls['textSecurityCode']"
        [parentForm]="formGroup"
        [text]="'Código de seguridad'"
        appOnlyNumber
      ></adl-form-field>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-form-field
        [lengthInput]="'4'"
        [fieldControl]="formGroup.controls['textPassword']"
        [parentForm]="formGroup"
        [text]="'Clave de cajero'"
        appOnlyNumber
      ></adl-form-field>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-button">
      <adl-button
        [cssClass]="'w-50'"
        [mandatory]="false"
        [parentForm]="formGroup"
        [text]="'Ingresar'"
      ></adl-button>
    </div>
  </form>
</div>
