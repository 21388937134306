import { Injectable } from "@angular/core";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { LocalStorageKeyEnum } from "../../core/enums/local-storage-key.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { IToggle } from "../../core/interfaces/toggle.interface";

import { ToggleRemoteService } from "../remote/toggle-remote.service";

@Injectable({
  providedIn: "root",
})
export class ToggleService {
  private listToggle: IToggle[] = [];

  constructor(private toggleRemoteService: ToggleRemoteService) {}

  async resolve(): Promise<any | null> {
    await this.asyncToggle();
  }

  async asyncToggle() {
    if (localStorage.getItem(LocalStorageKeyEnum.TOGGLES) !== null) {
      this.listToggle = JSON.parse(
        localStorage.getItem(LocalStorageKeyEnum.TOGGLES),
      );
    }

    if (this.listToggle.length === 0) {
      const $this = this;
      await this.toggleRemoteService
        .asyncToggleList()
        .then((data: any) => {
          $this.listToggle = data;
          localStorage.setItem(
            LocalStorageKeyEnum.TOGGLES,
            JSON.stringify(data),
          );
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log(
            `ToggleService - asyncToggle - Error ${JSON.stringify(error)}`,
          );
        });
    }
  }

  getListToggle(): IToggle[] {
    return this.listToggle;
  }

  getToggleEnabledById(toggleEnum: ToggleEnum) {
    let enabled = false;

    const toggle = this.listToggle.filter((x) => x.featureName === toggleEnum);
    if (toggle.length > 0) {
      enabled = toggle[0].featureEnabled;
    }

    return enabled;
  }

  isChargedListToggle() {
    return this.listToggle.length > 0;
  }

  setToogleFeatureEnabled(toggleEnum: ToggleEnum, featureEnabled: boolean) {
    if (this.listToggle !== undefined && this.listToggle !== null) {
      const toggle = this.listToggle.filter(
        (x: any) => x.featureName === toggleEnum,
      );

      toggle[0].featureEnabled = featureEnabled;
    }
  }

  async validateUpdatedTogglesDate(updatedTogglesDate: any) {
    if (updatedTogglesDate !== ApplicationConstantsEnum.CHARACTER_EMPTY) {
      const currenlyUpdatedTogglesDate = localStorage.getItem(
        LocalStorageKeyEnum.UPDATEDTOGGLESDATE,
      );

      if (
        !currenlyUpdatedTogglesDate ||
        currenlyUpdatedTogglesDate ===
          ApplicationConstantsEnum.CHARACTER_EMPTY ||
        new Date(currenlyUpdatedTogglesDate) < new Date(updatedTogglesDate)
      ) {
        localStorage.removeItem(LocalStorageKeyEnum.TOGGLES);
        this.listToggle = [];

        await this.asyncToggle();

        localStorage.setItem(
          LocalStorageKeyEnum.UPDATEDTOGGLESDATE,
          updatedTogglesDate,
        );
      }
    }
  }
}
