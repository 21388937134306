import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "CapitalCasePipe" })
export class CapitalCasePipe implements PipeTransform {
  transform(stringTransform: string): string {
    let stringReturn = "";
    const stringArray = stringTransform.split(" ");

    stringArray.forEach((str: string) => {
      if (str.indexOf("¡") === -1) {
        if (stringReturn !== "") {
          stringReturn += " ";
        }
      } else {
        stringReturn += str.substr(0, 1);
      }

      const startIndex = str.indexOf("¡") === -1 ? 0 : str.indexOf("¡") + 1;
      const firstChar = str.substr(startIndex, 1).toUpperCase();
      const restOfString = str
        .substr(startIndex + 1, str.length - startIndex - 1)
        .toLowerCase();

      stringReturn += firstChar + restOfString;
    });

    return stringReturn;
  }
}
