export enum OverflowErrorsEnum {
  BucketFile001 = "BucketFile001",
  BucketFile002 = "BucketFile002",
  CUSTOMER_AGE_BAD_REQUEST = "CustomerAgeService8899",
  CUSTOMER_AGE_DATA_BASE_UNKNOW_ERROR = "CustomerAgeKnown9998",
  CUSTOMER_AGE_ERROR_TRANSACTION_BUSINESS = "CustomerAgeKnown9997",
  CUSTOMER_AGE_NOT_VIABLE = "CustomerAgeService8889",
  CustomerManagement001 = "CustomerManagement001",
  CustomerManagement014 = "CustomerManagement014",
  CustomerManagement015 = "CustomerManagement015",
  CustomerManagement018 = "CustomerManagement018",
  CustomerManagement019 = "CustomerManagement019",
  CustomerManagement022 = "CustomerManagement022",
  CustomerManagement023 = "CustomerManagement023",
  CustomerManagement026 = "CustomerManagement026",
  CustomerManagement9998 = "CustomerManagement9998",
  CustomerManagement9999 = "CustomerManagement9999",
  CUSTOMER_WITHOUT_LOANS = "CUSTOMER_WITHOUT_LOANS",
  ERROR_TIME_LIMIT = "ERROR_TIME_LIMIT",
  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS",
  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_DECRIM = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_DECRIM",
  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_CUSTOMER = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_CUSTOMER",
  EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_SIMULATION = "EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_SIMULATION",
  EXTRACTOR_SIMULATION_FAIL = "EXTRACTOR_SIMULATION_FAIL",
  FAILFLEXCUBEOWNERship001 = "failFlexcubeOwnership001",
  FAILFLEXCUBEOWNERship002 = "failFlexcubeOwnership002",
  FAILFLEXCUBEOWNERship003 = "failFlexCubeOwnership003",
  FAILFLEXCUBEOWNERship004 = "failFlexCubeOwnership004",
  FAILFLEXCUBEOWNERship005 = "failFlexcubeOwnership005",
  FAILFLEXCUBEOWNERship006 = "failFlexcubeOwnership006",
  FAILFLEXCUBEOWNERship007 = "failFlexcubeOwnership007",
  FAILFLEXCUBEOWNERship008 = "failFlexcubeOwnership008",
  FAILFLEXCUBEOWNERship009 = "failFlexcubeOwnership009",
  FAILFLEXCUBEOWNERship0010 = "failFlexcubeOwnership0010",
  BASE_NUMBER_ACCOUNTS = "0",
  FEDERATED_AUTH_INVALID_GRANT_ERROR = "FEDERATED_AUTH_INVALID_GRANT_ERROR",
  PayerPlatform02 = "PayerPlatform02",
  PayrollChecks9999 = "PayrollChecks9999",
  PayrollCheckUpload001 = "PayrollCheckUpload001",
  PayrollCheckUpload002 = "PayrollCheckUpload002",
  PayrollCheckUpload003 = "PayrollCheckUpload003",
  SecurityManager0019 = "SecurityManager0019",
  SecurityManager0025 = "SecurityManager0025",
  TECHNICAL_ERROR = "TECHNICAL_ERROR",
  UNVIABLE_CUSTOMER_REPRICING = "UNVIABLE_CUSTOMER_REPRICING",
}
