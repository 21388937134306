export class AwsSignatureInputData {
  method: string;
  service: string;
  host: string;
  region: string;
  endpoint: string;
  requestParameters: string;
  contentType: string;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  canonicalUri: string;
  canonicalQuerystring: string;
  path: string;
}
