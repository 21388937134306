import { Injectable } from "@angular/core";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IAdministrativeOffice } from "../../core/interfaces/administrative-office.interface";
import { IOffice } from "../../core/interfaces/office.interface";

import { OrganizationManagerRemoteService } from "../remote/organization-manager-remote.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationManagerService {
  administrativeOffices: IAdministrativeOffice[];
  offices: IOffice[];

  constructor(
    private organizationManagerRemoteService: OrganizationManagerRemoteService,
  ) {}

  searchAdministrativeOfficesSelect(
    cognitoToken: string = "",
  ): Observable<IListSelect[]> {
    return this.organizationManagerRemoteService
      .searchAdministrativeOffice(cognitoToken)
      .pipe(
        map((dataAdministrativeOffice: any) => {
          const listAdministrativeOffices: IListSelect[] = [];
          this.administrativeOffices = dataAdministrativeOffice;

          dataAdministrativeOffice.forEach((data: any) => {
            listAdministrativeOffices.push({
              value: data.code,
              text: data.name + " - " + data.code,
            });
          });

          return listAdministrativeOffices;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getAdministrativeOffices() {
    return this.administrativeOffices;
  }

  getAdministrativeOfficeByCode(code: number) {
    return this.administrativeOffices.filter((x) => x.code === code).length > 0
      ? this.administrativeOffices.filter((x) => x.code === code)[0]
      : undefined;
  }

  searchOfficesSelect(cognitoToken: string = ""): Observable<IListSelect[]> {
    return this.organizationManagerRemoteService
      .searchOffices(cognitoToken)
      .pipe(
        map((dataOffices: any) => {
          const listOffices: IListSelect[] = [];
          this.offices = dataOffices;

          dataOffices.forEach((data: any) => {
            listOffices.push({
              value: data.idoffice,
              text: data.name_office + " - " + data.idoffice,
            });
          });

          return listOffices;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getOffices() {
    return this.offices;
  }

  getOfficeByIdOffice(idoffice: number) {
    return this.offices.filter((x) => x.idoffice === idoffice).length > 0
      ? this.offices.filter((x) => x.idoffice === idoffice)[0]
      : undefined;
  }
}
