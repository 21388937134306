<div id="main" class="d-flex flex-column justify-content-center">
  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6">
        <adl-progress-step-bar-with-number
          [steps]="steps"
        ></adl-progress-step-bar-with-number>
      </div>
    </div>

    <div class="h-30-px"></div>

    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-8 col-lg-8 content-message">
        <adl-content-message [icon]="" [title]="title" [description]="">
        </adl-content-message>
        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <adl-information-bar> </adl-information-bar>
          </div>
        </div>

        <div class="h-30-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85">
            <adl-radio-group-with-state></adl-radio-group-with-state>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85 d-flex">
            <adl-text-title
              [text]="descriptionText"
              [level]="5"
            ></adl-text-title>
            <div class="p-l-10-px" *ngIf="toolTipText !== ''">
              <adl-information-tool-tip
                [text]="toolTipText"
              ></adl-information-tool-tip>
            </div>
          </div>
        </div>

        <div class="h-30-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-85 d-flex">
            <div class="animation-text-offices">
              <adl-form-checkbox
                id="app-form-checkbox_data-treatment"
                [parentForm]="formGroup"
                [fieldControl]="formGroup.controls['checkDataTreatment']"
                [text]="'Pagaré'"
              >
              </adl-form-checkbox>
            </div>

            <div class="animation-text-offices">
              <adl-form-checkbox
                id="app-form-checkbox_order"
                [parentForm]="formGroup"
                [fieldControl]="formGroup.controls['checkDataTreatment']"
                [text]="'Orden de descuento'"
              >
              </adl-form-checkbox>
            </div>

            <div class="animation-text-offices">
              <adl-form-checkbox
                id="app-form-checkbox_discount-form"
                [parentForm]="formGroup"
                [fieldControl]="formGroup.controls['checkDataTreatment']"
                [text]="'Planilla autorización descuento'"
              >
              </adl-form-checkbox>
            </div>
          </div>
        </div>

        <div class="h-30-px"></div>

        <div class="row d-flex justify-content-center">
          <div class="w-289-px h-50-px">
            <adl-button
              id="adl-button_button-upload-decrim"
              [mandatory]="true"
              [text]="captionButton"
              [parentForm]="formGroup"
            ></adl-button>
          </div>
        </div>

        <div class="h-30-px"></div>
      </div>
    </div>
  </form>
</div>
