import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICourse } from "../../../core/interfaces/course.interface";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { LoanService } from "../../../services/local/loan.service";
import { ToggleService } from "../../../services/local/toggle.service";

import { FormPopupStrongAuthenticationComponent } from "../form-popup-strong-authentication/form-popup-strong-authentication.component";
import { FormPopupTrainingComponent } from "../form-popup-training/form-popup-training.component";

@Component({
  selector: "app-loan-form-decision",
  styleUrls: ["./form-loan-decision.component.scss"],
  templateUrl: "./form-loan-decision.component.html",
})
export class FormLoanDecisionComponent implements OnInit {
  @ViewChild(FormPopupStrongAuthenticationComponent, { static: false })
  formPopupStrongAuthenticationComponent: FormPopupStrongAuthenticationComponent;

  set advisorDecisionTypeEnum(advisorDecisionTypeEnum) {}
  get advisorDecisionTypeEnum() {
    return AdvisorDecisionTypeEnum;
  }

  decisionStrongAuthentication: any;
  isStrongAuthenticationCustomerInfo: boolean;
  toggleOnbaseLoad: boolean;
  togglePrevalidationSimulation: boolean;
  toggleTelephonicModel: boolean;

  advisor: IAdvisor;
  courses: ICourse[];
  formPopupTraining: FormPopupTrainingComponent;

  constructor(
    private advisorService: AdvisorService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.DECISION_INIT_PAGE,
    );

    this.setStrongAuthenticationCustomer();
    this.setToggleOnbaseLoad();
    this.setToggleTelephonicModel();

    this.advisor = this.advisorService.getAdvisor();
    this.loanService.setLoanFlowChanged(undefined);
  }

  closeStrongAuthenticationPopup() {
    this.formPopupStrongAuthenticationComponent.setPopupStrongAuthentication = false;
  }

  goToDecision(decision: string) {
    this.googleAnalyticsEventsService.emitEvent(
      decision === AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN
        ? GoogleAnalyticsEventsEnum.DECISION_CLICK_NEW_PAYROLL_LOAN
        : decision === AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
        ? GoogleAnalyticsEventsEnum.DECISION_CLICK_NEW_PAYROLL_LOAN_BY_PHONE_CALL
        : decision ===
          AdvisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL
        ? GoogleAnalyticsEventsEnum.DECICION_CLICK_GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL
        : GoogleAnalyticsEventsEnum.DECISION_CLICK_SEND_STRONG_AUTHENTICATION_CUSTOMER_INFO,
    );

    this.advisor.decisionType =
      decision === AdvisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
      !this.isStrongAuthenticationCustomerInfo
        ? AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
        : (decision as AdvisorDecisionTypeEnum);

    if (
      this.advisor.decisionType ===
      AdvisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL
    ) {
      this.formPopupStrongAuthenticationComponent.setPopupStrongAuthentication = true;
    } else if (
      this.advisor.decisionType ===
      AdvisorDecisionTypeEnum.SEND_STRONG_AUTHENTICATION_CUSTOMER_INFO
    ) {
      this.advisor.decisionType =
        AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL;
      this.router.navigate(["validationmethod", decision], {
        queryParams: this.decisionStrongAuthentication,
      });
    } else {
      this.router.navigate(["logincustomer", decision]);
    }
  }

  get isUnificationFlowActive() {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  private setStrongAuthenticationCustomer() {
    this.decisionStrongAuthentication = {
      r: this.toggleService.getToggleEnabledById(
        ToggleEnum.CERTIHUELLA_PHONE_FUNCTION,
      ),
      d: this.toggleService.getToggleEnabledById(
        ToggleEnum.DECRIM_PHONE_FUNCTION,
      ),
    };

    this.isStrongAuthenticationCustomerInfo =
      this.decisionStrongAuthentication.r ||
      this.decisionStrongAuthentication.d;
  }

  private setToggleOnbaseLoad() {
    this.toggleOnbaseLoad = this.toggleService.getToggleEnabledById(
      ToggleEnum.ONBASE_LOAD_DOCUMENT_FEATURE_TOGGLE,
    );
  }

  private setToggleTelephonicModel() {
    this.toggleTelephonicModel = this.toggleService.getToggleEnabledById(
      ToggleEnum.TELEPHONIC_MODEL,
    );
  }
}
