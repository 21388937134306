import { Injectable } from "@angular/core";
import {
  INavPill,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { InitialNavPillsRemoteService } from "../remote/initial-nav-pills-remote.service";

@Injectable({
  providedIn: "root",
})
export class InitialNavPillsService {
  constructor(
    private initialNavPillsRemoteService: InitialNavPillsRemoteService,
  ) {}

  getInitialNavPills(initialNavPillsEnum: InitialNavPillsEnum): INavPill[] {
    return this.initialNavPillsRemoteService.getInitialNavPillsByEnum(
      initialNavPillsEnum,
    );
  }
}
