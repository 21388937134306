import { Injectable } from "@angular/core";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable, Subscription } from "rxjs";

import { ToggleEnum } from "../../core/enums/toggle.enum";

import { ToggleService } from "./toggle.service";

@Injectable({
  providedIn: "root",
})
export class GoogleRecaptchaService {
  private recaptchaObservable: any;
  private recaptchaThreadId: Subscription;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private toggleService: ToggleService,
  ) {}

  generateTokenRecaptcha(): Observable<string> {
    this.unsubscribeRecaptcha();

    if (this.getToggleRecaptcha()) {
      this.recaptchaObservable = this.recaptchaV3Service.execute("onInit");
      this.recaptchaThreadId = this.recaptchaObservable.subscribe();
    } else {
      this.recaptchaObservable = new Observable((observer: any) => {
        observer.next("dummy_test");
      });
    }

    return this.recaptchaObservable;
  }

  private getToggleRecaptcha() {
    return this.toggleService.getToggleEnabledById(ToggleEnum.RECAPTCHA);
  }

  private unsubscribeRecaptcha() {
    if (this.recaptchaThreadId) {
      this.recaptchaThreadId.unsubscribe();
    }
  }
}
