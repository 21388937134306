import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { IOverflowError } from "../../../core/interfaces/overflow-error.interface";

import { CustomerService } from "../../../services/local/customer.service";
import { OverflowErrorsService } from "../../../services/local/overflow-errors.service";

@Component({
  selector: "app-overflow-error-view",
  templateUrl: "./overflow-error-view.component.html",
  styleUrls: ["./overflow-error-view.component.scss"],
})
export class OverflowErrorViewComponent implements OnInit, OnDestroy {
  overflowError: IOverflowError;
  formGroup: UntypedFormGroup;

  constructor(
    private customerService: CustomerService,
    private overflowErrorsService: OverflowErrorsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.overflowError = this.overflowErrorsService.getOverflowError();
    this.initForm();
  }

  ngOnDestroy() {
    this.overflowErrorsService.clearOverflowError();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  goTo() {
    this.customerService.setCustomer(undefined);
    this.router.navigate([this.overflowError.url]);
  }
}
