import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "../../../environments/environment";

import { CustomerDecisionTypeEnum } from "../../core/enums/customer-decision-type.enum";
import { CustomerTypeEnum } from "../../core/enums/customer-type.enum";
import { DocumentTypeEnum } from "../../core/enums/document-type.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { AdvisorService } from "./advisor.service";
import { CustomerService } from "./customer.service";
import { FederatedAuthService } from "./federated-auth.service";
import { FileManagerService } from "./file-manager.service";
import { LoanService } from "./loan.service";
import { ToggleService } from "./toggle.service";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private fileManagerService: FileManagerService,
    private federatedAuthService: FederatedAuthService,
    private loanService: LoanService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  public redirectByCustomerType() {
    const customer = this.customerService.getCustomer();
    customer.decisionType = CustomerDecisionTypeEnum.EXTEND_LOAN;

    switch (customer.customerType) {
      case CustomerTypeEnum.CUSTOMER_NEW_WITH_LOANS:
        this.router.navigate([
          this.toggleService.getToggleEnabledById(ToggleEnum.NEW_CLIENT_ACCESS)
            ? "customerdecision"
            : "salaryloan",
        ]);

        break;
      case CustomerTypeEnum.CUSTOMER_WITHOUT_LOANS:
      case CustomerTypeEnum.CUSTOMER_WITHOUT_LOANS_ACTIVE:
        customer.decisionType = CustomerDecisionTypeEnum.NEW_LOAN;
        this.router.navigate(["newloan"]);
        break;
      default:
        this.router.navigate(["salaryloan"]);
        break;
    }
  }

  public async redirectTo(path: string, data?: any) {
    const externalUser = await this.setExternalUser();

    window.name = btoa(
      data ??
        JSON.stringify({
          externalUser,
          toogleList: this.toggleService.getListToggle(),
        }),
    );
    this.windowRedirect(path);
  }

  private windowRedirect(path) {
    window.location.href = path;
  }

  public async redirectToFrontOldToPep() {
    this.redirectTo(environment.serverUrlOld + "master-page/peps");
  }

  public async redirectToPreviability() {
    const customer = { ...this.customerService.getCustomer() };
    const data = {
      client: {
        documentNumber: customer.documentId,
        documentType: customer.documentType === DocumentTypeEnum.CC ? "1" : "2",
        documentTypeText: customer.documentTypeText,
        sectorRisk: customer.sectorRisk,
        sectorRiskText: customer.sectorRiskText,
        cellPhone: customer.cellphone,
      },

      advisor: {
        ...this.advisorService.getAdvisor(),
        officeId: this.advisorService.getAdvisor().office.idoffice,
        businessAdvisorNumber: this.advisorService.getAdvisor().sellerIdNumber,
      },

      toggleList: this.toggleService.getListToggle(),
    };

    this.redirectTo(environment.serverUrlPreviability, JSON.stringify(data));
  }

  public redirectToFederatedAuth() {
    window.location.assign(
      `${
        environment.federatedAuthUrl
      }/authorize?response_type=code&client_id=${this.federatedAuthService.getClientId()}&redirect_uri=${
        environment.federatedAuthRedirectUrl
      }`,
    );
  }

  public redirectToFederatedAuthLogout() {
    window.location.assign(
      `${
        environment.federatedAuthUrl
      }/logout?client_id=${this.federatedAuthService.getClientId()}&logout_uri=${
        environment.federatedAuthLogoutUrl
      }`,
    );
  }

  private async getBase64DecrimFiles(decrimFiles: File[]) {
    const base64DecrimFiles = [];

    for (const decrimFile of decrimFiles) {
      await new Promise((resolve) => {
        this.fileManagerService
          .castFileToBase64(decrimFile)
          .subscribe((base64File) => {
            base64DecrimFiles.push({
              base64File,
              name: decrimFile.name,
              type: decrimFile.type,
            });
            resolve(true);
          });
      });
    }

    return base64DecrimFiles;
  }

  private async setExternalUser() {
    const externalUser = {
      advisor: this.advisorService.getAdvisor(),
      customer: this.customerService.getCustomer(),
      preloan: this.loanService.getPreloan(),
    };

    if (
      externalUser.customer.decrimFiles &&
      this.isValidTypeDecrimFiles(externalUser.customer.decrimFiles)
    ) {
      externalUser.customer.decrimFiles = await this.getBase64DecrimFiles(
        externalUser.customer.decrimFiles,
      );
    }

    return externalUser;
  }

  private isValidTypeDecrimFiles(decrimFiles: any[]): boolean {
    return decrimFiles.every((decrimFile) => decrimFile instanceof File);
  }
}
