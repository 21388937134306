import { Router } from "@angular/router";
import { LoadingComponent } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { AdvisorService } from "../advisor.service";
import { ClientEducationalService } from "../client-educational.service";
import { CustomerService } from "../customer.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { ExtractorService } from "../extractor.service";
import { GoogleAnalyticsEventsService } from "../google-analytics-events.service";
import { LoanService } from "../loan.service";
import { MessageService } from "../message.service";
import { OverflowErrorsService } from "../overflow-errors.service";
import { PayrollService } from "../payroll.service";

import { MessageLoanOperationTenPerAnyFee } from "../../remote/strategy/message-loan-operation-ten-per-any-fee";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { CustomerTypeEnum } from "../../../core/enums/customer-type.enum";
import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ModalityTypeEnum } from "../../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";
import { ProcessStatusEnum } from "../../../core/enums/process-status.enum";
import { SalesProcessTypeEnum } from "../../../core/enums/sales-process-type.enum";

import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { FormPopupUploadPaymentReceiptComponent } from "../../../design/structures/form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";
import { SalaryLoanActionFactoryService } from "./salary-loan-action-factory.service";

import { ISimulationResponse } from "../../../core/interfaces/simulation-response.interface";

export class SalaryLoanActionExtractor implements ISalaryLoanActionFactory {
  formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent;
  loadingComponent: LoadingComponent;

  constructor(
    private advisorService: AdvisorService,
    private clientEducationalService: ClientEducationalService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private extractorService: ExtractorService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private payrollService: PayrollService,
    private router: Router,
    private factory: SalaryLoanActionFactoryService,
    private form: any,
  ) {
    this.formPopupUploadPaymentReceiptComponent =
      form.formPopupUploadPaymentReceiptComponent;
    this.loadingComponent = form.loading;
  }

  get advisor() {
    return this.advisorService.getAdvisor();
  }

  get customer() {
    return this.customerService.getCustomer();
  }

  get loan() {
    return this.loanService.getPreloan();
  }

  salaryLoanAction(): void {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.SALARY_LOAN_CLICK_ANY_FEE,
    );

    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      this.loanService.getPayerName(),
    );

    this.pollingExtraction();
  }

  async salaryLoanResolve(event?: any) {}

  private pollingExtraction(): void {
    this.loadingComponent.show = true;
    this.extractorService
      .pollingExtraction(this.advisor, this.loan, this.customer)
      .subscribe(
        (data) => {
          const { response } = data;

          if (this.extractorService.hasStatusFail(data)) {
            this.pollingExtraction();
            return;
          }
          switch (response.processStatus) {
            case ProcessStatusEnum.INTERPRETER_SUCCESS:
              this.simulationLoan([this.extractorService.getExecuteId()]);
              break;
            case ProcessStatusEnum.CUSTOMER_WITHOUT_EXTRACT:
            case ProcessStatusEnum.ALLOW_UPLOAD:
              this.loadingComponent.show = false;
              this.redirectAnyFeeFlow();
              break;
          }
        },
        () => {
          this.loadingComponent.show = false;
          this.redirectAnyFeeFlow();
        },
      );
  }

  redirectAnyFeeFlow() {
    this.loan.loanFlow = LoanFlowEnum.ANYFEE;
    this.loan.messageLoan = new MessageLoanOperationTenPerAnyFee(
      {
        loanRenewValidation: this.loan.customerInBureauTrail,
      } as any,
      this.advisor.decisionType,
    ).getMessageLoan();

    if (this.advisorService.isLoanByPhoneCall() && this.loan.hasDoublePension) {
      this.loan.showDoublePensionControl = true;
      return;
    }

    this.factory.salaryLoanAction(this.form, LoanFlowEnum.ANYFEE);
  }

  private getMessages(messageId: MessageEnum) {
    if (!this.messageService.existMessage(messageId)) {
      messageId = MessageEnum.SIMULATION_EXTRACTOR_ERROR;
    }
    return this.messageService.getMessageById(messageId);
  }

  private overflow() {
    const extractionOverflow = OverflowErrorsEnum.EXTRACTOR_SIMULATION_FAIL;
    this.overflowErrorsService.setOverflowError(extractionOverflow);
    this.router.navigate(["overflowerror"]);
  }

  private simulationLoan(providersId: string[]) {
    this.payrollService
      .simulationLoan(providersId, CustomerTypeEnum.NEW_CUSTOMER_KNOWN)
      .subscribe(
        (data: ISimulationResponse) => {
          this.processSimulationData(data);
        },
        (error) => {
          if (error.error.code) {
            this.overflow();
          } else {
            this.showMessagePopup(MessageEnum.SIMULATION_EXTRACTOR_ERROR);
          }
        },
      );
  }

  private processSimulationData(data: ISimulationResponse) {
    this.setSalesProcessLoanDecision();

    const errorCodes = this.clientEducationalService.getErrorCodes(data);

    if (
      errorCodes.length &&
      errorCodes[0].code !== ApplicationConstantsEnum.SUCCESS
    ) {
      this.overflow();
      return;
    }

    this.showMessagePopup(MessageEnum.SUCCESS_UPLOAD_PAYMENT_RECEIPT);
  }

  private setSalesProcessLoanDecision() {
    this.loanService.setLoanFlow(LoanFlowEnum.ANYFEE);
    if (this.customerService.isNewLoanDecision()) {
      this.loanService.setModalityType(ModalityTypeEnum.ORDINARY);
    }

    this.loanService.setSalesProcessType(
      this.advisorService.isLoanByPhoneCall()
        ? SalesProcessTypeEnum.TELEFONIC_SALE_TC
        : SalesProcessTypeEnum.PRESENTIAL_SALE_TC,
    );
  }

  private showMessagePopup(messageId: MessageEnum) {
    const message = this.getMessages(messageId);

    this.loadingComponent.show = false;
    this.formPopupUploadPaymentReceiptComponent.setMessage = message;
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = true;
  }
}
