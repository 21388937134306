import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ViabilityQueryRemoteService {
  constructor(private httpClient: HttpClient) {}

  getDeclaratedSectorRisk(accessToken: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: accessToken,
    });
    return this.httpClient.get(
      environment.serverUrlPublicResources +
        "catalog/get-rule-pre-viability/declarated-sector-risk",
      { headers },
    );
  }
}
