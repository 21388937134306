import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ICustomer } from "../../../core/interfaces/customer.interface";

import { CustomerDecisionTypeEnum } from "../../../core/enums/customer-decision-type.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";

import { CustomerService } from "../../../services/local/customer.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";

@Component({
  selector: "app-form-customer-decision",
  templateUrl: "./form-customer-decision.component.html",
  styleUrls: ["./form-customer-decision.component.scss"],
})
export class FormCustomerDecisionComponent implements OnInit {
  set customerDecisionTypeEnum(customerDecisionTypeEnum) {}
  get customerDecisionTypeEnum() {
    return CustomerDecisionTypeEnum;
  }

  customer: ICustomer;

  constructor(
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.customer = this.customerService.getCustomer();
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.CUSTOMER_DECISION_INIT_PAGE,
    );
  }

  goToDecision(decision: string) {
    this.googleAnalyticsEventsService.emitEvent(
      decision === CustomerDecisionTypeEnum.NEW_LOAN
        ? GoogleAnalyticsEventsEnum.CUSTOMER_DECISION_CLICK_NEW_PAYROLL_LOAN
        : GoogleAnalyticsEventsEnum.CUSTOMER_DECISION_CLICK_EXTEND_PAYROLL_LOAN,
    );

    this.customer.decisionType = decision as CustomerDecisionTypeEnum;

    this.customerService.sendCreditTypeDecision().subscribe(
      // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
      () => {},
      (error) =>
        // eslint-disable-next-line no-console
        console.log(
          `FormCustomerDecisionComponent - sendCreditTypeDecision - Error ${JSON.stringify(
            error,
          )}`,
        ),
    );

    this.router.navigate([
      decision === CustomerDecisionTypeEnum.NEW_LOAN ? "newloan" : "salaryloan",
    ]);
  }
}
