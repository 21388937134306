import { Component, Input, OnInit } from "@angular/core";

import {
  ValidationQueryDecrimResponseStatus,
  ValidationQueryDecrimResponseStatusEnum,
} from "./../../../core/enums/validation-query-decrim-response-status.enum";

import { IResponseValidateDecrimQuery } from "./../../../core/interfaces/response-validate-decrim-query.interface";

@Component({
  selector: "app-authentication-states",
  templateUrl: "./authentication-states.component.html",
  styleUrls: ["./authentication-states.component.scss"],
})
export class AuthenticationStatesComponent implements OnInit {
  @Input() authenticationState: IResponseValidateDecrimQuery;
  @Input() cssClass: string;

  ngOnInit(): void {
    return;
  }

  getCssClass(patialCssName: string): string {
    return patialCssName + this.authenticationState.authStatus.toLowerCase();
  }

  getLabel(): string {
    return ValidationQueryDecrimResponseStatus.getLabel(
      this.authenticationState
        .authStatus as ValidationQueryDecrimResponseStatusEnum,
    );
  }

  isError() {
    return (
      this.authenticationState.authStatus ===
      ValidationQueryDecrimResponseStatusEnum.ERROR
    );
  }

  getDescriptions() {
    return this.authenticationState.messageValidation
      ?.split("\\r\\n")
      .filter((value) => value);
  }
}
