import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { INewClientFilesRequest } from "../../core/interfaces/new-client-status-request.interfaces";
import { IPayrollFilePayload } from "../../core/interfaces/payroll-file-payload.interface";
import { ISendDocumentTap } from "../../core/interfaces/send-document-tap.interface";
import { ISendLinkUploadFileRequestInterface } from "../../core/interfaces/send-link-upload-file-request.interface";

import { CustomerTypeEnum } from "../../core/enums/customer-type.enum";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PayrollRemoteService {
  constructor(private httpClient: HttpClient) {}

  getFilesValidityStatus(payrollDocumentPayload: IPayrollFilePayload) {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "ocr/documents/validity-status",
      payrollDocumentPayload,
    );
  }

  sendFileToTap(dataDocument: ISendDocumentTap) {
    return this.httpClient.post(
      environment.federatedAuthParamsServerUrl + "file-provider-service/load",
      dataDocument,
    );
  }

  sendLinkForUploadFile(
    requestSendLink: ISendLinkUploadFileRequestInterface,
  ): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "payrollCheck/uploads",
      requestSendLink,
    );
  }

  setPayrollFile(file: any): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "payrollCheck",
      file,
    );
  }

  getFileCustomer(
    payrollCheckId: string,
    obligationId: string,
    documentType: string,
    documentId: string,
    queryParamsCustomerNew: string,
  ): Observable<object> {
    return this.httpClient.get(
      environment.serverUrlSalaryLoans +
        `payrollCheck/${payrollCheckId}/${obligationId}/${documentType}/${documentId}` +
        queryParamsCustomerNew,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
  }

  getFileStatusDocuments(
    clientOrcRequest: INewClientFilesRequest,
  ): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + `ocr/documents/status`,
      clientOrcRequest,
    );
  }

  simulationLoan(
    clientOrcRequest: INewClientFilesRequest,
    customerType: CustomerTypeEnum,
  ): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + `simulation/${customerType}`,
      clientOrcRequest,
    );
  }
}
