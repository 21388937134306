<div class="container-fluid h-100">
  <div class="header">
    <app-content-header></app-content-header>
  </div>
  <div class="row h-100">
    <div class="col-xl-5 col-md-12 h-100 w-100">
      <router-outlet name="part-left"></router-outlet>
    </div>
    <div class="col-xl-7 col-md-0 h-100 w-100">
      <router-outlet name="part-right"></router-outlet>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-xl-4 col-md-12 footer-left-content"></div>
      <div class="col-xl-8 col-md-12 footer-right-content"></div>
    </div>
  </div>
</div>
