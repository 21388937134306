export enum ValidationMethodEnum {
  CERTIHUELLA = "Certihuella",
  CERTIHUELLA_WITH_CUSTOMER_INFO = "CertihuellaWithCustomerInfo",
  DECRIM = "Decrim",
  DECRIM_WITH_CUSTOMER_INFO = "DecrimWithCustomerInfo",
  WITHOUT_VALIDATION_METHOD = "",
}

export class ValidationMethod {
  static getChannel(validationMethodEnum: ValidationMethodEnum): number {
    return validationMethodEnum === ValidationMethodEnum.CERTIHUELLA ||
      validationMethodEnum ===
        ValidationMethodEnum.CERTIHUELLA_WITH_CUSTOMER_INFO
      ? 1
      : validationMethodEnum === ValidationMethodEnum.DECRIM
      ? 2
      : undefined;
  }
}
