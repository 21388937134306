<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title [text]="title" [level]="2"></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="description"
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-nav-pills
        [pills]="pills"
        [checkMark]="false"
        [valueControl]="formGroup.controls['valuePill']"
        [parentForm]="formGroup"
      ></adl-nav-pills>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="'font-weight-bold'"
        [text]="'Opciones para recibir tu código'"
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-label
        [cssClass]="'font-weight-bold'"
        [text]="'1.'"
      ></adl-text-label>
      <adl-text-label
        [cssClass]="''"
        [text]="
          'Agrega al +85264298371 a Whatsapp y envía un mensaje diciendo ¨Hola¨'
        "
      ></adl-text-label>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-label
        [cssClass]="'font-weight-bold'"
        [text]="'2.'"
      ></adl-text-label>
      <adl-text-label
        [cssClass]="''"
        [text]="'Empieza la conversación'"
      ></adl-text-label>
      <adl-text-link [text]="'aquí'"></adl-text-link>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-label
        [cssClass]="'font-weight-bold'"
        [text]="'3.'"
      ></adl-text-label>
      <adl-text-label
        [cssClass]="''"
        [text]="'Con un app de lectura de QR escanea este código:'"
      ></adl-text-label>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-button">
      <adl-button
        [cssClass]="'w-50'"
        [mandatory]="false"
        [parentForm]="formGroup"
        [text]="'Recibir código'"
        (clickEvent)="receiveCode()"
      ></adl-button>
    </div>
  </form>
</div>
