import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

import { IPayrollLoanStatus } from "../../core/interfaces/payroll-loan-status.interface";

import * as payrollLoanStatusData from "../data/payroll-loan-status-data.json";

@Injectable({
  providedIn: "root",
})
export class PayrollLoanStatusRemoteService {
  constructor(private http: HttpClient) {}

  public searchPayrollLoanStatusData(
    statusId: string,
    payerName: string,
    toDo: string,
  ): IPayrollLoanStatus {
    return this.mapSearchPayrollLoanStatusData(statusId, payerName, toDo);
  }

  async asyncSearchPayrollLoanStatus(
    sellerIdNumber: number,
    documentType: string,
    documentId: number,
  ) {
    return this.http
      .get(
        environment.serverUrlSalaryLoans +
          `api/back-office/payrollloans/${sellerIdNumber}/${documentType}/${documentId}/status`,
      )
      .toPromise();
  }

  private mapSearchPayrollLoanStatusData(
    statusId: string,
    payerName: string,
    toDo: string,
  ): IPayrollLoanStatus {
    const payrollLoanStatus: IPayrollLoanStatus = {
      ...(payrollLoanStatusData as any).default.find(
        (payrollStatus: any) => payrollStatus.statusId === statusId,
      ),
    };

    if (payrollLoanStatus.description) {
      payrollLoanStatus.description = payrollLoanStatus.description
        .replace(/{payerName}/, payerName)
        .replace(/{toDo}/, toDo);
    }

    return payrollLoanStatus;
  }
}
