import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { IPassedCourse } from "../../core/interfaces/passed-course.interface";
import { AwsSignatureRemoteService } from "./aws-signature-remote.service";

@Injectable({
  providedIn: "root",
})
export class TrainingRemoteService {
  constructor(
    private http: HttpClient,
    private awsSignatureRemoteService: AwsSignatureRemoteService,
  ) {}

  async asyncSearchCourses(advisor: IAdvisor, sellerIdNumber: number) {
    const headers = this.awsSignatureRemoteService.setHeaders(
      advisor,
      environment.awsCognitoData.host,
      "GET",
      `/training-manager/courses/${sellerIdNumber}`,
      "application/x-www-form-urlencoded",
    );

    return this.http
      .get(environment.serverUrlTraining + "courses/" + sellerIdNumber, {
        headers,
      })
      .toPromise();
  }

  savePassedCourse(
    advisor: IAdvisor,
    passedCourse: IPassedCourse,
  ): Observable<object> {
    const headers = this.awsSignatureRemoteService.setHeaders(
      advisor,
      environment.awsCognitoData.host,
      "PUT",
      "/training-manager/courses/vendor/" + passedCourse.idVendor,
      "application/json",
      JSON.stringify(passedCourse),
    );

    return this.http.put(
      environment.serverUrlTraining + "courses/vendor/" + passedCourse.idVendor,
      JSON.stringify(passedCourse),
      {
        headers,
      },
    );
  }
}
