import { Injectable } from "@angular/core";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CashierOfficeSectorEnum } from "../../core/enums/cashier-office-sector.enum";

import { CustomerService } from "./customer.service";

import { CashierOfficeRemoteService } from "../remote/cashier-office-remote.service";

@Injectable({
  providedIn: "root",
})
export class CashierOfficeService {
  sector: any[];
  payer: any;
  selectedSector: CashierOfficeSectorEnum;
  selectedSectorName: string;
  selectedPayer: string;
  selectedPayerNit: string;

  constructor(
    private customerService: CustomerService,
    private cashierOfficeRemoteService: CashierOfficeRemoteService,
  ) {}

  searchSectors(): Observable<IListSelect[]> {
    return this.cashierOfficeRemoteService.searchSectors().pipe(
      map((data: any) => {
        const listSector: IListSelect[] = [];
        this.sector = data;

        data.forEach((dat: any) => {
          listSector.push({
            value: dat.sectorNumber,
            text: dat.sectorName,
          });
        });

        return listSector;
      }),
      catchError(() => {
        return throwError([]);
      }),
    );
  }

  searchPayersBySector(sector: number): Observable<any[]> {
    return this.cashierOfficeRemoteService
      .searchPayers(this.customerService.getCustomer(), sector)
      .pipe(
        map((data: any) => {
          const listPayer: any[] = [];
          data.forEach((dat: any) => {
            listPayer.push({
              value: dat.payerNit,
              text: dat.payerName,
              subSector: dat.subSector,
              platform: dat.platform,
            });
          });

          this.payer = data;

          return listPayer;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getPayer() {
    return this.payer;
  }

  hasPayerExtractor(payerName: string, isExtractionActive: boolean) {
    if (!isExtractionActive) {
      return false;
    }
    return (
      this.payer.find((item) => item.payerName === payerName)?.extractor ??
      false
    );
  }
}
