import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/internal/operators";

import { environment } from "../../../environments/environment";
import { LoggerService } from "src/app/services/local/logger-service";

@Injectable()
export class AppLoggerInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.shouldEncryptUrl(req.url)) {
      this.loggerService.setEncriptHttpRequest(req);
    } else {
      this.loggerService.setHttpRequest(req);
    }
    return next.handle(req).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (this.shouldEncryptUrl(event.url)) {
              this.loggerService.setEncriptHttpResponse(event);
            }
          }
        },
        error: (_error) => {
          // eslint-disable-next-line no-console
          console.log("failed");
        },
      }),
    );
  }

  private shouldEncryptUrl(url: string): boolean {
    return (
      environment.encryptUrls.filter((encryptUrl: any) => encryptUrl === url)
        .length !== 0
    );
  }
}
