import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IMessage } from "../../../core/interfaces/message.interface";

import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";

import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { RedirectService } from "../../../services/local/redirect.service";

@Component({
  selector: "app-form-popup-sygnus-link",
  templateUrl: "./form-popup-sygnus-link.component.html",
})
export class FormPopupSygnusLinkComponent implements OnInit {
  @Input("message")
  set setMessage(message: IMessage) {
    this.message = message;
  }

  @Input("showPopUpSygnusLink")
  set setShowPopUpSygnusLink(showPopUpSygnusLink: boolean) {
    this.showPopUpSygnusLink = showPopUpSygnusLink;
  }

  @Output() resolveSygnusEvent = new EventEmitter<any>();

  description: string;
  loadingButtonSendSygnusLink: boolean;
  showPopUpSygnusLink: boolean;
  title: string;

  formGroup: UntypedFormGroup;
  listBindings: IListSelect;
  message: IMessage;

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private redirectService: RedirectService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE,
    );

    this.initForm();
  }

  buttonProcess() {
    if (this.message.actionButton === MessageActionEnum.CLOSE_MESSAGE) {
      this.closePopup();
    } else if (
      this.message.actionButton ===
      MessageActionEnum.CHOOSE_OTHER_BINDING_SYGNUS
    ) {
      this.setInitialMessage();
    } else if (this.message.actionButton === MessageActionEnum.GO_INIT) {
      this.router.navigate(["loginadvisor"]);
    } else if (this.message.actionButton === MessageActionEnum.GO_PEP) {
      this.redirectService.redirectToFrontOldToPep();
    } else if (this.message.actionButton === MessageActionEnum.PROCESS_SYGNUS) {
      this.processSygnus();
    } else if (
      this.message.actionButton === MessageActionEnum.SEND_SYGNUS_LINK
    ) {
      this.sendSygnusLink();
    }
  }

  clearTextBindingsValidator() {
    this.formGroup.get("textBindings").clearValidators();
  }

  closePopup() {
    this.setShowPopUpSygnusLink = false;
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});

    this.formGroup.addControl(
      "textBindings",
      new UntypedFormControl("", Validators.required),
    );
    this.formGroup.addControl("valueBindings", new UntypedFormControl());
  }

  presetValues(bindingsName: string) {
    this.formGroup = new UntypedFormGroup({});

    this.formGroup.addControl(
      "textBindings",
      new UntypedFormControl(bindingsName, Validators.required),
    );
    this.formGroup.addControl("valueBindings", new UntypedFormControl());
  }

  isFormPopupSygnusLinkConfiguration(messageId: MessageEnum) {
    return messageId === MessageEnum.FORM_POPUP_SYGNUS_LINK_CONFIGURATION;
  }

  processSygnus() {
    this.resolveSygnusEvent.emit(MessageActionEnum.PROCESS_SYGNUS);
  }

  sendSygnusLink() {
    this.resolveSygnusEvent.emit(MessageActionEnum.SEND_SYGNUS_LINK);
  }

  setInitialMessage() {
    this.resolveSygnusEvent.emit(MessageActionEnum.CHOOSE_OTHER_BINDING_SYGNUS);
  }
}
