import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  IRadioButtonData,
  LoadingComponent,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { AdvisorDecisionTypeEnum } from "src/app/core/enums/advisor-decision-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { EventTopic } from "../../../core/enums/event-topic.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CashierOfficeService } from "../../../services/local/cashier-office.service";
import { CustomerService } from "../../../services/local/customer.service";
import { DoublePensionService } from "../../../services/local/double-pension.service";
import { EventAnalyticsService } from "../../../services/local/event-analytics.service";
import { EventManagerService } from "../../../services/local/event-manager.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { LoanService } from "../../../services/local/loan.service";
import { SalaryLoanActionFactoryService } from "../../../services/local/factory/salary-loan-action-factory.service";
import { TealiumUtagService } from "../../../tealium/utag.service";
import { ToggleService } from "../../../services/local/toggle.service";

import { FormPopupSygnusLinkComponent } from "../form-popup-sygnus-link/form-popup-sygnus-link.component";
import { FormPopupUploadPaymentReceiptComponent } from "../form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { IPreloan } from "../../../core/interfaces/pre-loan.interface";
import { PopupPayrollcheckUploadResponseComponent } from "../popup-payrollcheck-upload-response/popup-payrollcheck-upload-response.component";
import { IMessage } from "../../../core/interfaces/message.interface";

@Component({
  selector: "app-form-new-loan",
  templateUrl: "./form-new-loan.component.html",
})
export class FormNewLoanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(LoadingComponent, { static: false })
  loading: LoadingComponent;

  @ViewChild(FormPopupSygnusLinkComponent, { static: false })
  formPopupSygnusLinkComponent: FormPopupSygnusLinkComponent;
  isPlatformSygnus: boolean = false;

  @ViewChild(FormPopupUploadPaymentReceiptComponent, { static: false })
  formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent;
  disableUploadButton: boolean = true;

  get captionButton(): string {
    return this.loanByPhoneCall ? "Enviar enlace" : "Continuar";
  }

  description: string;
  isDoublePension = false;
  isNotificationVisible = false;
  listSector: any;
  listPayer: any;
  loanByPhoneCall: boolean;
  loadingCss: string;
  loadingDescription: string;
  loadingButton: boolean;
  loadPercentage: number;
  notificationTitle: string;
  notificationDescription: string;
  payerDisabled = true;
  showLoading: boolean;
  title: string;
  toogleClientEducational: boolean;

  formGroup: UntypedFormGroup;

  radioButtonData: IRadioButtonData[];
  loan: IPreloan = {};
  decisionType: AdvisorDecisionTypeEnum;
  advisor: IAdvisor;
  unificationFlowActive = false;
  @ViewChild("popupUploadResponse", { static: false })
  popupUploadResponseRef: PopupPayrollcheckUploadResponseComponent;
  uploadResponse: IMessage;
  attemptsUpload = 0;

  constructor(
    private advisorService: AdvisorService,
    private cashierOfficeService: CashierOfficeService,
    private customerService: CustomerService,
    private doublePensionService: DoublePensionService,
    private eventAnalyticsService: EventAnalyticsService,
    private eventManagerService: EventManagerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private salaryLoanActionFactoryService: SalaryLoanActionFactoryService,
    private toggleService: ToggleService,
    private tealiumUtagService: TealiumUtagService,
  ) {
    this.radioButtonData = this.doublePensionService.getInformationDoublePension();
    this.advisor = this.advisorService.getAdvisor();
    this.decisionType = this.advisor.decisionType;
    this.unificationFlowActive = this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  get isExtractionActive() {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.EXTRACTION_AND_INTERPRETER,
    );
  }

  get payerExtractor() {
    return this.cashierOfficeService.hasPayerExtractor(
      this.formGroup.controls.textPayer.value,
      this.isExtractionActive,
    );
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE,
    );
    this.notificationTitle = "Consulta en ALALA";
    this.notificationDescription =
      "Asegúrate de realizar la consulta en la plataforma ALALA para las pagadurías que aplique antes de continuar con el proceso.";
    this.title = "Nueva Libranza";
    this.description = "Estos datos nos ayudarán a crear la libranza";
    this.loanByPhoneCall = this.advisorService.isLoanByPhoneCall();

    this.setSelectSector();
    this.setToggleClientEducational();

    this.initForm();
    this.eventManagerService
      .on(EventTopic.OPEN_POPUP_UPLOAD)
      .subscribe((data) => {
        const { message, attempt } = data;
        this.popupUploadResponseRef.show();
        this.uploadResponse = message;
        this.attemptsUpload = attempt;
      });
  }

  ngAfterViewInit(): void {
    this.tealiumUtagService.view({
      tealium_event: "page_view",
      page_path: document.location.pathname,
    });
  }

  ngOnDestroy(): void {
    this.setAdvisorDecision(this.decisionType, false);
    this.eventManagerService.unsubscribe(EventTopic.OPEN_POPUP_UPLOAD);
    this.eventManagerService.unsubscribe(
      EventTopic.ACTION_BUTTON_POPUP_RESPONSE,
    );
  }

  private configureLoanDetails(): void {
    const controls = this.formGroup.controls;
    this.loadingButton = !this.payerExtractor;
    this.doublePensionService.setDoublePension(this.formGroup);
    this.loan.payerName = controls.textPayer.value;
    this.loan.payerUniqueName = controls.textPayer.value;
    this.loan.payerNit = controls.valuePayer.value.value;
    this.loan.sectorName = controls.textSector.value;
    this.loan.sectorNumber = controls.valueSector.value.value;
    this.loan.subSectorNumber = this.listPayer.find(
      (payer) => payer.text === controls.textPayer.value,
    )?.subSector;
    this.loanService.setPreloan(this.loan);
  }

  continue() {
    this.configureLoanDetails();

    this.loanService.setPreloan(this.loan);

    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECT_SECTOR_AND_PAYER,
      `Sector: ${this.formGroup.controls.textSector.value}, Pagaduría: ${this.formGroup.controls.textPayer.value}`,
    );

    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE_CLICK_CONTINUE,
    );

    this.salaryLoanActionFactoryService.salaryLoanAction(
      this,
      this.loan.loanFlow,
    );
  }

  closeNotification() {
    this.isNotificationVisible = false;
  }

  onClickLoadDocuments() {
    this.setAdvisorDecision(AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN, true);
    if (this.isPlatformSygnus) {
      this.configureLoanDetails();
      this.salaryLoanActionFactoryService.salaryLoanAction(
        this,
        LoanFlowEnum.SYGNUS,
      );
    } else {
      this.continue();
    }

    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "nueva_libranza",
      event_label: "subir_documentos",
      user_agent: this.tealiumUtagService.managementUserAgent(),
      userId: this.tealiumUtagService.managementUserId(),
      request_id: this.customerService.getCustomer().salesJourneyId,
    });
  }

  onClickSendLink() {
    this.configureLoanDetails();
    this.setAdvisorDecision(
      AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
      false,
    );
    if (this.isPlatformSygnus) {
      this.salaryLoanActionFactoryService.salaryLoanAction(
        this,
        LoanFlowEnum.SYGNUS,
      );
    } else {
      this.continue();
    }

    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "nueva_libranza",
      event_label: "enviar_enlace",
      user_agent: this.tealiumUtagService.managementUserAgent(),
      userId: this.tealiumUtagService.managementUserId(),
      request_id: this.customerService.getCustomer().salesJourneyId,
    });
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl("textSector", new UntypedFormControl());
    this.formGroup.addControl("valueSector", new UntypedFormControl());
    this.formGroup.addControl("textPayer", new UntypedFormControl());
    this.formGroup.addControl("valuePayer", new UntypedFormControl());
  }

  salaryLoanResolve(event: any) {
    this.loadingButton = false;
    if (event === UploadDocumentsStatesEnum.DOCUMENTS_LOAD_EMPTY) {
      const advisor = {
        ...this.advisor,
        decisionType: this.decisionType,
        isInPersonPhone: false,
      };
      this.advisorService.setAdvisor(advisor);
      this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = false;
      this.formPopupUploadPaymentReceiptComponent.clearAllValidatorsControl();
      return;
    }
    this.salaryLoanActionFactoryService.salaryLoanResolve(event);
  }

  setSelectSector() {
    this.cashierOfficeService.searchSectors().subscribe(
      (data: any) => {
        this.listSector = this.checkPayrollToggle(data);
      },
      (error: any) => (this.listSector = error),
    );
  }

  setSelectPayer() {
    this.disableUploadButton = true;
    this.formGroup.controls.textPayer.setValue("");

    this.cashierOfficeService
      .searchPayersBySector(this.formGroup.controls.valueSector.value.value)
      .subscribe(
        (data: any) => {
          this.listPayer = data;
          this.payerDisabled = false;
          this.payerValueChanges();
        },
        (error: any) => (this.listPayer = error),
      );
  }

  private payerValueChanges() {
    this.formGroup.controls.textPayer.valueChanges.subscribe(
      (selectedPayerName: string) => {
        const selectedPayer = this.listPayer.find(
          (payer: any) => payer.text === selectedPayerName,
        );
        if (selectedPayer) {
          this.loan.payerUniqueName = selectedPayer.text;

          this.isPlatformSygnus = selectedPayer.platform === "SYGNUS";
        }
      },
    );
  }

  salaryLoanResolveSygnus(event: any) {
    this.salaryLoanActionFactoryService.salaryLoanResolve(event);
  }

  canShowDoublePensionButton() {
    this.isNotificationVisible = true;
    this.disableUploadButton = false;
    const { doublePensionRadioGroup, valuePayer } = this.formGroup.controls;
    if (doublePensionRadioGroup) {
      this.formGroup.removeControl("doublePensionRadioGroup");
    }

    const hasDoublePension = this.loanService.hasDoublePension(
      valuePayer.value.text,
    );

    const loanFlow = this.payerExtractor
      ? LoanFlowEnum.EXTRACTOR
      : LoanFlowEnum.ANYFEE;

    const showDoublePensionControl =
      this.loanService.isEnabledDoublePensionControl(
        loanFlow,
        hasDoublePension,
      ) && this.loanByPhoneCall;

    this.loan = {
      hasDoublePension,
      loanFlow,
      showDoublePensionControl,
    };
  }

  setToggleClientEducational() {
    this.toogleClientEducational = this.toggleService.getToggleEnabledById(
      ToggleEnum.NEW_CLIENT_EDUCATIONAL_SECTOR_TOGGLE,
    );
  }

  retryUploadDocument() {
    this.eventManagerService.publish(
      EventTopic.ACTION_BUTTON_POPUP_RESPONSE,
      this.uploadResponse,
    );
  }

  private checkPayrollToggle(listSector: any) {
    let lisDataSector: any;

    listSector.forEach((sector: any, index: number) => {
      if (
        sector.text === ApplicationConstantsEnum.EDUCATIONAL_SECTOR &&
        !this.toogleClientEducational
      ) {
        listSector.splice(
          index,
          ApplicationConstantsEnum.REMOVE_ITEM_IN_SECTOR,
        );
      }
    });

    lisDataSector = listSector.sort((prevSector: any, nextSector: any) => {
      if (prevSector.text < nextSector.text) {
        return ApplicationConstantsEnum.SORT_SECTOR_ITEMS_LESS_ONE;
      }

      if (prevSector.text > nextSector.text) {
        return ApplicationConstantsEnum.SORT_SECTOR_ITEMS_ONE;
      }

      return ApplicationConstantsEnum.SORT_SECTOR_ITEMS_ZERO;
    });

    return lisDataSector;
  }

  private setAdvisorDecision(
    decisionType: AdvisorDecisionTypeEnum,
    isInPersonPhone: boolean,
  ) {
    const advisor = {
      ...this.advisor,
      decisionType,
      isInPersonPhone,
      isUnification: true,
    };
    this.advisorService.setAdvisor(advisor);
  }
}
