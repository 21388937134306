import { Injectable } from "@angular/core";
import { IGroupedMessage } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IMessage } from "../../core/interfaces/message.interface";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { MessageEnum } from "../../core/enums/message.enum";

import { MessageRemoteService } from "../remote/message-remote.service";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(private messageRemoteService: MessageRemoteService) {}

  getGroupedMessages(messageId: string, error: any): IGroupedMessage[] {
    let groupedMessages = [];
    let message = this.getMessageById(messageId);

    const groupedMessage = {
      description: message.description,
      groupName: error.documentName,
      messageId: message.messageId,
      title: message.title,
      captionButton: message.captionButton,
      icon: message.icon,
      actionButton: message.actionButton,
    };

    if (!message.messageId) {
      message = this.getMessageById(MessageEnum.ERROR_UPLOAD_FILE_TAP_SOLUTION);
      groupedMessages.push({ ...message, groupName: error.documentName });
      return groupedMessages;
    }

    if (error.causes.length) {
      for (const cause of error.causes) {
        groupedMessage.description = cause;
        groupedMessage.title = message.description;
        groupedMessages.push(groupedMessage);
      }
    } else {
      groupedMessages.push(groupedMessage);
    }

    return groupedMessages;
  }

  getMessageById(
    messageEnum: string,
    description: string = ApplicationConstantsEnum.CHARACTER_EMPTY,
  ): IMessage {
    return this.messageRemoteService.getMessageById(messageEnum, description);
  }

  existMessage(messageId: string): boolean {
    const message = this.messageRemoteService.getMessageById(messageId);
    return Boolean(message.messageId);
  }
}
