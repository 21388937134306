<div class="notification-upload-container" *ngIf="viewNotification">
  <div class="notification-header-upload">
    <div class="notification-content">
      <adl-text-title text="Estado de cargue" [level]="5"></adl-text-title>
    </div>
    <div>
      <adl-button-close
        id="close-notification"
        (click)="closeNotification()"
      ></adl-button-close>
    </div>
  </div>

  <div
    class="notification-body"
    *ngFor="let file of files; let i = index"
    [ngClass]="file.reasons.length > 0 ? 'error-message' : ''"
  >
    <div *ngIf="!file.documentComplete && file.file">
      <div class="notification-upload" [ngClass]="notificationDetails(file)">
        <div
          class="vertical-line {{ elementDetailsNotification.verticalLine }}"
        ></div>
        <div
          class="icon-notification {{
            elementDetailsNotification.iconNotification
          }}"
        >
          <adl-icon
            [urlIcon]="elementDetailsNotification.icon"
            altIcon="loading"
          ></adl-icon>
        </div>

        <div class="text-capitalize" *ngIf="file.reasons.length === 0">
          <adl-text-title
            [text]="file.name | lowercase"
            [level]="5"
          ></adl-text-title>
          <div class="notification-content-file">
            {{ file.file.name }}
            <span
              class="text"
              [ngClass]="file.status ? 'text-success' : 'text-upload'"
              >{{ file.documentUpload }}</span
            >
          </div>
        </div>
        <div
          class="close-orange"
          *ngIf="!file.status && file.reasons.length === 0"
        >
          <adl-text-link
            id="cancel-upload-document-{{ i }}"
            (click)="uploadCancel(file)"
            html="<img  src='./assets/images/onbase_load/close_orange.svg' alt='orange-close'>"
          >
          </adl-text-link>
        </div>
        <div
          class="notification-error"
          *ngIf="file.reasons.length > 0 && !notificationErrorVisible(file)"
        >
          <div class="message-error">
            <adl-text-title
              text="Revisa y carga nuevamente el archivo"
              [level]="5"
            >
            </adl-text-title>
          </div>
          <div>
            <adl-button-close
              id="close-notification-message"
              (click)="closeMessageNotification(file)"
            ></adl-button-close>
          </div>
        </div>
      </div>

      <div class="loading">
        <adl-progress-bar
          *ngIf="!file.status && file.reasons.length === 0"
          #progressBar
          [seconds]="2"
          (infoBarCompleteEvent)="infoBarComplete(file)"
        ></adl-progress-bar>
      </div>
    </div>
  </div>
</div>
