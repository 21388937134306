<ng-container>
  <div class="w-100 h-100 d-flex justify-content-center">
    <div
      id="{{ data.id }}"
      class="decision-button bt-left text-center pt-4"
      (click)="handleClick(data)"
    >
      <div class="new-label" *ngIf="data.badge">
        {{ data.badgeLabel }}
      </div>
      <img [src]="data.imageUrl" alt="" />
      <p>{{ data.label }}</p>
    </div>
  </div>
</ng-container>
