import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  logs: Map<string, Log>;
  debugg: boolean;
  useJson: boolean;
  constructor() {
    this.logs = new Map();
    this.debugg = environment.debugg ?? false;
    this.useJson = environment.useJson ?? false;
  }

  public put(key: string, value: Log): void {
    this.logs.set(key, value);
  }

  public setHttpPreRequest(req: HttpRequest<any>): void {
    let { url, body, method } = req;
    let log: Log = this.get(url);
    log.startTime = Date.now();
    log.request.body = body;
    log.request.method = method;
    this.logs.set(url, log);
  }

  public setEncriptHttpRequest(req: HttpRequest<any>) {
    let { url, body } = req;
    let log: Log = this.get(url);
    log.cipher = true;
    log.request.bodyEncrypt = body;

    let headers: string[] = [];
    req.headers.keys().forEach((key) => {
      let value = req.headers.get(key);
      headers.push(`${key}:${value}`);
    });

    log.request.headers = headers;
    this.logs.set(url, log);
  }

  public setHttpRequest(req: HttpRequest<any>) {
    let { url, body } = req;
    let log: Log = this.get(url);
    log.cipher = false;
    log.request.body = body;

    let headers: string[] = [];
    req.headers.keys().forEach((key) => {
      let value = req.headers.get(key);
      headers.push(`${key}:${value}`);
    });

    log.request.headers = headers;
    this.logs.set(url, log);
  }

  public setEncriptHttpResponse(response: HttpResponse<any>) {
    let { url, body, status } = response;
    let log: Log = this.get(url);
    log.response.bodyEncrypt = body;
    log.response.statusCode = status.toString();
    this.logs.set(url, log);
  }

  public setHttpResponse(response: HttpResponse<any>) {
    let { url, body, status } = response;
    let log: Log = this.get(url);
    log.response.body = body;
    log.response.statusCode = status.toString();
    log.endTime = Date.now();
    log.executionTime = log.endTime - log.startTime;
    this.logs.set(url, log);
    if (this.debugg) this.toString(url);
  }

  public get(key: string): Log {
    const log = this.logs.get(key);
    if (log) {
      return log;
    }
    let logTmp: Log = { url: key, request: {}, response: {} };
    this.logs.set(key, logTmp);
    return this.logs.get(key);
  }
  public toString(key) {
    let log: Log = this.get(key);
    if (this.useJson) {
      // eslint-disable-next-line no-console
      console.log(log.url + " : \n", log);
    } else {
      let template: string = `
      ---------------------------------------------
      url : ${log.url}
      cipher: ${log.cipher ? "Si" : "No"}
      
      Request
      headers: ${
        log.request.headers ? JSON.stringify(log.request.headers, null, 2) : ""
      }
      bodyEncrypt:${log.request.bodyEncrypt ? log.request.bodyEncrypt : ""}
      body: ${log.request.body ? JSON.stringify(log.request.body, null, 2) : ""}
  
      Response
  
      headers: ${
        log.request.headers ? JSON.stringify(log.request.headers, null, 2) : ""
      }
      bodyEncrypt:${log.request.bodyEncrypt ? log.request.bodyEncrypt : ""}
      body: ${log.request.body ? JSON.stringify(log.request.body, null, 2) : ""}
  
      ---------------------------------------------
      `;
      // eslint-disable-next-line no-console
      console.log(template);
    }
  }
}
export interface Log {
  startTime?: number;
  endTime?: number;
  executionTime?: number;
  url: string;
  cipher?: boolean;
  request?: Request;
  response?: Response;
}

export interface Request {
  method?: string;
  bodyEncrypt?: string;
  body?: object;
  headers?: object;
}

export interface Response extends Request {
  statusCode?: string;
}
