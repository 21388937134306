import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  OtpFieldComponent,
  ProgressBarComponent,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { AdvisorTypeEnum } from "../../../core/enums/advisor-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { GoogleRecaptchaService } from "../../../services/local/google-recaptcha.service";
import { OtpService } from "../../../services/local/otp.service";
import { OverflowErrorsService } from "../../../services/local/overflow-errors.service";
import { RedirectService } from "../../../services/local/redirect.service";
import { ToggleService } from "../../../services/local/toggle.service";

import { PhoneNumberObfuscatePipe } from "../../../core/pipes/phone-number-obfuscate.pipe";

@Component({
  selector: "app-form-otp",
  styleUrls: ["./form-otp.component.scss"],
  templateUrl: "./form-otp.component.html",
})
export class FormOtpComponent implements OnInit {
  @ViewChild("progressBar", { static: false })
  progressBar: ProgressBarComponent;

  @ViewChild("otpField", { static: false })
  otpField: OtpFieldComponent;

  authenticationOtpSuccess: boolean;
  cellPhone: string;
  countRetrySendOtp: number;
  description: string;
  loadingButtonAuthenticateOtp: boolean;
  showMessageBox: boolean;
  showMessageAdvisorTypeBox: boolean;
  numberInputOtp: number;
  timeRemaining: string;
  title: string;

  advisor: IAdvisor;
  customer: ICustomer;
  formGroup: UntypedFormGroup;
  messageEnum: MessageEnum;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private googleRecaptchaService: GoogleRecaptchaService,
    private otpService: OtpService,
    private overflowErrorsService: OverflowErrorsService,
    private redirectService: RedirectService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.QUERY_CUSTOMER_STATUS_INIT_PAGE,
    );

    this.advisor = this.advisorService.getAdvisor();
    this.customer = this.customerService.getCustomer();

    this.initForm();

    if (!this.isUnificationFlowActive) {
      this.messageAdvisorType();
    }
  }

  initForm() {
    this.countRetrySendOtp = 0;
    this.numberInputOtp = ApplicationConstantsEnum.OTP_SHORT;

    this.cellPhone = new PhoneNumberObfuscatePipe().transform(
      this.customer.cellphone,
    );

    this.description = `Ingresa el código de seguridad que enviamos a tu celular:
       ${this.cellPhone} ${
      this.advisorService.isAdvisorDecisionSendViabilityQuery()
        ? ""
        : `y a tu correo ${this.customer.email}`
    }`;
    this.title = this.advisorService.isAdvisorDecisionSendViabilityQuery()
      ? "Código de seguridad"
      : "¡Muy bien " +
        this.customer.name.trim() +
        (" " + this.customer.lastName.trim()) +
        "!";

    this.formGroup = new UntypedFormGroup({});
  }

  async authenticateCodeOtp() {
    if (this.formGroup.valid) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE,
      );

      this.loadingButtonAuthenticateOtp = true;

      this.googleRecaptchaService
        .generateTokenRecaptcha()
        .subscribe((token: any) => {
          this.otpService
            .authenticationOtp(
              this.advisor,
              this.customer,
              this.otpField.getValueOtp(),
              token,
            )
            .subscribe(
              () => {
                this.authenticationOtpSuccess = true;
                this.customer.authenticationDone = true;

                if (this.advisorService.isAdvisorDecisionSendViabilityQuery()) {
                  this.redirectService.redirectToPreviability();
                } else if (
                  this.customer.simPendingAuthentication &&
                  this.advisor.decisionType !==
                    AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
                  !this.isUnificationFlowActive
                ) {
                  this.router.navigate([
                    "validationmethod",
                    ApplicationConstantsEnum.CHARACTER_EMPTY,
                  ]);
                } else {
                  this.redirectService.redirectByCustomerType();
                }
              },
              () => this.authenticateCodeOtpError(),
            );
        });
    }
  }

  authenticateCodeOtpError() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE_ERROR,
    );

    this.countRetrySendOtp++;

    if (this.countRetrySendOtp > 3) {
      this.overflowErrorsService.setOverflowError(
        OverflowErrorsEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS,
      );

      this.router.navigate(["overflowerror"]);
    } else {
      this.showMessageBox = true;
      this.messageEnum = MessageEnum.ERROR_INCORRECT_OTP;
    }
  }

  async generateOtpByCall() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_CLICK_LINK_CALL_OTP,
    );

    this.loadingButtonAuthenticateOtp = true;

    this.googleRecaptchaService
      .generateTokenRecaptcha()
      .subscribe((token: any) => {
        this.otpService
          .generateOtpByCall({
            advisorDecisionType: this.advisor.decisionType,
            advisorDocumentNumber: this.advisor.sellerIdNumber.toString(),
            advisorJourneyId: this.advisor.advisorJourneyId,
            cellphoneNumber: (this.customer.cellphone as unknown) as number,
            documentNumber: this.customer.documentId.toString(),
            documentType: this.customer.documentType,
            officeId: this.advisor.office.idoffice.toString(),
            officeName: this.advisor.office.name_office,
            recaptchaResponse: token,
          })
          .subscribe(
            () => this.clearOtpText(true),
            () => this.errorTechnical(),
          );
      });
  }

  infoBarComplete() {
    if (!this.authenticationOtpSuccess) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_EXPIRED_TIME,
      );

      this.showMessageBox = true;
      this.messageEnum = MessageEnum.ERROR_EXPIRE_OTP;
    }
  }

  async closeMessageBox() {
    this.showMessageBox = false;

    if (this.messageEnum === MessageEnum.ERROR_EXPIRE_OTP) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_CLICK_GENERATE_OTHER_OTP,
      );

      this.loadingButtonAuthenticateOtp = true;

      this.googleRecaptchaService
        .generateTokenRecaptcha()
        .subscribe((token: any) => {
          this.otpService
            .generateOtp({
              advisorDecisionType: this.advisor.decisionType,
              advisorDocumentNumber: this.advisor.sellerIdNumber.toString(),
              advisorJourneyId: this.advisor.advisorJourneyId,
              cellphoneNumber: (this.customer.cellphone as unknown) as number,
              documentNumber: this.customer.documentId.toString(),
              documentType: this.customer.documentType,
              officeId: this.advisor.office.idoffice.toString(),
              officeName: this.advisor.office.name_office,
              recaptchaResponse: token,
            })
            .then(() => this.clearOtpText(true))
            .catch(() => this.errorTechnical());
        });
    } else {
      this.clearOtpText(false);
    }
  }

  closeMessageAdvisorTypeBox() {
    this.showMessageAdvisorTypeBox = false;
  }

  private clearOtpText(restart: boolean) {
    this.otpField.clearOtpText();
    this.loadingButtonAuthenticateOtp = false;

    if (restart) {
      this.progressBar.restartWithSeconds();
    }
  }

  get isUnificationFlowActive() {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  private errorTechnical() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_GENERATE_OTHER_OTP_ERROR,
    );

    this.overflowErrorsService.setOverflowError(
      OverflowErrorsEnum.TECHNICAL_ERROR,
    );

    this.router.navigate(["overflowerror"]);
  }

  private messageAdvisorType() {
    if (
      this.customer.simPendingAuthentication &&
      this.advisor.decisionType !==
        AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
    ) {
      setTimeout(() => {
        this.showMessageAdvisorTypeBox = true;
        this.messageEnum =
          this.advisor.advisorType === AdvisorTypeEnum.OFFICE_SALES
            ? MessageEnum.INFO_ADVISOR_TYPE_RNEC
            : MessageEnum.INFO_ADVISOR_TYPE_DECRIM;
      }, 500);
    }
  }
}
