<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title
        [text]="title | CapitalCasePipe"
        [level]="2"
        id="app-text-title_title-authentication-code"
      ></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="description"
        id="app-text-description_description-authentication-code"
      ></adl-text-description>
    </div>

    <div class="animation-description">
      <adl-nav-pills
        [pills]="pills"
        [checkMark]="false"
        [valueControl]="formGroup.controls['valuePill']"
        [parentForm]="formGroup"
        id="app-nav-pills_pill-authentication-code"
      ></adl-nav-pills>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="''"
        [text]="
          'Enviaremos un código por mensaje de texto o llamada a tus datos de contacto:'
        "
        id="app-text-description_description-send-code"
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <div class="row">
        <div [ngClass]="[showButtonEditCellPhoneOrEmail()]">
          <ng-container *ngIf="!editCellPhone; else inputCellPhone">
            <adl-text-description
              [cssClass]="'font-weight-bold'"
              [text]="'Celular: ' + cellPhone"
              [icon]="'phone'"
              id="app-text-description_description-cell-phone"
            >
            </adl-text-description>
          </ng-container>

          <ng-template #inputCellPhone>
            <adl-form-field
              [lengthInput]="'10'"
              [fieldControl]="formGroup.controls['textCellPhone']"
              [parentForm]="formGroup"
              [text]="'Celular'"
              [textError]="'Celular no válido'"
              id="app-form-field_cell-phone"
              appOnlyNumber
            >
            </adl-form-field>
          </ng-template>

          <img
            class="cursor-pointer close"
            *ngIf="editCellPhone"
            src="./assets/images/icons/close.svg"
            alt="Icon delete"
            (click)="noEditTextCellPhone()"
          />
        </div>
        <ng-container
          *ngIf="
            (advisor.decisionType === decision.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
              !editCellPhone) ||
            unificationFlowActive
          "
        >
          <div class="col-2 text-center">
            <img
              class="cursor-pointer"
              src="./assets/images/icons/pen-orange.svg"
              alt="Icon pen orange"
              (click)="editTextCellPhone()"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div class="h-14-px"></div>

    <div
      class="animation-description"
      *ngIf="!isAdvisorDecisionSendViabilityQuery"
    >
      <div class="row">
        <div [ngClass]="[showButtonEditCellPhoneOrEmail()]">
          <ng-container *ngIf="!editEmail; else inputEmail">
            <adl-text-description
              [cssClass]="'font-weight-bold'"
              [text]="'Correo: ' + customer.email"
              [icon]="'at'"
              id="app-text-description_description-email"
            >
            </adl-text-description>
          </ng-container>

          <ng-template #inputEmail>
            <div class="h-75-px">
              <adl-form-field
                [lengthInput]="'40'"
                [fieldControl]="formGroup.controls['textEmail']"
                [parentForm]="formGroup"
                [text]="'Correo'"
                [textError]="'Correo no válido'"
                id="app-form-field_email"
              >
              </adl-form-field>
            </div>
          </ng-template>

          <img
            class="cursor-pointer close"
            *ngIf="editEmail"
            src="./assets/images/icons/close.svg"
            alt="Icon delete"
            (click)="noEditTextEmail()"
          />
        </div>
        <ng-container
          *ngIf="
            (advisor.decisionType === decision.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
              !editEmail) ||
            unificationFlowActive
          "
        >
          <div class="col-2 text-center">
            <img
              class="cursor-pointer"
              src="./assets/images/icons/pen-orange.svg"
              alt="Icon pen orange"
              (click)="editTextEmail()"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div class="h-25-px"></div>

    <div class="animation-button">
      <adl-button
        [cssClass]="'w-50'"
        [loading]="loadingButtonGenerate"
        [mandatory]="true"
        [parentForm]="formGroup"
        [text]="'Continuar'"
        (clickEvent)="generateCodeOtp()"
        id="adl-button_generate-code-otp"
      >
      </adl-button>
    </div>
  </form>
</div>

<app-form-popup-message-box
  #formPopupMessageBox
  [showMessageBox]="showMessageBox"
  [messageEnum]="messageEnum"
  (closePopupEvent)="closeMessageBox()"
></app-form-popup-message-box>
