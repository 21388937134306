import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, forkJoin, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { ToggleEnum } from "../../core/enums/toggle.enum";

import { ICognito } from "../../core/interfaces/cognito.interface";

import { ToggleService } from "./toggle.service";

import { CognitoRemoteService } from "../remote/cognito-remote.service";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  cognitoToken = new BehaviorSubject<any | null>(null);
  currentCognitoToken = this.cognitoToken.asObservable();

  private cognitoTokenActiveDirectory = new BehaviorSubject<ICognito>(null);
  private currentCognitoTokenActiveDirectory = this.cognitoTokenActiveDirectory.asObservable();

  private cognitoTokenLegacy = new BehaviorSubject<ICognito>(null);
  private currentCognitoTokenLegacy = this.cognitoTokenLegacy.asObservable();

  private cognitoTokenNew = new BehaviorSubject<ICognito>(null);
  private currentCognitoTokenNew = this.cognitoTokenLegacy.asObservable();

  private failTokens = new BehaviorSubject<boolean>(null);
  private currentfailTokens = this.failTokens.asObservable();

  constructor(
    private cognitoRemoteService: CognitoRemoteService,
    private toggleService: ToggleService,
  ) {}

  getCognitoCredentials(): Observable<object> {
    return this.cognitoRemoteService.getToken().pipe(
      tap((ev) => {
        this.setToken(ev);
      }),
    );
  }

  getCognitoCredentialsActiveDirectory(access_token): Observable<any> {
    return this.cognitoRemoteService.getTokenActiveDirectory(access_token).pipe(
      tap((data) => {
        const newTokenData = {
          accessToken: data.tokenNewCognito,
          expires_in: 3600,
          token_type: "Bearer",
        };

        const newTokenDataICognito: ICognito = {
          access_token: data.tokenNewCognito,
          expires_in: 3600,
          token_type: "Bearer",
        };

        const oldTokenData: ICognito = {
          access_token: data.tokenOldCognito,
          expires_in: 3600,
          token_type: "Bearer",
        };

        this.setToken(newTokenData);
        this.setTokenLegacy(oldTokenData);
        this.setTokenNew(newTokenDataICognito);

        this.storeToken("cognitoTokenLegacy", oldTokenData);
        this.storeToken("cognitoTokenNew", newTokenDataICognito);
      }),
    );
  }

  getCognitoCredentialsLegacy(): Observable<object> {
    return this.cognitoRemoteService.getTokenLegacy().pipe(
      tap((ev) => {
        this.setTokenLegacy(ev);
      }),
    );
  }

  getCognitoCredentialsNew(): Observable<object> {
    return this.cognitoRemoteService.getTokenNew().pipe(
      tap((ev) => {
        this.setTokenNew(ev);
      }),
    );
  }

  getAllCognitoTokens() {
    return forkJoin([
      this.getCognitoCredentials(),
      this.getCognitoCredentialsLegacy(),
      this.getCognitoCredentialsNew(),
    ]).pipe(
      catchError((error) => {
        this.failTokens.next(true);
        return of(error);
      }),
    );
  }

  getAllCognitoTokenActiveDirectory(accesToken: string) {
    const isAuthenticationActiveDirectory = this.toggleService.getToggleEnabledById(
      ToggleEnum.AUTHENTICATION_ACTIVE_DIRECTORY,
    );

    const isFederatedAuthentication = this.toggleService.getToggleEnabledById(
      ToggleEnum.FEDERATED_AUTHENTICATION,
    );

    if (isAuthenticationActiveDirectory && isFederatedAuthentication) {
      return this.getCognitoCredentialsActiveDirectory(accesToken);
    } else {
      return this.getAllCognitoTokens();
    }
  }

  getToken(): Observable<any | null> {
    return this.cognitoToken.asObservable();
  }

  getTokenActiveDirectory() {
    return this.cognitoTokenActiveDirectory.value !== null
      ? this.cognitoTokenActiveDirectory.value.access_token
      : "";
  }

  getTokenLegacy(): string {
    return this.cognitoTokenLegacy.value !== null
      ? this.cognitoTokenLegacy.value.access_token
      : "";
  }

  getTokenNew(): string {
    return this.cognitoTokenNew.value !== null
      ? this.cognitoTokenNew.value.access_token
      : "";
  }

  getTokenActiveDirectoryObservable(): Observable<ICognito> {
    return this.currentCognitoTokenActiveDirectory;
  }

  getTokenLegacyObservable(): Observable<ICognito> {
    return this.currentCognitoTokenLegacy;
  }

  getTokenNewObservable(): Observable<ICognito> {
    return this.currentCognitoTokenNew;
  }

  setToken(cognitoCredentials: any): void {
    this.cognitoToken.next(cognitoCredentials);
  }

  setTokenActiveDirectory(cognitoCredentialsActiveDirectory: ICognito): void {
    this.cognitoTokenActiveDirectory.next(cognitoCredentialsActiveDirectory);
  }

  setTokenLegacy(cognitoCredentialsLegacy: ICognito): void {
    this.cognitoTokenLegacy.next(cognitoCredentialsLegacy);
  }

  setTokenNew(cognitoCredentialsNew: ICognito): void {
    this.cognitoTokenNew.next(cognitoCredentialsNew);
  }

  isFailToken(): Observable<boolean> {
    return this.currentfailTokens;
  }

  private storeToken(key: string, tokenData: any): void {
    localStorage.setItem(key, JSON.stringify(tokenData));
  }
}
