<div *ngIf="popUpDecrim">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopupAndRedirect(false)"
      />
    </div>

    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img [src]="message.icon" alt="Icon Decrim" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 text-center">
        <adl-text-description
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 h-75-px">
        <adl-form-file-input-view
          [title]="'Cédula de frente'"
          [fileInputId]="0"
          [fileName]="files[0]?.name"
          [parentForm]="formGroup"
          [validFile]="validatedFiles[0]"
          (fileUploadEvent)="fileUpload(0, $event)"
          (restoreFileUploadEvent)="restoreFileUpload(0)"
        >
        </adl-form-file-input-view>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 h-75-px">
        <adl-form-file-input-view
          [title]="'Cédula por detrás'"
          [fileInputId]="1"
          [fileName]="files[1]?.name"
          [parentForm]="formGroup"
          [validFile]="validatedFiles[1]"
          (fileUploadEvent)="fileUpload(1, $event)"
          (restoreFileUploadEvent)="restoreFileUpload(1)"
        >
        </adl-form-file-input-view>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 h-75-px">
        <adl-form-file-input-view
          [title]="'Huella y Firma'"
          [fileInputId]="2"
          [fileName]="files[2]?.name"
          [parentForm]="formGroup"
          [validFile]="validatedFiles[2]"
          (fileUploadEvent)="fileUpload(2, $event)"
          (restoreFileUploadEvent)="restoreFileUpload(2)"
        >
        </adl-form-file-input-view>
      </div>
    </div>

    <div
      *ngIf="validateUploadFacialIdentification()"
      class="w-100 d-flex align-items-center justify-content-center"
    >
      <div class="w-85 h-75-px">
        <adl-form-file-input-view
          [title]="'Reconocimiento facial'"
          [fileInputId]="3"
          [fileName]="files[3]?.name"
          [parentForm]="formGroup"
          [validFile]="validatedFiles[3]"
          (fileUploadEvent)="fileUpload(3, $event)"
          (restoreFileUploadEvent)="restoreFileUpload(3)"
        >
        </adl-form-file-input-view>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 h-75-px">
        <adl-button
          id="adl-button_button-upload-decrim"
          cssClass="w-100 buttonTextMedium  {{
            formGroup.disabled ? 'buttonSilver' : ''
          }} "
          [mandatory]="true"
          [loading]="loadingUploadFile"
          [text]="message.captionButton"
          [parentForm]="formGroup"
          (clickEvent)="upload()"
        ></adl-button>
      </div>
    </div>
  </form>
</div>
