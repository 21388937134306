export enum UploadDocumentsStatesEnum {
  BIG_FILE_ERROR = "Archivo muy pesado. Máximo 400KB",
  BIG_ONBASE_FILE_ERROR = "Archivo muy pesado. Máximo 1 MB",
  DOCUMENT_CAPTURE_DATA = "DOCUMENT_CAPTURE_DATA",
  DOCUMENT_EXTRACTOR = "DOCUMENT_EXTRACTOR",
  DOCUMENT_IN_PROCESS = "Documento en proceso",
  DOCUMENT_LOAD_INVALIDATE = "No se pudo cargar el archivo",
  DOCUMENT_LOAD_ONBASE = "Documentos Cargados a Onbase",
  DOCUMENT_LOAD_ONBASE_ON_PROCESS = "Carga de documentos a onbase en proceso",
  DOCUMENT_LOAD_ONBASE_REJECT = "Carga de Documentos en Onbase Fallido",
  DOCUMENT_LOAD_VALIDATE = "Validando Documento",
  DOCUMENTS_LOAD = "Documentos Cargados",
  DOCUMENTS_LOAD_CANCEL = "Documento Cancelado",
  DOCUMENTS_LOAD_EMPTY = "",
  DOCUMENTS_SEND_TO_OCR = "Documentos Enviados a OCR",
  DOCUMENTS_TO_LOAD = "Documentos Por Cargar",
  FILE_EMPTY = "No se ha seleccionado ningún archivo",
  FILE_EXISTS = "Ya cargaste un archivo con este mismo nombre",
  OCR_INCORRECT = "Validación OCR Incorrecta",
  OCR_TO_VALIDATE = "Validación OCR",
  OCR_VALIDATE = "Validación OCR Correcta",
  RELOAD_DOCUMENTS = "Cargar de nuevo",
  SEND_TO_BANK = "Envío a fábrica",
  VALIDATE_INCORRECT = "Validación Incorrecta",
}
