<div
  id="main"
  class="d-flex flex-column align-items-center justify-content-center"
>
  <div class="row">
    <div class="col-12 d-flex align-items-end p-b-18 p-b-sm-6">
      <div class="text-tittle">
        <adl-text-title
          [text]="'¿Qué deseas hacer?'"
          [level]="2"
        ></adl-text-title>
      </div>
    </div>
  </div>
  <div class="menu-container">
    <ng-container *ngFor="let item of menuDecision">
      <ng-container *ngIf="item.visible">
        <app-menu-item
          [data]="item"
          (selectDecision)="goToDecision($event)"
        ></app-menu-item>
      </ng-container>
    </ng-container>
  </div>

  <app-form-popup-strong-authentication
    #formPopupStrongAuthentication
    (closePopupEvent)="closeStrongAuthenticationPopup()"
    (goToDecisionEvent)="goToDecision($event)"
  ></app-form-popup-strong-authentication>

  <app-form-popup-training
    #formPopupTraining
    (closePopupEvent)="closeTrainingPopup()"
  ></app-form-popup-training>

  <app-form-popup-viability-query
    #formPopupViabilityQuery
    (closePopupAndRedirectEvent)="closeViabilityQueryPopup($event)"
  ></app-form-popup-viability-query>
</div>
<app-fresh-chat></app-fresh-chat>
