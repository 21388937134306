import { Injectable } from "@angular/core";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";

import { ValidationSimRemoteService } from "../remote/validation-sim-remote.service";

@Injectable({
  providedIn: "root",
})
export class ValidationSimService {
  constructor(private validationSimRemoteService: ValidationSimRemoteService) {}

  async asyncValidateCustomerCellPhone(customer: ICustomer, customerInfo: any) {
    await this.validationSimRemoteService
      .asyncValidateCustomerCellPhone(customerInfo)
      .then((dataValidateCustomerCellPhone: any) => {
        customer.simPendingAuthentication =
          dataValidateCustomerCellPhone.status ===
          ApplicationConstantsEnum.AUTHENTICATION_SIM_PENDING;
      })
      .catch((errorValidateCustomerCellPhone: any) => {
        errorValidateCustomerCellPhone.console = "Validacion Cell Phone";
        throw errorValidateCustomerCellPhone;
      });
  }
}
