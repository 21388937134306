<div *ngIf="showPopUpSygnusLink">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopup()"
      />
    </div>

    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img [src]="message.icon" alt="Icon Sygnus Link" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 text-center">
        <adl-text-description
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>

    <ng-container *ngIf="isFormPopupSygnusLinkConfiguration(message.messageId)">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <div class="w-85 text-justify">
          <adl-form-select
            id="app-form-select_link-type"
            [fieldControl]="formGroup.controls['textBindings']"
            [valueControl]="formGroup.controls['valueBindings']"
            [lengthInput]="'100'"
            [listSelect]="listBindings"
            [parentForm]="formGroup"
            [text]="'Tipo de vinculación'"
          ></adl-form-select>
        </div>
      </div>
    </ng-container>

    <div class="w-100 h-110-px d-flex justify-content-center">
      <div class="w-85 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <adl-button
            id="adl-button_button-send-sygnus-link"
            [cssClass]="'w-100 buttonTextMedium'"
            [loading]="loadingButtonSendSygnusLink"
            [mandatory]="true"
            [parentForm]="formGroup"
            [text]="message.captionButton"
            (click)="buttonProcess()"
          ></adl-button>
        </div>
      </div>
    </div>
  </form>
</div>
