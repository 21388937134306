import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BpopVisualComponentsFrontendLibModule } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

// Environment
import { environment } from "../../environments/environment";

// Modules
import { AppRoutingModule } from "../app-routing.module";
import { AppCoreModule } from "../core/app-core.module";

// Groups
import { AuthenticationStatesComponent } from "./groups/authentication-states/authentication-states.component";
import { CloseSessionComponent } from "./groups/close-session/close-session.component";
import { ContentHeaderComponent } from "./groups/content-header/content-header.component";
import { DocumentUploadNotificationComponent } from "./groups/document-upload-notification/document-upload-notification.component";
import { FormSalaryLoanComponent } from "./groups/form-salary-loan/form-salary-loan.component";
import { TableComponent } from "./groups/table/table.component";

// Structures
import { AuthenticationCardDebitImageComponent } from "./structures/authentication-card-debit-image/authentication-card-debit-image.component";
import { AuthenticationCodeImageComponent } from "./structures/authentication-code-image/authentication-code-image.component";
import { AuthenticationWhatsappImageComponent } from "./structures/authentication-whatsapp-image/authentication-whatsapp-image.component";
import { FormAccountSelectionComponent } from "./structures/form-account-selection/form-account-selection.component";
import { FormAuthenticationCardDebitComponent } from "./structures/form-authentication-card-debit/form-authentication-card-debit.component";
import { FormAuthenticationCodeComponent } from "./structures/form-authentication-code/form-authentication-code.component";
import { FormAuthenticationWhatsappComponent } from "./structures/form-authentication-whatsapp/form-authentication-whatsapp.component";
import { FormAuthorizationDataComponent } from "./structures/form-authorization-data/form-authorization-data.component";
import { FormCustomerDecisionComponent } from "./structures/form-customer-decision/form-customer-decision.component";
import { FormDecisionComponent } from "./structures/form-decision/form-decision.component";
import { FormLoanDecisionComponent } from "./structures/form-loan-decision/form-loan-decision.component";
import { FormLoginAdvisorComponent } from "./structures/form-login-advisor/form-login-advisor.component";
import { FormLoginCustomerComponent } from "./structures/form-login-customer/form-login-customer.component";
import { FormNewLoanComponent } from "./structures/form-new-loan/form-new-loan.component";
import { FormOtpComponent } from "./structures/form-otp/form-otp.component";
import { FormPepComponent } from "./structures/form-pep/form-pep.component";
import { FormPopupDecrimComponent } from "./structures/form-popup-decrim/form-popup-decrim.component";
import { FormPopupDecrimWithCustomerInfoComponent } from "./structures/form-popup-decrim-with-customer-info/form-popup-decrim-with-customer-info.component";
import { FormPopupInsurabilityQuestionComponent } from "./structures/form-popup-insurability-question/form-popup-insurability-question.component";
import { FormPopupMessageBoxComponent } from "./structures/form-popup-message-box/form-popup-message-box.component";
import { FormPopupPayrollCheckCaptureComponent } from "./structures/form-popup-payroll-check-capture/form-popup-payroll-check-capture.component";
import { FormPopupRnecWithCustomerInfoComponent } from "./structures/form-popup-rnec-with-customer-info/form-popup-rnec-with-customer-info.component";
import { FormPopupShortFlowRepricingComponent } from "./structures/form-popup-short-flow-repricing/form-popup-short-flow-repricing.component";
import { FormPopupRnecComponent } from "./structures/form-popup-rnec/form-popup-rnec.component";
import { FormPopupStrongAuthenticationComponent } from "./structures/form-popup-strong-authentication/form-popup-strong-authentication.component";
import { FormPopupSygnusLinkComponent } from "./structures/form-popup-sygnus-link/form-popup-sygnus-link.component";
import { FormPopupTrainingComponent } from "./structures/form-popup-training/form-popup-training.component";
import { FormPopupUploadPaymentReceiptComponent } from "./structures/form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";
import { FormPopupValidationQueryDecrimComponent } from "./structures/form-popup-validation-query-decrim/form-popup-validation-query-decrim.component";
import { FormPopupViabilityQueryComponent } from "./structures/form-popup-viability-query/form-popup-viability-query.component";
import { FormPopupViewMoreComponent } from "./structures/form-popup-view-more/form-popup-view-more.component";
import { FormQueryUploadDocumentsComponent } from "./structures/form-query-upload-documents/form-query-upload-documents.component";
import { FormUploadDocumentsOnbaseComponent } from "./structures/form-upload-documents-onbase/form-upload-documents-onbase.component";
import { FreshChatComponent } from "./structures/fresh-chat/fresh-chat.component";
import { LoginImageComponent } from "./structures/login-image/login-image.component";
import { MainImageComponent } from "./structures/main-image/main-image.component";
import { MenuItemComponent } from "./structures/form-decision/components/menu-item/menu-item.component";
import { OtpImageComponent } from "./structures/otp-image/otp-image.component";
import { OverflowErrorViewComponent } from "./structures/overflow-error-view/overflow-error-view.component";
import { PayrollLoanStatusViewComponent } from "./structures/payroll-loan-status-view/payroll-loan-status-view.component";
import { PopupDocumentUploadTipsComponent } from "./structures/popup-document-upload-tips/popup-document-upload-tips.component";
import { PopupPayrollcheckUploadResponseComponent } from "./structures/popup-payrollcheck-upload-response/popup-payrollcheck-upload-response.component";
import { SalaryLoanSuccessViewComponent } from "./structures/salary-loan-success-view/salary-loan-success-view.component";
import { SalaryLoanViewComponent } from "./structures/salary-loan-view/salary-loan-view.component";
import { ValidationMethodViewComponent } from "./structures/validation-method-view/validation-method-view.component";

// Templates
import { OneColumnComponent } from "./templates/one-column/one-column.component";
import { TwoColumnComponent } from "./templates/two-column/two-column.component";

// Pages
import { AccountSelectionComponent } from "./pages/account-selection/account-selection.component";
import { AuthenticationCardDebitComponent } from "./pages/authentication-card-debit/authentication-card-debit.component";
import { AuthenticationCodeComponent } from "./pages/authentication-code/authentication-code.component";
import { AuthenticationWhatsappComponent } from "./pages/authentication-whatsapp/authentication-whatsapp.component";
import { AuthorizationDataComponent } from "./pages/authorization-data/authorization-data.component";
import { CustomerDecisionComponent } from "./pages/customer-decision/customer-decision.component";
import { DecisionComponent } from "./pages/decision/decision.component";
import { LoanDecisionComponent } from "./pages/loan-decision/loan-decision.component";
import { LoginAdvisorComponent } from "./pages/login-advisor/login-advisor.component";
import { LoginCustomerComponent } from "./pages/login-customer/login-customer.component";
import { NewLoanComponent } from "./pages/new-loan/new-loan.component";
import { OtpAuthenticationComponent } from "./pages/otp-authentication/otp-authentication.component";
import { OverflowErrorComponent } from "./pages/overflow-error/overflow-error.component";
import { PayrollLoanStatusComponent } from "./pages/payroll-loan-status/payroll-loan-status.component";
import { PepComponent } from "./pages/pep/pep.component";
import { QueryUploadDocumentsComponent } from "./pages/query-upload-documents/query-upload-documents.component";
import { SalaryLoanSuccessComponent } from "./pages/salary-loan-success/salary-loan-success.component";
import { SalaryLoanComponent } from "./pages/salary-loan/salary-loan.component";
import { UploadDocumentsOnbaseComponent } from "./pages/upload-documents-onbase/upload-documents-onbase.component";
import { ValidationMethodComponent } from "./pages/validation-method/validation-method.component";

// Pipes
import { CapitalCasePipe } from "../core/pipes/capital-case.pipe";
import { MissingQuotas } from "../core/pipes/missing-quotas.pipe";
import { PhoneNumberObfuscatePipe } from "../core/pipes/phone-number-obfuscate.pipe";
import { TermQuotas } from "../core/pipes/term-quotas.pipe";

@NgModule({
  declarations: [
    AccountSelectionComponent,
    AuthenticationCardDebitComponent,
    AuthenticationCardDebitImageComponent,
    AuthenticationCodeComponent,
    AuthenticationCodeImageComponent,
    AuthenticationStatesComponent,
    AuthenticationWhatsappComponent,
    AuthenticationWhatsappImageComponent,
    AuthorizationDataComponent,
    CapitalCasePipe,
    CloseSessionComponent,
    ContentHeaderComponent,
    CustomerDecisionComponent,
    DecisionComponent,
    DocumentUploadNotificationComponent,
    FormAccountSelectionComponent,
    FormAuthenticationCardDebitComponent,
    FormAuthenticationCodeComponent,
    FormAuthenticationWhatsappComponent,
    FormAuthorizationDataComponent,
    FormCustomerDecisionComponent,
    FormDecisionComponent,
    FormLoanDecisionComponent,
    FormLoginAdvisorComponent,
    FormLoginCustomerComponent,
    FormNewLoanComponent,
    FormOtpComponent,
    FormPepComponent,
    FormPopupDecrimComponent,
    FormPopupDecrimWithCustomerInfoComponent,
    FormPopupInsurabilityQuestionComponent,
    FormPopupMessageBoxComponent,
    FormPopupPayrollCheckCaptureComponent,
    FormPopupRnecComponent,
    FormPopupRnecWithCustomerInfoComponent,
    FormPopupShortFlowRepricingComponent,
    FormPopupStrongAuthenticationComponent,
    FormPopupSygnusLinkComponent,
    FormPopupTrainingComponent,
    FormPopupUploadPaymentReceiptComponent,
    FormPopupValidationQueryDecrimComponent,
    FormPopupViabilityQueryComponent,
    FormPopupViewMoreComponent,
    FormQueryUploadDocumentsComponent,
    FormSalaryLoanComponent,
    FormUploadDocumentsOnbaseComponent,
    FreshChatComponent,
    LoanDecisionComponent,
    LoginAdvisorComponent,
    LoginCustomerComponent,
    LoginImageComponent,
    MainImageComponent,
    MissingQuotas,
    NewLoanComponent,
    OneColumnComponent,
    OtpAuthenticationComponent,
    OtpImageComponent,
    OverflowErrorComponent,
    OverflowErrorViewComponent,
    PayrollLoanStatusComponent,
    PayrollLoanStatusViewComponent,
    PepComponent,
    PopupDocumentUploadTipsComponent,
    PopupPayrollcheckUploadResponseComponent,
    PhoneNumberObfuscatePipe,
    QueryUploadDocumentsComponent,
    SalaryLoanComponent,
    SalaryLoanViewComponent,
    SalaryLoanSuccessComponent,
    SalaryLoanSuccessViewComponent,
    TableComponent,
    TermQuotas,
    TwoColumnComponent,
    UploadDocumentsOnbaseComponent,
    ValidationMethodComponent,
    ValidationMethodViewComponent,
    MenuItemComponent,
  ],
  imports: [
    AppCoreModule,
    AppRoutingModule,
    BpopVisualComponentsFrontendLibModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ],
  exports: [FormPopupMessageBoxComponent],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleRecaptchaKey,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppDesignModule {}
