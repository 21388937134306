import { Injectable } from "@angular/core";

import { IPep } from "../../core/interfaces/pep.interface";

import { PepRemoteService } from "../remote/pep-remote.service";

@Injectable({
  providedIn: "root",
})
export class PepService {
  constructor(private pepRemoteService: PepRemoteService) {}

  getInformationPep(): IPep[] {
    return this.pepRemoteService.getInformationPep();
  }
}
