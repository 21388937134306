import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";

import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { CognitoService } from "./cognito.service";

import { ViabilityQueryRemoteService } from "../remote/viability-query-remote.service";

@Injectable({
  providedIn: "root",
})
export class ViabilityQueryService {
  sectors: IListSelect[];

  constructor(
    private cognitoService: CognitoService,
    private viabilityQueryRemoteService: ViabilityQueryRemoteService,
  ) {}

  getDeclaratedSectorRisk(): Observable<IListSelect[]> {
    return this.viabilityQueryRemoteService
      .getDeclaratedSectorRisk(this.cognitoService.getTokenNew())
      .pipe(
        map((data: any) => {
          this.sectors = data;

          return data.map((dat: any) => {
            return {
              value: dat.key,
              text: dat.value,
            };
          });
        }),
      );
  }

  getSectors() {
    return this.sectors;
  }
}
