export enum MessageEnum {
  CLIENT_HAVE_PENDING_ONBASE_SALES = "CLIENT_HAVE_PENDING_ONBASE_SALES",
  CLIENT_HAVE_SUCCESS_AUTHENTICATION_SALES = "CLIENT_HAVE_SUCCESS_AUTHENTICATION_SALES",
  DECRIM_CASE_AUTHORIZED = "DECRIM_CASE_AUTHORIZED",
  END_TIME_INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE = "END_TIME_INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE",
  ERROR_CRASHED_OVERALL_SERVICES = "ERROR_CRASHED_OVERALL_SERVICES",
  ERROR_CREATED_CASE_DECRIM = "ERROR_CREATED_CASE_DECRIM",
  ERROR_EXPIRE_OTP = "ERROR_EXPIRE_OTP",
  ERROR_GENERATE_OTP = "ERROR_GENERATE_OTP",
  ERROR_INCORRECT_OTP = "ERROR_INCORRECT_OTP",
  ERROR_QUOTA_GREATER_AVAILABLE = "117",
  ERROR_SAME_FEE_IN_SALARY_LOANS = "ERROR_SAME_FEE_IN_SALARY_LOANS",
  ERROR_SEARCH_ADVISOR = "ERROR_SEARCH_ADVISOR",
  ERROR_SEARCH_CUSTOMER = "ERROR_SEARCH_CUSTOMER",
  ERROR_SEND_FILE_TO_S3 = "ERROR_SEND_FILE_TO_S3",
  ERROR_TECHNICAL = "ERROR_TECHNICAL",
  ERROR_UPLOAD_FILE_TAP_SOLUTION = "ERROR_UPLOAD_FILE_TAP_SOLUTION",
  FAIL_SIMULATION = "FAIL_SIMULATION",
  FORM_POPUP_PAYROLL_CHECK_CAPTURE = "FORM_POPUP_PAYROLL_CHECK_CAPTURE",
  FORM_POPUP_SYGNUS_LINK_CONFIGURATION = "FORM_POPUP_SYGNUS_LINK_CONFIGURATION",
  FORM_POPUP_UPLOAD_PAYMENT_RECEIPT_CONFIGURATION = "FORM_POPUP_UPLOAD_PAYMENT_RECEIPT_CONFIGURATION",
  GrowthAmount001 = "GrowthAmount001",
  GrowthAmount002 = "GrowthAmount002",
  INFO_ADVISOR_TYPE_DECRIM = "INFO_ADVISOR_TYPE_DECRIM",
  INFO_ADVISOR_TYPE_RNEC = "INFO_ADVISOR_TYPE_RNEC",
  INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE = "INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE",
  INFO_CUSTOMER_HAS_UPLOAD_FILE = "INFO_CUSTOMER_HAS_UPLOAD_FILE",
  INFO_UPLOAD_FILE_DECRIM = "INFO_UPLOAD_FILE_DECRIM",
  INSURABILITY_QUESTION_ERROR = "INSURABILITY_QUESTION_ERROR",
  laborCertification104 = "laborCertification104",
  laborCertification106 = "laborCertification106",
  LOADING_INFORMATION = "LOADING_INFORMATION",
  MULTIPLE_DOCUMENTS_VALIDATIONS = "MULTIPLE_DOCUMENTS_VALIDATIONS",
  NAVIGATOR_OFFLINE = "NAVIGATOR_OFFLINE",
  OVERFLOW_COMPARE_RATE = "OVERFLOW_COMPARE_RATE",
  PayerPlatform01 = "PayerPlatform01",
  PayerPlatform03_AfterProcessSygnus = "PayerPlatform03_AfterProcessSygnus",
  PayerPlatform04_AfterProcessSygnus = "PayerPlatform04_AfterProcessSygnus",
  PayerPlatform05_AfterProcessSygnus = "PayerPlatform05_AfterProcessSygnus",
  PayerPlatform05 = "PayerPlatform05",
  PayerPlatform07_OnePayer = "PayerPlatform07_OnePayer",
  PayerPlatform07_ManyPayer = "PayerPlatform07_ManyPayer",
  PayerPlatform09_AfterProcessSygnus = "PayerPlatform09_AfterProcessSygnus",
  PayerPlatform10_AfterProcessSygnus = "PayerPlatform10_AfterProcessSygnus",
  PayerPlatform12_AfterProcessSygnus = "PayerPlatform12_AfterProcessSygnus",
  PayrollChecks001 = "PayrollChecks001",
  PayrollChecks003 = "PayrollChecks003",
  PayrollChecks004 = "PayrollChecks004",
  PayrollChecks007 = "PayrollChecks007",
  PayrollChecks008 = "PayrollChecks008",
  PayrollChecks009 = "PayrollChecks009",
  PayrollChecks011 = "PayrollChecks011",
  PayrollChecks013 = "PayrollChecks013",
  PayrollChecks014 = "PayrollChecks014",
  PayrollChecks017 = "PayrollChecks017",
  PayrollChecks018 = "PayrollChecks018",
  PayrollChecks019 = "PayrollChecks019",
  PayrollChecks020 = "PayrollChecks020",
  PayrollChecks021 = "PayrollChecks021",
  PayrollSimulation001 = "PayrollSimulation001",
  PayrollSimulation002 = "PayrollSimulation002",
  PERSONAL_DATA_INFO = "PERSONAL_DATA_INFO",
  QueryAuthCase002 = "QueryAuthCase002",
  RISK_CENTERS_INFO = "RISK_CENTERS_INFO",
  RNEC_CASE_AUTHORIZED = "RNEC_CASE_AUTHORIZED",
  SecurityManager0017 = "SecurityManager0017",
  SecurityManager0018 = "SecurityManager0018",
  SecurityManager0021 = "SecurityManager0021",
  SecurityManager0021_Decrim = "SecurityManager0021_Decrim",
  SecurityManager0021_Rnec = "SecurityManager0021_Rnec",
  SecurityManager0022 = "SecurityManager0022",
  SecurityManager0023 = "SecurityManager0023",
  SecurityManager0024 = "SecurityManager0024",
  Simulate001_AfterProcessSygnus = "Simulate001_AfterProcessSygnus",
  SIMULATION_ERROR_105 = "105",
  SIMULATION_ERROR_ADITIONAL_145 = "145",
  SIMULATION_ERROR_117 = "117",
  SIMULATION_EXTRACTOR_ERROR = "SIMULATION_EXTRACTOR_ERROR",
  SUCCESS_UPLOAD_PAYMENT_RECEIPT = "SUCCESS_UPLOAD_PAYMENT_RECEIPT",
  SUCCESS_CLIENT_VALIDATION_REPRICING = "SUCCESS_CLIENT_VALIDATION_REPRICING",
  SUCCESS_CLIENT_VALIDATION_REPRICINGV2 = "SUCCESS_CLIENT_VALIDATION_REPRICINGV2",
  UpdatePhoneAuth004 = "UpdatePhoneAuth004",
  UpdatePhoneAuth005 = "UpdatePhoneAuth005",
  UpdatePhoneAuth006 = "UpdatePhoneAuth006",
  UpdatePhoneAuth009 = "UpdatePhoneAuth009",
  UpdatePhoneAuth999 = "UpdatePhoneAuth999",
  UPLOAD_EXCEEDED_TIMEOUT = "UPLOAD_EXCEEDED_TIMEOUT",
  VALIDATION_QUERY_DECRIM = "VALIDATION_QUERY_DECRIM",
  VIABILITY_QUERY = "VIABILITY_QUERY",
  WITHOUT_CODE = "WITHOUT_CODE",
}
