import { Injectable } from "@angular/core";

import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageLoanEnum } from "../../../core/enums/message-loan.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../advisor.service";
import { ToggleService } from "../toggle.service";

import { MessageLoanOperationNormalConditions } from "../../remote/strategy/message-loan-operation-normal-conditions";
import { MessageLoanOperationPayerrollPlatform } from "../../remote/strategy/message-loan-operation-payerroll-platform";
import { MessageLoanOperationTenPerAnyFee } from "../../remote/strategy/message-loan-operation-ten-per-any-fee";
import { MessageLoanOperationWithoutLogic } from "../../remote/strategy/message-loan-operation-without-logic";

import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";
import { IMessageLoanStrategy } from "../../remote/strategy/message-loan-strategy.interface";

@Injectable({
  providedIn: "root",
})
export class MessageLoanStrategyService {
  isPaystubsSygnusRequired = false;
  constructor(
    private advisorService: AdvisorService,
    private toggleService: ToggleService,
  ) {
    this.isPaystubsSygnusRequired = this.toggleService.getToggleEnabledById(
      ToggleEnum.PAYSTUBS_SYGNUS_REQUIRED,
    );
  }

  getMessageLoan(
    dataLoanStatus: ILoanStatus,
    canRenewLoan: boolean,
    loanFlow: LoanFlowEnum,
  ) {
    dataLoanStatus.loanRenewValidation = canRenewLoan
      ? dataLoanStatus.loanRenewValidation
      : MessageLoanEnum.MESSAGE_LOAN_WITH_ERRORS;

    const messageLoan = this.getStrategy(dataLoanStatus, loanFlow);
    return messageLoan.getMessageLoan();
  }

  private getStrategy(
    dataLoansStatus: ILoanStatus,
    loanFlow: string,
  ): IMessageLoanStrategy {
    const messageId = dataLoansStatus.loanRenewValidation;
    switch (messageId) {
      case MessageLoanEnum.INVALID_PAYMENT_TYPE:
      case MessageLoanEnum.MESSAGE_LOAN_WITH_ERRORS:
      case MessageLoanEnum.NOT_APPROVE_BURO_DENEGATION:
      case MessageLoanEnum.NOT_APPROVE_BUSINESS_RULES:
      case MessageLoanEnum.NOT_APPROVE_EXTERNAL_VIABILITY:
      case MessageLoanEnum.NOT_APPROVE_MAX_AGE_EXCEEDED:
      case MessageLoanEnum.NOT_APPROVE_MODALITY_OR_SEGMENT_NOT_SOPPORTED_ADL:
      case MessageLoanEnum.NOT_APPROVE_OTHER_LOAN_IN_PROCESS:
      case MessageLoanEnum.NOT_APPROVE_PAYER_NOT_SOPPORTED_ADL:
      case MessageLoanEnum.SIMULATE_SAME_FEE_ERROR:
        return new MessageLoanOperationWithoutLogic(dataLoansStatus);
      case MessageLoanEnum.PAYERROLL_PLATFORM_ACTIVE:
        return new MessageLoanOperationPayerrollPlatform(dataLoansStatus);
      case MessageLoanEnum.LOAN_NOT_GENERATE_TEN_PERCENTAGE_GROWTH:
        return new MessageLoanOperationTenPerAnyFee(
          dataLoansStatus,
          this.advisorService.getDecisionType(),
        );
      case MessageLoanEnum.NORMAL_CONDITIONS:
      case MessageLoanEnum.PAYER_WITH_EXTRACTOR:
        return new MessageLoanOperationNormalConditions(dataLoansStatus);
      default:
        return loanFlow === LoanFlowEnum.ANYFEE
          ? new MessageLoanOperationTenPerAnyFee(
              dataLoansStatus,
              this.advisorService.getDecisionType(),
            )
          : new MessageLoanOperationNormalConditions(dataLoansStatus);
    }
  }
}
