import { IMessageLoan } from "../../../core/interfaces/message-loan.interface";
import { IMessageLoanStrategy } from "./message-loan-strategy.interface";

import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";

import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageLoanEnum } from "../../../core/enums/message-loan.enum";

export class MessageLoanOperationNormalConditions extends IMessageLoanStrategy {
  constructor(dataLoanStatus: ILoanStatus) {
    super(dataLoanStatus);
  }

  getMessageId(): string {
    return this.dataLoanStatus.customerInBureauTrail
      ? "NORMAL_CONDITIONS_WITH_IN_BUREAU_TRAIL"
      : "NORMAL_CONDITIONS_WITHOUT_IN_BUREAU_TRAIL";
  }

  getMessageLoan(): IMessageLoan {
    const messageLoanReturn = { ...super.getMessageLoan() };

    if (messageLoanReturn.description) {
      messageLoanReturn.description = messageLoanReturn.description.replace(
        /{payerUniqueName}/,
        this.dataLoanStatus.payerUniqueName,
      );
    }

    return this.getMessageLoanWrapper(messageLoanReturn);
  }

  private getMessageLoanWrapper(messageLoan: IMessageLoan) {
    if (
      this.dataLoanStatus.loanRenewValidation ===
      MessageLoanEnum.PAYER_WITH_EXTRACTOR
    ) {
      messageLoan.loanFlowType = LoanFlowEnum.EXTRACTOR;
      messageLoan.messageLoanId = this.dataLoanStatus.loanRenewValidation;
    }
    return messageLoan;
  }
}
