import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { IExtractorRequest } from "../../core/interfaces/extractor-request.interface";

import { IExtractionResponse } from "../../core/interfaces/extraction-response.interface";

@Injectable({
  providedIn: "root",
})
export class ExtractorRemoteService {
  constructor(private httpClient: HttpClient) {}

  startExtraction(body: IExtractorRequest) {
    return this.httpClient.post(
      `${environment.serverUrlPublicResources}extractor-interpreter/extraction`,
      body,
    );
  }

  getExtractionResult(executeId: string): Observable<IExtractionResponse> {
    return this.httpClient.post<IExtractionResponse>(
      `${environment.serverUrlPublicResources}extractor-interpreter/extraction/result`,
      { executeId },
    );
  }
}
