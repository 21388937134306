import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  INotificationResponse,
  IRadioButtonData,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IPreloan } from "../../../core/interfaces/pre-loan.interface";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { DoublePensionService } from "../../../services/local/double-pension.service";

@Component({
  selector: "app-form-salary-loan",
  templateUrl: "./form-salary-loan.component.html",
  styleUrls: ["./form-salary-loan.component.scss"],
})
export class FormSalaryLoanComponent implements OnInit, OnDestroy {
  @Input() color: string;
  @Input("customerLoans")
  set setCustomerLoans(value: IPreloan) {
    this.customerLoans = value;
    this.progressQuotas = this.calcProgressLoan(
      this.customerLoans.loanQuota,
      this.customerLoans.missingLoanQuotas,
    );
  }

  @Output() clickButtonEvent = new EventEmitter<string>();

  isDisableButton: boolean;
  progressQuotas: number;
  showButton: boolean;

  customerLoans: IPreloan;
  formGroup: UntypedFormGroup;
  notificationResponse: INotificationResponse;
  radioButtonData: IRadioButtonData[];
  loanByPhoneCall = false;
  decisionAdvisor;

  constructor(
    private advisorService: AdvisorService,
    private doublePensionService: DoublePensionService,
  ) {
    this.radioButtonData = this.doublePensionService.getInformationDoublePension();
    this.loanByPhoneCall = this.advisorService.isLoanByPhoneCall();
    this.decisionAdvisor = this.advisorService.getDecisionType();
  }

  ngOnDestroy(): void {
    this.setAdvisorDecision(this.decisionAdvisor, false);
  }

  ngOnInit() {
    this.initForm();
  }

  clickButton(loanFlowType: string) {
    this.doublePensionService.setDoublePension(this.formGroup);
    this.clickButtonEvent.emit(loanFlowType);
  }

  private calcProgressLoan(loanQuota: number, missingLoanQuotas: number) {
    const missingQuotas = loanQuota - missingLoanQuotas;
    return Math.round((missingQuotas / loanQuota) * 100);
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({});

    this.notificationResponse = {
      description: this.customerLoans.messageLoan.description,
      processResponseStatus: this.customerLoans.messageLoan
        .processResponseStatus,
      title: this.customerLoans.messageLoan.title,
    };
    this.showButton = this.customerLoans.messageLoan.captionButton !== "";
  }

  onClickLoadDocuments() {
    this.setAdvisorDecision(AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN, true);
    this.clickButton(this.customerLoans.messageLoan.loanFlowType);
  }

  onClickSendLink() {
    this.setAdvisorDecision(
      AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
      false,
    );
    this.clickButton(this.customerLoans.messageLoan.loanFlowType);
  }

  private setAdvisorDecision(
    decisionType: AdvisorDecisionTypeEnum,
    isInPersonPhone: boolean,
  ) {
    const advisor = {
      ...this.advisorService.getAdvisor(),
      decisionType,
      isInPersonPhone,
      isUnification: true,
    };
    this.advisorService.setAdvisor(advisor);
  }
}
