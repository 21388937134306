import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomerRemoteService {
  constructor(private httpClient: HttpClient) {}

  async asyncSearchCustomer(customerInfo: object) {
    return this.httpClient
      .post(environment.serverUrlSalaryLoans + "customer/search", customerInfo)
      .toPromise();
  }

  async asyncValidateCustomerViabilityODM(customerInfo: object) {
    return this.httpClient
      .post(
        environment.serverUrlSalaryLoans +
          "validations/customer/internal-viability",
        customerInfo,
      )
      .toPromise();
  }

  sendCreditTypeDecision(customerInfo: object): Observable<any> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "customers/credit-type-decision",
      customerInfo,
    );
  }
}
