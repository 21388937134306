import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { ICourse } from "../../../../app/core/interfaces/course.interface";

import { TrainingService } from "../../../services/local/training.service";

@Component({
  selector: "app-form-popup-training",
  templateUrl: "./form-popup-training.component.html",
})
export class FormPopupTrainingComponent implements OnInit {
  @Output() closePopupEvent = new EventEmitter<string>();

  @Input("popupTraining")
  set setPopupTraining(popupTraining: boolean) {
    this.popupTraining = popupTraining;
  }

  continueButton: boolean;
  courseDisplayIndex = 0;
  nextButton: boolean;
  pageDisplay: any;
  pageDisplayIndex = 0;
  popupTraining: boolean;

  courses: ICourse[];
  courseDisplay: ICourse;
  formGroup: UntypedFormGroup;

  constructor(private trainingService: TrainingService) {}

  ngOnInit() {
    this.initForm();
    this.courses = this.trainingService.getCourses();
    if (this.courses && this.courses.length !== 0) {
      this.courseDisplay = this.courses[0];
      this.continueButton = !(this.courseDisplay.pageResponse.length > 1);
      this.nextButton = this.courseDisplay.pageResponse.length > 1;
      this.pageDisplay = this.courseDisplay.pageResponse[0];
    }
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  next() {
    this.pageDisplayIndex++;
    this.pageDisplay = this.courseDisplay.pageResponse[this.pageDisplayIndex];

    if (this.courseDisplay.pageResponse.length === this.pageDisplayIndex + 1) {
      this.nextButton = false;
      this.continueButton = true;
    }
  }

  continue(): void {
    this.trainingService.savePassedCourse(this.courseDisplay.id);
    if (this.courses.length === this.courseDisplayIndex + 1) {
      this.closePopupEvent.emit();
    } else {
      this.courseDisplayIndex++;
      this.courseDisplay = this.courses[this.courseDisplayIndex];
      this.pageDisplayIndex = 0;
      this.pageDisplay = this.courseDisplay.pageResponse[this.pageDisplayIndex];
      if (
        this.courseDisplay.pageResponse.length > 1 &&
        this.pageDisplayIndex !== this.courseDisplay.pageResponse.length - 1
      ) {
        this.continueButton = false;
        this.nextButton = true;
      } else {
        this.continueButton = true;
        this.nextButton = false;
      }
    }
  }
}
