import { Injectable } from "@angular/core";

import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";

import { IAdvisor } from "src/app/core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { ITransactionAudit } from "../../core/interfaces/transaction-audit.interface";

import { OverflowErrorsService } from "./overflow-errors.service";

import { AuthorizationDataRemoteService } from "../remote/authorization-data-remote.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationDataService {
  transactionAudit: ITransactionAudit;

  constructor(
    private authorizationDataRemoteService: AuthorizationDataRemoteService,
    private overflowErrorsService: OverflowErrorsService,
  ) {}

  async acceptData(advisor: IAdvisor, customer: ICustomer): Promise<boolean> {
    this.transactionAudit = {
      acceptance: "true",
      advisorDecisionType: advisor.decisionType,
      advisorDocumentNumber: advisor.sellerIdNumber.toString(),
      advisorJourneyId: advisor.advisorJourneyId,
      documentNumber: customer.documentId.toString(),
      documentType: customer.documentType,
    };

    return this.authorizationDataRemoteService
      .saveDataTreatment(this.transactionAudit)
      .then(() => {
        this.authorizationDataRemoteService.saveCreditReporting(
          this.transactionAudit,
        );

        return true;
      })
      .catch((errorSearchCustomerOrValidations: any) => {
        // eslint-disable-next-line no-console
        console.log(
          `CustomerService - asyncSearchCustomer - Error ${JSON.stringify(
            errorSearchCustomerOrValidations,
          )}`,
        );

        this.overflowErrorsService.setOverflowError(
          errorSearchCustomerOrValidations.error.code
            ? errorSearchCustomerOrValidations.error.code
            : errorSearchCustomerOrValidations.code
            ? errorSearchCustomerOrValidations.code
            : OverflowErrorsEnum.CustomerManagement9999,
        );

        return false;
      });
  }

  async noAcceptData(
    advisor: IAdvisor,
    customer: ICustomer,
    checkData: boolean,
    checkDataTreatment: boolean,
    checkCreditReporting: boolean,
  ) {
    this.transactionAudit = {
      acceptance: null,
      advisorDecisionType: advisor.decisionType,
      advisorDocumentNumber: advisor.sellerIdNumber.toString(),
      advisorJourneyId: advisor.advisorJourneyId,
      documentNumber: customer.documentId.toString(),
      documentType: customer.documentType,
    };

    if (!checkDataTreatment || checkData) {
      this.transactionAudit.acceptance = "false";

      await this.authorizationDataRemoteService.saveDataTreatment(
        this.transactionAudit,
      );
    }

    if (!checkCreditReporting || checkData) {
      this.transactionAudit.acceptance = "false";

      await this.authorizationDataRemoteService.saveCreditReporting(
        this.transactionAudit,
      );
    }
  }
}
