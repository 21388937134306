<div
  id="main"
  class="d-flex flex-column align-items-center justify-content-center"
>
  <div class="row">
    <div class="col-12 d-flex align-items-end ">
      <div class="text-tittle">
        <adl-text-title
          [text]="'Antes de continuar...'"
          [level]="2"
        ></adl-text-title>
      </div>
    </div>
  </div>
  <div class="pb-5 p-b-sm-6">
    <adl-text-description
      text="Selecciona la opción que deseas realizar"
    ></adl-text-description>
  </div>
  <div class="menu-container">
    <div class="d-flex justify-content-center">
      <div
        id="app-form-decision_new-payroll-loan"
        class="decision-button bt-left text-center pt-4"
        (click)="goToDecision(advisorDecisionTypeEnum.NEW_PAYROLL_LOAN)"
      >
        <img
          src="assets/images/decision/new.svg"
          alt=""
          width="100"
          height="100"
        />
        <p>Libranza Presencial</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div
        id="app-form-decision_new-payroll-loan-by-phone-call"
        class="decision-button bt-left text-center pt-4"
        (click)="
          goToDecision(
            advisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL
          )
        "
      >
        <img
          src="assets/images/decision/phone-call.svg"
          alt=""
          width="100"
          height="100"
        />
        <p>libranza telefónica</p>
      </div>
    </div>
  </div>
</div>

<app-form-popup-strong-authentication
  #formPopupStrongAuthentication
  (closePopupEvent)="closeStrongAuthenticationPopup()"
  (goToDecisionEvent)="goToDecision($event)"
></app-form-popup-strong-authentication>
