import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IStepNumber } from "./../../../core/interfaces/step-number.interface";

@Component({
  selector: "app-form-account-selection",
  templateUrl: "./form-account-selection.component.html",
})
export class FormAccountSelectionComponent implements OnInit {
  captionButton: string;
  descriptionText: string;
  formGroup: UntypedFormGroup;
  steps: IStepNumber;
  title: string;
  toolTipText: string;

  ngOnInit() {
    this.captionButton = "Continuar";
    this.formGroup = new UntypedFormGroup({});
    this.descriptionText = `Para garantizar un desembolso rápido, marca los documentos
                            actualizados en OnBase.`;
    this.steps = {
      numberSteps: 6,
      stepActive: 4,
    };
    this.title = "Cuenta para desembolso";
    this.toolTipText =
      "Debes verificar que los documentos solicitados aquí, estén debidamente cargados en OnBase para poder garantizarle al cliente un desembolso rápido y que la libranza no sea rechazada en Fábrica.";
  }
}
