import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-salary-loan-success-view",
  templateUrl: "./salary-loan-success-view.component.html",
  styleUrls: ["./salary-loan-success-view.component.scss"],
})
export class SalaryLoanSuccessViewComponent implements OnInit {
  captionButton: string;
  description: string;
  footerText: string;
  formGroup: UntypedFormGroup;
  icon: string;
  iconLoanAmount: string;
  summaryTitle: string;
  title: string;

  ngOnInit() {
    this.title = "¡Crédito pre aprobado!";
    this.description = `Recuerda que los documentos deben ser cargados a tiempo para evitar
      que la Libranza sea rechazada en fábrica. <br/><br/> El desembolso será notificado al cliente
      por medio de sus canales de contacto: `;
    this.icon = "../../../../assets/images/decision/extend-payroll-loan.svg";
    this.iconLoanAmount = "../../../../assets/images/icons/cash.svg";
    this.summaryTitle = "Resumen de transacción";
    this.captionButton = "Ir a zona de asesor";
    this.formGroup = new UntypedFormGroup({});
    this.footerText = `*Estos valores pueden estar sujetos a modificaciones conforme a
      políticas y análisis de riesgo de crédito. Incluye un seguro de vida obligatoria
      que pagas en cada cuota fija. Incluye abono a capital y a intereses.`;
  }
}
