import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ValidationSimRemoteService {
  constructor(private httpClient: HttpClient) {}

  asyncValidateCustomerCellPhone(customerInfo: object) {
    return this.httpClient
      .post(environment.serverUrlSalaryLoans + "auth/phoneNumber", customerInfo)
      .toPromise();
  }
}
