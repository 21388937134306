import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-authentication-card-debit-image",
  styleUrls: ["./authentication-card-debit-image.component.scss"],
  templateUrl: "./authentication-card-debit-image.component.html",
})
export class AuthenticationCardDebitImageComponent implements OnInit {
  @Input() entry: boolean;

  ngOnInit() {
    this.entry = true;
  }
}
