import { Router } from "@angular/router";

import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { SalesProcessTypeEnum } from "../../../core/enums/sales-process-type.enum";

import { AdvisorService } from "../advisor.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { GoogleAnalyticsEventsService } from "../google-analytics-events.service";
import { LoanService } from "../loan.service";
import { RedirectService } from "../redirect.service";

export class SalaryLoanActionSameFee implements ISalaryLoanActionFactory {
  constructor(
    private advisorService: AdvisorService,
    private eventAnalyticsService: EventAnalyticsService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private redirectService: RedirectService,
    private router: Router,
  ) {}

  salaryLoanAction() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.SALARY_LOAN_CLICK_SAME_FEE,
    );

    this.salaryLoanResolve();
  }

  salaryLoanResolve() {
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      this.loanService.getPayerName(),
    );

    this.loanService.setSalesProcessType(
      this.advisorService.isLoanByPhoneCall()
        ? SalesProcessTypeEnum.TELEFONIC_SALE_MC
        : SalesProcessTypeEnum.PRESENTIAL_SALE_MC,
    );
    this.redirectService.redirectToFrontOldToPep();
  }
}
