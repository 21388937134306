import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMimeTypeConstantsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable } from "rxjs";

import { HttpHeaderConstantsEnum } from "../../core/enums/http-header-constants.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICreateDecrim } from "../../core/interfaces/create-decrim.interface";
import { IRequestValidateDecrimQuery } from "../../core/interfaces/request-validate-decrim-query.interface";
import { IResponseValidateDecrimQuery } from "../../core/interfaces/response-validate-decrim-query.interface";

import { AwsSignV4RemoteService } from "./aws-sign-v4-remote.service";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DecrimRemoteService {
  constructor(
    private awsSignV4RemoteService: AwsSignV4RemoteService,
    private http: HttpClient,
  ) {}

  async createCase(advisor: IAdvisor, createDecrim: ICreateDecrim) {
    const headers = this.awsSignV4RemoteService.getHeadersAwsSignV4(
      HttpMimeTypeConstantsEnum.APPLICATION_JSON,
    );
    return this.http
      .post(
        environment.serverUrlBackendSecurity + "auth/decrim",
        createDecrim,
        { headers },
      )
      .toPromise();
  }

  validateCase(advisor: IAdvisor, caseDecrim: any): Observable<object> {
    const headers = this.awsSignV4RemoteService.getHeadersAwsSignV4(
      HttpMimeTypeConstantsEnum.APPLICATION_JSON,
    );
    return this.http.put(
      environment.serverUrlBackendSecurity + "auth/decrim",
      caseDecrim,
      { headers },
    );
  }

  postValidateDecrimQuery(
    request: IRequestValidateDecrimQuery,
    token: string,
  ): Observable<IResponseValidateDecrimQuery[]> {
    const headers = new HttpHeaders()
      .set(
        HttpHeaderConstantsEnum.CONTENT_TYPE,
        HttpMimeTypeConstantsEnum.APPLICATION_JSON,
      )
      .set(HttpHeaderConstantsEnum.AUTHORIZATION, token);
    return this.http.post<IResponseValidateDecrimQuery[]>(
      environment.serverUrlSalaryLoans + "api/telephonic/query",
      request,
      { headers },
    );
  }
}
