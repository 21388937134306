import { Router } from "@angular/router";
import { LoadingComponent } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AdvisorService } from "../advisor.service";
import { ClientEducationalService } from "../client-educational.service";
import { CustomerService } from "../customer.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { ExtractorService } from "../extractor.service";
import { GoogleAnalyticsEventsService } from "../google-analytics-events.service";
import { LoanService } from "../loan.service";
import { MessageService } from "../message.service";
import { OverflowErrorsService } from "../overflow-errors.service";
import { PayrollService } from "../payroll.service";
import { ToggleService } from "../toggle.service";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { CustomerTypeEnum } from "../../../core/enums/customer-type.enum";
import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { ModalityTypeEnum } from "../../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";
import { PayrollFileValidityStatusEnum } from "../../../core/enums/payroll-file-validity-status.enum";
import { SalesProcessTypeEnum } from "../../../core/enums/sales-process-type.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { IMessage } from "../../../core/interfaces/message.interface";
import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { FormPopupUploadPaymentReceiptComponent } from "../../../design/structures/form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";
import { EventManagerService } from "../event-manager.service";
import { EventTopic } from "../../../core/enums/event-topic.enum";

export class SalaryLoanActionAnyFee implements ISalaryLoanActionFactory {
  attemptsCount: number = ApplicationConstantsEnum.START_NUMBER_ATTEMPTS;
  dataNewClient: any;
  documentsWithProviderOld: any;
  providersId: any = [];
  setTimeOutRetryId: any;
  timeAdvacedUploadFileCount: number = ApplicationConstantsEnum.RESTART_COUNT;

  formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent;
  loadingComponent: LoadingComponent;
  messageEnumInPhoneCall: MessageEnum;

  constructor(
    private advisorService: AdvisorService,
    private clientEducationalService: ClientEducationalService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    loadingComponent: LoadingComponent,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private payrollService: PayrollService,
    private router: Router,
    private toggleService: ToggleService,
    private eventManagerService: EventManagerService,
  ) {
    this.formPopupUploadPaymentReceiptComponent = formPopupUploadPaymentReceiptComponent;
    this.loadingComponent = loadingComponent;
    this.eventManagerService
      .on(EventTopic.ACTION_BUTTON_POPUP_RESPONSE)
      .subscribe((data) => {
        this.handlerActionButton(data);
      });
  }

  get advisor() {
    return this.advisorService.getAdvisor();
  }

  get customer() {
    return this.customerService.getCustomer();
  }

  get loan() {
    return this.loanService.getPreloan();
  }

  get isExtendedDecision() {
    return this.customerService.isExtendedDecision();
  }

  get messageLoan() {
    return this.loan.messageLoan;
  }

  salaryLoanAction(): void {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.SALARY_LOAN_CLICK_ANY_FEE,
    );

    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      this.loanService.getPayerName(),
    );

    this.validationWhenIsNewLoanAndPhoneCall();
  }

  async salaryLoanResolve(event?: any) {
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = false;
    const captureDataIncorrect = this.formPopupUploadPaymentReceiptComponent
      .captureDataIncorrect;

    if (event === UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS) {
      if (
        this.timeAdvacedUploadFileCount >=
        ApplicationConstantsEnum.TIME_LIMIT_RETRY_UPLOAD_FILE
      ) {
        this.uploadFileProcessEnd(
          this.advisorService.isLoanByPhoneCall()
            ? MessageEnum.END_TIME_INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE
            : MessageEnum.PayrollChecks014,
        );
      } else {
        this.loadingComponent.show = true;
        if (
          !this.advisorService.isLoanByPhoneCall() &&
          !this.clientEducationalService.getIsUploadedDocuments()
        ) {
          await this.sendToTap(captureDataIncorrect);
        }
        const payrollCheckId = this.loanService.getPayrollCheckId();

        if (
          this.dataNewClient?.validationStatus ===
            ApplicationConstantsEnum.SUCCESS &&
          !captureDataIncorrect
        ) {
          this.simulationLoan(this.payrollService.getPayrollCheckProvider());
          return;
        }

        if (this.advisorService.isLoanByPhoneCall()) {
          this.verifyUploadDocumentsTelephoneLoan();
          return;
        }

        this.getPayrollCheckPreLoaded(payrollCheckId);
      }
    } else if (event === UploadDocumentsStatesEnum.DOCUMENTS_LOAD_EMPTY) {
      if (this.advisorService.isLoanByPhoneCall()) {
        this.sendLinkForUploadFile();
      }

      this.payrollService.setProvidersId([]);
      this.payrollService.updateProviderId = [];
    } else if (event === UploadDocumentsStatesEnum.DOCUMENT_CAPTURE_DATA) {
      this.timeAdvacedUploadFileCount = ApplicationConstantsEnum.RESTART_COUNT;
      this.loadingComponent.show = true;
      this.clientEducationalService.setFileDocuments(this.dataNewClient);
      await this.sendToTap(captureDataIncorrect);
      this.getPayrollCheckPreLoaded(this.loanService.getPayrollCheckId());
    }
  }

  private attemptsText() {
    return this.advisorService.isLoanByPresential()
      ? ApplicationConstantsEnum.RETRY +
          ApplicationConstantsEnum.CHARACTER_SPACE +
          this.attemptsCount.toString() +
          "."
      : ApplicationConstantsEnum.CHARACTER_EMPTY;
  }

  private buildProvidersId(payrollCheckId) {
    let providers: any = [];
    if (!this.payrollService.getProvidersId()) {
      providers.push(payrollCheckId);
      this.payrollService.setProvidersId(providers);
    } else {
      providers = [
        ...this.clientEducationalService.getDocumentPreloaded(),
        ...this.payrollService.getProvidersId(),
      ];
    }

    this.providersId = [...new Set(providers)];
  }

  private getMessages(messageEnum: MessageEnum, description?: string) {
    let attemptsText = description;
    let message = this.messageService.getMessageById(messageEnum, attemptsText);

    if (!message.messageId) {
      message = this.messageService.getMessageById(
        MessageEnum.ERROR_UPLOAD_FILE_TAP_SOLUTION,
        this.attemptsText(),
      );
    }

    return message;
  }

  private handlerActionButton(message: IMessage) {
    if (message.actionButton === MessageActionEnum.GO_DECISION) {
      this.router.navigate(["decision"]);
      return;
    }

    if (
      message.actionButton === MessageActionEnum.RETRY_UPLOAD_FILE &&
      this.advisorService.isLoanByPhoneCall()
    ) {
      this.salaryLoanAction();
      return;
    }

    if (message.actionButton === MessageActionEnum.RETRY_UPLOAD_FILE) {
      this.formPopupUploadPaymentReceiptComponent.show();
      return;
    }

    if (
      message.actionButton === MessageActionEnum.UPLOAD_FILE &&
      this.advisorService.isLoanByPhoneCall()
    ) {
      this.salaryLoanResolve(UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS);
      return;
    }

    if (message.actionButton === MessageActionEnum.CLOSE_MESSAGE) {
      window.location.reload();
    }
  }

  private getPayrollCheckPreLoaded(payrollCheckId: string) {
    this.buildProvidersId(payrollCheckId);

    this.payrollService
      .getPayrollCheckPreLoadedNewClient(this.providersId)
      .subscribe(
        (dataNewClient: any) => {
          this.dataNewClient = dataNewClient;
          this.payrollCheckPreLoadedSuccess(dataNewClient);
        },
        (errorNewClient: any) => this.salaryLoanResolveError(errorNewClient),
      );
  }

  private simulationLoan(payrollCheckId?: string) {
    const customerType = this.isExtendedDecision
      ? CustomerTypeEnum.CUSTOMER_WITH_LOANS
      : CustomerTypeEnum.NEW_CUSTOMER_KNOWN;

    this.payrollService
      .simulationLoan([payrollCheckId], customerType)
      .subscribe(
        (data) => {
          this.payrollCheckPreLoadedSuccess(this.dataNewClient ?? data);
        },
        (errorNewClient: any) => {
          this.formPopupUploadPaymentReceiptComponent.captureDataIncorrect =
            ApplicationConstantsEnum.CHARACTER_EMPTY;
          this.salaryLoanResolveError(errorNewClient);
        },
      );
  }

  private increaseAttempsError(messageData?: IMessage) {
    let overflowMessage =
      OverflowErrorsEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_CUSTOMER;
    if (
      messageData &&
      messageData.actionButton === MessageActionEnum.RETRY_SIMULATION
    ) {
      overflowMessage =
        OverflowErrorsEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_SIMULATION;
    }
    if (this.attemptsCount > ApplicationConstantsEnum.NUMBER_ATTEMPTS) {
      this.overflowErrorsService.setOverflowError(overflowMessage);
      this.router.navigate(["overflowerror"]);
    }

    this.attemptsCount++;
  }

  private initFormPopupUploadPaymentReceipt() {
    this.formPopupUploadPaymentReceiptComponent.initForm();
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = true;
    this.formPopupUploadPaymentReceiptComponent.resetProgress();
  }

  private isPhoneCallAndRetryUploadFileAction(
    messageActionEnum: MessageActionEnum,
  ): boolean {
    return (
      this.advisorService.isLoanByPhoneCall() &&
      messageActionEnum === MessageActionEnum.RETRY_UPLOAD_FILE
    );
  }

  private isPresentialAndRetryUploadFileAction(
    messageActionEnum: MessageActionEnum,
  ): boolean {
    return (
      this.advisorService.isLoanByPresential() &&
      (messageActionEnum === MessageActionEnum.RETRY_UPLOAD_FILE ||
        messageActionEnum === MessageActionEnum.RETRY_SIMULATION)
    );
  }

  private payrollCheckErrors(errorCodes: any[]) {
    const notError = errorCodes.length === ApplicationConstantsEnum.EMPTY_ARRAY;
    const isActivePayrollCheckOptimization = this.toggleService.getToggleEnabledById(
      ToggleEnum.PAYROLL_CHECK_OPTIMIZATION_II,
    );

    if (
      isActivePayrollCheckOptimization &&
      notError &&
      this.formPopupUploadPaymentReceiptComponent.captureDataIncorrect !== null
    ) {
      this.showCapturePopup(MessageEnum.FORM_POPUP_PAYROLL_CHECK_CAPTURE);
      return true;
    }

    if (notError) {
      this.uploadFileProcessEnd(MessageEnum.SUCCESS_UPLOAD_PAYMENT_RECEIPT);
      return true;
    }

    if (
      errorCodes[0].code === ApplicationConstantsEnum.UNKNOWN ||
      !this.messageService.getMessageById(errorCodes[0].code).messageId
    ) {
      this.uploadFileProcessEnd(errorCodes[0].code as any);
      return true;
    }
    return false;
  }

  private payrollCheckMessage(errorCodes: any) {
    let messageError: IMessage;
    messageError = this.messageService.getMessageById(
      MessageEnum.MULTIPLE_DOCUMENTS_VALIDATIONS,
    );

    messageError.title += `, ${this.attemptsText()}`;
    messageError.groupedMessages = [];

    for (let error of errorCodes) {
      const message = this.messageService.getMessageById(error.code);

      if (message.actionButton === MessageActionEnum.GO_DECISION) {
        this.uploadFileProcessEnd(message.messageId as MessageEnum);
        return;
      }

      messageError.groupedMessages = [
        ...messageError.groupedMessages,
        ...this.messageService.getGroupedMessages(message.messageId, error),
      ];
    }

    return messageError;
  }

  private payrollCheckPreLoadedSuccess(dataClient: any) {
    if (this.payrollCheckValidatusStatus(dataClient.validationStatus)) {
      return;
    }
    this.setSalesProccessLoanDecision();

    const errorCodes = this.clientEducationalService.getErrorCodes(dataClient);

    if (!this.payrollCheckErrors(errorCodes)) {
      const messageError = this.payrollCheckMessage(errorCodes);

      this.showMessagePopup(messageError);
    }
  }

  private payrollCheckValidatusStatus(validationStatus: string) {
    if (ApplicationConstantsEnum.PROGRESS === validationStatus) {
      this.setTimeoutSalaryLoanResolve();
      return true;
    }

    if (ApplicationConstantsEnum.TIME_OUT === validationStatus) {
      this.clientEducationalService.setIsUploadedDocuments(false);
      this.uploadFileProcessEnd(MessageEnum.PayrollChecks014);
      return true;
    }
    return false;
  }

  private refreshLoading() {
    if (this.setTimeOutRetryId) {
      clearTimeout(this.setTimeOutRetryId);
    }

    this.timeAdvacedUploadFileCount +=
      ApplicationConstantsEnum.TIME_RETRY_UPLOAD_FILE;

    this.loadingComponent.loadingCss = ApplicationConstantsEnum.TEXT_WHITE_BOLD;
    this.loadingComponent.loadingDescription =
      "Estamos cargando la documentación";
    this.loadingComponent.loadPercentage = Math.round(
      (this.timeAdvacedUploadFileCount /
        ApplicationConstantsEnum.TIME_LIMIT_RETRY_UPLOAD_FILE) *
        100,
    );
  }

  private salaryLoanResolveError(errorPayrollCheck: any) {
    const responseError = errorPayrollCheck.error.code
      ? errorPayrollCheck.error.code
      : MessageEnum.WITHOUT_CODE;

    if (
      this.advisorService.isLoanByPhoneCall() &&
      this.messageEnumInPhoneCall ===
        MessageEnum.INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE &&
      responseError === MessageEnum.PayrollChecks001
    ) {
      this.setTimeoutSalaryLoanResolve();
      return;
    }

    this.uploadFileProcessEnd(
      responseError,
      errorPayrollCheck.error.statusDesc,
    );
  }

  private sendLinkForUploadFile() {
    this.payrollService.sendLinkForUploadFile().subscribe(
      () => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_SUCCESS,
        );

        this.messageEnumInPhoneCall =
          MessageEnum.INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE;

        this.uploadFileProcessEnd(this.messageEnumInPhoneCall);
      },
      (errorSendLinkForUploadFile: any) => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_ERROR,
        );
        if (errorSendLinkForUploadFile.overFlowError) {
          this.router.navigate(["overflowerror"]);
        } else {
          this.messageEnumInPhoneCall =
            MessageEnum.INFO_CUSTOMER_HAS_UPLOAD_FILE;
          this.uploadFileProcessEnd(this.messageEnumInPhoneCall);
        }
      },
    );
  }

  private async sendToTap(captureDataIncorrect?: string) {
    const promises = [];
    const uploadedDocuments = captureDataIncorrect
      ? this.clientEducationalService.getFileData()
      : this.clientEducationalService.getUploadedDocuments();
    uploadedDocuments.forEach((data: any) => {
      const promise = new Promise((resolve, reject) => {
        this.payrollService
          .preSendFileTap(
            data.file,
            data.name,
            data.url,
            captureDataIncorrect,
            data.fileData,
          )
          .subscribe(
            () => resolve(true),
            (error) => reject(error),
          );
      });
      promises.push(promise);
    });
    await Promise.all(promises)
      .then(() => {
        this.clientEducationalService.setIsUploadedDocuments(true);
      })
      .catch((error) => {
        if (error.overFlowError) {
          this.router.navigate(["overflowerror"]);
        }
      });
  }

  private setTimeoutSalaryLoanResolve() {
    this.refreshLoading();

    this.setTimeOutRetryId = setTimeout(() => {
      this.salaryLoanResolve(UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS);
    }, ApplicationConstantsEnum.TIME_RETRY_UPLOAD_FILE);
  }

  private setSalesProccessLoanDecision() {
    if (this.customerService.isNewLoanDecision()) {
      this.loanService.setLoanFlow(LoanFlowEnum.ANYFEE);
      this.loanService.setModalityType(ModalityTypeEnum.ORDINARY);
    }

    this.loanService.setSalesProcessType(
      this.advisorService.isLoanByPhoneCall()
        ? SalesProcessTypeEnum.TELEFONIC_SALE_TC
        : SalesProcessTypeEnum.PRESENTIAL_SALE_TC,
    );
  }

  private shouldCallValidyStatus(documentsWithProviders: any[]) {
    let responseProvider: boolean;
    let minimunDocuments = ApplicationConstantsEnum.NUMBER_DOCUMENTS_PENSIONER;

    if (this.clientEducationalService.isEducationalSector()) {
      minimunDocuments = ApplicationConstantsEnum.NUMBER_DOCUMENTS_EDUCATIONAL;
    }

    if (
      !this.documentsWithProviderOld ||
      documentsWithProviders.length === ApplicationConstantsEnum.EMPTY_ARRAY
    ) {
      responseProvider = documentsWithProviders.length < minimunDocuments;
    } else {
      const documentsOK = documentsWithProviders.filter(
        (item: any) =>
          item.document.status === PayrollFileValidityStatusEnum.OK,
      );
      let sameDocuments = true;

      for (let index in documentsWithProviders) {
        if (
          documentsWithProviders[index].document.providerId !==
          this.documentsWithProviderOld[index].document.providerId
        ) {
          sameDocuments = false;
        }
      }
      responseProvider = documentsOK.length < minimunDocuments && sameDocuments;
    }
    return responseProvider;
  }

  private showCapturePopup(messageEnum: MessageEnum) {
    this.formPopupUploadPaymentReceiptComponent.setMessage = this.getMessages(
      messageEnum,
    );
    this.loadingComponent.show = false;
    this.formPopupUploadPaymentReceiptComponent.showPayrollcheckCapture = true;
    setTimeout(() => {
      this.formPopupUploadPaymentReceiptComponent.popupCapture.setCaptureData = {
        ...this.dataNewClient.responseFromOCRTap,
        customerName: this.customerService.getCustomer().name,
      };
    }, 500);
  }

  private showMessagePopup(message: IMessage) {
    if (!message) {
      return;
    }
    const messageToShow = message.groupedMessages?.length
      ? message.groupedMessages[0]
      : message;
    if (
      (this.isExtendedDecision ||
        this.clientEducationalService.isPensionerSector()) &&
      (messageToShow.messageId.includes("PayrollChecks") ||
        messageToShow.messageId.includes("GrowthAmount") ||
        messageToShow.messageId === MessageEnum.ERROR_UPLOAD_FILE_TAP_SOLUTION)
    ) {
      this.loadingComponent.show = false;
      this.eventManagerService.publish(EventTopic.OPEN_POPUP_UPLOAD, {
        message: messageToShow,
        attempt: this.attemptsCount,
      });
      this.increaseAttempsError(message);
      return;
    }
    if (this.isPhoneCallAndRetryUploadFileAction(message.actionButton)) {
      message.actionButton = MessageActionEnum.CLOSE_MESSAGE;
    } else if (
      this.isPresentialAndRetryUploadFileAction(message.actionButton)
    ) {
      this.increaseAttempsError(message);
    }

    if (message.actionButton === MessageActionEnum.CLOSE_MESSAGE) {
      this.loanService.setPayrollCheckId(undefined);
    }

    this.loadingComponent.show = false;
    this.timeAdvacedUploadFileCount = ApplicationConstantsEnum.RESTART_COUNT;
    this.formPopupUploadPaymentReceiptComponent.setMessage = message;
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = true;
  }

  private uploadFileProcessEnd(messageEnum: MessageEnum, description?: string) {
    const message = this.getMessages(messageEnum, description);

    this.showMessagePopup(message);
  }

  private validateAgeRestriction() {
    return this.loanService.validateAgeRestriction().pipe(
      catchError((errorResponse) => {
        let overflowCode = OverflowErrorsEnum.TECHNICAL_ERROR;
        if (
          errorResponse.error.code ===
          OverflowErrorsEnum.CUSTOMER_AGE_NOT_VIABLE
        ) {
          overflowCode = OverflowErrorsEnum.CUSTOMER_AGE_NOT_VIABLE;
        }

        this.overflowErrorsService.setOverflowError(overflowCode);
        this.router.navigate(["overflowerror"]);
        return of(errorResponse);
      }),
    );
  }

  private validityStatus() {
    this.payrollService
      .getFilesValidityStatus()
      .pipe(map((result: any) => result.map((item) => item.document)))
      .subscribe(
        (result: any) => {
          this.clientEducationalService.getResetArrayUploadsDocuments();

          const numberDocumentsEmpty = result.filter((data: any) => {
            return data.status === PayrollFileValidityStatusEnum.NOT_EXIST;
          });

          this.payrollService.setProvidersId(
            this.clientEducationalService.verifyDocumentsPreloads(result),
          );

          this.clientEducationalService.setListFileDocuments(result);
          this.refreshLoading();

          if (
            numberDocumentsEmpty.length >
              ApplicationConstantsEnum.EMPTY_ARRAY ||
            this.payrollService.getProvidersId().length ===
              ApplicationConstantsEnum.EMPTY_ARRAY
          ) {
            this.clientEducationalService.setIsUploadedDocuments(false);
            this.initFormPopupUploadPaymentReceipt();
          } else {
            this.salaryLoanResolve(
              UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS,
            );
          }
        },
        () => {
          this.clientEducationalService.setIsUploadedDocuments(false);
          this.initFormPopupUploadPaymentReceipt();
        },
      );
  }

  private validationWhenIsNewLoanAndPhoneCall() {
    this.validateAgeRestriction().subscribe(() => {
      if (this.advisorService.isLoanByPhoneCall()) {
        this.sendLinkForUploadFile();
        return;
      }
      this.validityStatus();
    });
  }

  private verifyUploadDocumentsTelephoneLoan() {
    this.payrollService.getFilesValidityStatus().subscribe(
      (response) => {
        const documentsWithProviders = response.filter(
          (item) =>
            item.document.providerId !==
            ApplicationConstantsEnum.CHARACTER_EMPTY,
        );

        if (this.shouldCallValidyStatus(documentsWithProviders)) {
          this.setTimeoutSalaryLoanResolve();
          return;
        }
        this.payrollService.setProvidersId(
          documentsWithProviders.map((item) => item.document.providerId) as any,
        );
        this.payrollService
          .getPayrollCheckPreLoadedNewClient(
            documentsWithProviders.map((item) => item.document.providerId),
          )
          .subscribe(
            (dataNewClient: any) => {
              this.dataNewClient = dataNewClient;
              this.payrollCheckPreLoadedSuccess(dataNewClient);
              this.documentsWithProviderOld = documentsWithProviders;
            },
            (errorNewClient: any) => {
              this.salaryLoanResolveError(errorNewClient);
            },
          );
      },
      () => {
        this.uploadFileProcessEnd(
          MessageEnum.END_TIME_INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE,
        );
      },
    );
  }
}
