import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import {
  catchError,
  delay,
  map,
  mergeMap,
  retryWhen,
  tap,
} from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { CashierOfficeSectorEnum } from "../../core/enums/cashier-office-sector.enum";
import { LoanFlowEnum } from "../../core/enums/loan-flow.enum";
import { MessageEnum } from "../../core/enums/message.enum";
import { MessageLoanEnum } from "../../core/enums/message-loan.enum";
import { ModalityTypeEnum } from "../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { SalesProcessTypeEnum } from "../../core/enums/sales-process-type.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { IPreloan } from "../../core/interfaces/pre-loan.interface";

import { AdvisorService } from "./advisor.service";
import { CustomerService } from "./customer.service";
import { MessageService } from "./message.service";
import { MessageLoanStrategyService } from "./strategy/message-loan-strategy.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { RepricingCampaignService } from "./repricing-campaign.service";
import { ToggleService } from "./toggle.service";

import { LoanRemoteService } from "../remote/loan-remote.service";

import { ILoanStatus } from "../../core/interfaces/loan-status.interface";
import { ICustomerLoan } from "../../core/interfaces/customer-loan.interface";

@Injectable({
  providedIn: "root",
})
export class LoanService {
  private isFirstCall = true;
  private loanFlowChanged: LoanFlowEnum;
  private loansNumber: number;
  private preloan: IPreloan = {};

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private loanRemoteService: LoanRemoteService,
    private messageService: MessageService,
    private messageLoanStrategyService: MessageLoanStrategyService,
    private overflowErrorsService: OverflowErrorsService,
    private repricingCampaignService: RepricingCampaignService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  getCustomerLoans(): Observable<IPreloan[]> {
    let retryConsumerService = ApplicationConstantsEnum.START_NUMBER_ATTEMPTS as number;
    const customer = this.customerService.getCustomer();

    return this.loanRemoteService
      .getCustomerLoans(customer.documentType, customer.documentId)
      .pipe(
        mergeMap((dataCustomerLoans: any[]) => {
          this.loansNumber = dataCustomerLoans.length;
          return this.getLoansStatus(
            customer.documentType,
            customer.documentId,
            dataCustomerLoans,
          );
        }),
        retryWhen((error) => {
          this.isFirstCall = false;
          return error.pipe(
            tap((dataError) => {
              if (
                retryConsumerService ===
                ApplicationConstantsEnum.RETRY_CONSUME_SERVICE
              ) {
                if (!dataError.error) {
                  throw {
                    messageId: MessageEnum.ERROR_SAME_FEE_IN_SALARY_LOANS,
                  };
                }

                const { simulationError } = dataError.error;

                if (!simulationError) {
                  throw {
                    messageId: MessageEnum.ERROR_SAME_FEE_IN_SALARY_LOANS,
                  };
                }

                const error = simulationError.additionalStatus[0];
                const params: [string, string] = [error.statusCode, ""];

                if (error.statusCode === MessageEnum.SIMULATION_ERROR_105) {
                  params[1] = error.statusDesc.match(/\d+/)[0];
                }

                const message = this.messageService.getMessageById(...params);

                if (message.messageId === MessageEnum.SIMULATION_ERROR_117) {
                  message.captionButton =
                    ApplicationConstantsEnum.TEXT_TRY_AGAIN;
                }

                throw message.messageId
                  ? message
                  : {
                      messageId: MessageEnum.ERROR_SAME_FEE_IN_SALARY_LOANS,
                    };
              }

              retryConsumerService++;
            }),
            delay(5000),
          );
        }),
      );
  }

  getLoansNumber() {
    return this.loansNumber;
  }

  getObligationId(): string {
    return this.preloan.obligationId;
  }

  getPayerName() {
    return this.preloan.payerName;
  }

  getPayerNameAndSector() {
    return `${this.preloan.payerName} - ${this.preloan.sectorName}`;
  }

  getPayerUniqueName() {
    return this.preloan.payerUniqueName;
  }

  getPayrollCheckId(): string {
    return this.preloan.payrollCheckId;
  }

  getPreloan() {
    return this.preloan;
  }

  getSectorNumber() {
    return this.preloan.sectorNumber;
  }

  getSubSectorNumber() {
    return this.preloan.subSectorNumber;
  }

  getPlatform() {
    return this.preloan.platform;
  }

  setPlatform(platform: string) {
    this.preloan.platform = platform;
  }

  isPensionerSector() {
    return +this.preloan.sectorNumber === CashierOfficeSectorEnum.PENSIONERS;
  }

  setLoanFlow(loanFlow: LoanFlowEnum) {
    this.preloan.loanFlow = loanFlow;
  }

  setLoanFlowChanged(loanFlowChanged: LoanFlowEnum) {
    this.loanFlowChanged = loanFlowChanged;
  }

  setModalityType(modalityType: ModalityTypeEnum) {
    this.preloan.modalityType = modalityType;
  }

  setPayrollCheckId(payrollCheckId: string) {
    this.preloan.payrollCheckId = payrollCheckId;
  }

  setPreloan(preloan: IPreloan) {
    this.preloan = preloan;

    if (!preloan.obligationId) {
      this.setObligationId(this.preloan.payerNit);
    }
  }

  setSalesProcessType(salesProcessType: SalesProcessTypeEnum) {
    this.preloan.salesProcessType = salesProcessType;
  }

  validateAgeRestriction() {
    const customer = this.customerService.getCustomer();
    const payload = {
      documentType: customer.documentType,
      documentNumber: customer.documentId,
      obligationId: this.preloan.obligationId,
      payerName: this.preloan.payerName,
      sectorName: this.preloan.sectorName,
      sectorNumber: this.preloan.sectorNumber,
    };
    return this.loanRemoteService.validateAgeRestriction(payload);
  }

  private getLoanFlowChanged() {
    return this.loanFlowChanged;
  }

  canShowUnificationControls(loanFlowType: LoanFlowEnum) {
    return (
      (loanFlowType === LoanFlowEnum.ANYFEE ||
        loanFlowType === LoanFlowEnum.EXTRACTOR) &&
      this.toggleService.getToggleEnabledById(
        ToggleEnum.UNIFICATION_FLOW_TOOGLE,
      )
    );
  }

  private getLoansStatus(
    documentType: string,
    documentId: number,
    dataCustomerLoans: ICustomerLoan[],
  ): Observable<IPreloan[]> {
    return this.loanRemoteService
      .getLoansStatus(documentType, documentId, this.isFirstCall)
      .pipe(
        map((dataLoansStatus: ILoanStatus[]) => {
          this.isMessageLoanStatus(
            dataLoansStatus,
            OverflowErrorsEnum.UNVIABLE_CUSTOMER_REPRICING,
          );

          const canRenew = this.canRenewLoan(dataLoansStatus);
          return dataLoansStatus.map((status) => {
            const find = dataCustomerLoans.find(
              (item) => item.obligationId === status.obligationId,
            );
            const messageLoan = this.messageLoanStrategyService.getMessageLoan(
              status,
              canRenew,
              this.getLoanFlowChanged(),
            );
            return {
              currentDeb: find.currentDeb,
              currentQuotaAmount: find.currentQuotaAmount,
              customerInBureauTrail: status.customerInBureauTrail,
              feeAmount: find.feeAmount,
              hasDoublePension: this.hasDoublePension(find.payerUniqueName),
              loanAmount: find.loanAmount,
              loanQuota: find.loanQuotas,
              messageLoan,
              missingLoanQuotas: find.missingLoanQuotas,
              obligationId: find.obligationId,
              openingObligationDate: find.openingObligationDate,
              payerId: find.payerId,
              payerLocationCode: find.payerLocationCode,
              payerName: find.payerName,
              payerNit: status.payerNit,
              payerUniqueName: find.payerUniqueName,
              payerWeb: find.payerWeb,
              platformAuthorizationUrl: status.platformAuthorizationUrl,
              sectorNumber: find.sector,
              subSectorNumber: find.subSector,
              loanFlow: <LoanFlowEnum>messageLoan.loanFlowType,
              compareRateDetail: status.compareRateDetail,
              loanRenewValidation: status.loanRenewValidation,
              showUnificationControls: this.canShowUnificationControls(
                <LoanFlowEnum>messageLoan.loanFlowType,
              ),
              showDoublePensionControl: this.isEnabledDoublePensionControl(
                messageLoan.loanFlowType as LoanFlowEnum,
                this.hasDoublePension(find.payerUniqueName),
              ),
            };
          });
        }),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  private setObligationId(payerNit: string) {
    const customer = this.customerService.getCustomer();

    this.preloan.obligationId =
      payerNit + customer.documentType + customer.documentId;
  }

  private canRenewLoan(loanStatus: ILoanStatus[]) {
    return (
      loanStatus.filter(
        (loan) =>
          loan.loanRenewValidation ===
          MessageLoanEnum.NOT_APPROVE_ACCOUNT_ISSUE,
      ).length === 0
    );
  }

  hasDoublePension(payerUniqueName: string) {
    return payerUniqueName === ApplicationConstantsEnum.COLPENSIONES_PAYER_NAME;
  }

  isEnabledDoublePensionControl(
    loanFlow: LoanFlowEnum,
    hasDoublePension: boolean,
  ) {
    return (
      loanFlow === LoanFlowEnum.ANYFEE &&
      hasDoublePension &&
      this.isActiveOptimizationPayrollCheck() &&
      this.advisorService.isLoanByPhoneCall()
    );
  }

  private isActiveOptimizationPayrollCheck() {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.PAYROLL_CHECK_OPTIMIZATION,
    );
  }

  private isMessageLoanStatus(
    loanStatus: ILoanStatus[],
    overflowMessage: OverflowErrorsEnum,
  ) {
    if (
      loanStatus.length === ApplicationConstantsEnum.EMPTY_ARRAY &&
      (this.repricingCampaignService.isEnable() ||
        this.repricingCampaignService.isEnableV2())
    ) {
      this.overflowErrorsService.setOverflowError(overflowMessage);
      this.navigateToOverflowError();
    }
  }

  private navigateToOverflowError() {
    this.router.navigate(["overflowerror"]);
  }
}
