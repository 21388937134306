import { Injectable } from "@angular/core";
import { IRadioButtonData } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import * as doublePension from "../data/radio-button-double-pension-data.json";

@Injectable({
  providedIn: "root",
})
export class DoublePensionRemoteService {
  getInformationDoublePension(): IRadioButtonData[] {
    return (doublePension as any).default;
  }
}
