import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-otp-authentication",
  template: "<app-two-column></app-two-column>",
})
export class OtpAuthenticationComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      [
        "twocolumn",
        { outlets: { "part-left": ["formotp"], "part-right": ["otpimage"] } },
      ],
      { skipLocationChange: true },
    );
  }
}
