import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { FileExtensionEnum } from "../../../core/enums/file-extension.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";
import { IMessage } from "../../../core/interfaces/message.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { DecrimService } from "../../../services/local/decrim.service";
import { FileManagerService } from "../../../services/local/file-manager.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { MessageService } from "../../../services/local/message.service";
import { OverflowErrorsService } from "../../../services/local/overflow-errors.service";
import { ToggleService } from "../../../services/local/toggle.service";

@Component({
  selector: "app-form-popup-decrim",
  templateUrl: "./form-popup-decrim.component.html",
})
export class FormPopupDecrimComponent implements OnInit {
  @Input()
  isWithCustomerInfo = false;

  @Input("messageEnum")
  set setMessageEnum(messageEnum: MessageEnum) {
    const message = this.messageService.getMessageById(messageEnum);
    if (message.messageId) {
      this.message = message;
    }
  }

  @Input("popUpDecrim")
  set setPopUpDecrim(popUpDecrim: boolean) {
    this.popUpDecrim = popUpDecrim;
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<boolean>();
  @Output() closePopupFileDecrimWithCustomerInfoEvent = new EventEmitter<
    MessageEnum
  >();

  advisor: IAdvisor;
  customer: ICustomer;
  files: File[];
  formGroup: UntypedFormGroup;
  loadingUploadFile: boolean;
  message: IMessage;
  numberAttemptsError: number;
  popUpDecrim: boolean;
  validatedFiles: boolean[];

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private decrimService: DecrimService,
    private fileManagerService: FileManagerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private router: Router,
    private toggleService: ToggleService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.validateUploadFacialIdentification();
  }

  closePopupAndRedirect(redirect: boolean) {
    this.closePopupAndRedirectEvent.emit(redirect);
    this.initForm();
  }

  fileUpload(index: number, file: File) {
    this.files[index] = file;

    this.validatedFiles[index] = this.fileManagerService.validateFile(
      file,
      ApplicationConstantsEnum.MAXIMUM_DECRIM_FILE_SIZE,
      [FileExtensionEnum.PDF, FileExtensionEnum.JPG, FileExtensionEnum.JPGE],
    );
    this.validateRequiredFiles();
  }

  initForm() {
    this.numberAttemptsError = 0;
    this.loadingUploadFile = false;
    this.files = [undefined, undefined, undefined];
    this.validatedFiles = [false, false, false, false];
    this.formGroup = new UntypedFormGroup({});
    this.validateRequiredFiles();
  }

  restoreFileUpload(index: number) {
    index ===
    ApplicationConstantsEnum.NUMBER_DECRIM_FILES_WITH_FACIAL_IDENTIFICATION - 1
      ? this.files.splice(index, 1)
      : (this.files[index] = undefined);

    this.validateRequiredFiles();
  }

  upload() {
    this.customer = this.customerService.getCustomer();
    this.advisor = this.advisorService.getAdvisor();

    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_CLICK_AUTHENTICATE,
    );

    this.loadingUploadFile = true;
    this.decrimService.setFiles(this.files);

    this.decrimService.uploadFiles(this.files).then((loadCounter) => {
      if (loadCounter === this.files.length) {
        this.createCase();
      } else {
        this.displayError(
          this.messageService.getMessageById(MessageEnum.NAVIGATOR_OFFLINE),
        );
      }
    });
  }

  private checkValidatedFiles() {
    for (let index = 0; index < this.files.length; index++) {
      if (!this.validatedFiles[index]) {
        return false;
      }
    }
    return true;
  }

  private createCase() {
    this.decrimService
      .createCase(this.isWithCustomerInfo)
      .then(() => {
        if (this.isWithCustomerInfo) {
          this.closePopupFileDecrimWithCustomerInfoEvent.emit(
            MessageEnum.DECRIM_CASE_AUTHORIZED,
          );
          this.initForm();
        } else {
          this.closePopupAndRedirect(true);
        }
      })
      .catch((message?: IMessage) => {
        if (message) {
          if (message.messageId === MessageEnum.SecurityManager0021_Decrim) {
            this.closePopupFileDecrimWithCustomerInfoEvent.emit(
              message.messageId,
            );

            this.initForm();
          } else {
            this.displayError(message);
          }
        } else {
          this.router.navigate(["overflowerror"]);
        }
      });
  }

  private displayError(message: IMessage) {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.AUTHENTICATION_VALIDATE_OTP_UPLOAD_FILES_DECRIM_ERROR,
    );

    this.loadingUploadFile = false;
    this.message = message;

    this.goToOverflowError();
  }

  private goToOverflowError() {
    this.numberAttemptsError++;
    if (this.numberAttemptsError === ApplicationConstantsEnum.NUMBER_ATTEMPTS) {
      this.overflowErrorsService.setOverflowError(
        OverflowErrorsEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_DECRIM,
      );

      this.router.navigate(["overflowerror"]);
    }
  }

  private validateRequiredFiles() {
    this.validateUploadFiles() && this.checkValidatedFiles()
      ? this.formGroup.enable()
      : this.formGroup.disable();
  }

  private validateUploadFacialIdentification(): boolean {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.UPLOAD_FACIAL_IDENTIFICATION_DECRIM_TOGGLE,
    );
  }

  private validateUploadFiles() {
    return (
      this.files[0] !== undefined &&
      this.files[1] !== undefined &&
      this.files[2] !== undefined &&
      this.files[3] !== undefined
    );
  }
}
