import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrganizationManagerRemoteService {
  constructor(private httpClient: HttpClient) {}

  searchAdministrativeOffice(cognitoToken: string = ""): Observable<object> {
    return this.callAdmnistrativeOfficeLambda(cognitoToken);
  }

  searchOffices(cognitoToken: string = ""): Observable<object> {
    return this.callOfficesLambda(cognitoToken);
  }

  callAdmnistrativeOfficeLambda(cognitoToken: string): Observable<object> {
    const headers = new HttpHeaders({ Authorization: cognitoToken });
    return this.httpClient.get(
      `${environment.serverUrlOrganizationManagerNew}administrative-offices`,
      { headers },
    );
  }

  callOfficesLambda(cognitoToken: string): Observable<object> {
    const headers = new HttpHeaders({ Authorization: cognitoToken });
    return this.httpClient.get(
      `${environment.serverUrlOrganizationManagerNew}offices`,
      { headers },
    );
  }
}
