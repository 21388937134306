import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";

import { IMessageLoan } from "../../../core/interfaces/message-loan.interface";
import { IMessageLoanStrategy } from "./message-loan-strategy.interface";

import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";

export class MessageLoanOperationTenPerAnyFee extends IMessageLoanStrategy {
  private decisionType: AdvisorDecisionTypeEnum;

  constructor(
    dataLoanStatus: ILoanStatus,
    decisionType: AdvisorDecisionTypeEnum,
  ) {
    super(dataLoanStatus);
    this.decisionType = decisionType;
  }

  getMessageId(): string {
    let messageLoanId = this.dataLoanStatus.customerInBureauTrail
      ? "LOAN_TEN_PERCENTAGE_GROWTH_OR_ANYFEE_WITH_IN_BUREAU_TRAIL"
      : "LOAN_TEN_PERCENTAGE_GROWTH_OR_ANYFEE_WITHOUT_IN_BUREAU_TRAIL";

    if (
      this.decisionType ===
      AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
    ) {
      messageLoanId = this.dataLoanStatus.customerInBureauTrail
        ? "LOAN_TEN_PERCENTAGE_GROWTH_OR_ANYFEE_NEW_LOAN_BY_PHONE_CALL_WITH_IN_BUREAU_TRAIL"
        : "LOAN_TEN_PERCENTAGE_GROWTH_OR_ANYFEE_NEW_LOAN_BY_PHONE_CALL_WITHOUT_IN_BUREAU_TRAIL";
    }
    return messageLoanId;
  }

  getMessageLoan(): IMessageLoan {
    return super.getMessageLoan();
  }
}
