import { Injectable } from "@angular/core";
import {
  FormFileInputDragComponent,
  NotificationResponseTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { CustomerService } from "./customer.service";
import { LoanService } from "./loan.service";
import { NotificationReponseService } from "./notification-reponse.service";
import { ToggleService } from "./toggle.service";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { CashierOfficeSectorEnum } from "../../core/enums/cashier-office-sector.enum";
import { DocumentTypeTapEnum } from "../../core/enums/document-type-tap.enum";
import { MessageEnum } from "../../core/enums/message.enum";
import { PayrollFileValidityStatusEnum } from "../../core/enums/payroll-file-validity-status.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

@Injectable({
  providedIn: "root",
})
export class ClientEducationalService {
  messages = [];

  private documentPreloaded: any = [];
  private fileData: any = [];
  private isUploadedDocuments: boolean = true;
  private listFileDocuments = [];
  private providersId: any = [];
  private uplodedDocuments: any = [];

  constructor(
    private customerService: CustomerService,
    private loanService: LoanService,
    private notificationResponseService: NotificationReponseService,
    private toggleService: ToggleService,
  ) {}

  getDocumentPreloaded() {
    return this.documentPreloaded;
  }

  getErrorCodes(dataPayrollCheck: any): any[] {
    if (!dataPayrollCheck.statusOCRByDocument) {
      dataPayrollCheck.statusOCRByDocument = [];
    }

    this.messages = [];

    let documentsNames = new Map([
      [
        DocumentTypeTapEnum.LABOR_CERTIFICATION,
        ApplicationConstantsEnum.LABOR_CERTIFICATION_TEXT,
      ],
      [
        DocumentTypeTapEnum.PAYROLLCHECK,
        ApplicationConstantsEnum.PAYROLL_CHECK_TEXT,
      ],
    ]);

    this.verifyDocumentsPreloads(dataPayrollCheck.statusOCRByDocument);
    this.setListFileDocuments(dataPayrollCheck.statusOCRByDocument);

    dataPayrollCheck.statusOCRByDocument.forEach((data: any) => {
      const result = data.errors.find(
        (error: any) =>
          error.code === MessageEnum.laborCertification104 ||
          error.code === MessageEnum.laborCertification106,
      );
      if (result) {
        this.addMessage({
          ...result,
          documentName: documentsNames.get(data.onbaseCode),
        });
      }
    });

    if (this.messages.length === 0) {
      dataPayrollCheck.statusOCRByDocument.forEach((data) => {
        data.errors.forEach((error: any) => {
          if (
            error.code !== ApplicationConstantsEnum.PAYROLL_CHECKS_005 &&
            error.code !== ApplicationConstantsEnum.PAYROLL_CHECKS_012
          ) {
            this.addMessage({
              ...error,
              documentName: documentsNames.get(data.onbaseCode),
            });
          }
        });
      });
    }

    this.messages =
      this.messages.length === 0 &&
      dataPayrollCheck.validationStatus !== ApplicationConstantsEnum.SUCCESS
        ? dataPayrollCheck?.simulationErrorCode
          ? [{ code: dataPayrollCheck?.simulationErrorCode, causes: [] }]
          : [{ code: ApplicationConstantsEnum.UNKNOWN, causes: [] }]
        : this.messages;

    return this.messages;
  }

  getListFileDocuments() {
    return this.listFileDocuments;
  }

  getIsUploadedDocuments() {
    return this.isUploadedDocuments;
  }

  getResetArrayUploadsDocuments() {
    this.uplodedDocuments = [];
    this.providersId = [];
    this.documentPreloaded = [];
  }

  getResetUplodedDocuments() {
    this.uplodedDocuments = [];
  }

  getToggleEducational() {
    return (
      this.toggleService.getToggleEnabledById(
        ToggleEnum.NEW_CLIENT_EDUCATIONAL_SECTOR_TOGGLE,
      ) && this.customerService.isNewLoanDecision()
    );
  }

  getFileData() {
    return this.fileData;
  }

  getUploadedDocuments() {
    return this.uplodedDocuments;
  }

  isEducationalSector() {
    return (
      this.getToggleEducational() &&
      this.loanService.getSectorNumber() === CashierOfficeSectorEnum.EDUCATIONAL
    );
  }

  isNewLoanDecision() {
    return this.customerService.isNewLoanDecision();
  }

  isPensionerSector() {
    return this.getToggleEducational() && this.loanService.isPensionerSector();
  }

  notificationDocumentsUpload(
    file: File,
    fileInputDrag: FormFileInputDragComponent,
    payrollFileType: DocumentTypeTapEnum,
  ) {
    const customer = this.customerService.getCustomer();
    this.uplodedDocuments.push({
      file,
      name: payrollFileType,
      url: customer.urlFile,
    });

    return this.notificationResponseService.getNotificationResponse(
      NotificationResponseTypeEnum.UPLOAD_FILE_SUCCESS,
      file.name,
      fileInputDrag.title,
    );
  }

  setFileDocuments(tapData: any) {
    const statusOCRByDocument = tapData.statusOCRByDocument
      .reverse()
      .find(
        (resp: any) =>
          resp.onbaseCode === DocumentTypeTapEnum.PAYROLLCHECK &&
          resp.status === PayrollFileValidityStatusEnum.OK,
      );

    this.fileData[0] = {
      file: undefined,
      name: DocumentTypeTapEnum.PAYROLLCHECK,
      fileData: statusOCRByDocument.fileData,
    };
  }

  setListFileDocuments(documents: any[]) {
    this.listFileDocuments = [];
    for (const document of documents) {
      if (document.status !== PayrollFileValidityStatusEnum.OK) {
        this.isUploadedDocuments = false;
      }
      this.listFileDocuments.push({
        preloaded: document.status === PayrollFileValidityStatusEnum.OK,
        document: document,
      });
    }
  }

  setIsUploadedDocuments(isUploadedDocuments: boolean) {
    this.isUploadedDocuments = isUploadedDocuments;
  }

  verifyDocumentsPreloads(result: any) {
    result.forEach((data: any) => {
      if (data.providerId !== ApplicationConstantsEnum.CHARACTER_EMPTY) {
        this.providersId.push(data.providerId);
      }

      if (data.status === PayrollFileValidityStatusEnum.OK) {
        this.documentPreloaded.push(data.providerId);
      }
    });

    return this.providersId;
  }

  private addMessage(error: any) {
    const messageFind = this.messages.some(
      (message) => message.code === error.code,
    );
    if (!messageFind) {
      this.messages.push(error);
    }
  }
}
