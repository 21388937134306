import { Component, OnInit } from "@angular/core";

import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

import { OnbaseNotificationService } from "../../../services/local/onbase-notification.service";

@Component({
  selector: "app-document-upload-notification",
  templateUrl: "./document-upload-notification.component.html",
  styleUrls: ["./document-upload-notification.component.scss"],
})
export class DocumentUploadNotificationComponent implements OnInit {
  elementDetailsNotification: any;
  files: any[];
  messageError: string = "";
  notification: boolean;
  timer: any;
  viewNotification = false;

  constructor(private onbaseNotificationService: OnbaseNotificationService) {}

  ngOnInit() {
    this.onbaseNotificationService.currentNotification.subscribe((files) => {
      if (files.length > 0) {
        this.files = files;
        this.viewNotification = true;
      }
    });
  }

  infoBarComplete(data: any) {
    data.status = true;
    this.viewNotification = true;
    data.documentUpload = UploadDocumentsStatesEnum.DOCUMENTS_LOAD;
    this.verifyNotification();

    setTimeout(() => {
      data.documentComplete = true;
    }, 5000);
  }

  verifyNotification() {
    const documentFiles = this.onbaseNotificationService.getDocumentFiles();
    this.onbaseNotificationService.documentHasFile();

    const numberFiles = this.files.filter((data: any) => {
      return data.documentUpload === UploadDocumentsStatesEnum.DOCUMENTS_LOAD;
    });

    if (numberFiles.length === documentFiles.length) {
      setTimeout(() => {
        this.viewNotification = false;
      }, 3000);
    }
  }

  closeNotification() {
    this.viewNotification = false;
  }

  closeMessageNotification(data: any) {
    this.viewNotification = true;
    data.documentComplete = true;
  }

  notificationDetails(notification: any) {
    this.elementDetailsNotification = this.onbaseNotificationService.notificationDetails(
      notification,
    );
  }

  notificationErrorVisible(data: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      data.documentComplete = true;
    }, 3000);
  }

  uploadCancel(data: any) {
    this.viewNotification = true;
    data.documentUpload = UploadDocumentsStatesEnum.DOCUMENTS_LOAD_CANCEL;
    data.documentComplete = true;
    data.file = null;

    this.onbaseNotificationService.deleteExistingItem(data);
    this.verifyNotification();
  }
}
