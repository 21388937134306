<div *ngIf="showPopUpViabilityQuery">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopupAndRedirect(false)"
      />
    </div>

    <div class="w-100 h-90-px d-flex align-items-center justify-content-center">
      <div>
        <img [src]="message.icon" alt="Icon Viability Query" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 mb-3 text-center">
        <adl-text-description
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 mb-3 text-justify">
        <adl-form-select
          [fieldControl]="formGroup.controls['textDocumentType']"
          [valueControl]="formGroup.controls['valueDocumentType']"
          [lengthInput]="'25'"
          [listSelect]="listDocumentType"
          [parentForm]="formGroup"
          [text]="'Tipo de documento'"
          id="app-form-select_document-type"
        ></adl-form-select>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 mb-3 text-justify">
        <adl-form-field
          [lengthInput]="'15'"
          [fieldControl]="formGroup.controls['textDocumentNumber']"
          [obfuscate]="true"
          [parentForm]="formGroup"
          [text]="'Número de documento'"
          id="app-form-field_document_number"
          appOnlyNumber
        ></adl-form-field>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 mb-3 text-justify">
        <adl-form-select
          [fieldControl]="formGroup.controls['textSector']"
          [valueControl]="formGroup.controls['valueSector']"
          [lengthInput]="'25'"
          [listSelect]="sectors"
          [parentForm]="formGroup"
          [text]="'Sector'"
          id="app-form-select_sector-list"
        ></adl-form-select>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 mb-3 text-justify">
        <adl-form-field
          [lengthInput]="'10'"
          [fieldControl]="formGroup.controls['textPhoneNumber']"
          [obfuscate]="true"
          [parentForm]="formGroup"
          [text]="'Número de teléfono móvil'"
          id="app-form-field_phone_number"
          appOnlyNumber
        ></adl-form-field>
      </div>
    </div>

    <div class="h-20-px"></div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-132-px h-50-px">
        <adl-button
          id="app-button_button-viability-query"
          cssClass="w-100 buttonTextMedium"
          [loading]="isInProgressEvent"
          [mandatory]="true"
          [text]="message.captionButton"
          [parentForm]="formGroup"
          (click)="redirectToFormAuthorizationData()"
        ></adl-button>
      </div>
    </div>

    <div class="h-20-px"></div>
  </form>
</div>
