import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/internal/operators";

import { AwsSignV4Service } from "../../services/local/aws-sign-v4.service";

@Injectable()
export class AppIamInterceptor implements HttpInterceptor {
  constructor(private awsSignV4Service: AwsSignV4Service) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.awsSignV4Service.build(req)).pipe(
      tap(
        (event: any) => {
          if (event instanceof HttpResponse && event.status === 206) {
            // eslint-disable-next-line no-console
            console.log(event.status);
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            // eslint-disable-next-line no-console
            console.log({ error });
          }
        },
      ),
    );
  }
}
