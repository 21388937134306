export const environment = {
  production: false,
  debugg: true,
  useJson: true,

  uicomponents: {
    previabilityNewFeature: true,
  },

  serverUrlOld: "https://d2jswhkp9evx3k.cloudfront.net/",
  serverUrlPreviability:
    "https://d1vpp1h2fbk89p.cloudfront.net/bpop-salaryloans-frontend-previability-microfront/index.html",
  serverUrlOrganizationManager:
    "https://xrq3hw3qs3.execute-api.us-east-2.amazonaws.com/organization-manager/",
  serverUrlOrganizationManagerNew:
    "https://bvmm7p1yva.execute-api.us-east-2.amazonaws.com/v1/organization/",
  serverUrlSalaryLoans:
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/",
  serverUrlTraining:
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/dev/training-manager/",
  serverUrlSecurityManager:
    "https://801b5y3fcj.execute-api.us-east-2.amazonaws.com/security-manager/",
  serverUrlBackendSecurity:
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/",
  serverUrlOnbaseLoadDocument:
    "https://lvnuqrtzbb.execute-api.us-east-2.amazonaws.com/v1/",
  serverUrlPublicResources:
    "https://1pulahfoo7.execute-api.us-east-2.amazonaws.com/v1/",

  googleRecaptchaKey: "6LfWScYUAAAAAMyl3_sWSZRGtfBeKZOuJnCDJQWq",

  codeGoogleAnalytics: "uXrIEOicg5U2IGSEdYfYpA&gtm_preview=env-7",
  codeUAGoogleAnalytics: "UA-120789499-1",
  rsaOaep512PublicKey:
    "zCi8mi3DgzVwwbVEhb3oTvWr73RG3CKfkVirxjpA5DLjBRslXICgBtVlMW3zkLqcz75f_cz0B9GqL2y6weRtGkPyUTaOuea1ZkeuGmAXDfXEdYpmRFxQ6D5B-49S8x4iUe-Jd4nL4qWuQGtWXNPKSu8_mOPKj64Nw5eA5IKLOdPZd3D0hrfWxNXWWEzcvbArVrhA00xgmZb23V2z_HufHVsnlyFlWKNcXmepB_76wlW5RKoh2Ns0ZbOrASvbTYB4NqLHZy3YQ0mkimNH1JOUmtZ-XeGIPOUpKPM_bah0BTaFOzFq8UaNLhs2_kgOfzgZI5bFx__JmwOYbVBhR_MAJw",

  awsCognitoData: {
    host: "lnypxqjh16.execute-api.us-east-2.amazonaws.com",
    hostBackendSecurity: "7kzbg9b5j1.execute-api.us-east-2.amazonaws.com",
    hostSecurity: "801b5y3fcj.execute-api.us-east-2.amazonaws.com",
    hostOrganizationManager: "xrq3hw3qs3.execute-api.us-east-2.amazonaws.com",
    hostRiskManager: "p6gh609uq1.execute-api.us-east-2.amazonaws.com",
    region: "us-east-2",
    service: "execute-api",
  },

  // Por favor no cambie este valor, esto es parte de un toggle compuesto para habilitar la autenticación federada
  federatedAuthEnabled: true,

  federatedAuthParamsServerUrl:
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/",
  federatedAuthUrl:
    "https://adl-habilitadora-stg-employee-bpop-federation.auth.us-east-2.amazoncognito.com",
  federatedAuthRedirectUrl:
    "https://gb-salaryloans-dev-front.avaldigitallabs.com/loginadvisor",
  federatedAuthLogoutUrl:
    "https://gb-salaryloans-dev-front.avaldigitallabs.com/loginadvisor",

  security: {
    cipherPublicKeyUrl:
      "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/dev/cipher/public-key",
    symmetricKeyUrl:
      "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/dev/cipher/symmetric-key",
  },

  cognitoLegacy: {
    url: "https://bpop-adl-dev.auth.us-east-2.amazoncognito.com/oauth2/token",
    clientId: "5mbe5hp40p7mtcc7l21jvflf9c",
    clientSecret: "162ulv2hjadh00jsl369tfg1qhj83m5l3gl9tatm56mcmodfjgse",
  },

  cognitoNew: {
    url:
      "https://gb-salaryloans-dev.auth.us-east-2.amazoncognito.com/oauth2/token",
    clientId: "6klafi6snfmrb40u89lootr5jv",
    clientSecret: "17hasqbb00d83t86ap2pvf5aj3oovpb27br2n1q8jqt3vgh36q0m",
  },

  encryptUrls: [
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/advisor/search",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/auth/otp",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/auth/cognito/caller/token",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/auth/phoneNumber",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/auth/callCenter",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/api/phone/auth",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/customer/search",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/customer/campaign/repricing/search",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/users/documents",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/users/documents/fail",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/users/documents/pending",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/users/documents/vendors",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/payer/platform/bindings",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/api/telephonic/query",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/advisor/loan",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/onbase/advisor/documents",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/flexcube-ownership/accounts-list",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/auth/certihuella",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/auth/decrim",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/session/audit",
    "https://1pulahfoo7.execute-api.us-east-2.amazonaws.com/v1/analytic-events/send",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/features/all",
    "https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/auth/active-directory",
    "https://1pulahfoo7.execute-api.us-east-2.amazonaws.com/v1/freshchat-history",
  ],
};
