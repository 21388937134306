import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { ISendDocumentOnbase } from "../../core/interfaces/send-document-onbase.interface";

import * as onbaseLoadDocumentStatesData from "../data/onbase-load-document-states-data.json";

@Injectable({
  providedIn: "root",
})
export class OnbaseLoadRemoteService {
  constructor(private http: HttpClient) {}

  async uploadDocumentCustomer(createUploadDocuments: ISendDocumentOnbase) {
    return this.http
      .post(
        environment.serverUrlSalaryLoans + "onbase/users/documents",
        createUploadDocuments,
      )
      .toPromise();
  }

  getConsultDocumentsOnbaseLoad(sellerIdNumber: number): Observable<any> {
    return this.http.post(
      `${environment.serverUrlSalaryLoans}onbase/advisor/documents`,
      { sellerIdNumber },
    );
  }

  getOnbaseLoadDocumentStates() {
    return (onbaseLoadDocumentStatesData as any).default;
  }

  getSearchDocumentsUploadClient(
    documentType: string,
    documentNumber: string,
  ): Observable<any> {
    return this.http.post(
      `${environment.serverUrlSalaryLoans}onbase/users/documents/pending`,
      { documentType, documentNumber },
    );
  }

  getSearchDocumentsFailedUploadClient(factoryFileId: number): Observable<any> {
    return this.http.post(
      `${environment.serverUrlSalaryLoans}onbase/users/documents/fail`,
      { factoryFileId },
    );
  }

  getConsultDocumentsStateOnbaseLoad(sellerIdNumber: number): Observable<any> {
    return this.http.post(
      `${environment.serverUrlSalaryLoans}onbase/advisor/loan`,
      { sellerIdNumber },
    );
  }

  getCustomerDocumentFinder(documentNumber: string): Observable<any> {
    return this.http.post(
      `${environment.serverUrlSalaryLoans}onbase/users/documents/vendors`,
      { documentNumber },
    );
  }
}
