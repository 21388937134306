import { Injectable } from "@angular/core";

import {
  INotificationResponse,
  NotificationResponseTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import * as notificationResponseData from "../data/notification-response-data.json";

@Injectable({
  providedIn: "root",
})
export class NotificationReponseRemoteService {
  getNotificationResponse(
    notificationResponseTypeEnum: NotificationResponseTypeEnum,
    description?: string,
    title?: string,
  ): INotificationResponse {
    return this.mapGetNotificationResponse(
      notificationResponseTypeEnum,
      description,
      title,
    );
  }

  private mapGetNotificationResponse(
    notificationResponseTypeEnum: NotificationResponseTypeEnum,
    description?: string,
    title?: string,
  ): INotificationResponse {
    const notificationResponse: INotificationResponse = {
      ...(notificationResponseData as any).default.find(
        (notificationResponse: any) =>
          notificationResponse.id === notificationResponseTypeEnum,
      ),
    };

    if (notificationResponse.description) {
      notificationResponse.description = notificationResponse.description.replace(
        /{description}/,
        description,
      );
    }

    if (notificationResponse.title) {
      notificationResponse.title = notificationResponse.title.replace(
        /{title}/,
        title,
      );
    }

    return notificationResponse;
  }
}
