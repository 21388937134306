<div *ngIf="showMessageBox">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img [src]="message.icon" alt="Icon Message Box" />
      </div>
    </div>
    <div
      class="w-100 m-b-10-px d-flex align-items-start justify-content-center"
    >
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-justify">
        <adl-text-description
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>
    <div class="w-100 h-110-px d-flex justify-content-center">
      <div class="w-85 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <adl-button
            id="adl-button_button-click"
            [cssClass]="'w-100 buttonTextMedium'"
            [mandatory]="true"
            [parentForm]="formGroup"
            [text]="message.captionButton"
            (clickEvent)="closePopup()"
          ></adl-button>
        </div>
      </div>
    </div>
  </form>
</div>
