import { Injectable } from "@angular/core";

import { IPep } from "../../core/interfaces/pep.interface";

import * as messageData from "../data/pep-data.json";

@Injectable({
  providedIn: "root",
})
export class PepRemoteService {
  getInformationPep(): IPep[] {
    return (messageData as any).default;
  }
}
