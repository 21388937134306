export enum EventCodeEnum {
  COMPARE_RATE_RESULT = 16001,
  CLOSE_SESSION_BY_USER_STEP_ID = "CLOSE_SESSION_BY_USER_STEP_ID",
  CLOSE_SESSION_DUE_TO_INACTIVITY_STEP_ID = "CLOSE_SESSION_DUE_TO_INACTIVITY_STEP_ID",
  SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID = "SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID",
  CUSTOMER_DATA_PROCESSING_AUTORIZATION_STEP_ID = "CUSTOMER_DATA_PROCESSING_AUTORIZATION_STEP_ID",
  CUSTOMER_REJECTED_DATA_PROCESSING_AUTORIZATION_STEP_ID = "CUSTOMER_REJECTED_DATA_PROCESSING_AUTORIZATION_STEP_ID",
  PEP_AFFIRMATIVE_DECLARATION = "PEP_AFFIRMATIVE_DECLARATION",
  PEP_NEGATIVE_DECLARATION = "PEP_NEGATIVE_DECLARATION",
  US_GAP_AFFIRMATIVE_DECLARATION = "US_GAP_AFFIRMATIVE_DECLARATION",
  US_GAP_NEGATIVE_DECLARATION = "US_GAP_NEGATIVE_DECLARATION",
  BOARD_MEMBER_AFFIRMATIVE_DECLARATION = "BOARD_MEMBER_AFFIRMATIVE_DECLARATION",
  PAYROLLCHECK_DIGITALIZATION_NOT_COMPLETED = "PAYROLLCHECK_DIGITALIZATION_NOT_COMPLETED",
  BOARD_MEMBER_NEGATIVE_DECLARATION = "BOARD_MEMBER_NEGATIVE_DECLARATION",
  CUSTOMER_AUTHENTICATION = 2009,
  SELECT_SECTOR_AND_PAYER = "SELECT_SECTOR_AND_PAYER",
  PREVIABILITY_SIMULATOR_LOGIN = 13001,
  SELECTED_FLOW_POP_UP_REPRICING = 19002,
}
