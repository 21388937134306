import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { CognitoService } from "./../../services/local/cognito.service";
import { FederatedAuthService } from "./../../services/local/federated-auth.service";
import { ToggleService } from "./../../services/local/toggle.service";

@Injectable({
  providedIn: "root",
})
export class InitResolverService
  implements Resolve<{ toggle: any; access: any }> {
  constructor(
    protected toggleService: ToggleService,
    protected federatedAuthService: FederatedAuthService,
    protected cognitoService: CognitoService,
  ) {}

  async resolve(): Promise<{ toggle: any; access: any }> {
    await this.toggleService.resolve();

    const isFederatedEnable = this.federatedAuthService.federatedAuthEnabled();

    const access = await this.federatedAuthService.resolve();
    const accesToken = access?.access_token ?? "";

    if (access || !isFederatedEnable) {
      this.cognitoService
        .getAllCognitoTokenActiveDirectory(accesToken)
        .subscribe();
    }

    return access;
  }
}
