import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IRadioButtonData } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { DoublePensionRemoteService } from "../remote/double-pension.remote.service";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";

@Injectable({
  providedIn: "root",
})
export class DoublePensionService {
  private doublePension: string;

  constructor(private doublePensionRemoteService: DoublePensionRemoteService) {}

  getInformationDoublePension(): IRadioButtonData[] {
    return this.doublePensionRemoteService.getInformationDoublePension();
  }

  getDoublePension() {
    return this.doublePension;
  }

  setDoublePension(formGroup: UntypedFormGroup) {
    this.doublePension =
      formGroup.controls.doublePensionRadioGroup?.value.radioButtonValue ||
      ApplicationConstantsEnum.CHARACTER_EMPTY;
  }
}
