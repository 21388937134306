<div class="table-responsive table-wrapper-scroll-y  {{ cssClass }} ">
  <table class="table" id="table">
    <caption *ngIf="false"></caption>
    <thead>
      <tr>
        <th *ngFor="let title of titles; let i = index" id="title-{{ i }}">
          {{ title.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataTable; let row = index">
        <td *ngFor="let val of data; let col = index">
          <div
            class="w-100"
            [ngStyle]="{ 'background-color': val }"
            *ngIf="mapData[col].columnType === tableColumnTypeEnum.COLOR"
          ></div>

          <div *ngIf="mapData[col].columnType === tableColumnTypeEnum.TEXT">
            {{ val }}
          </div>

          <div
            *ngIf="
              mapData[col].columnType === tableColumnTypeEnum.TEXT_WITH_LIST
            "
            class="table-capitalize  table-size-name"
          >
            <div class="w-100">
              {{ val?.toLowerCase() }}
            </div>
            <div>
              <adl-ul
                [dataList]="dataListTable[row][0]"
                [fieldView]="mapData[col].fieldListView"
              ></adl-ul>
            </div>
          </div>

          <div
            class="d-flex justify-content-center"
            *ngIf="mapData[col].columnType === tableColumnTypeEnum.UPLOAD"
          >
            <button
              type="button"
              class="btn btn-link-green"
              (click)="onClick(data)"
              id="upload-{{ row }}"
            >
              Cargar Ahora
              <img src="./assets/images/onbase_load/add.svg" alt="Icon add" />
            </button>
          </div>
          <div
            class="d-flex justify-content-end"
            *ngIf="mapData[col].columnType === tableColumnTypeEnum.COLOR"
            (click)="onClick(data)"
          >
            <button
              type="button"
              [class]="setColorColumn(val).class"
              (click)="onClickList(data, dataListTable[row][0])"
              id="upload-response-{{ row }}"
            >
              {{ val }}
              <img [src]="setColorColumn(val).icon" alt="Icon" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
