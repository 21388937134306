import { Injectable } from "@angular/core";

import * as notificationDetailsUploadDocuments from "../data/onbase-notification-details-upload-documents-data.json";

@Injectable({
  providedIn: "root",
})
export class OnbaseNotificationRemoteService {
  notificationSearch(notificationId: string) {
    return (notificationDetailsUploadDocuments as any).default.find(
      (details: any) => details.notificationId === notificationId,
    );
  }
}
