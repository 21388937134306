import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { CognitoService } from "../local/cognito.service";

@Injectable({
  providedIn: "root",
})
export class RepricingRemoteService {
  constructor(
    private http: HttpClient,
    private cognitoService: CognitoService,
  ) {}

  repricingSearch(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.cognitoService.getTokenNew(),
    });
    return this.http.post(
      environment.serverUrlSalaryLoans + "customer/campaign/repricing/search",
      body,
      { headers },
    );
  }
}
