export enum PayrollLoanStatusEnum {
  CAPTURED = "1",
  NOT_CAPTURED = "2",
  APPROVED = "3",
  NOT_APPROVED = "4",
  IMPROVED = "5",
  NOT_IMPROVED = "6",
  IMPROVED_READY_FOR_DISBURSEMENT = "7",
  CAPTURED_INCOMPLETE_DOCUMENTATION = "9",
  CAPTURED_PENDING_PARAMETERS = "10",
  NO_STATE = "11",
  WAITING_FOR_DOCUMENTS = "12",
  IN_PROCESS = "13",
  SCALING = "14",
}
