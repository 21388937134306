import { Injectable } from "@angular/core";

import {
  INavPill,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import * as initialNavPillsData from "../data/initial-nav-pills-data.json";

@Injectable({
  providedIn: "root",
})
export class InitialNavPillsRemoteService {
  getInitialNavPillsByEnum(
    initialNavPillsEnum: InitialNavPillsEnum,
  ): INavPill[] {
    return (initialNavPillsData as any).default.filter(
      (initialNavPill: INavPill) => initialNavPill.id === initialNavPillsEnum,
    );
  }
}
