import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CognitoRemoteService {
  constructor(private http: HttpClient) {}

  getToken(): Observable<any> {
    return this.http.get(
      `${environment.serverUrlSalaryLoans}auth/cognito/caller/token`,
    );
  }

  getTokenActiveDirectory(access_token): Observable<any> {
    return this.http.post(
      environment.serverUrlBackendSecurity + "auth/active-directory",
      {
        access_token: access_token,
      },
    );
  }

  getTokenLegacy(): Observable<any> {
    return this.http.post(
      environment.cognitoLegacy.url,
      `grant_type=client_credentials&client_id=${environment.cognitoLegacy.clientId}&client_secret=${environment.cognitoLegacy.clientSecret}`,
    );
  }

  getTokenNew(): Observable<any> {
    return this.http.post(
      environment.cognitoNew.url,
      `grant_type=client_credentials&client_id=${environment.cognitoNew.clientId}&client_secret=${environment.cognitoNew.clientSecret}`,
    );
  }
}
