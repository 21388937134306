import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";
import { IMessageLoan } from "../../../core/interfaces/message-loan.interface";

import * as messageLoanData from "../../data/message-loan-data.json";

export abstract class IMessageLoanStrategy {
  constructor(protected dataLoanStatus: ILoanStatus) {}

  abstract getMessageId(): string;

  getMessageLoan(): IMessageLoan {
    const messageId = this.getMessageId();
    return (messageLoanData as any).default.find(
      (messageLoan: IMessageLoan) => messageLoan.messageLoanId === messageId,
    );
  }
}
