import { Injectable } from "@angular/core";

import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";

import { IOverflowError } from "../../core/interfaces/overflow-error.interface";

import { OverflowErrorsRemoteService } from "../remote/overflow-errors-remote.service";

@Injectable({
  providedIn: "root",
})
export class OverflowErrorsService {
  overflowError: IOverflowError;

  constructor(
    private overflowErrorsRemoteService: OverflowErrorsRemoteService,
  ) {}

  clearOverflowError() {
    this.overflowError = undefined;
  }

  getOverflowError(): IOverflowError {
    return this.overflowError;
  }

  setOverflowError(overflowErrorsEnum: OverflowErrorsEnum) {
    this.overflowError = this.overflowErrorsRemoteService.searchOverflowErrorsData(
      overflowErrorsEnum,
    );
  }
}
