import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

import { AdvisorDecisionTypeEnum } from "../../core/enums/advisor-decision-type.enum";
import { ChannelTypeEnum } from "../../core/enums/channel-type.enum";
import { MessageEnum } from "./../../core/enums/message.enum";
import { ValidationMethodEnum } from "../../core/enums/validation-method.enum";
import { ValidationQueryDecrimResponseStatusEnum } from "../../core/enums/validation-query-decrim-response-status.enum";
import { OverflowErrorsEnum } from "./../../core/enums/overflow-errors.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICreateDecrim } from "../../core/interfaces/create-decrim.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IRequestValidateDecrimQuery } from "../../core/interfaces/request-validate-decrim-query.interface";
import { IResponseValidateDecrimQuery } from "../../core/interfaces/response-validate-decrim-query.interface";

import { AdvisorService } from "./advisor.service";
import { CognitoService } from "./cognito.service";
import { CustomerService } from "./customer.service";
import { FileManagerService } from "./file-manager.service";
import { MessageService } from "./message.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { ToggleService } from "./toggle.service";

import { ToggleEnum } from "src/app/core/enums/toggle.enum";

import { DecrimRemoteService } from "../remote/decrim-remote.service";
import { FileManagerRemoteService } from "../remote/file-manager-remote.service";

@Injectable({
  providedIn: "root",
})
export class DecrimService {
  advisor: IAdvisor;
  createDecrim: ICreateDecrim;
  customer: ICustomer;
  decrimFiles: any[] = [undefined, undefined, undefined];
  unificationFlowActive: boolean;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private decrimRemoteService: DecrimRemoteService,
    private fileManagerRemoteService: FileManagerRemoteService,
    private fileManagerService: FileManagerService,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private cognitoService: CognitoService,
    private toggleService: ToggleService,
  ) {
    this.unificationFlowActive = this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  public getFiles(): any[] {
    return this.decrimFiles;
  }

  public setFiles(decrimFiles: any[]) {
    this.decrimFiles = decrimFiles;
  }

  async uploadFiles(files: File[]) {
    let index = 0;
    let loadCounter = 0;
    this.customer = this.customerService.getCustomer();
    this.advisor = this.advisorService.getAdvisor();
    this.createDecrim = this.initCreateDecrim(this.customer, this.advisor);

    for (const file of files) {
      index++;

      const decrimFileName = this.fileManagerService.renameFile(
        file,
        index,
        this.customer.documentType,
        this.customer.documentId,
      );
      const decrimFileExtension = this.fileManagerService.getFileExtension(
        file,
      );

      this.createDecrim["fileName" + index] = decrimFileName;
      this.createDecrim["fileType" + index] = decrimFileExtension;

      await this.fileManagerRemoteService
        .uploadFile(
          this.advisor,
          decrimFileName,
          decrimFileExtension,
          file,
          ChannelTypeEnum.DECRIM,
        )
        .toPromise()
        .then(() => {
          loadCounter++;
        })
        .catch((error: any) =>
          // eslint-disable-next-line no-console
          console.log(
            `DecrimComponent - setFileDecrimInS3 - File${index} Error`,
            JSON.stringify(error),
          ),
        );
    }

    return loadCounter;
  }

  async createCase(isWithCustomerInfo?: boolean) {
    this.createDecrim.office = this.advisor.office.idoffice.toString();
    this.createDecrim.salesModel = isWithCustomerInfo
      ? AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
      : AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN;

    return new Promise<void>((resolve, reject) => {
      this.decrimRemoteService
        .createCase(this.advisor, this.createDecrim)
        .then((response: any) => {
          if (response.idCase) {
            this.customer.decrimFiles = this.decrimFiles;
            this.customer.idCaseDecrim = response.idCase;
            this.customer.validationMethod = ValidationMethodEnum.DECRIM;
            this.customerService.setCustomer(this.customer);
            resolve();
          } else {
            response.code =
              response.code === MessageEnum.SecurityManager0021
                ? MessageEnum.SecurityManager0021_Decrim
                : response.code;
            let message = this.messageService.getMessageById(response.code);
            if (message.messageId) {
              reject(message);
            } else {
              this.overflowErrorsService.setOverflowError(response.code);
              reject(null);
            }
          }
        })
        .catch((error) => {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.SecurityManager0019,
          );
          reject(null);
        });
    });
  }

  private initCreateDecrim(
    customer: ICustomer,
    advisor: IAdvisor,
  ): ICreateDecrim {
    return {
      fullName: customer.name,
      documentNumber: customer.documentId.toString(),
      documentType: customer.documentType === "CC" ? "1" : "2",
      advisorId: advisor.sellerIdNumber.toString(),
      fileName1: "",
      fileType1: "",
      fileName2: "",
      fileType2: "",
      fileName3: "",
      fileType3: "",
    };
  }

  postValidateDecrimQuery(
    requestValidateDecrimQuery: IRequestValidateDecrimQuery,
  ): Observable<IResponseValidateDecrimQuery[] | MessageEnum> {
    let token = this.cognitoService.getTokenNew();
    if (!token) {
      const advisorInfo = this.advisorService.getAdvisor();
      token = advisorInfo.cognitoToken;
    }
    return new Observable((subscriber) => {
      this.decrimRemoteService
        .postValidateDecrimQuery(requestValidateDecrimQuery, token)
        .pipe(
          catchError((errorValidateQueryDecrim) => {
            return throwError(
              errorValidateQueryDecrim.code ??
                errorValidateQueryDecrim.error.code ??
                MessageEnum.ERROR_TECHNICAL,
            );
          }),
        )
        .subscribe(
          (messageResponse: IResponseValidateDecrimQuery[]) => {
            const validationOnbaseStatus = messageResponse.some(
              (responseValidateDecrimQuery) =>
                responseValidateDecrimQuery.onbaseStatus ===
                ValidationQueryDecrimResponseStatusEnum.PENDING,
            );
            if (messageResponse.length > 0) {
              const customer: ICustomer = this.customerService.getCustomer();
              customer.completeName = messageResponse[0].clientFullName;
              this.customerService.setCustomer(customer);
            }
            if (validationOnbaseStatus && !this.unificationFlowActive) {
              subscriber.error(MessageEnum.CLIENT_HAVE_PENDING_ONBASE_SALES);
            } else {
              subscriber.next(messageResponse);
            }
          },
          (errorValidateQueryDecrim: any) => {
            subscriber.error(errorValidateQueryDecrim);
          },
        );
    });
  }
}
