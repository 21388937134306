<adl-popup *ngIf="canShow">
  <div header>
    <figure>
      <img [src]="message.icon" alt="info" />
    </figure>
    <div class="container-title">
      <adl-text-title
        [classList]="'text-title'"
        [text]="message.title"
        [level]="5"
      ></adl-text-title>
    </div>
  </div>
  <div content>
    <adl-text-description
      cssClass="text-description"
      [text]="message.description"
    ></adl-text-description>
    <section
      class="container-badge"
      *ngIf="message.actionButton === 'RETRY_UPLOAD_FILE'"
    >
      <div class="badge">
        <img src="assets/images/icons/bell.svg" alt="info" />
        Intento {{ attempt }} de {{ attemptMax }}
      </div>
    </section>
  </div>
  <div footer>
    <adl-button
      style="width: 100%;"
      cssClass="button-confirm"
      [mandatory]="false"
      [disabled]="false"
      [text]="message.captionButton"
      [parentForm]="formGroup"
      (clickEvent)="onClickButtonOk()"
    >
    </adl-button>
  </div>
</adl-popup>
