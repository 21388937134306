import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { INavPill } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

@Component({
  selector: "app-form-authentication-card-debit",
  templateUrl: "./form-authentication-card-debit.component.html",
})
export class FormAuthenticationCardDebitComponent implements OnInit {
  description: string;
  pills: INavPill[];
  title: string;
  formGroup: UntypedFormGroup;

  ngOnInit() {
    this.title = "¡Listo Kelly!";
    this.description = "Ahora selecciona como quieres autenticar tus datos";
    this.pills = [
      {
        active: false,
        text: "Código",
        url: "authenticationcode",
        value: "Código",
      },
      {
        active: false,
        text: "Whatsapp",
        url: "authenticationwhatsapp",
        value: "Whatsapp",
      },
      {
        active: true,
        text: "Tarjeta Débito",
        url: "",
        value: "Tarjeta Débito",
      },
    ];

    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl(
      "textSecurityCode",
      new UntypedFormControl("", Validators.required),
    );
    this.formGroup.addControl(
      "textPassword",
      new UntypedFormControl("", Validators.required),
    );
    this.formGroup.addControl("valuePill", new UntypedFormControl());
  }
}
