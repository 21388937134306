<div id="main">
  <div
    id="shapecommon"
    [ngClass]="entry ? 'shape-common-in' : 'shape-common-out'"
  ></div>
  <div
    id="womenwhatsapp"
    [ngClass]="entry ? 'women-whatsapp-in' : 'women-whatsapp-out'"
  ></div>
  <div
    id="shapebigauth"
    [ngClass]="entry ? 'shapebig-auth-in' : 'shapebig-auth-out'"
  ></div>
  <div
    id="plantinvert"
    [ngClass]="entry ? 'plant-invert-in' : 'plant-invert-out'"
  ></div>
  <div id="codeqr" [ngClass]="entry ? 'code-qr-in' : 'code-qr-out'"></div>
  <div
    id="textcodeqr"
    class="d-flex justify-content-center"
    [ngClass]="entry ? 'text-code-qr-in' : 'text-code-qr-out'"
  >
    <adl-text-label [cssClass]="''" [text]="'ESCANEAR'"></adl-text-label>
  </div>
  <div
    id="plantauth"
    [ngClass]="entry ? 'plant-auth-in' : 'plant-auth-out'"
  ></div>
</div>
