import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Guard
import { AppAccessGuard } from "./app-access.guard";
import { InitResolverService } from "./core/resolvers/init-resolver.service";

// Structures
import { AuthenticationCardDebitImageComponent } from "./design/structures/authentication-card-debit-image/authentication-card-debit-image.component";
import { AuthenticationCodeImageComponent } from "./design/structures/authentication-code-image/authentication-code-image.component";
import { AuthenticationWhatsappImageComponent } from "./design/structures/authentication-whatsapp-image/authentication-whatsapp-image.component";
import { FormAccountSelectionComponent } from "./design/structures/form-account-selection/form-account-selection.component";
import { FormAuthenticationCardDebitComponent } from "./design/structures/form-authentication-card-debit/form-authentication-card-debit.component";
import { FormAuthenticationCodeComponent } from "./design/structures/form-authentication-code/form-authentication-code.component";
import { FormAuthenticationWhatsappComponent } from "./design/structures/form-authentication-whatsapp/form-authentication-whatsapp.component";
import { FormAuthorizationDataComponent } from "./design/structures/form-authorization-data/form-authorization-data.component";
import { FormCustomerDecisionComponent } from "./design/structures/form-customer-decision/form-customer-decision.component";
import { FormDecisionComponent } from "./design/structures/form-decision/form-decision.component";
import { FormLoanDecisionComponent } from "./design/structures/form-loan-decision/form-loan-decision.component";
import { FormLoginAdvisorComponent } from "./design/structures/form-login-advisor/form-login-advisor.component";
import { FormLoginCustomerComponent } from "./design/structures/form-login-customer/form-login-customer.component";
import { FormNewLoanComponent } from "./design/structures/form-new-loan/form-new-loan.component";
import { FormOtpComponent } from "./design/structures/form-otp/form-otp.component";
import { FormQueryUploadDocumentsComponent } from "./design/structures/form-query-upload-documents/form-query-upload-documents.component";
import { FormUploadDocumentsOnbaseComponent } from "./design/structures/form-upload-documents-onbase/form-upload-documents-onbase.component";
import { LoginImageComponent } from "./design/structures/login-image/login-image.component";
import { MainImageComponent } from "./design/structures/main-image/main-image.component";
import { OtpImageComponent } from "./design/structures/otp-image/otp-image.component";
import { OverflowErrorViewComponent } from "./design/structures/overflow-error-view/overflow-error-view.component";
import { PayrollLoanStatusViewComponent } from "./design/structures/payroll-loan-status-view/payroll-loan-status-view.component";
import { FormPepComponent } from "./design/structures/form-pep/form-pep.component";
import { SalaryLoanViewComponent } from "./design/structures/salary-loan-view/salary-loan-view.component";
import { SalaryLoanSuccessViewComponent } from "./design/structures/salary-loan-success-view/salary-loan-success-view.component";
import { ValidationMethodViewComponent } from "./design/structures/validation-method-view/validation-method-view.component";

// Pages
import { AccountSelectionComponent } from "./design/pages/account-selection/account-selection.component";
import { AuthenticationCardDebitComponent } from "./design/pages/authentication-card-debit/authentication-card-debit.component";
import { AuthenticationCodeComponent } from "./design/pages/authentication-code/authentication-code.component";
import { AuthenticationWhatsappComponent } from "./design/pages/authentication-whatsapp/authentication-whatsapp.component";
import { AuthorizationDataComponent } from "./design/pages/authorization-data/authorization-data.component";
import { CustomerDecisionComponent } from "./design/pages/customer-decision/customer-decision.component";
import { DecisionComponent } from "./design/pages/decision/decision.component";
import { LoanDecisionComponent } from "./design/pages/loan-decision/loan-decision.component";
import { LoginAdvisorComponent } from "./design/pages/login-advisor/login-advisor.component";
import { LoginCustomerComponent } from "./design/pages/login-customer/login-customer.component";
import { NewLoanComponent } from "./design/pages/new-loan/new-loan.component";
import { OtpAuthenticationComponent } from "./design/pages/otp-authentication/otp-authentication.component";
import { OverflowErrorComponent } from "./design/pages/overflow-error/overflow-error.component";
import { PayrollLoanStatusComponent } from "./design/pages/payroll-loan-status/payroll-loan-status.component";
import { PepComponent } from "./design/pages/pep/pep.component";
import { QueryUploadDocumentsComponent } from "./design/pages/query-upload-documents/query-upload-documents.component";
import { SalaryLoanComponent } from "./design/pages/salary-loan/salary-loan.component";
import { SalaryLoanSuccessComponent } from "./design/pages/salary-loan-success/salary-loan-success.component";
import { UploadDocumentsOnbaseComponent } from "./design/pages/upload-documents-onbase/upload-documents-onbase.component";
import { ValidationMethodComponent } from "./design/pages/validation-method/validation-method.component";

// Templates
import { OneColumnComponent } from "./design/templates/one-column/one-column.component";
import { TwoColumnComponent } from "./design/templates/two-column/two-column.component";

// Local services
import { ToggleService } from "./services/local/toggle.service";

const routes: Routes = [
  { path: "", redirectTo: "loginadvisor", pathMatch: "full" },
  {
    path: "accountselection",
    component: AccountSelectionComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "authenticationcarddebit",
    component: AuthenticationCardDebitComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "authenticationcode",
    component: AuthenticationCodeComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "authenticationwhatsapp",
    component: AuthenticationWhatsappComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "authorizationdata",
    component: AuthorizationDataComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "customerdecision",
    component: CustomerDecisionComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "decision",
    component: DecisionComponent,
    canActivate: [AppAccessGuard],
    resolve: {
      toggle: ToggleService,
    },
  },
  {
    path: "loandecision",
    component: LoanDecisionComponent,
    canActivate: [AppAccessGuard],
    resolve: {
      toggle: ToggleService,
    },
  },
  {
    path: "loginadvisor",
    component: LoginAdvisorComponent,
    resolve: {
      access: InitResolverService,
    },
  },
  {
    path: "logincustomer/:decision",
    component: LoginCustomerComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "newloan",
    component: NewLoanComponent,
    canActivate: [AppAccessGuard],
    resolve: {
      access: InitResolverService,
    },
  },
  {
    path: "otpauthentication",
    component: OtpAuthenticationComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "overflowerror",
    component: OverflowErrorComponent,
  },
  {
    path: "payrollloanstatus",
    component: PayrollLoanStatusComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "pep",
    component: PepComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "queryuploaddocument",
    component: QueryUploadDocumentsComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "salaryloan",
    component: SalaryLoanComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "salaryloansuccess",
    component: SalaryLoanSuccessComponent,
    canActivate: [AppAccessGuard],
  },
  {
    path: "uploaddocumentsonbase",
    component: UploadDocumentsOnbaseComponent,
    canActivate: [AppAccessGuard],
  },

  {
    path: "validationmethod/:decision",
    component: ValidationMethodComponent,
  },
  {
    path: "onecolumn",
    component: OneColumnComponent,
    children: [
      { path: "", component: OneColumnComponent, pathMatch: "full" },
      {
        path: "formaccountselection",
        component: FormAccountSelectionComponent,
        outlet: "part-center",
      },
      {
        path: "formcustomerdecision",
        component: FormCustomerDecisionComponent,
        outlet: "part-center",
      },
      {
        path: "formdecision",
        component: FormDecisionComponent,
        outlet: "part-center",
      },
      {
        path: "formloandecision",
        component: FormLoanDecisionComponent,
        outlet: "part-center",
      },
      {
        path: "formauthorizationdata",
        component: FormAuthorizationDataComponent,
        outlet: "part-center",
      },
      {
        path: "formnewloan",
        component: FormNewLoanComponent,
        outlet: "part-center",
      },
      {
        path: "formpep",
        component: FormPepComponent,
        outlet: "part-center",
      },
      {
        path: "formquerydocument",
        component: FormQueryUploadDocumentsComponent,
        outlet: "part-center",
      },
      {
        path: "formuploaddocumentsonbase",
        component: FormUploadDocumentsOnbaseComponent,
        outlet: "part-center",
      },
      {
        path: "overflowerrorview",
        component: OverflowErrorViewComponent,
        outlet: "part-center",
      },
      {
        path: "formauthorizationdata",
        component: FormAuthorizationDataComponent,
        outlet: "part-center",
      },
      {
        path: "payrollloanstatusview",
        component: PayrollLoanStatusViewComponent,
        outlet: "part-center",
      },
      {
        path: "salaryloanview",
        component: SalaryLoanViewComponent,
        outlet: "part-center",
      },
      {
        path: "salaryloansuccessview",
        component: SalaryLoanSuccessViewComponent,
        outlet: "part-center",
      },
      {
        path: "validationmethodview/:decision",
        component: ValidationMethodViewComponent,
        outlet: "part-center",
      },
    ],
  },
  {
    path: "twocolumn",
    component: TwoColumnComponent,
    children: [
      { path: "", component: TwoColumnComponent, pathMatch: "full" },
      {
        path: "formauthenticationcarddebit",
        component: FormAuthenticationCardDebitComponent,
        outlet: "part-left",
      },
      {
        path: "formauthenticationcode",
        component: FormAuthenticationCodeComponent,
        outlet: "part-left",
      },
      {
        path: "formauthenticationwhatsapp",
        component: FormAuthenticationWhatsappComponent,
        outlet: "part-left",
      },
      {
        path: "formloginadvisor",
        component: FormLoginAdvisorComponent,
        outlet: "part-left",
      },
      {
        path: "formlogincustomer/:decision",
        component: FormLoginCustomerComponent,
        outlet: "part-left",
      },
      { path: "formotp", component: FormOtpComponent, outlet: "part-left" },

      {
        path: "authenticationcarddebitimage",
        component: AuthenticationCardDebitImageComponent,
        outlet: "part-right",
      },
      {
        path: "authenticationcodeimage",
        component: AuthenticationCodeImageComponent,
        outlet: "part-right",
      },
      {
        path: "authenticationwhatsappimage",
        component: AuthenticationWhatsappImageComponent,
        outlet: "part-right",
      },
      {
        path: "mainimage",
        component: MainImageComponent,
        outlet: "part-right",
      },
      {
        path: "loginimage",
        component: LoginImageComponent,
        outlet: "part-right",
      },
      { path: "otpimage", component: OtpImageComponent, outlet: "part-right" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
