import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  INavPill,
  InitialNavPillsEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { FormPopupShortFlowRepricingComponent } from "../form-popup-short-flow-repricing/form-popup-short-flow-repricing.component";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { DocumentTypeEnum } from "../../../core/enums/document-type.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";

import { IOverflowError } from "../../../core/interfaces/overflow-error.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { OverflowErrorsService } from "../../../services/local/overflow-errors.service";
import { PayrollLoanStatusService } from "../../../services/local/payroll-loan-status.service";
import { RepricingCampaignService } from "../../../services/local/repricing-campaign.service";

@Component({
  selector: "app-form-login-customer",
  templateUrl: "./form-login-customer.component.html",
})
export class FormLoginCustomerComponent implements OnInit {
  @ViewChild(FormPopupShortFlowRepricingComponent, { static: false })
  formPopupShortFlowRepricingComponent: FormPopupShortFlowRepricingComponent;

  decision: string;
  description: string;
  loadingButtonCustomer: boolean;
  numberAttemptsError: number;
  showMessageBox: boolean;
  pills: INavPill[];
  title: string;

  formGroup: UntypedFormGroup;
  messageEnum: MessageEnum;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private initialNavPillsService: InitialNavPillsService,
    private overflowErrorsService: OverflowErrorsService,
    private payrollLoanStatusService: PayrollLoanStatusService,
    private repricingCampaignService: RepricingCampaignService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.numberAttemptsError = 0;
    this.route.params.subscribe((params) => {
      this.decision = params.decision;
    });

    this.googleAnalyticsEventsService.emitEvent(
      this.decision === AdvisorDecisionTypeEnum.GET_PAYROLL_LOAN_STATUS
        ? GoogleAnalyticsEventsEnum.QUERY_CUSTOMER_STATUS_INIT_PAGE
        : GoogleAnalyticsEventsEnum.LOGIN_CUSTOMER_INIT_PAGE,
    );

    this.title =
      this.decision === AdvisorDecisionTypeEnum.GET_PAYROLL_LOAN_STATUS
        ? "Consulta tu Libranza"
        : "Tramita tu Libranza";

    this.description =
      "Para comenzar ingresa tipo de documento y número de cédula";
    this.loadingButtonCustomer = false;

    this.addPills();
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      textDocumentCustomer: new UntypedFormControl("", Validators.required),
      valuePill: new UntypedFormControl(),
    });
  }

  closeMessageBox() {
    this.showMessageBox = false;
    if (this.customerService.getIsRepricingMessage()) {
      this.customerService.setIsRepricingMessage(false);
      this.navigateToAuthorizationData();
    }
  }

  async closeShortFlowRepricing(shortFlowPopup) {
    this.formPopupShortFlowRepricingComponent.popupdisabledtOkButton = true;
    if (this.customerService.getIsRepricingV2Message()) {
      let documentType = this.formGroup.controls.valuePill.value;
      let documentId = this.formGroup.controls.textDocumentCustomer.value;
      shortFlowPopup = !!Number(shortFlowPopup);
      this.repricingCampaignService.setRepricingV2ShortFlow(shortFlowPopup);
      this.customerService.setIsRepricingV2Message(false);
      if (shortFlowPopup)
        this.repricingCampaignService
          .search(documentType, documentId, shortFlowPopup)
          .subscribe(
            () => {
              this.navigateToAuthorizationData();
            },
            () => {
              this.overflowErrorsService.setOverflowError(
                OverflowErrorsEnum.TECHNICAL_ERROR,
              );
              this.router.navigate(["overflowerror"]);
            },
          );
      else {
        try {
          await this.customerService.validateCustomerCellPhone();
          await this.customerService.validateCustomerViabilityODM(
            documentType,
            documentId,
          );
          this.navigateToAuthorizationData();
        } catch (errorSearchCustomerOrValidations) {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.TECHNICAL_ERROR,
          );
          this.router.navigate(["overflowerror"]);
        }
      }
    }
  }

  searchCustomer() {
    if (this.formGroup.valid) {
      if (this.decision === AdvisorDecisionTypeEnum.GET_PAYROLL_LOAN_STATUS) {
        this.searchCustomerGetPayrollLoanStatus();
      } else {
        this.searchCustomerNewPayrollLoan();
      }
    }
  }

  private addPills() {
    this.pills = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.LOGIN_CUSTOMER,
    );
  }

  private navigateToAuthorizationData() {
    this.router.navigate(["authorizationdata"]);
  }

  private searchCustomerNewPayrollLoan() {
    this.googleAnalyticsEventsService.emitEvent(
      this.formGroup.controls.valuePill.value === DocumentTypeEnum.CC
        ? GoogleAnalyticsEventsEnum.LOGIN_CUSTOMER_SEARCH_NATIONAL
        : GoogleAnalyticsEventsEnum.LOGIN_CUSTOMER_SEARCH_FOREIGN,
    );

    const $this = this;
    this.loadingButtonCustomer = true;

    this.customerService
      .asyncSearchCustomer(
        this.formGroup.controls.valuePill.value,
        this.formGroup.controls.textDocumentCustomer.value,
      )
      .then((response: boolean) => {
        $this.loadingButtonCustomer = false;
        const isRepricing = this.customerService.getIsRepricingMessage();
        const isRepricingV2 = this.customerService.getIsRepricingV2Message();
        if (response && isRepricing) {
          $this.messageEnum = MessageEnum.SUCCESS_CLIENT_VALIDATION_REPRICING;
          $this.showMessageBox = true;
        } else if (response && isRepricingV2) {
          this.formPopupShortFlowRepricingComponent.setShowPopupShortFlow = true;
        } else {
          if (response) {
            $this.navigateToAuthorizationData();
          } else {
            this.validateOverflowError(
              this.overflowErrorsService.getOverflowError(),
            );
          }
        }
      });
  }

  private searchCustomerGetPayrollLoanStatus() {
    this.googleAnalyticsEventsService.emitEvent(
      this.formGroup.controls.valuePill.value === DocumentTypeEnum.CC
        ? GoogleAnalyticsEventsEnum.QUERY_CUSTOMER_STATUS_SEARCH_NATIONAL
        : GoogleAnalyticsEventsEnum.QUERY_CUSTOMER_STATUS_SEARCH_FOREIGN,
    );

    const $this = this;
    this.loadingButtonCustomer = true;

    this.customerService
      .asyncSearchCustomer(
        this.formGroup.controls.valuePill.value,
        this.formGroup.controls.textDocumentCustomer.value,
        true,
      )
      .then((response) => {
        $this.loadingButtonCustomer = false;
        if (response) {
          this.payrollLoanStatusService
            .searchPayrollLoanStatus(
              this.advisorService.getAdvisor(),
              this.customerService.getCustomer(),
            )
            .then(() => {
              this.overflowErrorsService.getOverflowError()
                ? this.router.navigate(["overflowerror"])
                : this.router.navigate(["payrollloanstatus"]);
            });
        } else {
          this.validateOverflowError(
            this.overflowErrorsService.getOverflowError(),
          );
        }
      });
  }

  private validateOverflowError(overflowError: IOverflowError) {
    if (overflowError) {
      this.googleAnalyticsEventsService.emitEvent(
        this.formGroup.controls.valuePill.value === DocumentTypeEnum.CC
          ? GoogleAnalyticsEventsEnum.LOGIN_CUSTOMER_SEARCH_NATIONAL_ERROR
          : GoogleAnalyticsEventsEnum.LOGIN_CUSTOMER_SEARCH_FOREIGN_ERROR,
      );

      if (
        overflowError.errorCode === OverflowErrorsEnum.CustomerManagement001
      ) {
        this.numberAttemptsError++;

        if (
          this.numberAttemptsError === ApplicationConstantsEnum.NUMBER_ATTEMPTS
        ) {
          this.router.navigate(["overflowerror"]);
        } else {
          this.showMessageBox = true;
          this.messageEnum = MessageEnum.ERROR_SEARCH_CUSTOMER;
        }
      } else {
        this.router.navigate(["overflowerror"]);
      }
    }
  }
}
