<div id="main" class="d-flex flex-column justify-content-center">
  <form [formGroup]="formGroup" novalidate>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6">
        <adl-progress-step-bar-with-number
          [steps]="steps"
        ></adl-progress-step-bar-with-number>
      </div>
    </div>

    <div class="h-30-px"></div>

    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-8 col-lg-8 content-message">
        <adl-content-message
          [cssClass]="'title-questions'"
          [icon]=""
          [title]="title"
          [description]=""
        >
        </adl-content-message>

        <div class="h-20-px"></div>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-90">
            <div class="row">
              <div class="col-sm-12 container-questions">
                <div class="question" *ngFor="let pep of infoPep">
                  <div class="d-flex align-items-center">
                    <adl-text-label
                      [cssClass]="'text-extra-small'"
                      [text]="pep.question"
                    >
                    </adl-text-label>
                    <div class="p-l-10-px" *ngIf="pep.toolTipText !== ''">
                      <adl-information-tool-tip
                        [text]="pep.toolTipText"
                      ></adl-information-tool-tip>
                    </div>
                  </div>

                  <div class="h-14-px"></div>

                  <adl-radio-group
                    [parentForm]="formGroup"
                    [nameRadioGroup]="pep.questionName"
                    [radioButtonData]="radioButtonData"
                  ></adl-radio-group>

                  <div class="h-20-px"></div>
                </div>

                <div class="border-separate-line"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 h-110-px d-flex justify-content-center">
          <div class="container-button">
            <div class="container-center-button">
              <adl-button
                id="adl-button_button-click"
                [cssClass]="'w-100 buttonTextMedium'"
                [mandatory]="true"
                [text]="captionButton"
                [parentForm]="formGroup"
                (clickEvent)="continue()"
                [loading]="loadingButton"
              ></adl-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
