import { Injectable } from "@angular/core";
import { NotificationResponseTypeEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { CashierOfficeSectorEnum } from "../../core/enums/cashier-office-sector.enum";
import { CustomerTypeEnum } from "../../core/enums/customer-type.enum";
import { DocumentTypeTapEnum } from "../../core/enums/document-type-tap.enum";
import { GoogleAnalyticsEventsEnum } from "../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../core/enums/message.enum";
import { ModalityTypeEnum } from "../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { PayrollFileValidityStatusEnum } from "../../core/enums/payroll-file-validity-status.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { ICustomer } from "../../core/interfaces/customer.interface";
import { IPayrollFileResponse } from "../../core/interfaces/payroll-file-response.interface";
import { IPreloan } from "../../core/interfaces/pre-loan.interface";
import { ISendDocumentTap } from "../../core/interfaces/send-document-tap.interface";

import { CustomerService } from "./customer.service";
import { DoublePensionService } from "./double-pension.service";
import { FileManagerService } from "./file-manager.service";
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";
import { LoanService } from "./loan.service";
import { NotificationReponseService } from "./notification-reponse.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { ToggleService } from "./toggle.service";

import { PayrollRemoteService } from "../remote/payroll-remote.service";

@Injectable({
  providedIn: "root",
})
export class PayrollService {
  private providersId: any = [];

  customer: ICustomer;
  preloan: IPreloan;
  updateProviderId: any = [];
  documents: IPayrollFileResponse[] = [];

  constructor(
    private customerService: CustomerService,
    private doublePensionService: DoublePensionService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private fileManagerService: FileManagerService,
    private loanService: LoanService,
    private notificationResponseService: NotificationReponseService,
    private overflowErrorsService: OverflowErrorsService,
    private payrollServiceRemote: PayrollRemoteService,
    private toggleService: ToggleService,
  ) {}

  getFile() {
    return this.fileManagerService.fileUrl;
  }

  getFilesValidityStatus() {
    this.loadCustomerAndPreloan();

    let onbaseCodes = [DocumentTypeTapEnum.PAYROLLCHECK];

    if (
      this.loanService.getSectorNumber() === CashierOfficeSectorEnum.EDUCATIONAL
    ) {
      onbaseCodes.push(DocumentTypeTapEnum.LABOR_CERTIFICATION);
    }

    const payrollFile = {
      documentNumber: this.customerService.getCustomer().documentId.toString(),
      documentType: this.customerService.getCustomer().documentType,
      obligationId: this.loanService.getObligationId(),
      onbaseCodes,
      payerNit: this.loanService.getPreloan().payerNit,
      sectorNumber: this.loanService.getSectorNumber().toString(),
      subSectorNumber: this.loanService.getSubSectorNumber().toString(),
    };

    return this.payrollServiceRemote.getFilesValidityStatus(payrollFile).pipe(
      map((response: any) => {
        const documents: IPayrollFileResponse[] = response.documents;
        this.documents = documents.filter((item) => item.providerId);
        let listResponse = [];
        for (const document of documents) {
          listResponse.push({
            notification: this.validateFileStatus(document),
            document: document,
          });
        }
        return listResponse;
      }),
    );
  }

  getPayrollCheckPreLoaded(payrollCheckId: string): Observable<any> {
    this.loadCustomerAndPreloan();

    return this.payrollServiceRemote
      .getFileCustomer(
        payrollCheckId,
        this.preloan.obligationId,
        this.customer.documentType,
        this.customer.documentId.toString(),
        this.customerService.getQueryParamsCustomerNew(this.preloan),
      )
      .pipe(
        map((dataFileCustomer: any) => {
          if (dataFileCustomer.simulationError) {
            const status = dataFileCustomer.simulationError.additionalStatus[0];

            throw {
              error: {
                code: status.statusCode,
                statusDesc:
                  status.statusCode === MessageEnum.SIMULATION_ERROR_105
                    ? this.extractObligationFromError(status.statusDesc)
                    : null,
              },
            };
          }
          if (!dataFileCustomer.code) {
            this.preloan.payrollCheckId = payrollCheckId;
            this.preloan.transactionId = dataFileCustomer.id;

            this.setLoyaltyModalityType(dataFileCustomer.modalityType);
          }

          return dataFileCustomer;
        }),
        catchError((errorFileCustomer) => {
          return throwError(errorFileCustomer);
        }),
      );
  }

  getPayrollCheckPreLoadedNewClient(providersId: any[]) {
    this.loadCustomerAndPreloan();

    return this.payrollServiceRemote
      .getFileStatusDocuments(this.buildClientOcrRequest(providersId))
      .pipe(
        map((dataFileCustomer: any) => {
          this.throwSimulationError(dataFileCustomer);

          const providerIdPayrollPayer =
            dataFileCustomer.statusOCRByDocument === null
              ? ApplicationConstantsEnum.DEFAULT_PROVIDER_ID
              : dataFileCustomer.statusOCRByDocument.find(
                  (document) =>
                    document.onbaseCode === DocumentTypeTapEnum.PAYROLLCHECK,
                );
          this.preloan.transactionId = providerIdPayrollPayer.providerId;
          return dataFileCustomer;
        }),
        catchError((errorFileCustomer) => {
          return throwError(errorFileCustomer);
        }),
      );
  }

  getProvidersId() {
    return this.providersId;
  }

  isPayrollCheckPreLoaded(): Observable<boolean> {
    this.loadCustomerAndPreloan();

    return this.payrollServiceRemote
      .getFileCustomer(
        ApplicationConstantsEnum.UNKNOWN,
        this.preloan.obligationId,
        this.customer.documentType,
        this.customer.documentId.toString(),
        this.customerService.getQueryParamsCustomerNew(this.preloan),
      )
      .pipe(
        map((dataFileCustomer: any) => {
          this.preloan.payrollCheckId = dataFileCustomer.id;
          return true;
        }),
        catchError((errorFileCustomer) => {
          return throwError(errorFileCustomer);
        }),
      );
  }

  preSendFileTap(
    file: File,
    payrollFileType: DocumentTypeTapEnum,
    urlFile: string,
    incorrectData?: string,
    fileData?: any,
  ) {
    let observableResponse: Observable<any>;
    const codeName =
      payrollFileType === DocumentTypeTapEnum.LABOR_CERTIFICATION
        ? ApplicationConstantsEnum.LABOR_CERTIFICATION_OCR_CODE
        : ApplicationConstantsEnum.PAYROLL_CHECKS_OCR_CODE;

    const body = this.buildRequestBodySendTap(
      codeName,
      payrollFileType,
      file,
      urlFile,
      incorrectData,
      fileData,
    );

    observableResponse = this.payrollServiceRemote.sendFileToTap(body);

    return observableResponse.pipe(
      tap((data: any) => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_SUCCESS,
        );
        this.documents.push({
          onbaseCode: payrollFileType,
          providerId: data.id.toString(),
          status: "" as any,
        });
        this.updateProviderId[payrollFileType] = data.id.toString();
        this.setProvidersId(this.updateProviderId);
      }),
      map(() => {
        return this.notificationResponseService.getNotificationResponse(
          NotificationResponseTypeEnum.UPLOAD_FILE_SUCCESS,
          file.name,
        );
      }),
    );
  }

  getPayrollCheckProvider() {
    const find = this.documents.filter(
      (item) => item.onbaseCode === DocumentTypeTapEnum.PAYROLLCHECK,
    );

    return find[find.length - 1]?.providerId;
  }

  sendLinkForUploadFile(): Observable<any> {
    this.loadCustomerAndPreloan();

    return this.payrollServiceRemote
      .sendLinkForUploadFile(this.getUploadFileRequest(false))
      .pipe(
        map((dataLinkForUploadFile: any) => {
          return dataLinkForUploadFile;
        }),
        catchError((errorLinkForUploadFile: any) => {
          let overflowError = false;
          if (
            errorLinkForUploadFile.error.code !==
            OverflowErrorsEnum.PayrollCheckUpload002
          ) {
            overflowError = true;

            this.overflowErrorsService.setOverflowError(
              errorLinkForUploadFile.error.code,
            );

            return throwError({
              overFlowError: overflowError,
            });
          }

          return throwError(errorLinkForUploadFile);
        }),
      );
  }

  setPayrollFile(): Observable<boolean> {
    this.loadCustomerAndPreloan();
    return this.payrollServiceRemote
      .setPayrollFile(this.getUploadFileRequest(true))
      .pipe(
        map((dataPayrollFile: any) => {
          this.preloan.payrollCheckId = dataPayrollFile.id;
          return dataPayrollFile;
        }),
        catchError((errorPayrollFile: any) => {
          let overflowError = false;
          if (
            errorPayrollFile.error.code === OverflowErrorsEnum.PayrollChecks9999
          ) {
            overflowError = true;

            this.overflowErrorsService.setOverflowError(
              errorPayrollFile.error.code,
            );
          }

          return throwError({
            overFlowError: overflowError,
          });
        }),
      );
  }

  setProvidersId(providersId: []) {
    this.providersId = Object.values(providersId);
  }

  simulationLoan(providersId: any, customerType: CustomerTypeEnum) {
    return this.payrollServiceRemote
      .simulationLoan(this.buildClientOcrRequest(providersId), customerType)
      .pipe(
        map((dataFileCustomer: any) => {
          this.setLoyaltyModalityType(
            dataFileCustomer.simulationResponse?.modalityType,
          );
          this.throwSimulationError(dataFileCustomer);
          return dataFileCustomer;
        }),
      );
  }

  private buildClientOcrRequest(providersId: any) {
    this.loadCustomerAndPreloan();
    return {
      documentType: this.customer.documentType,
      documentNumber: this.customer.documentId.toString(),
      providerId: providersId,
      obligationId: this.preloan.obligationId,
      payerNit: this.preloan.payerNit,
      sectorNumber: this.preloan.sectorNumber.toString(),
      subSectorNumber: this.preloan.subSectorNumber.toString(),
    };
  }

  private buildRequestBodySendTap(
    codeName: string,
    documentTypeTap: DocumentTypeTapEnum,
    file: File,
    urlFile: string,
    incorrectData = null,
    fileData = null,
  ): ISendDocumentTap {
    this.loadCustomerAndPreloan();
    const doublePension = {
      doublePension: this.doublePensionService.getDoublePension(),
    };

    return {
      journeyId: this.customer.salesJourneyId,
      data: JSON.stringify(doublePension),
      dataToCorrect: incorrectData,
      documentName: codeName,
      documentType: documentTypeTap,
      fileData: fileData
        ? fileData
        : {
            fileBucket: this.getFileBucket(urlFile),
            fileExtension: this.fileManagerService.getFileExtension(file),
            fileUrl: this.getFileUrl(urlFile),
          },
      loanData: {
        clientDocumentNumber: this.customer.documentId.toString(),
        clientDocumentType: this.customer.documentType,
        payrollPayer: this.loanService.getPayerUniqueName(),
        payerNit: this.loanService.getPreloan().payerNit,
        sectorNumber: this.loanService.getSectorNumber().toString(),
      },
      requestId: incorrectData ? this.getProvidersId()[0] : "",
    };
  }

  private setLoyaltyModalityType(modalityType: ModalityTypeEnum) {
    if (
      this.toggleService.getToggleEnabledById(
        ToggleEnum.USE_FOOTPRINTS_NEW_SERVICE_TOGGLE,
      ) &&
      modalityType === ModalityTypeEnum.LOYALTY
    ) {
      this.preloan.modalityType = ModalityTypeEnum.LOYALTY;
    }
  }

  private extractObligationFromError(statusDesc: string) {
    const match = statusDesc.match(/\d+/);
    return match ? match[0] : null;
  }

  private getFileBucket(urlComplete: string): string {
    return urlComplete.split("/")[3];
  }

  private getFileUrl(urlComplete: string): string {
    const endpoint = urlComplete.split("/")[4];
    const fileName = urlComplete.split("/")[5];
    const indexFile = fileName.indexOf("?");
    return `/${endpoint}/${fileName.substring(0, indexFile)}`;
  }

  private getUploadFileRequest(setFileName: boolean) {
    const uploadFileRequest: any = {
      clientType: CustomerTypeEnum.CUSTOMER_WITH_LOANS,
      documentType: this.customer.documentType,
      documentNumber: this.customer.documentId.toString(),
      fileName: setFileName
        ? this.fileManagerService.getFileName(this.preloan.obligationId)
        : undefined,
      obligationId: this.preloan.obligationId,
      data: { doublePension: this.doublePensionService.getDoublePension() },
      payerNit: this.preloan.payerNit,
      sectorNumber: this.preloan.sectorNumber,
      subSectorNumber: this.preloan.subSectorNumber,
    };

    if (this.customerService.isNewLoanDecision()) {
      uploadFileRequest.clientType = CustomerTypeEnum.NEW_CUSTOMER_KNOWN;
    }

    return uploadFileRequest;
  }

  private loadCustomerAndPreloan() {
    this.customer = this.customerService.getCustomer();
    this.preloan = this.loanService.getPreloan();
  }

  private setNotification(payrollFile: IPayrollFileResponse) {
    const notification = this.notificationResponseService.getNotificationResponse(
      NotificationResponseTypeEnum.UPLOAD_FILE_SUCCESS,
      ApplicationConstantsEnum.FILE_NAME_PRELOAD,
    );

    notification.title =
      payrollFile.onbaseCode === DocumentTypeTapEnum.LABOR_CERTIFICATION
        ? ApplicationConstantsEnum.LABOR_CERTIFICATION_TEXT
        : ApplicationConstantsEnum.PAYROLL_CHECK_TEXT;

    return notification;
  }

  private messageCodes() {
    return Object.values(MessageEnum);
  }

  private existSimulationErrorCode(code: string) {
    const message = this.messageCodes().find((item) => item === code);
    return Boolean(message);
  }

  private throwSimulationError(dataFileCustomer: any) {
    if (dataFileCustomer.simulationError) {
      const status = dataFileCustomer.simulationError.additionalStatus[0];
      throw {
        error: {
          code: status.statusCode,
          statusDesc:
            status.statusCode === MessageEnum.SIMULATION_ERROR_105
              ? this.extractObligationFromError(status.statusDesc)
              : null,
        },
      };
    }
    if (
      dataFileCustomer.validationStatus === MessageEnum.FAIL_SIMULATION &&
      !this.existSimulationErrorCode(dataFileCustomer.simulationErrorCode)
    ) {
      throw {
        error: {
          code: MessageEnum.FAIL_SIMULATION,
          statusDesc: null,
        },
      };
    }
    if (dataFileCustomer.simulationErrorCode) {
      throw {
        error: {
          code: dataFileCustomer.simulationErrorCode,
          statusDesc: null,
        },
      };
    }
  }

  private validateFileStatus(payrollFile: IPayrollFileResponse) {
    if (
      payrollFile.status === PayrollFileValidityStatusEnum.FAIL ||
      payrollFile.status === PayrollFileValidityStatusEnum.EXPIRED
    ) {
      return this.notificationResponseService.getNotificationResponse(
        NotificationResponseTypeEnum.UPLOAD_FILE_ERROR,
      );
    }

    if (payrollFile.status === PayrollFileValidityStatusEnum.OK) {
      return this.setNotification(payrollFile);
    }

    return null;
  }
}
