import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { INavPill } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

@Component({
  selector: "app-form-authentication-whatsapp",
  templateUrl: "./form-authentication-whatsapp.component.html",
})
export class FormAuthenticationWhatsappComponent implements OnInit {
  description: string;
  pills: INavPill[];
  title: string;

  formGroup: UntypedFormGroup;

  ngOnInit() {
    this.title = "¡Listo Kelly!";
    this.description = "Ahora selecciona como quieres autenticar tus datos";
    this.pills = [
      {
        active: false,
        text: "Código",
        url: "authenticationcode",
        value: "Código",
      },
      { text: "Whatsapp", active: true, value: "Whatsapp", url: "" },
      {
        active: false,
        text: "Tarjeta Débito",
        url: "authenticationcarddebit",
        value: "Tarjeta Débito",
      },
    ];

    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl("valuePill", new UntypedFormControl());
  }

  receiveCode() {}
}
