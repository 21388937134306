<div id="main" class="d-flex align-items-center justify-content-center">
  <form [formGroup]="formGroup" class="w-400-px" novalidate>
    <div class="animation-title-bold">
      <adl-text-title
        [text]="title | CapitalCasePipe"
        [level]="2"
      ></adl-text-title>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <adl-text-description
        [cssClass]="'text-large'"
        [text]="description"
      ></adl-text-description>
    </div>

    <div class="h-14-px"></div>

    <div class="animation-description">
      <p>
        El código tiene
        <strong>
          04:00
        </strong>
        minutos de validez
      </p>
    </div>

    <div class="animation-description">
      <adl-progress-bar
        #progressBar
        (infoBarCompleteEvent)="infoBarComplete()"
        [seconds]="240"
        [time]="true"
      ></adl-progress-bar>
    </div>

    <div class="h-25-px"></div>

    <adl-otp-field
      #otpField
      [numberInputText]="numberInputOtp"
      [formGroup]="formGroup"
    ></adl-otp-field>

    <div class="h-25-px"></div>

    <div class="animation-description">
      <adl-text-link
        (click)="generateOtpByCall()"
        [text]="'Solicita una nueva clave por llamada teléfonica'"
      ></adl-text-link>
    </div>

    <div class="h-40-px"></div>

    <div class="animation-button">
      <adl-button
        id="adl-button_authenticate-otp"
        [cssClass]="'w-50'"
        [loading]="loadingButtonAuthenticateOtp"
        [mandatory]="true"
        [parentForm]="formGroup"
        [text]="'Autenticar'"
        (clickEvent)="authenticateCodeOtp()"
      ></adl-button>
    </div>
  </form>
</div>

<app-form-popup-message-box
  #formPopupMessageBox
  [showMessageBox]="showMessageBox"
  [messageEnum]="messageEnum"
  (closePopupEvent)="closeMessageBox()"
></app-form-popup-message-box>

<app-form-popup-message-box
  #formPopupMessageAdvisorTypeBox
  [showMessageBox]="showMessageAdvisorTypeBox"
  [messageEnum]="messageEnum"
  (closePopupEvent)="closeMessageAdvisorTypeBox()"
></app-form-popup-message-box>
