import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-authorization-data",
  template: "<app-one-column></app-one-column>",
})
export class AuthorizationDataComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      ["onecolumn", { outlets: { "part-center": ["formauthorizationdata"] } }],
      { skipLocationChange: true },
    );
  }
}
