<div id="main" class="d-flex flex-column justify-content-center">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-sm-7 col-lg-6 content-message">
      <adl-content-message
        [icon]="icon"
        [title]="title"
        [description]="description"
      >
      </adl-content-message>

      <div class="h-14-px"></div>

      <div class="w-100 d-flex justify-content-center">
        <div class="container-pills">
          <adl-nav-pills
            [pills]="pills"
            [checkMark]="true"
            [valueControl]="formGroup.controls['valuePill']"
            [parentForm]="formGroup"
            id="app-nav-pills_pill-validation-method"
          >
          </adl-nav-pills>
        </div>
      </div>

      <div class="w-100 h-110-px d-flex justify-content-center">
        <div class="container-button">
          <div class="container-center-button">
            <adl-button
              id="adl-button_button-click"
              [cssClass]="'w-100 buttonTextMedium'"
              [mandatory]="true"
              [text]="captionButton"
              [parentForm]="formGroup"
              (click)="goToValidation()"
            ></adl-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-form-popup-decrim
  #formPopupDecrim
  [popUpDecrim]="popUpDecrim"
  [messageEnum]="messageEnum"
  [isWithCustomerInfo]="isWithCustomerInfo"
  (closePopupAndRedirectEvent)="closePopupDecrim($event)"
  (closePopupFileDecrimWithCustomerInfoEvent)="
    closePopupFileDecrimWithCustomerInfo($event)
  "
></app-form-popup-decrim>

<app-form-popup-decrim-with-customer-info
  #formPopupDecrimWithCustomerInfo
  [popUpDecrimWithCustomerInfo]="popUpDecrimWithCustomerInfo"
  (closePopupAndRedirectEvent)="closePopupDecrimWithCustomerInfo($event)"
></app-form-popup-decrim-with-customer-info>

<app-form-popup-rnec
  #formPopupRnec
  [popUpRnec]="popUpRnec"
  (closePopupAndRedirectEvent)="closePopupRnec($event)"
></app-form-popup-rnec>

<app-form-popup-rnec-with-customer-info
  #formPopupRnecWithCustomerInfo
  [popUpRnecWithCustomerInfo]="popUpRnecWithCustomerInfo"
  (closePopupAndRedirectEvent)="closePopupRnecWithCustomerInfo($event)"
>
</app-form-popup-rnec-with-customer-info>

<app-form-popup-message-box
  #formPopupMessageBox
  (closePopupEvent)="closeMessageBox()"
></app-form-popup-message-box>

<app-form-popup-validation-query-decrim
  #formPopupValidationQueryDecrim
  [showPopUpValidationQueryDecrim]="showPopUpValidationQueryDecrim"
  [dataValidationQueryDecrim]="dataValidationQueryDecrim"
  (closePopupAndRedirectEvent)="closePopupValidationQueryDecrim($event)"
></app-form-popup-validation-query-decrim>
