import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-overflow-error",
  template: "<app-one-column></app-one-column>",
})
export class OverflowErrorComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(
      ["onecolumn", { outlets: { "part-center": ["overflowerrorview"] } }],
      { skipLocationChange: true },
    );
  }
}
