import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-content-header",
  templateUrl: "./content-header.component.html",
  styleUrls: ["./content-header.component.scss"],
})
export class ContentHeaderComponent implements OnInit {
  ngOnInit(): void {
    return;
  }
}
