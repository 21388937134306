import { Component, OnInit } from "@angular/core";

import { FreshChatEnum } from "../../../core/enums/fresh-chat.enum";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { FreshChatService } from "../../../services/local/fresh-chat.service";
import { LoadFileScriptService } from "../../../services/local/load-file-script.service";

@Component({
  selector: "app-fresh-chat",
  template: "",
})
export class FreshChatComponent implements OnInit {
  advisor: IAdvisor;

  constructor(
    private advisorService: AdvisorService,
    private loadFileScriptService: LoadFileScriptService,
    private freshChatService: FreshChatService,
  ) {}

  ngOnInit() {
    this.advisor = this.advisorService.getAdvisor();
    let sellerId = String(this.advisor.sellerIdNumber);
    this.getRestoredId(sellerId);
  }

  getRestoredId(externalId: string) {
    let restoreId = this.advisor.restoreIdFreshChat
      ? this.advisor.restoreIdFreshChat
      : null;
    if (!restoreId) {
      this.freshChatService.getIdHistory(externalId).subscribe(
        (response) => {
          if (response && response.responseCode == "200") {
            restoreId =
              response.data.restoreId && response.data.restoreId.length > 1
                ? response.data.restoreId
                : null;
            this.advisorService.setRestoreId(restoreId);
          }
          this.loadFreshChat(restoreId);
        },
        () => {
          this.loadFreshChat(restoreId);
        },
      );
    } else {
      this.loadFreshChat(restoreId);
    }
  }

  setRestoreId(externalId: string, restoreId: string) {
    this.advisorService.setRestoreId(restoreId);
    this.freshChatService.saveIdHistory(externalId, restoreId).subscribe();
  }

  async loadFreshChat(restoreId: string) {
    const _this = this;
    const fcButtonElement = document.getElementById("custom_fc_button");
    if (
      fcButtonElement.style.visibility === "" ||
      fcButtonElement.style.visibility === "hidden"
    ) {
      const fcWidgetElement = document.getElementById("open_fc_widget");
      const urlScriptLib = FreshChatEnum.URL_LIBRARY_API;
      const externalId = this.advisor.sellerIdNumber
        ? String(this.advisor.sellerIdNumber)
        : null;
      const sellerNameUser = this.advisor.sellerName
        ? this.advisor.sellerName
        : null;
      await this.loadFileScriptService.loadFileScriptSync(urlScriptLib);

      if (fcButtonElement && fcWidgetElement) {
        fcButtonElement.style.visibility = "visible";
        fcWidgetElement.style.visibility = "visible";
        fcWidgetElement.addEventListener("click", function() {
          document.getElementById("fc_frame").style.visibility = "visible";
          window["fcWidget"].open();
        });
        window["fcWidget"].init({
          token: FreshChatEnum.TOKEN_API,
          host: FreshChatEnum.HOST_API,
          externalId,
          restoreId,
          locale: "es-LA",
          config: {
            headerProperty: {
              hideChatButton: true,
              backgroundColor: "#009a44",
              fontName: "Rubik",
            },
            content: {
              headers: {
                chat: "Banco Popular",
                chat_help: "Somos el soporte de Libranzas",
                channel_response: {
                  offline: "En el momento no podemos responderte",
                  online: "Escríbenos en qué podemos ayudarte",
                },
              },
            },
          },
        });

        window["fcWidget"].on("widget:closed", function() {
          document.getElementById("fc_frame").style.visibility = "hidden";
          fcWidgetElement.style.visibility = "visible";
        });
        window["fcWidget"].on("widget:opened", function(resp) {
          fcWidgetElement.style.visibility = "hidden";
        });

        window["fcWidget"].user.get(function(resp) {
          if (resp && resp.status !== 200 && sellerNameUser) {
            window["fcWidget"].user.setProperties({
              firstName: sellerNameUser,
            });

            window["fcWidget"].on("user:created", function(resp) {
              if (
                resp &&
                resp.status === 200 &&
                resp.data &&
                resp.data.restoreId
              ) {
                _this.setRestoreId(externalId, resp.data.restoreId);
              }
            });
          }
        });
      }
    }
  }
}
