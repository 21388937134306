import { IMessageLoan } from "../../../core/interfaces/message-loan.interface";
import { IMessageLoanStrategy } from "./message-loan-strategy.interface";

import { ILoanStatus } from "../../../core/interfaces/loan-status.interface";

export class MessageLoanOperationWithoutLogic extends IMessageLoanStrategy {
  constructor(dataLoanStatus: ILoanStatus) {
    super(dataLoanStatus);
  }

  getMessageId(): string {
    return this.dataLoanStatus.loanRenewValidation;
  }

  getMessageLoan(): IMessageLoan {
    return super.getMessageLoan();
  }
}
