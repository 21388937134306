<div *ngIf="showPopupUploadPaymentReceipt">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div
      class="close-button"
      *ngIf="isFormPopupUploadPaymentReceiptConfiguration(message.messageId)"
    >
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopup()"
      />
    </div>

    <div
      class="w-100 h-135-px d-flex align-items-center justify-content-center"
      *ngIf="message.icon !== ''"
    >
      <div>
        <img [src]="message.icon" alt="Icon Upload Payment" />
      </div>
    </div>

    <div class="h-75-px" *ngIf="message.icon === ''"></div>

    <div class="w-100 d-flex align-items-end justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-list-grouped-message
          *ngIf="message.groupedMessages"
          [groupedMessages]="message.groupedMessages"
        ></adl-list-grouped-message>
        <adl-text-description
          *ngIf="!message.groupedMessages"
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>

    <ng-container
      *ngIf="isFormPopupUploadPaymentReceiptConfiguration(message.messageId)"
    >
      <div class="w-100 d-flex align-items-center justify-content-center">
        <div class="w-85">
          <div class="d-flex align-items-center">
            <adl-text-title [text]="titlePayer" [level]="6"> </adl-text-title>
          </div>

          <div *ngIf="!isPreloadedPayrollCheck">
            <div class="h-20-px"></div>

            <adl-form-file-input-drag
              #fileInputDragPayrollcheck
              [fieldControl]="formGroup.controls['filePaymentReceipt']"
              [fileInputId]="'filePaymentReceipt'"
              [notificationResponse]="
                notifications.get(documetTapEnum.PAYROLLCHECK)
              "
              [clickOnCloseNotification]="true"
              [observable]="observableUploadFile"
              [parentForm]="formGroup"
              [progress]="progress.get(documetTapEnum.PAYROLLCHECK)"
              [title]="'Desprendible de pago del cliente'"
              [titleCenter]="true"
              (fileInputCompleteEvent)="
                preSendTap(
                  fileInputDragPayrollcheck,
                  documetTapEnum.PAYROLLCHECK
                )
              "
              (fileUploadEvent)="
                uploadFile(
                  $event,
                  fileInputDragPayrollcheck,
                  documetTapEnum.PAYROLLCHECK
                )
              "
            ></adl-form-file-input-drag>
          </div>

          <ng-container *ngIf="!isPreloadedLaborCertification">
            <div class="h-20-px"></div>
            <hr *ngIf="!isPreloadedPayrollCheck" />
            <div class="h-20-px"></div>

            <adl-form-file-input-drag
              #fileInputDragCertified
              [fieldControl]="formGroup.controls['fileCertified']"
              [fileInputId]="'fileCertified'"
              [notificationResponse]="
                notifications.get(documetTapEnum.LABOR_CERTIFICATION)
              "
              [observable]="observableUploadFile"
              [parentForm]="formGroup"
              [progress]="progress.get(documetTapEnum.LABOR_CERTIFICATION)"
              [title]="'Certificado laboral del cliente'"
              [titleCenter]="true"
              [clickOnCloseNotification]="true"
              (fileInputCompleteEvent)="
                preSendTap(
                  fileInputDragCertified,
                  documetTapEnum.LABOR_CERTIFICATION
                )
              "
              (fileUploadEvent)="
                uploadFile(
                  $event,
                  fileInputDragCertified,
                  documetTapEnum.LABOR_CERTIFICATION
                )
              "
            ></adl-form-file-input-drag>
          </ng-container>

          <div
            class="d-flex justify-content-center pb-0 pt-2"
            *ngIf="canShowPreviewDocumentButton()"
          >
            <adl-button
              id="adl-button_button-preview"
              [cssClass]="'w-100 buttonTextMedium buttonTextOrange'"
              [disabled]="formGroup.value.filePaymentReceipt === ''"
              [icon]="'../../assets/images/icon_desprendible.svg'"
              [mandatory]="false"
              [parentForm]="formGroup"
              text="Visualizar desprendible"
              (clickEvent)="openPreviewPopup()"
            ></adl-button>
          </div>

          <div class="mt-2 text-center" *ngIf="canShowDoublePensionButton()">
            <adl-text-label
              [cssClass]="'font-weight-normal'"
              style="margin-top: 1rem;"
              [text]="'¿Tu cliente cuenta con doble pension?'"
            >
            </adl-text-label>

            <div class="d-flex justify-content-center w-95">
              <adl-radio-group
                [parentForm]="formGroup"
                [nameRadioGroup]="'doublePensionRadioGroup'"
                [radioButtonData]="radioButtonData"
              ></adl-radio-group>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      class="w-100 h-110-px d-flex align-items-center justify-content-center"
    >
      <div class="w-85">
        <adl-button
          id="adl-button_button-close"
          [cssClass]="'w-100 buttonTextMedium'"
          [loading]="loadingUploadButton"
          [mandatory]="true"
          [parentForm]="formGroup"
          [text]="message.captionButton"
          (clickEvent)="buttonProcess()"
        ></adl-button>
      </div>
    </div>
  </form>
</div>
<div>
  <adl-popup-file-preview
    [showPopup]="showPreviewPopup"
    [title]="'Visualización del desprendible'"
    [fileUrl]="getUrlFile()"
    [type]="httpMimeTypeConstantsEnum.APPLICATION_PDF"
    (closeEvent)="onClosePreviewPopup()"
  >
  </adl-popup-file-preview>

  <adl-popup-container
    *ngIf="showPayrollcheckCapture"
    [title]="message.title"
    [textButtonOK]="contentRef.buttonText"
    [showCancelButton]="false"
    (okButton)="onCaptureCorrect()"
  >
    <app-form-popup-payroll-check-capture
      #contentRef
    ></app-form-popup-payroll-check-capture>
  </adl-popup-container>
</div>
