<div
  id="main"
  class="d-flex flex-column align-items-center justify-content-center"
>
  <div class="row">
    <div class="col-12 d-flex align-items-end p-b-9 p-b-sm-6">
      <div class="text-tittle">
        <adl-text-title
          [text]="'Elige la mejor opción para tu cliente'"
          [level]="2"
        ></adl-text-title>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 d-flex justify-content-center">
      <div
        id="app-form-customer-decision_new-payroll-loan"
        class="decision-button bt-left text-center pt-4"
        (click)="goToDecision(customerDecisionTypeEnum.NEW_LOAN)"
      >
        <img src="assets/images/decision/new-payroll-loan.png" alt="" />
        <p>Nueva Libranza</p>
        <img src="assets/images/decision/arrow-right-circle.png" alt="" />
      </div>
    </div>
    <div class="col-12 col-sm-6 d-flex justify-content-center">
      <div
        id="app-form-customer-decision_extend-payroll-loan"
        class="decision-button bt-right text-center pt-4"
        (click)="goToDecision(customerDecisionTypeEnum.EXTEND_LOAN)"
      >
        <img src="assets/images/decision/extend-payroll-loan.svg" alt="" />
        <p>Ampliar Libranza</p>
        <img src="assets/images/decision/arrow-right-circle.png" alt="" />
      </div>
    </div>
  </div>
</div>
