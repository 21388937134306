import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  FormFileInputDragComponent,
  HttpMimeTypeConstantsEnum,
  IRadioButtonData,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable } from "rxjs";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { ChannelTypeEnum } from "../../../core/enums/channel-type.enum";
import { DocumentTypeTapEnum } from "../../../core/enums/document-type-tap.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { PayrollCheckCaptureButtonTextEnum } from "../../../core/enums/payroll-check-capture-button-text.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

import { IMessage } from "../../../core/interfaces/message.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { ClientEducationalService } from "../../../services/local/client-educational.service";
import { DoublePensionService } from "../../../services/local/double-pension.service";
import { LoanService } from "../../../services/local/loan.service";
import { MessageService } from "../../../services/local/message.service";
import { PayrollFileErrorService } from "../../../services/local/payroll-file-error.service";
import { PayrollService } from "../../../services/local/payroll.service";
import { PayrollUploadFileService } from "../../../services/local/payroll-upload-file.service";
import { RedirectService } from "../../../services/local/redirect.service";
import { SalaryLoanActionFactoryService } from "../../../services/local/factory/salary-loan-action-factory.service";
import { ToggleService } from "../../../services/local/toggle.service";

import { FormPopupPayrollCheckCaptureComponent } from "../form-popup-payroll-check-capture/form-popup-payroll-check-capture.component";

@Component({
  selector: "app-form-popup-upload-payment-receipt",
  templateUrl: "./form-popup-upload-payment-receipt.component.html",
})
export class FormPopupUploadPaymentReceiptComponent implements OnInit {
  @ViewChild("contentRef", { static: false })
  popupCapture: FormPopupPayrollCheckCaptureComponent;
  @ViewChild("fileInputDragCertified", { static: false })
  fileInputDragCertified: FormFileInputDragComponent;
  @ViewChild("fileInputDragPayrollcheck", { static: false })
  fileInputDragPayrollcheck: FormFileInputDragComponent;

  @Input("message")
  set setMessage(message: IMessage) {
    this.message = message;
  }

  @Input() isFromNewLoanComponent: boolean = false;

  @Input("showPopupUploadPaymentReceipt")
  set setShowPopupUploadPaymentReceipt(showPopupUploadPaymentReceipt: boolean) {
    this.showPopupUploadPaymentReceipt = showPopupUploadPaymentReceipt;
  }

  @Output() resolveUploadDocumentsEvent = new EventEmitter<string>();

  set httpMimeTypeConstantsEnum(httpMimeTypeConstantsEnum) {}
  get httpMimeTypeConstantsEnum() {
    return HttpMimeTypeConstantsEnum;
  }

  isClientEducational: boolean = false;
  isPreloadedLaborCertification: boolean = true;
  isPreloadedPayrollCheck: boolean;
  loadingUploadButton: boolean = false;
  showPayrollcheckCapture = false;
  showPopupUploadPaymentReceipt: boolean;
  showPreviewPopup = false;
  titlePayer: string;

  captureDataIncorrect: string = ApplicationConstantsEnum.CHARACTER_EMPTY;
  documetTapEnum = DocumentTypeTapEnum;
  documentChannel = new Map([
    [
      DocumentTypeTapEnum.LABOR_CERTIFICATION,
      ChannelTypeEnum.LABOR_CERTIFICATION,
    ],
    [DocumentTypeTapEnum.PAYROLLCHECK, ChannelTypeEnum.PAYROLLCHECK],
  ]);
  formGroup: UntypedFormGroup;
  isActivePayrollCheckOptimization = false;
  message: IMessage;
  notifications = new Map();
  observableUploadFile: Observable<any>;
  progress = new Map();
  radioButtonData: IRadioButtonData[];
  isActiveExtraction = false;

  constructor(
    private advisorService: AdvisorService,
    private clientEducationalService: ClientEducationalService,
    private doublePensionService: DoublePensionService,
    private loanService: LoanService,
    private messageService: MessageService,
    private payrollErrorService: PayrollFileErrorService,
    private payrollService: PayrollService,
    private payrollUploadFileService: PayrollUploadFileService,
    private redirectService: RedirectService,
    private router: Router,
    private toggleService: ToggleService,
    private factory: SalaryLoanActionFactoryService,
  ) {
    this.radioButtonData = this.doublePensionService.getInformationDoublePension();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});
    this.isClientEducational = this.clientEducationalService.getToggleEducational();

    this.isActivePayrollCheckOptimization = this.toggleService.getToggleEnabledById(
      ToggleEnum.PAYROLL_CHECK_OPTIMIZATION,
    );
    this.isActiveExtraction = this.toggleService.getToggleEnabledById(
      ToggleEnum.EXTRACTION_AND_INTERPRETER,
    );
  }

  buttonProcess() {
    if (this.message.actionButton === MessageActionEnum.CLOSE_MESSAGE) {
      this.closePopup();
    } else if (this.message.actionButton === MessageActionEnum.GO_DECISION) {
      this.router.navigate(["decision"]);
    } else if (this.message.actionButton === MessageActionEnum.GO_INIT) {
      this.router.navigate(["loginadvisor"]);
    } else if (this.message.actionButton === MessageActionEnum.GO_PEP) {
      if (
        this.loanService.getPlatform() != null &&
        this.loanService.getPlatform() === "Sygnus"
      ) {
        this.setShowPopupUploadPaymentReceipt = false;
        this.factory.previousAction.salaryLoanResolve(
          MessageActionEnum.PROCESS_SYGNUS_WITH_PAYROLL,
        );
      } else {
        this.redirectService.redirectToFrontOldToPep();
      }
    } else if (
      this.message.actionButton === MessageActionEnum.RETRY_UPLOAD_FILE
    ) {
      this.initForm();
    } else if (
      this.message.actionButton === MessageActionEnum.RETRY_SIMULATION
    ) {
      this.resolveUploadDocumentsEvent.emit(
        UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS,
      );
    } else if (this.message.actionButton === MessageActionEnum.UPLOAD_FILE) {
      this.fileProcess();
    }
  }

  canShowPreviewDocumentButton() {
    return (
      (!this.clientEducationalService.isNewLoanDecision() ||
        this.clientEducationalService.isPensionerSector()) &&
      this.isActivePayrollCheckOptimization
    );
  }

  canShowDoublePensionButton() {
    return (
      (this.loanService.getPreloan().payerUniqueName ===
        ApplicationConstantsEnum.COLPENSIONES_PAYER_NAME &&
        this.isActivePayrollCheckOptimization &&
        !this.advisorService.getAdvisor().isInPersonPhone) ||
      (this.loanService.getPreloan().payerUniqueName ===
        ApplicationConstantsEnum.COLPENSIONES_PAYER_NAME &&
        this.isActivePayrollCheckOptimization &&
        this.doublePensionService.getDoublePension() === "" &&
        (this.isActiveExtraction ||
          this.advisorService.getAdvisor().isInPersonPhone))
    );
  }

  clearValidatorsControl(controlName: string) {
    this.formGroup.get(controlName).clearValidators();
    this.formGroup.get(controlName).updateValueAndValidity();
  }

  clearAllValidatorsControl() {
    if (this.clientEducationalService.isEducationalSector()) {
      this.clearValidatorsControl("fileCertified");
    }
    this.clearValidatorsControl("filePaymentReceipt");
  }

  closePopup() {
    this.resolveUploadDocumentsEvent.emit(
      UploadDocumentsStatesEnum.DOCUMENTS_LOAD_EMPTY,
    );
  }

  fileProcess() {
    if (this.canShowDoublePensionButton()) {
      this.doublePensionService.setDoublePension(this.formGroup);
    }
    this.resolveUploadDocumentsEvent.emit(
      UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS,
    );
  }

  getUrlFile() {
    return this.payrollService.getFile();
  }

  initForm() {
    this.initPreloadDocuments();

    if (!this.isPreloadedPayrollCheck) {
      this.formGroup = new UntypedFormGroup({
        filePaymentReceipt: new UntypedFormControl("", Validators.required),
      });
    }

    if (!this.isPreloadedLaborCertification) {
      this.formGroup.addControl(
        "fileCertified",
        new UntypedFormControl("", Validators.required),
      );
    }

    this.setMessage = this.messageService.getMessageById(
      MessageEnum.FORM_POPUP_UPLOAD_PAYMENT_RECEIPT_CONFIGURATION,
    );

    this.titlePayer = this.loanService.getPayerNameAndSector();
  }

  isFormPopupUploadPaymentReceiptConfiguration(messageId: MessageEnum) {
    return (
      messageId === MessageEnum.FORM_POPUP_UPLOAD_PAYMENT_RECEIPT_CONFIGURATION
    );
  }

  openPreviewPopup() {
    this.showPreviewPopup = true;
  }

  onClosePreviewPopup() {
    this.showPreviewPopup = false;
  }

  onCaptureCorrect() {
    this.showPayrollcheckCapture = false;
    this.captureDataIncorrect = this.popupCapture.getIncorrectValuesString();
    if (
      this.popupCapture.buttonText ===
      PayrollCheckCaptureButtonTextEnum.CORRECT_CAPTURE
    ) {
      this.fileProcess();
    } else {
      this.resolveUploadDocumentsEvent.emit(
        UploadDocumentsStatesEnum.DOCUMENT_CAPTURE_DATA,
      );
    }
  }

  preSendTap(
    fileInputDrag: FormFileInputDragComponent,
    payrollFileType: DocumentTypeTapEnum,
  ) {
    if (fileInputDrag.fileName) {
      const notificationResponse = this.clientEducationalService.notificationDocumentsUpload(
        this.payrollUploadFileService.file,
        fileInputDrag,
        payrollFileType,
      );

      this.notifications.set(payrollFileType, notificationResponse);
    }

    this.loadingUploadButton = false;
  }

  resetProgress() {
    this.progress.set(DocumentTypeTapEnum.LABOR_CERTIFICATION, 0);
    this.progress.set(DocumentTypeTapEnum.PAYROLLCHECK, 0);
  }

  show() {
    this.initForm();
    this.setShowPopupUploadPaymentReceipt = true;
  }

  uploadFile(
    file: File,
    fileInputDrag: FormFileInputDragComponent,
    payrollFileType: DocumentTypeTapEnum,
  ) {
    const channel = this.documentChannel.get(payrollFileType);
    try {
      this.loadingUploadButton = true;
      this.payrollUploadFileService
        .uploadWithPDFValidations(file, channel)
        .subscribe((progress) => {
          this.progress.set(payrollFileType, progress);
        });
    } catch (error) {
      if (error.message == "FileExtensionError") {
        this.notifications.set(
          payrollFileType,
          this.payrollErrorService.fileErrorExtension(fileInputDrag),
        );
      } else if (error.message == "FileSizeError") {
        this.notifications.set(
          payrollFileType,
          this.payrollErrorService.fileErrorSize(fileInputDrag),
        );
      }
    }
  }

  private initPreloadDocuments() {
    this.clientEducationalService.getResetUplodedDocuments();
    this.isPreloadedPayrollCheck = this.isPreloadedDocument(
      DocumentTypeTapEnum.PAYROLLCHECK,
    );

    if (this.clientEducationalService.isEducationalSector()) {
      this.isPreloadedLaborCertification = this.isPreloadedDocument(
        DocumentTypeTapEnum.LABOR_CERTIFICATION,
      );
    } else {
      this.isPreloadedLaborCertification = true;
    }
  }

  private isPreloadedDocument(documentTypeTapEnum: string) {
    const listFilePreloaded = this.clientEducationalService.getListFileDocuments();

    if (listFilePreloaded.length > ApplicationConstantsEnum.EMPTY_ARRAY) {
      const documentPreloaded = listFilePreloaded.find((list: any) => {
        return documentTypeTapEnum === list.document.onbaseCode;
      });

      return documentPreloaded.preloaded;
    }
    return false;
  }
}
