import { TableColumnTypeEnum } from "../../core/enums/table-column-type.enum";
import { IMapTableFabric } from "./map-table-fabric.interface";

export class NotFoundDocumentsMap implements IMapTableFabric {
  getMapTableData(): any[] {
    return [
      {
        field: "notification",
        title: "",
        columnType: TableColumnTypeEnum.TEXT,
      },
    ];
  }
}
