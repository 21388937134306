<div class="row justify-content-center w-100">
  <div class="col-8 header-content">
    <img
      class="image-header"
      src="./assets/images/logo-banco.svg"
      alt="Ir al inicio"
    />
    <label class="text-header">| Libranzas</label>
  </div>
  <div class="col-4 header-close-session">
    <app-close-session class="w-100"></app-close-session>
  </div>
</div>
