import { Injectable } from "@angular/core";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICourse } from "../../core/interfaces/course.interface";
import { TrainingRemoteService } from "../remote/training-remote.service";
import { AdvisorService } from "./advisor.service";

@Injectable({
  providedIn: "root",
})
export class TrainingService {
  courses: ICourse[];
  advisor: IAdvisor;

  constructor(
    private advisorService: AdvisorService,
    private trainingRemoteService: TrainingRemoteService,
  ) {}

  async searchAdvisorCourses(sellerIdNumber: number) {
    await this.trainingRemoteService
      .asyncSearchCourses(this.advisorService.getAdvisor(), sellerIdNumber)
      .then((res: any[]) => {
        this.setCourses(res);
      });
  }

  savePassedCourse(courseId: number) {
    const advisor = this.advisorService.getAdvisor();

    if (!advisor.administrativeOffice) {
      advisor.administrativeOffice = {
        code: null,
        name: null,
      };
    }

    this.trainingRemoteService
      .savePassedCourse(advisor, {
        idVendor: advisor.sellerIdNumber,
        idCourse: courseId,
        salesChannel: advisor.salesChannelName,
        idRegion: advisor.office.idRegion,
        region: advisor.office.nameRegion,
        idZone: advisor.office.idZone,
        zone: advisor.office.nameZone,
        idOffice: advisor.office.idoffice,
        office: advisor.office.name_office,
        idAdministrativeOffice: advisor.administrativeOffice.code,
        administrativeOffice: advisor.administrativeOffice.name,
      })
      .subscribe(
        // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
        () => {},
        (error) => this.savePassedCourseError(error),
      );
  }

  savePassedCourseError(error: any) {
    // eslint-disable-next-line no-console
    console.log(
      "TrainingService - setPassedCourse, Error: " + JSON.stringify(error),
    );
  }

  public getCourses(): ICourse[] {
    return this.courses;
  }

  public setCourses(courses: ICourse[]) {
    this.courses = courses;
  }
}
