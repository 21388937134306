import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IRadioButtonData } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";

import { IPep } from "../../../core/interfaces/pep.interface";
import { IStepNumber } from "../../../core/interfaces/step-number.interface";

import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { PepService } from "../../../services/local/pep.service";

@Component({
  selector: "app-form-pep",
  templateUrl: "./form-pep.component.html",
  styleUrls: ["./form-pep.component.scss"],
})
export class FormPepComponent implements OnInit {
  captionButton: string;
  loadingButton: boolean;
  title: string;

  formGroup: UntypedFormGroup;
  infoPep: IPep[];
  radioButtonData: IRadioButtonData[];
  steps: IStepNumber;

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private pepService: PepService,
  ) {}

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.PEP_INIT_PAGE,
    );

    this.initForm();
  }

  continue() {
    // Ejemplo de como se debe obtener un valor del radio button
    // this.formGroup.controls[this.infoPep[0].questionName].value.radioButtonValue,

    return;
  }

  private getInformationPep() {
    this.infoPep = this.pepService.getInformationPep();
  }

  private initForm() {
    this.radioButtonData = [
      {
        label: "Si",
        value: "Si",
      },
      {
        label: "No",
        value: "No",
      },
    ];

    this.steps = {
      numberSteps: 6,
      stepActive: 0,
    };

    this.captionButton = "Continuar";
    this.title =
      "Valida estos datos con el cliente para continuar con la aprobación";

    this.formGroup = new UntypedFormGroup({});

    this.getInformationPep();
  }
}
