<div *ngIf="popupStrongAuthentication">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopup()"
      />
    </div>

    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img [src]="icon" alt="Icon Error" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-description [text]="description"></adl-text-description>
      </div>
    </div>
    <ng-container *ngFor="let item of menu">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <div class="w-85">
          <div
            id="{{ item.id }}"
            class="decision-button bt-right text-center"
            (click)="goToDecision(item)"
          >
            <img src="assets/images/decision/{{ item.image }}.svg" alt="" />
            <p>{{ item.label }}</p>
            <img src="assets/images/decision/arrow-right-circle.png" alt="" />
          </div>
        </div>
      </div>
    </ng-container>

    <div class="h-20-px"></div>
  </form>
</div>
