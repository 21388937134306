import { Injectable } from "@angular/core";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { MessageEnum } from "../../core/enums/message.enum";
import { ModalityTypeEnum } from "../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { CustomerService } from "./customer.service";
import { LoanService } from "./loan.service";
import { MessageService } from "./message.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { ToggleService } from "./toggle.service";

import { PayerRemoteService } from "../remote/payer-remote.service";

@Injectable({
  providedIn: "root",
})
export class PayerService {
  constructor(
    private customerService: CustomerService,
    private loanService: LoanService,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private payerRemoteService: PayerRemoteService,
    private toggleService: ToggleService,
  ) {}

  generatePlatformOffer(
    bindingNumber: string,
    payrollCheckProcessId: string,
  ): Observable<any> {
    const customer = this.customerService.getCustomer();
    const preloan = this.loanService.getPreloan();

    return this.payerRemoteService
      .generatePlatformOffer({
        bindingNumber: bindingNumber,
        documentNumber: customer.documentId.toString(),
        documentType: customer.documentType,
        obligationId: preloan.obligationId,
        payerName: preloan.payerUniqueName,
        payrollCheckProcessId: payrollCheckProcessId,
      })
      .pipe(
        map((dataPlatformOffer: any) => {
          preloan.transactionId = dataPlatformOffer.id;

          if (
            dataPlatformOffer.modalityType === ModalityTypeEnum.LOYALTY &&
            this.toggleService.getToggleEnabledById(
              ToggleEnum.USE_FOOTPRINTS_NEW_SERVICE_TOGGLE,
            )
          ) {
            preloan.modalityType = ModalityTypeEnum.LOYALTY;
          }
        }),
        catchError((errorPlatformOffer: any) => {
          let errorPlatformOfferCode: any;
          if (errorPlatformOffer.error && errorPlatformOffer.error.code) {
            errorPlatformOfferCode = this.messageService.getMessageById(
              errorPlatformOffer.error.code +
                "_" +
                ApplicationConstantsEnum.AFTER_PROCESS_SYGNUS,
            );
          }

          if (
            !errorPlatformOfferCode ||
            Object.keys(errorPlatformOfferCode).length === 0
          ) {
            errorPlatformOfferCode = undefined;
            this.overflowErrorsService.setOverflowError(
              OverflowErrorsEnum.TECHNICAL_ERROR,
            );
          }

          return throwError(errorPlatformOfferCode);
        }),
      );
  }

  getBindingsPlatform(): Observable<any> {
    return this.payerRemoteService
      .getBindingsPlatform({
        documentNumber: this.customerService
          .getCustomer()
          .documentId.toString(),
        documentType: this.customerService.getCustomer().documentType,
        payerName: this.loanService.getPayerUniqueName(),
        cnc: true,
      })
      .pipe(
        map((dataBindings: any) => {
          const listBindings: IListSelect[] = [];

          dataBindings.forEach((data: any) => {
            listBindings.push({
              value: data.bindingNumber,
              text: data.position + " - " + data.bindingNumber,
            });
          });

          return listBindings;
        }),
        catchError((errorBindings: any) => {
          let errorBindingsCode: any;
          if (errorBindings.code) {
            errorBindingsCode = this.messageService.getMessageById(
              this.getErrorCodeInGetBindings(errorBindings.code),
            );
          }

          if (
            !errorBindingsCode ||
            Object.keys(errorBindingsCode).length === 0
          ) {
            errorBindingsCode = undefined;
            this.overflowErrorsService.setOverflowError(
              OverflowErrorsEnum.TECHNICAL_ERROR,
            );
          }

          return throwError(errorBindingsCode);
        }),
      );
  }

  private getErrorCodeInGetBindings(errorCode: any) {
    return errorCode === ApplicationConstantsEnum.PAYER_PLATFORM_07 &&
      this.loanService.getLoansNumber() > 1
      ? MessageEnum.PayerPlatform07_ManyPayer
      : errorCode === ApplicationConstantsEnum.PAYER_PLATFORM_07
      ? MessageEnum.PayerPlatform07_OnePayer
      : errorCode;
  }
}
