<div [ngClass]="cssClass" class="box-authentication-states-info ">
  <adl-text-title
    class="state-title-authentication-states"
    [text]="authenticationState.payerName"
    [level]="5"
    id="adl-text-title"
  ></adl-text-title>
  <adl-text-description
    [cssClass]="getCssClass('state-label-authentication-states-')"
    [text]="getLabel()"
    id="app-text-name"
  ></adl-text-description>
  <div *ngIf="isError()" >
    <div *ngFor="let messageValidation of getDescriptions(); let i = index;
    let isOdd = odd">
      <div [class.state-description-authentication-states-border]="isOdd"></div>
        <adl-text-description
        [cssClass]="getCssClass('state-description-authentication-states-')"
        [hidden]="!isError()"
        [text]="messageValidation"
        id="adl-text-description"
      ></adl-text-description>
      </div>
  </div>
</div>
