import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CognitoService } from "./cognito.service";

import { FreshChatRemoteService } from "../remote/fresh-chat-remote.service";

@Injectable({
  providedIn: "root",
})
export class FreshChatService {
  constructor(
    private freshChatRemoteService: FreshChatRemoteService,
    private cognitoService: CognitoService,
  ) {}

  getIdHistory(externalId: string): Observable<any> {
    return this.freshChatRemoteService.getIdHistory(
      externalId,
      this.cognitoService.getTokenNew(),
    );
  }

  saveIdHistory(externalId: string, restoreId: string): Observable<any> {
    return this.freshChatRemoteService.saveIdHistory(
      externalId,
      restoreId,
      this.cognitoService.getTokenNew(),
    );
  }
}
