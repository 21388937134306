import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FederatedAuthRemoteService {
  constructor(private httpClient: HttpClient) {}

  public getTokenDetailsFromCognito(
    callbackCode: string,
    clientId: string,
  ): Observable<any> {
    const details = {
      grant_type: "authorization_code",
      code: callbackCode,
      client_id: clientId,
      redirect_uri: environment.federatedAuthRedirectUrl,
    };

    const formBody = Object.keys(details)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`,
      )
      .join("&");

    return this.httpClient.post<any>(
      `${environment.federatedAuthUrl}/oauth2/token`,
      formBody,
      {
        responseType: "json",
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      },
    );
  }

  getFrontendParams(): Observable<any> {
    return this.httpClient.get(
      `${environment.federatedAuthParamsServerUrl}frontend/params`,
    );
  }
}
