<div class="background-gray container-fluid min-vh-100 ">
  <div class="header">
    <app-content-header></app-content-header>
  </div>
  <div class="row min-vh-100">
    <div class="col-12 min-vh-100 w-100 p-b-97-px p-t-97-px">
      <router-outlet name="part-center"></router-outlet>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-xl-4 col-md-12 footer-left-content"></div>
      <div class="col-xl-8 col-md-12 footer-right-content"></div>
    </div>
  </div>
</div>
