import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { InitialNavPillsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { RnecCodeDirectiveValidate } from "../../../core/directives/rnec-code.directive";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";

import { AdvisorService } from "./../../../services/local/advisor.service";
import { CustomerService } from "../../../services/local/customer.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";
import { RnecService } from "../../../services/local/rnec.service";

@Component({
  selector: "app-form-popup-rnec-with-customer-info",
  templateUrl: "./form-popup-rnec-with-customer-info.component.html",
})
export class FormPopupRnecWithCustomerInfoComponent implements OnInit {
  @Input("popUpRnecWithCustomerInfo")
  set setPopUpRnecWithCustomerInfo(popUpRnecWithCustomerInfo: boolean) {
    this.popUpRnecWithCustomerInfo = popUpRnecWithCustomerInfo;
  }

  @Input("urlNavigate")
  set setUrlNavigate(urlNavigate: string) {
    this.urlNavigate = urlNavigate;
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<any>();

  captionButton: string;
  description: string;
  listDocumentType: any[];
  loadingButtonSendCodeRnec: boolean;
  popUpRnecWithCustomerInfo: boolean;
  title: string;
  urlNavigate: string;

  formGroup: UntypedFormGroup;
  customerInfo: any;

  constructor(
    private advisorService: AdvisorService,
    private rnecService: RnecService,
    private initialNavPillsService: InitialNavPillsService,
    private customerService: CustomerService,
  ) {
    this.title = "Ingresa el código Certihuella";
    this.description = `Ingresa el código de validación que recibiste por Certihuella y completa el tipo y número de documento de identidad`;
    this.captionButton = "Enviar Datos";
    this.customerInfo = this.customerService.getCustomerInfo();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.listDocumentType = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
    );

    let textRnecCode: UntypedFormControl;
    textRnecCode = new UntypedFormControl(
      "",
      RnecCodeDirectiveValidate(textRnecCode),
    );
    this.formGroup = new UntypedFormGroup({
      textRnecCode,
      textDocumentType: new UntypedFormControl(),
      valueDocumentType: new UntypedFormControl(),
      textDocumentCustomer: new UntypedFormControl("", Validators.required),
    });
  }

  closePopupAndRedirect(redirect: boolean) {
    if (!redirect) {
      this.closePopupAndRedirectEvent.emit();
    } else if (redirect && this.formGroup.valid) {
      this.loadingButtonSendCodeRnec = true;
      this.validateRnec();
    }
  }

  private validateRnec() {
    const formValue = this.formGroup.getRawValue();
    let salesModelValue: AdvisorDecisionTypeEnum =
      this.customerInfo?.salesModel === AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN
        ? AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN
        : AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL;

    this.rnecService
      .postValidateRnec({
        documentNumber: formValue.textDocumentCustomer,
        documentType: formValue.valueDocumentType.value,
        nut: formValue.textRnecCode,
        salesModel: salesModelValue,
      })
      .subscribe(
        (messageResponse: any) => {
          this.loadingButtonSendCodeRnec = false;
          this.closePopupAndRedirectEvent.emit(messageResponse);
        },
        (error: any) => {
          this.loadingButtonSendCodeRnec = false;
          this.closePopupAndRedirectEvent.emit(error);
        },
      );
  }
}
