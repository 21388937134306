import { Injectable } from "@angular/core";

import { ToggleService } from "./toggle.service";

import { RepricingRemoteService } from "../remote/repricing-remote.service";

import { ToggleEnum } from "../../core/enums/toggle.enum";

import { IRepricingChannels } from "../../core/interfaces/repricing.interface";
import { SearchRepricingResponse } from "../../core/interfaces/search-repricing-response.interface";

import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

export type account = { accountNumber: string; accountType: string };

/* Repricing V3*/
@Injectable({
  providedIn: "root",
})
export class RepricingService {
  private data: IRepricingChannels;
  private channels: IRepricingChannels[] = [];
  private searchResponse = { repricing: [] } as SearchRepricingResponse;

  constructor(
    private repricingRemoteService: RepricingRemoteService,
    private toggleService: ToggleService,
  ) {}

  existChannel(idChannel: number): boolean {
    this.data = this.findChannel(idChannel);
    return Boolean(this.data);
  }

  getCatalog(): Observable<IRepricingChannels[]> {
    return this.repricingRemoteService.getCatalog().pipe(
      map((catalog) => catalog["repricingChannels"]),
      tap((repricingChannels) => (this.channels = repricingChannels)),
    );
  }

  getRepricing(): IRepricingChannels {
    return this.data;
  }

  isEnableToggle(): boolean {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.SHORT_FLOW_REPRICING_V3,
    );
  }

  search(
    documentType: string,
    documentNumber: string,
  ): Observable<SearchRepricingResponse> {
    return this.repricingRemoteService
      .repricingSearch({
        documentType,
        documentNumber,
      })
      .pipe(
        tap((response) => {
          this.searchResponse = response;
          return response;
        }),
      );
  }

  get repricingSearchData(): SearchRepricingResponse {
    return this.searchResponse;
  }

  private findChannel(idChannel: number) {
    return this.channels.find((channel) => {
      return channel.channel == idChannel;
    });
  }
}
