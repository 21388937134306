export enum MessageActionEnum {
  CLIENT_HAVE_PENDING_ONBASE_SALES = "CLIENT_HAVE_PENDING_ONBASE_SALES",
  CHOOSE_OTHER_BINDING_SYGNUS = "CHOOSE_OTHER_BINDING_SYGNUS",
  CLOSE_MESSAGE = "CLOSE_MESSAGE",
  CONTINUE = "CONTINUE",
  INFO_UPLOAD_FILE_DECRIM = "INFO_UPLOAD_FILE_DECRIM",
  GO_DECISION = "GO_DECISION",
  GO_INIT = "GO_INIT",
  GO_PEP = "GO_PEP",
  PROCESS_SYGNUS = "PROCESS_SYGNUS",
  RETRY = "RETRY",
  RETRY_UPLOAD_FILE = "RETRY_UPLOAD_FILE",
  RETRY_SIMULATION = "RETRY_SIMULATION",
  SEND_SYGNUS_LINK = "SEND_SYGNUS_LINK",
  UPLOAD_FILE = "UPLOAD_FILE",
  PROCESS_SYGNUS_WITH_PAYROLL = "PROCESS_SYGNUS_WITH_PAYROLL",
}
