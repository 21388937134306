export enum ValidationQueryDecrimResponseStatusEnum {
  ASSIGNED = "ASSIGNED",
  ERROR = "ERROR",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export class ValidationQueryDecrimResponseStatus {
  static getLabel(
    validationQueryDecrimResponseStatusEnum: ValidationQueryDecrimResponseStatusEnum,
  ): string {
    switch (validationQueryDecrimResponseStatusEnum) {
      case ValidationQueryDecrimResponseStatusEnum.ASSIGNED: {
        return "Solicitud en trámite";
      }
      case ValidationQueryDecrimResponseStatusEnum.PENDING: {
        return "Pendiente por Autenticar";
      }
      case ValidationQueryDecrimResponseStatusEnum.ERROR: {
        return "Validación Incorrecta";
      }
      case ValidationQueryDecrimResponseStatusEnum.SUCCESS: {
        return "Autenticación exitosa";
      }
      default:
        return "";
    }
  }
}
