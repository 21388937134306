import { Injectable } from "@angular/core";

import { ICommonText } from "../../core/interfaces/common-text.interface";

import * as commonTextData from "../data/common-text-data.json";

@Injectable({
  providedIn: "root",
})
export class CommonTextsRemoteService {
  public searchCommonTextData(textId: string): ICommonText {
    return (commonTextData as any).default.find(
      (commonText: ICommonText) => commonText.textId === textId,
    );
  }
}
