<adl-popup-simple
  *ngIf="show"
  icon="assets/images/icons/bell-blue.svg"
  title="Tips para subir los desprendibles"
  textOkButton="Entendido"
  (clickOkButton)="show = false"
>
  <ng-container *ngFor="let item of tips">
    <article class="tip-item">
      <img src="{{ item.icon }}" [alt]="'Icon for ' + item.title" />
      <div class="tip-item-info">
        <h1>{{ item.title }}</h1>
        <p>{{ item.message }}</p>
      </div>
    </article>
  </ng-container>
</adl-popup-simple>