import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ICustomer } from "../../core/interfaces/customer.interface";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CashierOfficeRemoteService {
  constructor(private httpClient: HttpClient) {}

  searchSectors(): Observable<object> {
    return this.httpClient.get(
      environment.serverUrlSalaryLoans + "cashier-office/sectors",
    );
  }

  searchPayers(customer: ICustomer, sector: number): Observable<object> {
    return this.httpClient.get(
      environment.serverUrlSalaryLoans +
        `cashier-office?documentType=${customer.documentType}&documentId=${customer.documentId}&sector=${sector}`,
    );
  }
}
