<div class="animation-notification" *ngIf="isNotificationVisible">
  <adl-notification-message
    [isClose]="true"
    [title]="notificationTitle"
    severity="info"
    [message]="notificationDescription"
    (clickClose)="closeNotification()"
  ></adl-notification-message>
</div>

<div
  id="main"
  class="d-flex flex-column align-items-center justify-with-scroll"
>
  <div class="row">
    <div class="col-12 d-flex align-items-end p-b-9 p-b-sm-6">
      <div class="text-tittle">
        <adl-text-title
          [text]="'Libranzas Activas'"
          [level]="2"
        ></adl-text-title>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center w-100">
    <ng-container *ngFor="let customerLoan of customerLoans; let i = index">
      <div
        class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex justify-content-center"
      >
        <app-form-salary-loan
          [color]="i"
          [customerLoans]="customerLoan"
          (clickButtonEvent)="clickButtonPayer(customerLoan, $event)"
        ></app-form-salary-loan>
      </div>
    </ng-container>
  </div>
</div>

<adl-loading
  #loading
  [loadingCss]="loadingCss"
  [loadingDescription]="loadingDescription"
  [loadPercentage]="loadPercentage"
  [show]="showLoading"
></adl-loading>

<app-form-popup-message-box
  #formPopupMessageBox
  [message]="message"
  [messageEnum]="messageEnum"
  (closePopupEvent)="closeMessageBox($event)"
></app-form-popup-message-box>

<app-form-popup-sygnus-link
  #formPopupSygnusLink
  (resolveSygnusEvent)="salaryLoanResolve($event)"
></app-form-popup-sygnus-link>

<app-form-popup-upload-payment-receipt
  #formPopupUploadPaymentReceipt
  (resolveUploadDocumentsEvent)="salaryLoanResolve($event)"
></app-form-popup-upload-payment-receipt>

<app-popup-payrollcheck-upload-response
  #popupUploadResponse
  [message]="uploadResponse"
  attemptMax="{{ 3 }}"
  [attempt]="attemptsUpload"
  (clickButtonOk)="retryUploadDocument()"
></app-popup-payrollcheck-upload-response>

<app-popup-document-upload-tips *ngIf="!showLoading">
</app-popup-document-upload-tips>

<app-fresh-chat></app-fresh-chat>
