<form [formGroup]="formDataCapture" class="w-100 p-2">
  <div class="row">
    <div
      class="col-sm d-flex justify-content-start align-items-baseline item-mg"
      [ngClass]="
        getCountSpacesPayerName() ? 'box-large-data' : 'box-single-data'
      "
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Nombre: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.customerName"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
    </div>

    <div
      class="col-sm d-flex justify-content-start align-items-baseline item-mg"
      [ngClass]="isFullGridPayerName ? 'box-large-data' : 'box-single-data'"
    >
      <adl-text-description
        cssClass="text-md-left"
        text="N° documento: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.documentNumber"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
    </div>
    <div
      class="col-sm d-flex justify-content-start align-items-baseline item-mg"
      [ngClass]="isFullGridPayerName ? 'box-large-data' : 'box-single-data'"
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Pagaduría: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.payerName"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
      <input type="checkbox" class="checkbox" formControlName="payerName" />
    </div>
  </div>
  <div class="row">
    <div
      class="col-sm d-flex justify-content-between align-items-baseline item-mg"
      [ngClass]="isFullGridPayerName ? 'box-double-data' : 'box-single-data'"
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Fecha de nomína: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.payrollCheckDate"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
      <input
        type="checkbox"
        class="checkbox"
        formControlName="payrollCheckDate"
      />
    </div>
    <div
      class="col-sm d-flex justify-content-between align-items-baseline item-mg"
      [ngClass]="isFullGridPayerName ? 'box-double-data' : 'box-single-data'"
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Afiliación: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.affiliationNumber"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
      <input
        type="checkbox"
        class="checkbox"
        formControlName="affiliationNumber"
      />
    </div>
    <div
      class="col-sm d-flex justify-content-between align-items-baseline item-mg"
      [ngClass]="isFullGridPayerName ? 'box-double-data' : 'box-single-data'"
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Cargo: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.position"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
      <input type="checkbox" class="checkbox" formControlName="position" />
    </div>

    <div
      class="col-sm d-flex justify-content-between align-items-baseline item-mg"
      [ngClass]="
        isFullGridPayerName
          ? 'box-double-data'
          : 'box-single-data box-single-data-with'
      "
    >
      <adl-text-description
        cssClass="text-md-left"
        text="Nivel: "
      ></adl-text-description>
      <adl-text-description
        [text]="captureData?.positionLevel"
        cssClass="pl-1 font-weight-bold"
      ></adl-text-description>
      <input type="checkbox" class="checkbox" formControlName="positionLevel" />
    </div>
  </div>

  <div class="flex-container">
    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-center title-table">
        <adl-text-description
          text="Ingresos"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center title-table">
        <adl-text-description
          text="Corregir"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-center title-table">
        <adl-text-description
          text="Egresos"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center title-table">
        <adl-text-description
          text="Corregir"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Sueldo:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.salary | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input type="checkbox" class="checkbox" formControlName="salary" />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Salud:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.healthDiscount | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="healthDiscount"
        />
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Horas extra:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.overtime | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input type="checkbox" class="checkbox" formControlName="overtime" />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Pensión:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.pensionDiscount | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="pensionDiscount"
        />
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Primas:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.extraPayment | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="extraPayment"
        />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Descuentos de ley:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.otherLawDiscount | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="otherLawDiscount"
        />
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Bonificaciones:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.bonuses | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input type="checkbox" class="checkbox" formControlName="bonuses" />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Descuento 1:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.otherDiscount1 | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="otherDiscount1"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Honorarios:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.honorarium | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input type="checkbox" class="checkbox" formControlName="honorarium" />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Descuento 2:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.otherDiscount2 | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="otherDiscount2"
        />
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Otros Ingresos:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.totalOtherIncome | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="totalOtherIncome"
        />
      </div>

      <div class="w-40 item mb-2 d-flex justify-content-between">
        <adl-text-description
          text="Descuento 3:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="
            captureData?.otherDiscount3 | currency: 'USD':'symbol':'1.0-0'
          "
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-10 item mb-2 d-flex justify-content-center height-checkbox">
        <input
          type="checkbox"
          class="checkbox"
          formControlName="otherDiscount3"
        />
      </div>
    </div>

    <div class="row d-flex justify-content-between pl-2 pr-2 box">
      <div class="w-50 item-green mb-2 d-flex">
        <adl-text-description
          text="Total ingresos:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.totalIncome | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>

      <div class="w-50 item-green mb-2 d-flex">
        <adl-text-description
          text="Total egresos:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.totalDiscount | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>
    </div>
    <div class="row d-flex pl-2 pr-2 box">
      <div class="w-100 item-green d-flex justify-content-center">
        <adl-text-description
          text="Pago Neto:"
          cssClass="text-md-left pl-1"
        ></adl-text-description>
        <adl-text-description
          [text]="captureData?.totalPayment | currency: 'USD':'symbol':'1.0-0'"
          cssClass="font-weight-bold pl-1"
        ></adl-text-description>
      </div>
    </div>
  </div>
</form>
