import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICourse } from "../../../core/interfaces/course.interface";

import { environment } from "../../../../environments/environment";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { ToggleEnum } from "../../../core/enums/toggle.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { LoanService } from "../../../services/local/loan.service";
import { MenuService } from "../../../services/local/menu.service";
import { RepricingCampaignService } from "../../../services/local/repricing-campaign.service";
import { ToggleService } from "../../../services/local/toggle.service";
import { TrainingService } from "../../../services/local/training.service";

import { TealiumUtagService } from "../../../tealium/utag.service";

import { FormPopupStrongAuthenticationComponent } from "../form-popup-strong-authentication/form-popup-strong-authentication.component";
import { FormPopupTrainingComponent } from "../form-popup-training/form-popup-training.component";
import { FormPopupViabilityQueryComponent } from "../form-popup-viability-query/form-popup-viability-query.component";

@Component({
  selector: "app-form-decision",
  styleUrls: ["./form-decision.component.scss"],
  templateUrl: "./form-decision.component.html",
})
export class FormDecisionComponent implements OnInit, AfterViewInit {
  public showNewLabel: boolean =
    environment.uicomponents.previabilityNewFeature;

  @ViewChild(FormPopupStrongAuthenticationComponent, { static: false })
  formPopupStrongAuthenticationComponent: FormPopupStrongAuthenticationComponent;

  @ViewChild(FormPopupTrainingComponent, { static: false })
  formPopupTrainingComponent: FormPopupTrainingComponent;

  @ViewChild(FormPopupViabilityQueryComponent, { static: false })
  formPopupViabilityQueryComponent: FormPopupViabilityQueryComponent;

  @ViewChild("formPopupTraining", { static: false })
  set advisorDecisionTypeEnum(advisorDecisionTypeEnum) {}

  get advisorDecisionTypeEnum() {
    return AdvisorDecisionTypeEnum;
  }

  columnClass: string;
  decisionStrongAuthentication: any;
  isStrongAuthenticationCustomerInfo: boolean;
  toggleOnbaseLoad: boolean;
  togglePrevalidationSimulation: boolean;
  toggleTelephonicModel: boolean;

  advisor: IAdvisor;
  courses: ICourse[];
  formPopupTraining: FormPopupTrainingComponent;
  isRepricingEnabled: boolean = false;
  menuDecision = [];

  constructor(
    private advisorService: AdvisorService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    private repricingService: RepricingCampaignService,
    private trainingService: TrainingService,
    private tealiumUtagSerivce: TealiumUtagService,
    private toggleService: ToggleService,
    private menuService: MenuService,
  ) {
    this.isRepricingEnabled = this.repricingService.isEnable();
    this.menuDecision = this.menuService.getMenu();
  }

  ngAfterViewInit() {
    this.menuService.setPopupViability(this.formPopupViabilityQueryComponent);
    this.tealiumUtagSerivce.view({
      tealium_event: "page_view",
      page_path: document.location.pathname,
    });
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.DECISION_INIT_PAGE,
    );

    this.setStrongAuthenticationCustomer();

    this.advisor = this.advisorService.getAdvisor();
    this.courses = this.trainingService.getCourses();
    this.loanService.setLoanFlowChanged(undefined);

    if (this.courses && this.courses.length !== 0) {
      setTimeout(() => {
        this.formPopupTrainingComponent.popupTraining = true;
      }, 500);
    }
  }

  closeStrongAuthenticationPopup() {
    this.formPopupStrongAuthenticationComponent.setPopupStrongAuthentication = false;
  }

  closeTrainingPopup() {
    this.formPopupTrainingComponent.popupTraining = false;
  }

  closeViabilityQueryPopup(redirect: boolean) {
    this.formPopupViabilityQueryComponent.setShowPopUpViabilityQuery = false;
  }

  goToDecision(decision) {
    this.advisor.decisionType = decision.decision as AdvisorDecisionTypeEnum;

    this.advisor.decisionType =
      decision.decision ===
        AdvisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
      this.isUnificationFlowActive
        ? AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
        : (decision.decision as AdvisorDecisionTypeEnum);
    this.googleAnalyticsEventsService.emitEvent(decision.event);

    if (
      this.advisor.decisionType ===
      AdvisorDecisionTypeEnum.GET_NEW_PAYROLL_LOAN_BY_PHONE_CALL
    ) {
      this.formPopupStrongAuthenticationComponent.setPopupStrongAuthentication = true;
    } else {
      this.tealiumUtagSerivce.link({
        tealium_event: "click",
        event_category: "decision",
        event_label: decision.label,
        user_agent: this.tealiumUtagSerivce.managementUserAgent(),
      });
      setTimeout(() => {
        this.menuService.select(decision);
      }, 500);
    }
  }

  get isUnificationFlowActive() {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.UNIFICATION_FLOW_TOOGLE,
    );
  }

  private setStrongAuthenticationCustomer() {
    this.decisionStrongAuthentication = {
      r: this.toggleService.getToggleEnabledById(
        ToggleEnum.CERTIHUELLA_PHONE_FUNCTION,
      ),
      d: this.toggleService.getToggleEnabledById(
        ToggleEnum.DECRIM_PHONE_FUNCTION,
      ),
    };

    this.isStrongAuthenticationCustomerInfo =
      this.decisionStrongAuthentication.r ||
      this.decisionStrongAuthentication.d;
  }
}
