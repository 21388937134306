import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ITransactionAudit } from "../../core/interfaces/transaction-audit.interface";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthorizationDataRemoteService {
  constructor(private httpClient: HttpClient) {}

  async saveDataTreatment(transactionAudit: ITransactionAudit) {
    transactionAudit.termsAndConditionsVersion = "V5 16MARZ2021";
    return this.httpClient
      .post(
        environment.serverUrlSalaryLoans +
          "terms-and-conditions/" +
          transactionAudit.documentType +
          "/" +
          transactionAudit.documentNumber,
        transactionAudit,
      )
      .toPromise();
  }

  async saveCreditReporting(transactionAudit: ITransactionAudit) {
    return this.httpClient
      .post(
        environment.serverUrlSalaryLoans +
          "credit-bureau-reporting/" +
          transactionAudit.documentType +
          "/" +
          transactionAudit.documentNumber,
        transactionAudit,
      )
      .toPromise();
  }
}
