import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import { CommonTextsEnum } from "../../../core/enums/common-texts.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICustomer } from "../../../core/interfaces/customer.interface";

import { AdvisorService } from "../../../services/local/advisor.service";
import { AuthorizationDataService } from "../../../services/local/authorization-data.service";
import { CommonTextsService } from "../../../services/local/common-texts.service";
import { CustomerService } from "../../../services/local/customer.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { OtpService } from "../../../services/local/otp.service";
import { RepricingCampaignService } from "../../../services/local/repricing-campaign.service";
import { RepricingService } from "../../../services/local/repricing.service";

import { FormPopupViewMoreComponent } from "../form-popup-view-more/form-popup-view-more.component";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";

import { TealiumUtagService } from "../../../tealium/utag.service";

@Component({
  selector: "app-form-authorization-data",
  styleUrls: ["./form-authorization-data.component.scss"],
  templateUrl: "./form-authorization-data.component.html",
})
export class FormAuthorizationDataComponent implements OnInit, AfterViewInit {
  @ViewChild("formPopupViewMore", { static: false })
  formPopupViewMore: FormPopupViewMoreComponent;

  advisor: IAdvisor;
  customer: ICustomer;
  formGroup: UntypedFormGroup;

  hiddenViewMore = true;
  loadingButtonAuthorization: boolean;
  loadingButtonNoAuthorization: boolean;
  title: string;
  messageEnum = MessageEnum;

  constructor(
    private advisorService: AdvisorService,
    private authorizationDataService: AuthorizationDataService,
    private commonTextsService: CommonTextsService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private otpService: OtpService,
    private repricingCampaignService: RepricingCampaignService,
    private repricingService: RepricingService,
    private router: Router,
    private tealiumUtagService: TealiumUtagService,
  ) {
    this.advisor = this.advisorService.getAdvisor();
    this.formGroup = new UntypedFormGroup({});
  }

  authCallCenter() {
    this.otpService
      .authenticationCallCenter(this.advisor, this.customer)
      .subscribe(() => {
        this.customerService.setCustomer({
          ...this.customer,
          authenticationDone: true,
        });
        this.router.navigate(["salaryloan"]);
      });
  }

  ngAfterViewInit(): void {
    this.tealiumUtagService.view({
      tealium_event: "page_view",
      page_path: document.location.pathname,
    });
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.DATA_AUTHORIZATION_INIT_PAGE,
    );

    this.customer = this.customerService.getCustomer();
    this.title = this.advisorService.isAdvisorDecisionSendViabilityQuery()
      ? "Tratamiento de datos"
      : "¡Listo " +
        this.customer.name.trim() +
        (" " + this.customer.lastName.trim()) +
        "!";

    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl(
      "checkDataTreatment",
      new UntypedFormControl("", Validators.required),
    );
    this.formGroup.addControl(
      "checkCreditReporting",
      new UntypedFormControl("", Validators.required),
    );
  }

  cancelAuthorization() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.DATA_AUTHORIZATION_CLICK_NO_ACCEPT_DATA,
    );

    this.tealiumUtagService.link({
      tealium_event: "click",
      event_category: "Autorizaciones",
      event_label: "no_acepto",
    });

    this.loadingButtonNoAuthorization = true;

    this.authorizationDataService
      .noAcceptData(
        this.advisor,
        this.customer,
        this.formGroup.valid,
        this.formGroup.controls["checkDataTreatment"].value,
        this.formGroup.controls["checkCreditReporting"].value,
      )
      .then(() => {
        this.router.navigate(["/loginadvisor"]);
      });
  }

  tealiumEvent(event: string, category: string, label: string): void {
    this.tealiumUtagService.link({
      tealium_event: event,
      event_category: category,
      event_label: label,
    });
  }

  goToAuthentication() {
    if (this.formGroup.valid) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.DATA_AUTHORIZATION_CLICK_ACCEPT_DATA,
      );

      this.tealiumEvent("click", "Autorizaciones", "si_acepto");
      this.tealiumEvent("click", "chk_Autorizaciones", "check_tratamiento");
      this.tealiumEvent("click", "chk_Autorizaciones", "check_centrales");

      this.loadingButtonAuthorization = true;

      this.authorizationDataService
        .acceptData(this.advisor, this.customer)
        .then((response: boolean) => {
          if (response && this.validateRepricingV3()) {
            this.authCallCenter();
          } else if (
            response &&
            this.validateRepricing() &&
            !this.repricingService.isEnableToggle()
          ) {
            this.authCallCenter();
          } else if (response) {
            this.router.navigate(["authenticationcode"]);
          } else {
            this.router.navigate(["overflowerror"]);
          }
        });
    }
  }

  seeMoreDataTreatment() {
    this.formPopupViewMore.title =
      "Autorización Para el Tratamiento de Datos Personales";
    this.formPopupViewMore.contentText = this.commonTextsService.getCommonTextData(
      CommonTextsEnum.DATA_TREATMENT,
    );
    this.hiddenViewMore = false;
  }

  seeMoreCreditReporting() {
    this.formPopupViewMore.title =
      "El consumidor financiero autoriza al BANCO a realizar la consulta y reporte en centrales de información, además realiza las siguientes declaraciones";
    this.formPopupViewMore.contentText = this.commonTextsService.getCommonTextData(
      CommonTextsEnum.CREDIT_REPORTING,
    );
    this.hiddenViewMore = false;
  }

  validateRepricing(): boolean {
    return (
      (this.advisorService.getDecisionType() ==
        AdvisorDecisionTypeEnum.PAYROLL_LOAN_REPRICING ||
        this.advisorService.getDecisionType() ==
          AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL) &&
      (this.repricingCampaignService.isEnable() ||
        this.repricingCampaignService.isEnableV2())
    );
  }

  validateRepricingV3(): boolean {
    return (
      this.repricingService.isEnableToggle() &&
      this.repricingService.getRepricing() !== undefined &&
      this.advisorService.getDecisionType() ==
        AdvisorDecisionTypeEnum.PAYROLL_LOAN_REPRICING
    );
  }

  closeSeeMore() {
    this.hiddenViewMore = true;
  }
}
