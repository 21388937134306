import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";

import { AuthenticationTypeEnum } from "../../core/enums/authentication-type.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IGenerateCodeOtp } from "../../core/interfaces/generate-code-otp.interface";

import { AdvisorService } from "./advisor.service";
import { CustomerService } from "./customer.service";
import { GoogleRecaptchaService } from "./google-recaptcha.service";
import { ToggleService } from "./toggle.service";
import { ValidateResponseService } from "./validate-response.service";
import { ValidationSimService } from "./validation-sim.service";

import { AuthenticationRemoteService } from "../remote/authentication-remote.service";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  constructor(
    private authenticationRemoteService: AuthenticationRemoteService,
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private googleRecaptchaService: GoogleRecaptchaService,
    private toggleService: ToggleService,
    private validateResponseService: ValidateResponseService,
    private validationSimService: ValidationSimService,
  ) {}

  async generateOtp(dataGenerateCodeOtp: IGenerateCodeOtp) {
    return new Promise<void>((resolve, reject) => {
      this.authenticationRemoteService
        .generateOtp(dataGenerateCodeOtp)
        .then((dataGenerateOtp: any) => {
          if (
            !this.validateResponseService.validateGenerateOtp(dataGenerateOtp)
          ) {
            reject();
          }

          resolve();
        })
        .catch(() => reject());
    });
  }

  generateOtpByCall(dataGenerateCodeOtp: IGenerateCodeOtp): Observable<any> {
    return this.authenticationRemoteService
      .generateOtpByCall(dataGenerateCodeOtp)
      .pipe(
        map(() => {
          return;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  authenticationOtp(
    advisor: IAdvisor,
    customer: ICustomer,
    otpValue: string,
    recaptchaResponse: string,
  ): Observable<any> {
    const obs = this.authenticationRemoteService.authenticationOtp(
      advisor,
      customer,
      otpValue,
      recaptchaResponse,
    );
    return this.authOtp(advisor, customer, obs);
  }

  authenticationCallCenter(
    advisor: IAdvisor,
    customer: ICustomer,
  ): Observable<any> {
    return this.googleRecaptchaService.generateTokenRecaptcha().pipe(
      concatMap((token) => {
        const obs = this.authenticationRemoteService.authenticationCallCenter(
          advisor,
          customer,
          token,
        );
        return this.authOtp(advisor, customer, obs);
      }),
    );
  }

  private authOtp(
    advisor: IAdvisor,
    customer: ICustomer,
    observable: Observable<any>,
  ) {
    return observable.pipe(
      map((authenticationOtp: any) => {
        if (
          !this.validateResponseService.validateAuthenticationOtp(
            authenticationOtp,
          )
        ) {
          throw {};
        }

        advisor.accessToken = authenticationOtp.token;
        customer.authenticationType = AuthenticationTypeEnum.OTP;
        customer.generatedId = authenticationOtp.generatedId;
        customer.salesJourneyId = authenticationOtp.salesJourneyId;

        this.advisorService.setAdvisor(advisor);
        this.customerService.setCustomer(customer);
      }),
      catchError(() => {
        return throwError([]);
      }),
    );
  }
}
