import { Injectable } from "@angular/core";
import {
  FormFileInputDragComponent,
  INotificationResponse,
  NotificationResponseTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { DocumentTypeTapEnum } from "../../core/enums/document-type-tap.enum";
import { GoogleAnalyticsEventsEnum } from "../../core/enums/google-analytics-events.enum";

import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";
import { NotificationReponseService } from "./notification-reponse.service";

@Injectable({
  providedIn: "root",
})
export class PayrollFileErrorService {
  typeFiles = new Map();

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private notificationResponseService: NotificationReponseService,
  ) {
    this.typeFiles.set(DocumentTypeTapEnum.LABOR_CERTIFICATION, 0);
    this.typeFiles.set(DocumentTypeTapEnum.PAYROLLCHECK, 0);
  }

  fileErrorSize(
    fileInputDrag: FormFileInputDragComponent,
  ): INotificationResponse {
    return this.fileError(
      fileInputDrag,
      NotificationResponseTypeEnum.UPLOAD_FILE_ERROR_BY_SIZE,
    );
  }

  fileErrorExtension(
    fileInputDrag: FormFileInputDragComponent,
  ): INotificationResponse {
    return this.fileError(
      fileInputDrag,
      "UPLOAD_FILE_ERROR_BY_EXTENSION" as any,
    );
  }

  fileErrorUpload(
    fileInputDrag: FormFileInputDragComponent,
  ): INotificationResponse {
    return this.fileError(
      fileInputDrag,
      NotificationResponseTypeEnum.UPLOAD_FILE_ERROR,
    );
  }

  private fileError(
    fileInputDrag: FormFileInputDragComponent,
    notificationError: NotificationResponseTypeEnum,
  ) {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.UPLOAD_DOCUMENTS_CLICK_CONTINUE_SEND_FILE_INVALID,
    );

    fileInputDrag.showErrorUploadFile();

    return this.notificationResponseService.getNotificationResponse(
      notificationError,
    );
  }
}
