import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AccountCustomerRemoteService {
  constructor(private httpClient: HttpClient) {}

  listFlexcubeOwnership(
    identificationType: string,
    identificationNumber: string,
    reverse: boolean,
  ) {
    return this.httpClient
      .post(
        environment.serverUrlSalaryLoans + "flexcube-ownership/accounts-list",
        { identificationType, identificationNumber, reverse },
      )
      .toPromise();
  }
}
