export enum ApplicationConstantsEnum {
  AFTER_PROCESS_SYGNUS = "AfterProcessSygnus",
  AUTHENTICATION_SIM_PENDING = "AUTHENTICATION_SIM_PENDING",
  CERTIHUELLA = "Certihuella",
  CHARACTER_EMPTY = "",
  CHARACTER_NOT_FOUND = -1,
  CHARACTER_SPACE = " ",
  COLPENSIONES_PAYER_NAME = "COLPENSIONES",
  COL_10 = "col-10",
  COL_12 = "col-12",
  DEFAULT_PROVIDER_ID = 0,
  DOCUMENT_PRELOAD = "Documento precargado",
  EDUCATIONAL_SECTOR = "Educativo",
  EMPTY_ARRAY = 0,
  EMPTY_OBJECT = "{}",
  FEDERATED_AUTH_DUMMY_TOKEN = "dummy_federated_auth_token",
  FEDERATED_AUTH_INVALID_GRANT = "invalid_grant",
  FILE_CERTIFIED = "fileCertified",
  FILE_NAME_PRELOAD = "Documento.pdf",
  FILE_PAYMENT_RECEIPT = "filePaymentReceipt",
  IMAGE_JPEG = "image/jpeg",
  INDEX_EMPTY = -1,
  LABOR_CERTIFICATION_OCR_CODE = "0030 CERTIFICACION LABORAL",
  LABOR_CERTIFICATION_TEXT = "Certificado Laboral",
  LOADINGCOMPLETE = 100,
  MAXIMUM_DECRIM_FILE_SIZE = 2090000,
  MAXIMUM_FILE_SIZE = 400000,
  MAXIMUM_ONBASE_FILE_SIZE = 1040000,
  MAXIMUM_SPACES_PAYERNAME = 2,
  NOT_APPLY = "N/A",
  NUMBER_ATTEMPTS = 3,
  NUMBER_DECRIM_FILES_WITH_FACIAL_IDENTIFICATION = 4,
  NUMBER_DOCUMENTS_EDUCATIONAL = 2,
  NUMBER_DOCUMENTS_PENSIONER = 1,
  NUMBER_MAX_LENGTH_CODE_RNEC = 12,
  OFUSCATE = "*",
  OFUSCATE_PREFIX = 3,
  OTP_LONG = 8,
  OTP_SHORT = 6,
  PAYER_PLATFORM_01 = "PayerPlatform01",
  PAYER_PLATFORM_02 = "PayerPlatform02",
  PAYER_PLATFORM_07 = "PayerPlatform07",
  PAYROLL_CHECKS_005 = "PayrollChecks005",
  PAYROLL_CHECKS_012 = "PayrollChecks012",
  PAYROLL_CHECKS_OCR_CODE = "desprendible",
  PAYROLL_CHECK_TEXT = "Desprendible de pago",
  PROGRESS = "PROGRESS",
  REMOVE_ITEM_IN_SECTOR = 1,
  RESTART_COUNT = 0,
  RETRY = "Intento",
  RETRY_CONSUME_SERVICE = 3,
  SORT_SECTOR_ITEMS_LESS_ONE = -1,
  SORT_SECTOR_ITEMS_ONE = 1,
  SORT_SECTOR_ITEMS_ZERO = 0,
  START_NUMBER_ATTEMPTS = 1,
  SUCCESS = "SUCCESS",
  TEXT_WHITE_BOLD = "text-white-bold",
  TEXT_RED_BOLD = "text-error-bold",
  TEXT_TRY_AGAIN = "Volver a Intentar",
  TIME_OUT = "TIMEOUT",
  TIME_RETRY_UPLOAD_FILE = 15000,
  TIME_LIMIT_RETRY_UPLOAD_FILE = 550000,
  TIME_LIMIT_UPLOAD_PAYMENT_RECEIPT_ADVANCE = 10000,
  TIME_LIMIT_UPLOAD_PAYMENT_RECEIPT_VALIDATION = 300000,
  TIME_OUT_APP = 600000,
  TIME_RETRY_REQUEST_TOGGLE = 5000,
  UNKNOWN = "unknown",
}
