import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { IRadioButtonData } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { EventCodeEnum } from "../../../core/enums/event-code.enum";

import { CustomerService } from "../../../services/local/customer.service";
import { EventAnalyticsService } from "../../../services/local/event-analytics.service";
@Component({
  selector: "app-form-popup-short-flow-repricing",
  templateUrl: "./form-popup-short-flow-repricing.component.html",
  styleUrls: ["./form-popup-short-flow-repricing.component.scss"],
})
export class FormPopupShortFlowRepricingComponent implements OnInit {
  @Input("showPopupShortFlow")
  set setShowPopupShortFlow(showPopupShortFlow: boolean) {
    this.showPopupShortFlow = showPopupShortFlow;
  }

  @Output() closePopupEvent = new EventEmitter<string>();

  formGroup: UntypedFormGroup;
  showPopupShortFlow: boolean = false;
  radioButtons: IRadioButtonData[] = [
    {
      title: "Proceso corto",
      label: "Menos tiempo en el desembolso",
      value: "1",
    },
    {
      title: "Proceso completo",
      label: "Proceso más detallado y prolongado",
      value: "0",
    },
  ];
  popupIcon: string = "./assets/images/groups/success.svg";
  popupTitle: string = "Validación exitosa";
  popupMessage: string =
    "Tu cliente es apto para continuar con la solicitud. Elige entre estos dos procesos de la herramienta digital para facilitar la solicitud de tu cliente";
  popuptextOkButton: string = "Continuar";
  popupdisabledtOkButton: boolean = true;
  loading: boolean;

  constructor(
    private eventAnalyticsService: EventAnalyticsService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      shortFlow: new UntypedFormControl("", Validators.required),
    });
  }

  selectedValue() {
    if (this.popupdisabledtOkButton) {
      this.popupdisabledtOkButton = false;
    }
  }

  continueFlowReprecing() {
    const selectedFlow = this.formGroup.get("shortFlow").value;
    this.sendRepricingFlowSelectionEvent(selectedFlow);
    this.closePopupEvent.emit(selectedFlow);
    this.loading = true;
  }

  private sendRepricingFlowSelectionEvent(selectedFlow: string) {
    const customerData = this.customerService.getCustomer();
    const flowText =
      selectedFlow === "1" ? "Proceso corto" : "Proceso completo";
    const contentObject = {
      flowValue: selectedFlow,
      flowText: flowText,
    };

    this.eventAnalyticsService
      .sendNewEventAnalytics({
        documentType: customerData.documentType,
        documentNumber: customerData.documentId,
        eventCode: EventCodeEnum.SELECTED_FLOW_POP_UP_REPRICING,
        eventContent: { description: JSON.stringify(contentObject) },
      })
      .subscribe();
  }
}
