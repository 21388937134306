import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { CustomerDecisionTypeEnum } from "../../core/enums/customer-decision-type.enum";
import { ResultRepricingEnum } from "../../core/enums/result-repricing.enum";
import { CustomerTypeEnum } from "../../core/enums/customer-type.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { ICustomer } from "../../core/interfaces/customer.interface";
import { IPreloan } from "../../core/interfaces/pre-loan.interface";
import { SearchRepricingResponse } from "../../core/interfaces/search-repricing-response.interface";

import { AccountCustomer } from "./account-customer.service";
import { AdvisorService } from "./advisor.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { ToggleService } from "./toggle.service";
import { ValidationSimService } from "./validation-sim.service";

import { CustomerRemoteService } from "../remote/customer-remote.service";
import { RepricingCampaignService } from "./repricing-campaign.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private customer: ICustomer;
  private customerInfo: any;
  private isRepricingMessage = false;
  private isRepricingV2Message = false;

  constructor(
    private accountCustomer: AccountCustomer,
    private advisorService: AdvisorService,
    private customerRemoteService: CustomerRemoteService,
    private overflowErrorsService: OverflowErrorsService,
    private repricingService: RepricingCampaignService,
    private toggleService: ToggleService,
    private validationSimService: ValidationSimService,
  ) {}

  async asyncSearchCustomer(
    documentType: string,
    documentId: number,
    isQueryPayrollLoanStatus?: boolean,
  ): Promise<boolean> {
    await this.setCustomerData(documentType, documentId);

    return this.customerRemoteService
      .asyncSearchCustomer(this.customerInfo)
      .then(async (dataSearchCustomer: any) => {
        const enablev2Channel = this.repricingService.isEnableV2Channel();
        this.repricingService.setRepricingV2ShortFlow(enablev2Channel);

        if (dataSearchCustomer.cellphone.trim() !== "") {
          this.setDataSearchCustomer(
            documentType,
            documentId,
            dataSearchCustomer,
          );

          const validateFlexCube = await this.validateFlexcubeOwnership(
            documentType,
            documentId.toString(),
            true,
          );

          if (!validateFlexCube) {
            return false;
          }

          const isRepricing = this.repricingService.isEnable();
          let isRepricingV2 = this.repricingService.isEnableV2();

          if (isRepricing) {
            return this.executeSearchRepricing(documentType, documentId);
          } else if (isRepricingV2) {
            let result = await this.executeSearchRepricingV2(
              documentType,
              documentId,
            );

            if (typeof result == "string") {
              this.setIsRepricingV2Message(false);
              this.repricingService.setRepricingV2ShortFlow(false);
              isRepricingV2 = false;
            }
          }

          if (!isQueryPayrollLoanStatus && !isRepricing && !isRepricingV2) {
            await this.validateCustomerCellPhone();
            await this.validateCustomerViabilityODM(documentType, documentId);
          }
          return true;
        } else {
          this.overflowErrorsService.setOverflowError(
            OverflowErrorsEnum.CustomerManagement022,
          );
          return false;
        }
      })
      .catch((errorSearchCustomerOrValidations: any) => {
        this.overflowErrorsService.setOverflowError(
          errorSearchCustomerOrValidations === null
            ? OverflowErrorsEnum.TECHNICAL_ERROR
            : typeof errorSearchCustomerOrValidations === "string"
            ? OverflowErrorsEnum.CustomerManagement9998
            : errorSearchCustomerOrValidations.code
            ? errorSearchCustomerOrValidations.code
            : errorSearchCustomerOrValidations.error.code
            ? errorSearchCustomerOrValidations.error.code
            : OverflowErrorsEnum.TECHNICAL_ERROR,
        );
        return false;
      });
  }

  async executeSearchRepricing(documentType, documentId) {
    const response = await this.repricingService
      .search(documentType, String(documentId), true)
      .toPromise();

    return this.repricingMessage(response);
  }

  async executeSearchRepricingV2(documentType, documentId) {
    const response = await this.repricingService
      .search(documentType, String(documentId), false)
      .toPromise();
    return this.repricingMessageV2(response);
  }

  getIsRepricingMessage() {
    return this.isRepricingMessage;
  }
  getIsRepricingV2Message() {
    return this.isRepricingV2Message;
  }
  getCustomer() {
    return this.customer;
  }

  getCustomerInfo() {
    return this.customerInfo;
  }

  getDecisionType() {
    return this.customer.decisionType;
  }

  getQueryParamsCustomerNew(preloan: IPreloan): string {
    return this.isNewLoanDecision()
      ? `?payer-nit=${preloan.payerNit}` +
          `&sector-number=${preloan.sectorNumber}` +
          `&client-type=${CustomerTypeEnum.NEW_CUSTOMER_KNOWN}`
      : ApplicationConstantsEnum.CHARACTER_EMPTY;
  }

  isExtendedDecision() {
    return this.customer.decisionType === CustomerDecisionTypeEnum.EXTEND_LOAN;
  }

  isNewLoanDecision() {
    return this.customer.decisionType === CustomerDecisionTypeEnum.NEW_LOAN;
  }

  sendCreditTypeDecision(): Observable<any> {
    return this.customerRemoteService.sendCreditTypeDecision({
      documentType: this.customer.documentType,
      documentNumber: this.customer.documentId,
      creditTypeEnum: this.customer.decisionType,
    });
  }

  setCustomer(customer: ICustomer) {
    this.customer = customer;
  }

  setCustomerInfo(customerInfo: any) {
    this.customerInfo = customerInfo;
  }

  setIsRepricingMessage(value: boolean) {
    this.isRepricingMessage = value;
  }

  setIsRepricingV2Message(value: boolean) {
    this.isRepricingV2Message = value;
  }
  async setCustomerCellphone(cellphone: string) {
    this.customer.cellphone = this.maskTextCellPhone(cellphone);
    this.customer.updateCellphone = true;

    await this.setCustomerData(
      this.customer.documentType,
      this.customer.documentId,
    );

    if (this.customer.updateCellphone) {
      this.customerInfo.cellphoneNumber = cellphone;
    }

    await this.validateCustomerCellPhone();
  }

  async setCustomerEmail(email: string) {
    this.customer.email = this.maskTextEmail(email);
    this.customer.updateEmail = true;
    if (this.customer.updateEmail) {
      this.customerInfo.email = email;
    }
    await this.validateCustomerCellPhone();
  }

  setExternalCustomer(externalCustomer: any) {
    this.customer = {};
    this.customer.authenticationType = externalCustomer.authenticationType;
    this.customer.cellphone = externalCustomer.cellphone;
    this.customer.colombianNationality = externalCustomer.colombianNationality;
    this.customer.decisionType = externalCustomer.customerDecisionType;
    this.customer.customerType = externalCustomer.customerType;
    this.customer.debitCard = externalCustomer.debitCard;
    this.customer.decrimFiles = externalCustomer.decrimFiles;
    this.customer.documentId = +externalCustomer.documentId;
    this.customer.documentType = externalCustomer.documentType;
    this.customer.email = externalCustomer.email;
    this.customer.feedbackEnd = externalCustomer.feedbackEnd;
    this.customer.generatedId = externalCustomer.generatedId;
    this.customer.idCaseDecrim = externalCustomer.idCaseDecrim;
    this.customer.idCaseRnec = externalCustomer.idCaseRnec;
    this.customer.lastName = externalCustomer.lastName;
    this.customer.name = externalCustomer.name;
    this.customer.nameShort = externalCustomer.nameShort;
    this.customer.salesJourneyId = externalCustomer.salesJourneyId;
    this.customer.simPendingAuthentication =
      externalCustomer.simPendingAuthentication;
    this.customer.updateCellphone = externalCustomer.updateCellphone;
    this.customer.updateEmail = externalCustomer.updateEmail;
    this.customer.validationMethod = externalCustomer.validationMethod;
  }

  private maskTextCellPhone(textCellPhone: string): string {
    return `*******${textCellPhone.substr(7, 3)}`;
  }

  private maskTextEmail(textEmail: string): string {
    const splitText = textEmail.split("@");
    let asterisk = "";

    for (let index = 0; index < splitText[0].length - 3; index++) {
      asterisk += "*";
    }
    splitText[0] = splitText[0].substr(0, 3) + asterisk;

    return splitText.join("@");
  }

  private repricingMessage(result: SearchRepricingResponse) {
    if (result.repricing.length === ApplicationConstantsEnum.EMPTY_ARRAY) {
      this.overflowErrorsService.setOverflowError(
        OverflowErrorsEnum.UNVIABLE_CUSTOMER_REPRICING,
      );
      return false;
    }

    this.isRepricingMessage = true;
    this.isRepricingV2Message = false;
    return true;
  }

  private repricingMessageV2(
    result: SearchRepricingResponse,
  ): string | boolean {
    this.isRepricingMessage = false;
    this.isRepricingV2Message = true;

    if (result.repricing.length === ApplicationConstantsEnum.EMPTY_ARRAY)
      return ResultRepricingEnum.REPRICING_V2_OK;

    return true;
  }

  private async setCustomerData(documentType: string, documentId: number) {
    const advisor = this.advisorService.getAdvisor();
    this.customerInfo = {};

    this.customerInfo.documentType = documentType;
    this.customerInfo.documentNumber = documentId.toString();
    this.customerInfo.officeCode = advisor.office.idoffice.toString();
    this.customerInfo.businessAdvisorDocument = advisor.sellerIdNumber.toString();
    this.customerInfo.administrativeOfficeCode =
      advisor.administrativeOffice !== undefined
        ? advisor.administrativeOffice.code.toString()
        : "";
    this.customerInfo.officeName = advisor.office.name_office;
    this.customerInfo.administrativeOfficeName =
      advisor.administrativeOffice !== undefined
        ? advisor.administrativeOffice.name
        : "";
    this.customerInfo.advisorJourneyId = advisor.advisorJourneyId;
    this.customerInfo.salesModel = advisor.decisionType;
  }

  private setDataSearchCustomer(
    documentType: string,
    documentId: number,
    dataSearchCustomer: any,
  ) {
    this.setCustomer({
      documentId,
      documentType,
      colombianNationality: documentType === "CC",
      cellphone: dataSearchCustomer.cellphone,
      debitCard: dataSearchCustomer.debitCard,
      email: dataSearchCustomer.email,
      lastName:
        dataSearchCustomer.lastName !== undefined
          ? dataSearchCustomer.lastName
          : "",
      name: dataSearchCustomer.name,
      nameShort:
        dataSearchCustomer.name
          .split(" ", 1)[0]
          .toLowerCase()
          .substr(0, 1)
          .toUpperCase() +
        dataSearchCustomer.name
          .split(" ", 1)[0]
          .toLowerCase()
          .substr(1),
      simPendingAuthentication: true,
      customerType: dataSearchCustomer.customerType as CustomerTypeEnum,
    });
  }

  async validateCustomerCellPhone() {
    await this.validationSimService.asyncValidateCustomerCellPhone(
      this.customer,
      this.customerInfo,
    );
  }

  async validateCustomerViabilityODM(documentType: string, documentId: number) {
    if (
      this.toggleService.getToggleEnabledById(ToggleEnum.USE_INTERNAL_BANK_ODM)
    ) {
      await this.customerRemoteService
        .asyncValidateCustomerViabilityODM({
          type: documentType,
          identification: documentId.toString(),
        })
        .catch((errorValidateCustomerViabilityODM) => {
          errorValidateCustomerViabilityODM.console = "Validacion ODM";
          throw errorValidateCustomerViabilityODM;
        });
    }
  }

  private async validateFlexcubeOwnership(
    identificationType: string,
    identificationNumber: string,
    reverse: boolean,
  ) {
    if (
      this.toggleService.getToggleEnabledById(
        ToggleEnum.OWNERSHIP_REFACTOR_TOGGLE,
      )
    ) {
      return await this.accountCustomer.getFlexcubeOwnership(
        identificationType,
        identificationNumber,
        reverse,
      );
    }
    return true;
  }
}
