import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { AdvisorService } from "../local/advisor.service";
import { CognitoService } from "../local/cognito.service";

@Injectable({
  providedIn: "root",
})
export class RepricingRemoteService {
  accessToken: string = null;
  headers: HttpHeaders;

  constructor(
    private advisorService: AdvisorService,
    private httpClient: HttpClient,
    private cognitoService: CognitoService,
  ) {}

  getCatalog(): Observable<object> {
    let namespace = "repricing",
      resource = "repricingChannels";
    this.setConfig();
    return this.httpClient.get<object>(
      `${environment.serverUrlPublicResources}catalog/${namespace}/${resource}`,
      { headers: this.headers },
    );
  }

  repricingSearch(body: any): Observable<any> {
    this.setConfig();
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "customer/campaign/repricing/search",
      body,
      { headers: this.headers },
    );
  }

  setConfig() {
    if (!this.accessToken) {
      this.accessToken = this.cognitoService.getTokenNew();
      if (!this.accessToken) {
        const advisorInfo = this.advisorService.getAdvisor();
        this.accessToken = advisorInfo.cognitoToken;
      }

      this.headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.accessToken,
      });
    }
  }
}
