import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";

import { ChannelTypeEnum } from "../../core/enums/channel-type.enum";
import { FileExtensionEnum } from "../../core/enums/file-extension.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";

import { AdvisorService } from "./advisor.service";
import { CustomerService } from "./customer.service";
import { LoanService } from "./loan.service";
import { OverflowErrorsService } from "./overflow-errors.service";

import { FileManagerRemoteService } from "../remote/file-manager-remote.service";

@Injectable({
  providedIn: "root",
})
export class FileManagerService {
  fileType: string;
  fileUrl: string | ArrayBuffer;
  advisor: IAdvisor;
  customer: ICustomer;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private fileManagerRemoteService: FileManagerRemoteService,
    private loanService: LoanService,
    private overflowErrorsService: OverflowErrorsService,
  ) {}

  castFileToBase64(file: File): Observable<any> {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    return new Observable((observer: any) => {
      fileReader.onload = () => observer.next(fileReader.result);
      fileReader.onerror = () =>
        observer.error({
          error: {
            code: OverflowErrorsEnum.TECHNICAL_ERROR,
          },
        });
    });
  }

  getFileExtension(file: File) {
    return file.name.lastIndexOf(".") > -1
      ? file.name.substring(file.name.lastIndexOf(".") + 1)
      : "";
  }

  getFileName(obligationId: string) {
    return `${obligationId}_${this.customer.documentType}_${this.customer.documentId}.${this.fileType}`;
  }

  isValidExtension(file: File, fileExtensions: FileExtensionEnum[]) {
    this.fileType = this.getFileExtension(file);
    return fileExtensions.includes(this.fileType as FileExtensionEnum);
  }

  isValidSize(file: File, size: number): boolean {
    return file.size <= size;
  }

  renameFile(
    file: File,
    fileNumber: number,
    documentType: string,
    documentId: number,
  ): string {
    return `${documentType}_${documentId}_${fileNumber}.${this.getFileExtension(
      file,
    )}`;
  }

  sendFile(file: File, channelType: ChannelTypeEnum) {
    this.customer = this.customerService.getCustomer();
    this.advisor = this.advisorService.getAdvisor();

    const fileName = this.getFileName(this.loanService.getObligationId());

    return this.fileManagerRemoteService
      .getSignedUrl(this.advisor, fileName, channelType)
      .pipe(
        mergeMap((dataAuthentication: any) => {
          this.customer.urlFile = dataAuthentication.url;
          return this.putFile(file);
        }),
        catchError((errorClientEnabled: any) => {
          this.overflowErrorsService.setOverflowError(
            errorClientEnabled.error.code
              ? errorClientEnabled.error.code
              : OverflowErrorsEnum.BucketFile002,
          );

          return throwError([]);
        }),
      );
  }

  validateFile(
    file: File,
    fileSize: number,
    fileExtensions: FileExtensionEnum[],
  ): boolean {
    this.fileType = this.getFileExtension(file);
    return (
      this.isValidExtension(file, fileExtensions) &&
      this.isValidSize(file, fileSize)
    );
  }

  private putFile(file: File) {
    return this.castFileToBase64(file).pipe(
      mergeMap((fileToBase64: any) => {
        this.fileUrl = fileToBase64;
        return this.fileManagerRemoteService.putFileWithProgress(
          this.customer.urlFile,
          fileToBase64,
          this.fileType,
        );
      }),
      catchError((errorConvertFile: any) => {
        this.overflowErrorsService.setOverflowError(
          errorConvertFile.error.code
            ? errorConvertFile.error.code
            : OverflowErrorsEnum.BucketFile002,
        );

        return throwError([]);
      }),
    );
  }
}
