import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { InitialNavPillsEnum } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { ValidationMethodEnum } from "../../../core/enums/validation-method.enum";

import { CustomerService } from "./../../../services/local/customer.service";
import { DecrimService } from "../../../services/local/decrim.service";
import { EventAnalyticsService } from "../../../services/local/event-analytics.service";
import { InitialNavPillsService } from "../../../services/local/initial-nav-pills.service";

@Component({
  selector: "app-form-popup-decrim-with-customer-info",
  templateUrl: "./form-popup-decrim-with-customer-info.component.html",
})
export class FormPopupDecrimWithCustomerInfoComponent implements OnInit {
  @Input("popUpDecrimWithCustomerInfo")
  set setpopUpDecrimWithCustomerInfo(popUpDecrimWithCustomerInfo: boolean) {
    this.popUpDecrimWithCustomerInfo = popUpDecrimWithCustomerInfo;
  }

  @Output() closePopupAndRedirectEvent = new EventEmitter<any>();

  captionButton: string;
  description: string;
  listDocumentType: any[];
  loadingButtonDecrim: boolean;
  popUpDecrimWithCustomerInfo: boolean;
  title: string;
  urlNavigate: string;

  formGroup: UntypedFormGroup;

  constructor(
    private customerService: CustomerService,
    private decrimService: DecrimService,
    private initialNavPillsService: InitialNavPillsService,
    private eventAnalyticsService: EventAnalyticsService,
  ) {
    this.title = "Validación decrim";
    this.description = `Ingresa el tipo y número de documento de identidad de tu cliente`;
    this.captionButton = "Consultar caso";
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.listDocumentType = this.initialNavPillsService.getInitialNavPills(
      InitialNavPillsEnum.LOGIN_CUSTOMER_DESIGN,
    );

    this.formGroup = new UntypedFormGroup({
      textDocumentType: new UntypedFormControl(),
      valueDocumentType: new UntypedFormControl(),
      textDocumentCustomer: new UntypedFormControl("", Validators.required),
    });
  }

  closePopupAndRedirect(nextStep: boolean) {
    if (!nextStep) {
      this.closePopupAndRedirectEvent.emit();
    } else {
      this.formGroup.markAllAsTouched();
      if (this.formGroup.valid) {
        this.customerService.setCustomer({
          documentType: this.formGroup.getRawValue().valueDocumentType.value,
          documentId: this.formGroup.getRawValue().textDocumentCustomer,
        });
        this.loadingButtonDecrim = true;
        this.validateData();
      }
    }
  }

  private validateData() {
    const formValue = this.formGroup.getRawValue();

    this.decrimService
      .postValidateDecrimQuery({
        clientIdNumber: formValue.textDocumentCustomer,
        clientIdType: formValue.valueDocumentType.value,
      })
      .subscribe(
        (messageResponse: any) => {
          this.loadingButtonDecrim = false;
          this.sendAnalyticsEvent(formValue, true);
          this.closePopupAndRedirectEvent.emit(messageResponse);
        },
        (error: any) => {
          this.loadingButtonDecrim = false;
          this.sendAnalyticsEvent(formValue, false);
          this.closePopupAndRedirectEvent.emit(error);
        },
      );
  }

  private sendAnalyticsEvent(formValue: any, success: boolean) {
    const eventCode = EventCodeEnum.CUSTOMER_AUTHENTICATION;
    const eventContent = ValidationMethodEnum.DECRIM + "";

    this.eventAnalyticsService
      .sendNewEventAnalytics({
        documentType: formValue.valueDocumentType.value,
        documentNumber: formValue.textDocumentCustomer,
        eventCode,
        eventContent,
      })
      .subscribe();
  }
}
