import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { AdvisorService } from "./advisor.service";
import { FileManagerService } from "./file-manager.service";
import { OnbaseLoadService } from "./onbase-load.service";

import { UploadDocumentsStatesEnum } from "../../core/enums/upload-documents-states.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ISendDocumentOnbase } from "../../core/interfaces/send-document-onbase.interface";

import { FileManagerRemoteService } from "../remote/file-manager-remote.service";
import { OnbaseLoadRemoteService } from "../remote/onbase-load-remote.service";

@Injectable({
  providedIn: "root",
})
export class OnbaseUploadDocumentsService {
  advisor: IAdvisor;
  createUploadDocuments: ISendDocumentOnbase;
  customerOnbase: any;
  onbaseFiles: any[] = [];

  IDENTIFICATIONCUSTOMER = 2;
  IDENTIFICATIONTYPECUSTOMER = 1;
  NUMBERCOPY = 1;

  loadPercentage: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private advisorService: AdvisorService,
    private fileManagerService: FileManagerService,
    private fileManagerRemoteService: FileManagerRemoteService,
    private onbaseLoadRemoteService: OnbaseLoadRemoteService,
    private onbaseLoadService: OnbaseLoadService,
  ) {}

  setFiles(onbaseFiles: any[]) {
    this.onbaseFiles = onbaseFiles;
  }

  setPreviouslyUploadedDocuments(payer: string) {
    const documentsUploadSystem = this.onbaseLoadService.documentsUploadedSystem(
      payer,
    );

    documentsUploadSystem.forEach((dataPayer: any) => {
      this.createUploadDocuments.documents.push(
        this.initDocumentsUpload(dataPayer, "", ""),
      );
    });
  }

  async uploadFiles(payer: any, factoryFileId: string, payerName: string) {
    this.advisor = this.advisorService.getAdvisor();
    this.customerOnbase = this.onbaseLoadService.getClientDocumentsUpload();
    this.createUploadDocuments = this.initCreateSendDocuments();

    this.createUploadDocuments.factoryFileId = +factoryFileId;
    this.createUploadDocuments.payerName = payerName;

    let document: any;
    const promisesDocuments: any[] = [];

    payer.forEach((dataPayer: any) => {
      const fileExtension = this.fileManagerService.getFileExtension(
        dataPayer.file,
      );

      const numberOfCopy = dataPayer.quantityCopies
        ? dataPayer.quantityCopies
        : this.NUMBERCOPY;

      const fileName = dataPayer.file.name
        .replace(/[^a-zA-Z0-9 .]/g, "")
        .replace(/ /g, "_");

      const promiseDocument = new Promise((resolve, reject) => {
        let datePersisted: string = "";

        for (let i = 1; i <= numberOfCopy; i++) {
          this.fileManagerRemoteService
            .uploadOnbaseDocumentsFile(this.advisor, fileName, factoryFileId, i)
            .toPromise()
            .then((data: any) => {
              this.fileManagerRemoteService
                .putFileWithProgress(data.url, dataPayer.file, fileExtension)
                .subscribe((loadPercentage: number) => {
                  this.loadPercentage.next(loadPercentage);
                });

              datePersisted = data.urlDate;

              if (i === 1) {
                document = this.initDocumentsUpload(
                  dataPayer,
                  datePersisted,
                  fileName,
                );

                resolve(this.createUploadDocuments.documents.push(document));
              }
            })
            .catch((error) => {
              reject(error);
              dataPayer.reasons = [
                UploadDocumentsStatesEnum.DOCUMENT_LOAD_INVALIDATE,
              ];
            });
        }
      });

      promisesDocuments.push(promiseDocument);
    });

    await Promise.all(promisesDocuments);
  }

  async saveUrlQueues() {
    this.onbaseLoadRemoteService
      .uploadDocumentCustomer(this.createUploadDocuments)
      .then(() => {
        this.onbaseLoadService.getChargeDocumentPreload(
          this.createUploadDocuments,
        );
      })
      .catch((error: any) => {
        return error;
      });
  }

  private initDocumentsUpload(
    payer: any,
    datePersisted: string,
    fileName: string,
  ) {
    const cucCode = payer.name.split(" ");
    return {
      cucCode: cucCode[0],
      name: payer.name,
      datePersisted,
      fileName,
      id: payer.fileId ? payer.fileId : "",
      copy: this.NUMBERCOPY,
      documentAlreadyInCloud: payer.documentAlreadyInCloud,
    };
  }

  private initCreateSendDocuments(): ISendDocumentOnbase {
    return {
      factoryFileId: 0,
      documentType: this.customerOnbase.documentType,
      documentNumber: this.customerOnbase.documentNumber,
      payerName: "",
      documentStatus: this.customerOnbase.documentStatus
        ? this.customerOnbase.documentStatus
        : "",
      advisorId: this.advisor.sellerIdNumber.toString(),
      advisorOfficeId: this.advisor.office.idoffice.toString(),
      documents: [],
    };
  }
}
