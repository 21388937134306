import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";

@Injectable({
  providedIn: "root",
})
export class AuthenticationRemoteService {
  authOtpEndpoint = "";

  constructor(private httpClient: HttpClient) {}

  async asyncSearchAdvisor(advisor: any) {
    return this.httpClient
      .post(environment.serverUrlSalaryLoans + "advisor/search", advisor)
      .toPromise();
  }

  generateOtp(dataGenerateCodeOtp: any) {
    return this.httpClient
      .put(environment.serverUrlSalaryLoans + "auth/otp", dataGenerateCodeOtp)
      .toPromise();
  }

  generateOtpByCall(dataGenerateCodeOtp: any): Observable<object> {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + "auth/otpCall",
      dataGenerateCodeOtp,
    );
  }

  authenticationOtp(
    advisor: IAdvisor,
    customer: ICustomer,
    otpValue: string,
    recaptchaResponse: string,
  ) {
    this.authOtpEndpoint = "auth/otp";
    return this.authOtp(advisor, customer, recaptchaResponse, otpValue);
  }

  authenticationCallCenter(
    advisor: IAdvisor,
    customer: ICustomer,
    recaptchaResponse: string,
  ) {
    this.authOtpEndpoint = "auth/repricing/generateToken";
    return this.authOtp(advisor, customer, recaptchaResponse);
  }

  private authOtp(
    advisor: IAdvisor,
    customer: ICustomer,
    recaptchaResponse: string,
    otpValue = null,
  ) {
    return this.httpClient.post(
      environment.serverUrlSalaryLoans + this.authOtpEndpoint,
      {
        advisorDecisionType: advisor.decisionType,
        advisorDocumentNumber: advisor.sellerIdNumber,
        advisorJourneyId: advisor.advisorJourneyId,
        documentNumber: customer.documentId,
        documentType: customer.documentType,
        officeId: advisor.office.idoffice.toString(),
        officeName: advisor.office.name_office,
        otpValue,
        recaptchaResponse,
      },
    );
  }

  setLogout(): Observable<object> {
    return this.httpClient.delete(
      environment.serverUrlSalaryLoans + "auth/user/session",
    );
  }
}
