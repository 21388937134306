import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { IStep } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { AdvisorDecisionTypeEnum } from "../../../core/enums/advisor-decision-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";

import { IPayrollLoanStatus } from "../../../core/interfaces/payroll-loan-status.interface";

import { PayrollLoanStatusService } from "../../../services/local/payroll-loan-status.service";
import { StepService } from "../../../services/local/step.service";

@Component({
  selector: "app-payroll-loan-status-view",
  templateUrl: "./payroll-loan-status-view.component.html",
  styleUrls: ["./payroll-loan-status-view.component.scss"],
})
export class PayrollLoanStatusViewComponent implements OnDestroy, OnInit {
  authenticationCellphoneText: string;
  captionButtonConsultOtherPayroll: string;
  captionButtonGoToInit: string;

  payrollLoanStatus: IPayrollLoanStatus;
  formGroup: UntypedFormGroup;
  listSteps: IStep[];

  constructor(
    private payrollLoanStatusService: PayrollLoanStatusService,
    private router: Router,
    private stepService: StepService,
  ) {}

  async ngOnInit() {
    this.listSteps = this.stepService.getListSteps();
    this.payrollLoanStatus = this.payrollLoanStatusService.getPayrollLoanStatus();
    this.authenticationCellphoneText = `El número de celular utilizado para autenticar la OTP es
      ${this.payrollLoanStatus.authenticationCellphone}`;
    this.captionButtonConsultOtherPayroll = "Consultar otra Libranza";
    this.captionButtonGoToInit = "Ir al inicio";

    this.initForm();
  }

  ngOnDestroy() {
    this.payrollLoanStatusService.clearPayrollLoanState();
    this.payrollLoanStatusService.clearPayrollLoanStatus();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  goToGetPayrollLoanStatus() {
    this.router.navigate([
      "logincustomer",
      AdvisorDecisionTypeEnum.GET_PAYROLL_LOAN_STATUS,
    ]);
  }

  goToLoginAdvisor() {
    this.router.navigate(["loginadvisor"]);
  }

  showAuthenticationCellphone(): boolean {
    return (
      this.payrollLoanStatus.authenticationCellphone !==
      ApplicationConstantsEnum.CHARACTER_EMPTY
    );
  }
}
