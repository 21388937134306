import { Injectable } from "@angular/core";

import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { PayrollLoanStatusEnum } from "../../core/enums/payroll-loan-status.enum";

import { IAdvisor } from "../../core/interfaces/advisor.interface";
import { ICustomer } from "../../core/interfaces/customer.interface";
import { IPayrollLoanStatus } from "../../core/interfaces/payroll-loan-status.interface";

import { PayrollLoanStatusCausalRemoteService } from "../remote/payroll-loan-status-causal-remote.service";
import { PayrollLoanStatusRemoteService } from "../remote/payroll-loan-status-remote.service";

import { OverflowErrorsService } from "./overflow-errors.service";

@Injectable({
  providedIn: "root",
})
export class PayrollLoanStatusService {
  payrollLoanState: string;
  payrollLoanStatus: IPayrollLoanStatus;
  constructor(
    private payrollLoanStatusCausalRemoteService: PayrollLoanStatusCausalRemoteService,
    private payrollLoanStatusRemoteService: PayrollLoanStatusRemoteService,
    private overflowErrorsService: OverflowErrorsService,
  ) {}

  clearPayrollLoanState() {
    this.payrollLoanState = null;
  }

  clearPayrollLoanStatus() {
    this.payrollLoanStatus = null;
  }

  getPayrollLoanStatus(): IPayrollLoanStatus {
    return this.payrollLoanStatus;
  }

  getPayrollLoanState(): string {
    return this.payrollLoanState;
  }

  async searchPayrollLoanStatus(advisor: IAdvisor, customer: ICustomer) {
    await this.payrollLoanStatusRemoteService
      .asyncSearchPayrollLoanStatus(
        advisor.sellerIdNumber,
        customer.documentType,
        customer.documentId,
      )
      .then((status: any) => {
        if (!status.state) {
          status.state = PayrollLoanStatusEnum.NO_STATE;
          this.setPayrollLoanStatus(status);
        } else {
          this.payrollLoanState = status.state;
          switch (status.state) {
            case PayrollLoanStatusEnum.CAPTURED:
            case PayrollLoanStatusEnum.APPROVED:
            case PayrollLoanStatusEnum.IMPROVED:
            case PayrollLoanStatusEnum.IMPROVED_READY_FOR_DISBURSEMENT:
            case PayrollLoanStatusEnum.CAPTURED_INCOMPLETE_DOCUMENTATION:
            case PayrollLoanStatusEnum.CAPTURED_PENDING_PARAMETERS:
            case PayrollLoanStatusEnum.WAITING_FOR_DOCUMENTS:
              this.setPayrollLoanStatus(status);
              break;
            case PayrollLoanStatusEnum.NOT_CAPTURED:
            case PayrollLoanStatusEnum.NOT_APPROVED:
              if (status.causal !== null) {
                this.setPayrollLoanStatusCausal(status);
                break;
              }
            case PayrollLoanStatusEnum.NOT_IMPROVED:
              if (status.causal !== null) {
                this.setPayrollLoanStatusCausal(status);
                break;
              }
            case PayrollLoanStatusEnum.IN_PROCESS:
              status.causal !== null
                ? this.setPayrollLoanStatusCausal(status)
                : this.setPayrollLoanStatus(status);
              break;
            default:
              status.state =
                PayrollLoanStatusEnum.IMPROVED_READY_FOR_DISBURSEMENT;
              this.setPayrollLoanStatus(status);
          }
        }
        this.payrollLoanStatus.authenticationCellphone = status.cellPhoneNumber;
      })
      .catch((statusError: any) => {
        if (statusError) {
          this.overflowErrorsService.setOverflowError(
            statusError.error.code
              ? statusError.error.code
              : OverflowErrorsEnum.TECHNICAL_ERROR,
          );
        }
      });
  }

  setPayrollLoanStatus(status: any) {
    this.payrollLoanStatus = this.payrollLoanStatusRemoteService.searchPayrollLoanStatusData(
      status.state,
      status.payerName,
      status.toDo,
    );
  }

  setPayrollLoanStatusCausal(status: any) {
    this.payrollLoanStatus = this.payrollLoanStatusCausalRemoteService.searchPayrollLoanStatusCausalData(
      status.causal,
      status.payerName,
      status.toDo,
    );
  }
}
