import { Injectable } from "@angular/core";

import { IOverflowError } from "../../core/interfaces/overflow-error.interface";

import * as overflowErrorsData from "../data/overflow-errors-data.json";

@Injectable({
  providedIn: "root",
})
export class OverflowErrorsRemoteService {
  public searchOverflowErrorsData(errorCode: string): IOverflowError {
    return (overflowErrorsData as any).default.find(
      (overflowError: IOverflowError) => overflowError.errorCode === errorCode,
    );
  }
}
