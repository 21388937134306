import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "MissingQuotas",
})
export class MissingQuotas implements PipeTransform {
  transform(progressQuotas: number): string {
    return `Llevas el ${progressQuotas}% de la deuda pagada`;
  }
}
