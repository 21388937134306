import { Injectable } from "@angular/core";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";
import { Observable } from "rxjs";

import { IAdvisor } from "../../core/interfaces/advisor.interface";

import { AdvisorDecisionTypeEnum } from "../../core/enums/advisor-decision-type.enum";
import { AdvisorTypeEnum } from "../../core/enums/advisor-type.enum";

import { AuthenticationRemoteService } from "../remote/authentication-remote.service";

import { OrganizationManagerService } from "./organization-manager.service";
import { ToggleService } from "./toggle.service";
import { ValidateResponseService } from "./validate-response.service";

@Injectable({
  providedIn: "root",
})
export class AdvisorService {
  private advisor: IAdvisor = {};

  constructor(
    private authenticationRemoteService: AuthenticationRemoteService,
    private organizationManagerService: OrganizationManagerService,
    private toggleService: ToggleService,
    private validateResponseService: ValidateResponseService,
  ) {}

  async asyncSearchAdvisor(dataAdvisor: any): Promise<IAdvisor> {
    const $this = this.getAdvisor();

    return this.authenticationRemoteService
      .asyncSearchAdvisor(dataAdvisor)
      .then((data: any) => {
        if (!this.validateResponseService.validateSearchAdvisor(data)) {
          throw {};
        }

        $this.administrativeOffice = dataAdvisor.administrativeOffice;
        $this.advisorJourneyId = data.advisorJourneyId;
        $this.advisorType = data.administrativeOffice
          ? AdvisorTypeEnum.EXTERNAL_SALES_FORCE
          : AdvisorTypeEnum.OFFICE_SALES;
        $this.cognitoKronos = data.cognitoKronos;
        $this.loyaltyAttempts = data.remainingAttempts;
        $this.office = dataAdvisor.office;
        $this.salesChannelName = data.nameChannel;
        $this.idChannel = data.idChannel;
        $this.sellerIdNumber = data.identificationNumber;
        $this.sellerName = data.name;
        $this.accessKey = data.accessKey;
        $this.secretKey = data.secretKey;
        $this.sessionToken = data.sessionToken;

        this.toggleService.validateUpdatedTogglesDate(data.updatedTogglesDate);

        return $this;
      })
      .catch((error) => {
        throw error;
      });
  }

  getAdvisor(): IAdvisor {
    return this.advisor;
  }

  getDecisionType() {
    return this.advisor.decisionType;
  }

  setAdvisor(advisor: IAdvisor) {
    this.advisor = advisor;
  }

  isAdvisorDecisionSendViabilityQuery() {
    return (
      this.advisor.decisionType === AdvisorDecisionTypeEnum.SEND_VIABILITY_QUERY
    );
  }

  isLoanByPhoneCall() {
    return (
      this.advisor.decisionType ===
      AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
    );
  }

  isLoanByPresential() {
    return (
      this.advisor.decisionType === AdvisorDecisionTypeEnum.NEW_PAYROLL_LOAN
    );
  }

  setAdministrativeOffice(administrativeOffice: IListSelect) {
    this.advisor.administrativeOffice =
      administrativeOffice !== undefined
        ? this.organizationManagerService.getAdministrativeOfficeByCode(
            administrativeOffice.value,
          )
        : undefined;
  }

  setCognitoToken(cognitoToken: string) {
    this.advisor.cognitoToken = cognitoToken;
  }

  setCognitoTokenLegacy(cognitoTokenLegacy: string) {
    this.advisor.cognitoTokenLegacy = cognitoTokenLegacy;
  }

  setCognitoTokenNew(cognitoTokenNew: string) {
    this.advisor.cognitoTokenNew = cognitoTokenNew;
  }

  setAccessToken(accessToken: string) {
    this.advisor.accessToken = accessToken;
  }

  setOffice(office: IListSelect) {
    this.advisor.office =
      office !== undefined
        ? this.organizationManagerService.getOfficeByIdOffice(office.value)
        : undefined;
  }

  setRestoreId(restoreId: string) {
    this.advisor.restoreIdFreshChat = restoreId;
  }

  clearAdvisor() {
    this.advisor = undefined;
  }

  setExternalAdvisor(externalAdvisor: any) {
    this.advisor = {};
    this.advisor.accessKey = externalAdvisor.accessKey;
    this.advisor.accessToken = externalAdvisor.accessToken;

    if (externalAdvisor.administrativeOffice) {
      this.advisor.administrativeOffice = externalAdvisor.administrativeOffice;
    }

    this.advisor.advisorJourneyId = externalAdvisor.advisorJourneyId;
    this.advisor.advisorType = externalAdvisor.advisorType;
    this.advisor.cognitoToken = externalAdvisor.cognitoToken;
    this.advisor.decisionType = externalAdvisor.decisionType;
    this.advisor.federatedAuthClientId = externalAdvisor.federatedAuthClientId;
    this.advisor.federatedAuthToken = externalAdvisor.federatedAuthToken;
    this.advisor.idChannel = externalAdvisor.idChannel;
    this.advisor.loyaltyAttempts = externalAdvisor.loyaltyAttempts;
    this.advisor.office = externalAdvisor.office;
    this.advisor.salesChannelName = externalAdvisor.salesChannelName;
    this.advisor.secretKey = externalAdvisor.secretKey;
    this.advisor.sellerIdNumber = externalAdvisor.sellerIdNumber;
    this.advisor.sellerName = externalAdvisor.sellerName;
    this.advisor.sessionToken = externalAdvisor.sessionToken;
    this.advisor.isUnification = externalAdvisor.isUnification;
    this.advisor.fromFrontOld = externalAdvisor.fromFrontOld;
  }

  setLogout(): Observable<object> {
    return this.authenticationRemoteService.setLogout();
  }
}
