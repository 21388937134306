import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-form-popup-view-more",
  templateUrl: "./form-popup-view-more.component.html",
})
export class FormPopupViewMoreComponent implements OnInit {
  @Input() title: string;
  @Input() contentText: string;
  @Output() closePopupEvent = new EventEmitter<string>();
  @Output() closeSavePopupEvent = new EventEmitter<string>();

  formGroup: UntypedFormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({});
  }

  closePopup() {
    this.closePopupEvent.emit();
  }

  closeSavePopup() {
    this.closeSavePopupEvent.emit();
  }
}
