<div *ngIf="showPopUpValidationQueryDecrim">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div class="close-button">
      <img
        src="./assets/images/icons/close.svg"
        alt="Cerrar"
        (click)="closePopupAndRedirect(false)"
      />
    </div>

    <div class="w-100 h-90-px d-flex align-items-center justify-content-center">
      <div>
        <img [src]="message.icon" alt="Icon Validation Query Decrim" />
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85 text-center">
        <adl-text-title [level]="3" [text]="message.title"></adl-text-title>
      </div>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85 text-center">
        <adl-text-description
          [text]="message.description"
        ></adl-text-description>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="w-85">
        <adl-information-bar
          [identificationText]="documentId"
          [clientText]="completeName"
        >
        </adl-information-bar>
      </div>
    </div>

    <div class="h-20-px"></div>

    <div
      *ngIf="!message.descriptionContent"
      class="w-100 d-flex justify-content-center"
    >
      <div class="w-85 box-authentication-states">
        <ng-container
          *ngFor="
            let itemResponseValidateDecrimQuery of responseValidateDecrimQuery;
            let i = index;
            let isOdd = odd
          "
        >
          <app-authentication-states
            [cssClass]="isOdd ? 'box-authentication-states-info-gray' : ''"
            [authenticationState]="itemResponseValidateDecrimQuery"
          ></app-authentication-states>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="message.descriptionContent"
      class="w-100 d-flex justify-content-center"
    >
      <div class="w-85 box-authentication-states">
        <adl-text-description
          [text]="message.descriptionContent"
          cssClass="text-center"
        ></adl-text-description>
      </div>
    </div>
    <div class="w-100 h-110-px d-flex justify-content-center">
      <div class="w-85 d-flex align-items-center justify-content-center">
        <div class="w-100">
          <adl-button
            id="adl-button_valildation-query"
            cssClass="w-100 buttonTextMedium  {{
              formGroup.disabled ? 'buttonSilver' : ''
            }} "
            [mandatory]="true"
            [text]="message.captionButton"
            [parentForm]="formGroup"
            (click)="goToAction()"
          ></adl-button>
        </div>
      </div>
    </div>
  </form>
</div>
