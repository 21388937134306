import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { PayrollCheckCaptureButtonTextEnum } from "../../../core/enums/payroll-check-capture-button-text.enum";

import { PayrollCheckCaptureData } from "../../../core/interfaces/payroll-check-capture-data.interface";

import { PayrollCheckCaptureDataService } from "../../../services/local/payroll-check-capture-data.service";

@Component({
  selector: "app-form-popup-payroll-check-capture",
  templateUrl: "./form-popup-payroll-check-capture.component.html",
  styleUrls: ["./form-popup-payroll-check-capture.component.scss"],
})
export class FormPopupPayrollCheckCaptureComponent implements OnInit {
  buttonText = PayrollCheckCaptureButtonTextEnum.CORRECT_CAPTURE;
  captureData: PayrollCheckCaptureData;
  formDataCapture: UntypedFormGroup;
  isFullGridPayerName: boolean = false;

  @Input() set setCaptureData(data: PayrollCheckCaptureData) {
    this.captureData = this.payrollCheckCaptureDataService.transformEmptyProperties(
      data,
    );
  }

  constructor(
    private fb: UntypedFormBuilder,
    private payrollCheckCaptureDataService: PayrollCheckCaptureDataService,
  ) {
    this.formDataCapture = this.fb.group({
      affiliationNumber: [false],
      bonuses: [false],
      customerName: [false],
      documentNumber: [false],
      extraPayment: [false],
      healthDiscount: [false],
      honorarium: [false],
      otherDiscount1: [false],
      otherDiscount2: [false],
      otherDiscount3: [false],
      otherLawDiscount: [false],
      overtime: [false],
      payerName: [false],
      payrollCheckDate: [false],
      pensionDiscount: [false],
      position: [false],
      positionLevel: [false],
      salary: [false],
      totalOtherIncome: [false],
    });
  }

  ngOnInit(): void {
    this.onFormValueChange();
  }

  getCountSpacesPayerName(): boolean {
    const spacesPayerName = this.captureData?.payerName.split(" ");
    const largePayerName = spacesPayerName ? spacesPayerName?.length : 0;
    this.isFullGridPayerName =
      largePayerName > ApplicationConstantsEnum.MAXIMUM_SPACES_PAYERNAME;
    return this.isFullGridPayerName;
  }

  getIncorrectValuesString() {
    return this.payrollCheckCaptureDataService.getIncorrectValuesString(
      this.captureData,
      this.formDataCapture.value,
    );
  }

  onFormValueChange() {
    this.formDataCapture.valueChanges.subscribe((values) => {
      const checks = Object.values(values);
      if (checks.some((item) => item === true)) {
        this.buttonText = PayrollCheckCaptureButtonTextEnum.SEND_TO_CORRECTION;
      } else {
        this.buttonText = PayrollCheckCaptureButtonTextEnum.CORRECT_CAPTURE;
      }
    });
  }
}
