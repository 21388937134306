import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { IRadioButtonData } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

@Component({
  selector: "app-form-popup-short-flow-repricing",
  templateUrl: "./form-popup-short-flow-repricing.component.html",
  styleUrls: ["./form-popup-short-flow-repricing.component.scss"],
})
export class FormPopupShortFlowRepricingComponent implements OnInit {
  @Input("showPopupShortFlow")
  set setShowPopupShortFlow(showPopupShortFlow: boolean) {
    this.showPopupShortFlow = showPopupShortFlow;
  }

  @Output() closePopupEvent = new EventEmitter<string>();

  formGroup: UntypedFormGroup;
  showPopupShortFlow: boolean = false;
  radioButtons: IRadioButtonData[] = [
    {
      title: "Proceso corto",
      label: "Menos tiempo en el desembolso",
      value: "1",
    },
    {
      title: "Proceso completo",
      label: "Proceso más detallado y prolongado",
      value: "0",
    },
  ];
  popupIcon: string = "./assets/images/groups/success.svg";
  popupTitle: string = "Validación exitosa";
  popupMessage: string =
    "Tu cliente es apto para continuar con la solicitud. Elige entre estos dos procesos de la herramienta digital para facilitar la solicitud de tu cliente";
  popuptextOkButton: string = "Continuar";
  popupdisabledtOkButton: boolean = true;

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      shortFlow: new UntypedFormControl("", Validators.required),
    });
  }

  selectedValue() {
    if (this.popupdisabledtOkButton) {
      this.popupdisabledtOkButton = false;
    }
  }

  continueFlowReprecing() {
    this.closePopupEvent.emit(this.formGroup.get("shortFlow").value);
  }
}
