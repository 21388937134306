import { Injectable } from "@angular/core";
import {
  INotificationResponse,
  NotificationResponseTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { NotificationReponseRemoteService } from "../remote/notification-reponse-remote.service";

@Injectable({
  providedIn: "root",
})
export class NotificationReponseService {
  constructor(
    private notificationResponseRemoteService: NotificationReponseRemoteService,
  ) {}

  getNotificationResponse(
    notificationResponseTypeEnum: NotificationResponseTypeEnum,
    description?: string,
    title?: string,
  ): INotificationResponse {
    return this.notificationResponseRemoteService.getNotificationResponse(
      notificationResponseTypeEnum,
      description,
      title,
    );
  }
}
