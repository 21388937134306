<div *ngIf="popupTraining">
  <adl-background-black></adl-background-black>
  <form class="form-popup" [formGroup]="formGroup" novalidate>
    <div
      class="w-100 h-175-px d-flex align-items-center justify-content-center"
    >
      <div>
        <img src="./assets/images/icon-training.png" alt="Icon Error" />
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <div class="w-85">
        <adl-text-title
          *ngIf="courseDisplay"
          [level]="3"
          [text]="courseDisplay.name"
        ></adl-text-title>
      </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="w-85">
        <adl-text-description
          *ngIf="pageDisplay"
          [text]="pageDisplay.textPage"
        ></adl-text-description>
      </div>
    </div>
    <div class="w-100 h-75-px d-flex align-items-start justify-content-center">
      <div class="w-85">
        <adl-button
          id="adl-button_button-next"
          *ngIf="nextButton"
          [cssClass]="'w-100 buttonTextMedium'"
          [mandatory]="true"
          [text]="'Siguiente >>'"
          [parentForm]="formGroup"
          (clickEvent)="next()"
        ></adl-button>
        <adl-button
          id="adl-button_button-continue"
          *ngIf="continueButton"
          [cssClass]="'w-100 buttonTextMedium'"
          [mandatory]="true"
          [text]="'Continuar'"
          [parentForm]="formGroup"
          (clickEvent)="continue()"
        ></adl-button>
      </div>
    </div>
  </form>
</div>
