<div
  class="container-close d-flex justify-content-end"
  *ngIf="authenticationDone"
  (click)="closeSession()"
>
  <img
    class="container-img"
    src="./assets/images/icons/close-sesion.svg"
    alt="Icon close session"
  />
  <span class="container-text p-l-10-px">Cerrar sesión</span>
</div>
