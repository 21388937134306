import { Injectable } from "@angular/core";

import { IMapTableFabric } from "../maps/map-table-fabric.interface";

import { StateDocumentsMap } from "../maps/state-documents-map.class";

import { TableTypeEnum } from "../../core/enums/table-type.enum";

import { NotFoundDocumentsMap } from "../maps/not-found-documents-map";
import { SearchCustomerMap } from "../maps/searchCustomer-map.class";
import { UploadDocumentsMap } from "../maps/upload-documents-map.class";

@Injectable({
  providedIn: "root",
})
export class MapTableRemoteService {
  private mapTableFabric: IMapTableFabric;

  getMapTableData(tableTypeEnum: TableTypeEnum): any[] {
    switch (tableTypeEnum) {
      case TableTypeEnum.UPLOAD_DOCUMENTS:
        this.mapTableFabric = new UploadDocumentsMap();
        break;
      case TableTypeEnum.UPLOAD_DOCUMENTS_STATE:
        this.mapTableFabric = new StateDocumentsMap();
        break;
      case TableTypeEnum.SEARCHCUSTOMER:
        this.mapTableFabric = new SearchCustomerMap();
        break;
      case TableTypeEnum.NOTFOUNDDOCUMENTS:
        this.mapTableFabric = new NotFoundDocumentsMap();
        break;
    }

    return this.mapTableFabric.getMapTableData();
  }
}
