import { HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import { from } from "rxjs";
import { concatMap } from "rxjs/operators";

import { BrowserModule } from "@angular/platform-browser";
import {
  BpopSecurityFrontendLibModule,
  MainService,
} from "@avaldigitallabs/bpop-security-frontend-lib";

import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppDesignModule } from "./design/app-design.module";

import { FederatedAuthService } from "./services/local/federated-auth.service";
import { ToggleService } from "./services/local/toggle.service";

function initializeAppFactory(
  provider: ToggleService,
  mainService: MainService,
) {
  return () => {
    return from(mainService.initializeSecurityKeys()).pipe(
      concatMap(() => from(provider.resolve())),
    );
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppDesignModule,
    AppRoutingModule,
    BrowserModule,
    BpopSecurityFrontendLibModule.forRoot({
      environment: environment.security,
    }),
    HttpClientModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [ToggleService, MainService],
      multi: true,
    },
    FederatedAuthService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
