import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EventManagerService {
  private subject = new Subject<any>();
  private subjectMap = new Map<string, Subject<any>>();

  publish(event: string, data: any) {
    if (this.subjectMap.has(event)) {
      this.subjectMap.get(event).next({ event, data });
    } else {
      this.subjectMap.set(event, new Subject<any>());
      this.subjectMap.get(event).next({ event, data });
    }
  }

  on(eventName: string): Observable<any> {
    const subject =
      this.subjectMap.get(eventName) ||
      this.subjectMap.set(eventName, new Subject<any>()).get(eventName);

    return subject.asObservable().pipe(
      map((item: { event: string; data: any }) => {
        return item.data;
      }),
    );
  }

  unsubscribe(eventName?: string) {
    this.subjectMap.get(eventName)?.complete();
    this.subjectMap.delete(eventName);
  }
}
