import { Injectable } from "@angular/core";
import {
  CirclePointTypeEnum,
  IStep,
  ProgressBarStepTypeEnum,
} from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { PayrollLoanStatusEnum } from "../../core/enums/payroll-loan-status.enum";

import { PayrollLoanStatusService } from "../local/payroll-loan-status.service";
import { StepRemoteService } from "../remote/step-remote.service";

@Injectable({
  providedIn: "root",
})
export class StepService {
  listSteps: IStep[];
  constructor(
    private payrollLoanStatusService: PayrollLoanStatusService,
    private stepRemoteService: StepRemoteService,
  ) {}

  getStepPayrollLoanStatus(): IStep[] {
    return this.stepRemoteService.getStepByEnum(
      ProgressBarStepTypeEnum.PAYROLL_LOAN_STATUS,
    );
  }

  getListSteps(): IStep[] {
    this.listSteps = this.getStepPayrollLoanStatus();

    switch (this.payrollLoanStatusService.getPayrollLoanState()) {
      case PayrollLoanStatusEnum.CAPTURED:
      case PayrollLoanStatusEnum.CAPTURED_INCOMPLETE_DOCUMENTATION:
      case PayrollLoanStatusEnum.CAPTURED_PENDING_PARAMETERS:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OFF;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OFF;
        break;
      case PayrollLoanStatusEnum.NOT_CAPTURED:
      case PayrollLoanStatusEnum.NO_STATE:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.ACTIVE;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OFF;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OFF;
        break;
      case PayrollLoanStatusEnum.APPROVED:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OFF;
        break;
      case PayrollLoanStatusEnum.NOT_APPROVED:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.ACTIVE;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OFF;
        break;
      case PayrollLoanStatusEnum.NOT_IMPROVED:
      case PayrollLoanStatusEnum.IN_PROCESS:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.ACTIVE;
        break;
      case PayrollLoanStatusEnum.IMPROVED:
      case PayrollLoanStatusEnum.IMPROVED_READY_FOR_DISBURSEMENT:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OK;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OK;
        break;
      default:
        this.listSteps[0].circlePointType = CirclePointTypeEnum.OFF;
        this.listSteps[1].circlePointType = CirclePointTypeEnum.OFF;
        this.listSteps[2].circlePointType = CirclePointTypeEnum.OFF;
        break;
    }

    return this.listSteps;
  }
}
