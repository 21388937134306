<div class="salaryLoan pt-3 mb-4 color-{{ color }} animation-{{ color }}">
  <form class="d-flex flex-column" [formGroup]="formGroup" novalidate>
    <div class="pr-3 pl-3 bb-gray">
      <div class="row mb-3">
        <div class="col-12 d-flex align-items-end p-b-sm-6">
          <div>
            <adl-text-title
              [text]="customerLoans.payerUniqueName"
              [level]="4"
            ></adl-text-title>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <adl-text-title
              [text]="'Monto inicial'"
              [level]="6"
            ></adl-text-title>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <adl-text-description
              [cssClass]="'font-lg-bold'"
              [text]="
                customerLoans.loanAmount | currency: 'COP':'symbol-narrow'
              "
            ></adl-text-description>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <div>
            <adl-text-title
              [text]="'Deuda actual: '"
              [level]="5"
            ></adl-text-title>
          </div>
        </div>
        <div class="col-6 text-right">
          <div>
            <adl-text-description
              [cssClass]="'mb-0 font-weight-bold'"
              [text]="
                customerLoans.currentDeb | currency: 'COP':'symbol-narrow'
              "
            ></adl-text-description>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <adl-progress-bar
            #progressBar
            [initialProgress]="progressQuotas"
          ></adl-progress-bar>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <adl-text-description
              [cssClass]="'text-sm-left'"
              [text]="progressQuotas | MissingQuotas"
            ></adl-text-description>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 br-gray">
          <div class="row">
            <div class="col-12">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Cuota'"
                ></adl-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-cuota ">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="
                    customerLoans.currentQuotaAmount
                      | currency: 'COP':'symbol-narrow'
                  "
                ></adl-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 br-gray">
          <div class="row">
            <div class="col-12 ">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Plazo'"
                ></adl-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 ">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="customerLoans.loanQuota | TermQuotas"
                ></adl-text-description>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="col-12 ">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left'"
                  [text]="'Pendientes'"
                ></adl-text-description>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 ">
              <div>
                <adl-text-description
                  [cssClass]="'mb-0 text-md-left font-weight-bold'"
                  [text]="customerLoans.missingLoanQuotas | TermQuotas"
                ></adl-text-description>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div class="w-100 float-left">
        <adl-notification-response
          [notificationResponse]="notificationResponse"
        ></adl-notification-response>
      </div>
    </div>

    <section *ngIf="!customerLoans.showUnificationControls">
      <div
        class="p-3 text-center"
        *ngIf="customerLoans.showDoublePensionControl"
      >
        <div class="w-100">
          <adl-text-label
            [cssClass]="'font-weight-normal'"
            style="font-size: 14px;"
            [text]="'¿Tu cliente cuenta con doble pension?'"
          >
          </adl-text-label>

          <div class="d-flex justify-content-center">
            <adl-radio-group
              [parentForm]="formGroup"
              [nameRadioGroup]="'doublePensionRadioGroup'"
              [radioButtonData]="radioButtonData"
            ></adl-radio-group>
          </div>
        </div>
      </div>

      <div class="p-3">
        <div class="animation-button w-100">
          <adl-button
            id="adl-button_form-salary-loan"
            *ngIf="showButton"
            [cssClass]="'w-100 h-40-px buttonTextSmall'"
            [mandatory]="true"
            [text]="customerLoans.messageLoan.captionButton"
            [parentForm]="formGroup"
            (clickEvent)="clickButton(customerLoans.messageLoan.loanFlowType)"
          ></adl-button>
        </div>
      </div>
    </section>

    <section
      class="w-100 d-flex flex-column px-4 py-1"
      *ngIf="customerLoans.showUnificationControls"
    >
      <ng-container *ngIf="customerLoans.showDoublePensionControl">
        <div class="w-100 d-flex justify-content-center text-center pb-4">
          <div class="w-100">
            <adl-text-label
              [cssClass]="'font-weight-normal'"
              style="font-size: 14px;"
              [text]="'¿Tu cliente cuenta con doble pension?'"
            >
            </adl-text-label>
            <div class="d-flex justify-content-center">
              <adl-radio-group
                [parentForm]="formGroup"
                [nameRadioGroup]="'doublePensionRadioGroup'"
                [radioButtonData]="radioButtonData"
              ></adl-radio-group>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="loanByPhoneCall || reprecing">
        <adl-button
          id="adl-button_button-upload"
          [cssClass]="'w-100 buttonTextSmall h-40-px animation-button'"
          [loading]="loadingButton"
          [mandatory]="true"
          [parentForm]="formGroup"
          text="Subir documentos"
          (clickEvent)="onClickLoadDocuments()"
        ></adl-button>

        <adl-text-label
          [cssClass]="'font-weight-normal text-center mt-3'"
          style="font-size: 14px; line-height: 24px;"
          text="
              El cliente debe subir su desprendible de pago. Le enviaremos un
            enlace a su email y celular registrados dónde podrá cargarlo."
        >
        </adl-text-label>

        <adl-button
          id="adl-button_send_link"
          [cssClass]="'w-100 buttonTextOrange h-40-px mt-0'"
          [mandatory]="true"
          [parentForm]="formGroup"
          text="Enviar enlace"
          (clickEvent)="onClickSendLink()"
        ></adl-button>
      </div>

      <div class="p-3" *ngIf="!loanByPhoneCall">
        <div class="animation-button w-100">
          <adl-button
            id="adl-button_form-salary-loan-unification"
            *ngIf="showButton"
            [cssClass]="'w-100 h-40-px buttonTextSmall'"
            [mandatory]="true"
            [text]="customerLoans.messageLoan.captionButton"
            [parentForm]="formGroup"
            (clickEvent)="clickButton(customerLoans.messageLoan.loanFlowType)"
          ></adl-button>
        </div>
      </div>
    </section>
  </form>
</div>
