import { Injectable } from "@angular/core";
import { find, propEq } from "ramda";

import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { LocalStorageKeyEnum } from "../../core/enums/local-storage-key.enum";

import { IOnBaseLoad } from "../../core/interfaces/onbase-load.interface";

import { OnbaseLoadRemoteService } from "../remote/onbase-load-remote.service";

@Injectable({
  providedIn: "root",
})
export class OnbaseLoadService {
  documentsUpload: any;
  documentsOriginal: any;
  factoryPayersPreload: BehaviorSubject<any[]> = new BehaviorSubject([]);
  payerClient: IOnBaseLoad;

  currentfactoryPayersPreload: Observable<any[]> = this.factoryPayersPreload;

  private factoryPayerLoans: any[] = [];

  constructor(private onbaseLoadRemoteService: OnbaseLoadRemoteService) {
    const factoryPreload = localStorage.getItem(
      LocalStorageKeyEnum.FACTORYPAYERSPRELOAD,
    );
    if (factoryPreload) {
      this.emitChange(JSON.parse(factoryPreload), false);
    }
  }

  getChargeDocumentPreload(documentPreload: any) {
    this.factoryPayerLoans = this.factoryPayersPreload.value;
    this.factoryPayerLoans.push({
      factoryFileId: documentPreload.factoryFileId,
      status: documentPreload.documentStatus,
    });
    this.emitChange(this.factoryPayerLoans, true);
  }

  getOnbaseLoadDocumentStates() {
    return this.onbaseLoadRemoteService.getOnbaseLoadDocumentStates();
  }

  setDeleteDocumentLoads(documentsLoad: any) {
    for (const document of documentsLoad) {
      const findIndex = this.factoryPayerLoans.findIndex((item: any) => {
        return (
          document.salaryLoans[0].factoryFileId === item.factoryFileId &&
          document.documentStatus !== item.status
        );
      });

      if (findIndex > ApplicationConstantsEnum.INDEX_EMPTY) {
        this.factoryPayerLoans = this.factoryPayerLoans.filter((item, key) => {
          return key !== findIndex;
        });
      }
    }

    this.emitChange(this.factoryPayerLoans, true);
  }

  getConsultDocumentsOnbaseLoad(sellerIdNumber: number): Observable<object> {
    return this.onbaseLoadRemoteService
      .getConsultDocumentsOnbaseLoad(sellerIdNumber)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getSearchDocumentsUploadClient(
    documentType: string,
    documentNumber: string,
  ): Observable<object> {
    return this.onbaseLoadRemoteService
      .getSearchDocumentsUploadClient(documentType, documentNumber)
      .pipe(
        map((data: any) => {
          this.setDocumentsUpload(data);
          return data;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getSearchDocumentsFailedUploadClient(
    factoryFileId: number,
  ): Observable<object> {
    return this.onbaseLoadRemoteService
      .getSearchDocumentsFailedUploadClient(factoryFileId)
      .pipe(
        map((data: any) => {
          this.setDocumentsUpload(data);
          return data;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getClientConsultDocumentStateList(
    sellerIdNumber: number,
  ): Observable<object> {
    return this.onbaseLoadRemoteService
      .getConsultDocumentsStateOnbaseLoad(sellerIdNumber)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getCustomerDocumentFinder(documentNumber: string): Observable<object> {
    return this.onbaseLoadRemoteService
      .getCustomerDocumentFinder(documentNumber)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError([]);
        }),
      );
  }

  getDocumentsUpload() {
    return this.documentsUpload;
  }

  setDocumentsUpload(documentsUpload: any[]) {
    this.documentsUpload = documentsUpload;
  }

  getClientDocumentsUpload() {
    return this.payerClient;
  }

  setClientDocumentsUpload(payerClient: IOnBaseLoad) {
    this.payerClient = payerClient;
  }

  getDocumentsUploadToShow() {
    this.documentsOriginal = JSON.parse(JSON.stringify(this.documentsUpload));
    this.documentsUpload.loanDocuments.forEach((item: any) => {
      item.documents = item.documents.filter(
        (d: any) => !d.documentAlreadyInCloud,
      );
    });
    return this.documentsUpload;
  }

  documentsUploadedSystem(payer: string) {
    const payerSeleted = this.searchPayer(payer);
    return payerSeleted.documents.filter((documentAlready: any) => {
      return !documentAlready.fileId && documentAlready.documentAlreadyInCloud;
    });
  }

  setSelectedFile(payer: string, documentName: string, file: File) {
    const payerOriginal = this.searchPayer(payer);
    const documentOriginal = payerOriginal.documents.find((item: any) => {
      return item.name === documentName;
    });
    documentOriginal.file = file;
  }

  private searchPayer(payer: string) {
    return find(propEq(payer, "payer"))(this.documentsOriginal.loanDocuments);
  }

  private emitChange(factoryPayerLoans: any[], isCreateItem: boolean = false) {
    this.factoryPayerLoans = factoryPayerLoans;

    if (isCreateItem) {
      localStorage.setItem(
        LocalStorageKeyEnum.FACTORYPAYERSPRELOAD,
        JSON.stringify(this.factoryPayerLoans),
      );
    }

    this.factoryPayersPreload.next(this.factoryPayerLoans);
  }
}
