import { Injectable } from "@angular/core";

import { IMessage } from "../../core/interfaces/message.interface";

import * as messageData from "../data/message-data.json";

@Injectable({
  providedIn: "root",
})
export class MessageRemoteService {
  getMessageById(messageEnum: string, description?: string): IMessage {
    return this.mapGetMessageById(messageEnum, description);
  }

  private mapGetMessageById(
    messageEnum: string,
    description?: string,
  ): IMessage {
    const message: IMessage = {
      ...(messageData as any).default.find(
        (msg: any) => msg.messageId === messageEnum,
      ),
    };

    if (message.description) {
      message.description = message.description.replace(
        /{description}/,
        description,
      );
    }

    return message;
  }
}
