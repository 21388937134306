import { Router } from "@angular/router";
import { LoadingComponent } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { FormPopupUploadPaymentReceiptComponent } from "../../../design/structures/form-popup-upload-payment-receipt/form-popup-upload-payment-receipt.component";

import { AdvisorService } from "../advisor.service";
import { CustomerService } from "../customer.service";
import { EventAnalyticsService } from "../event-analytics.service";
import { GoogleAnalyticsEventsService } from "../google-analytics-events.service";
import { LoanService } from "../loan.service";
import { MessageService } from "../message.service";
import { OverflowErrorsService } from "../overflow-errors.service";
import { PayrollService } from "../payroll.service";

import { IMessage } from "../../../core/interfaces/message.interface";
import { ISalaryLoanActionFactory } from "./salary-loan-action-factory.interface";

import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { EventCodeEnum } from "../../../core/enums/event-code.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { LoanFlowEnum } from "../../../core/enums/loan-flow.enum";
import { MessageEnum } from "../../../core/enums/message.enum";
import { MessageActionEnum } from "../../../core/enums/message-action.enum";
import { ModalityTypeEnum } from "../../../core/enums/modality-type.enum";
import { OverflowErrorsEnum } from "../../../core/enums/overflow-errors.enum";
import { SalesProcessTypeEnum } from "../../../core/enums/sales-process-type.enum";
import { UploadDocumentsStatesEnum } from "../../../core/enums/upload-documents-states.enum";

export class SalaryLoanActionAnyFeeOld implements ISalaryLoanActionFactory {
  attemptsCount: number = ApplicationConstantsEnum.START_NUMBER_ATTEMPTS;
  setTimeOutRetryId: any;
  timeAdvacedUploadFileCount: number = ApplicationConstantsEnum.RESTART_COUNT;

  formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent;
  loadingComponent: LoadingComponent;
  messageEnumInPhoneCall: MessageEnum;

  isUploadFiles: boolean = true;

  constructor(
    private advisorService: AdvisorService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    formPopupUploadPaymentReceiptComponent: FormPopupUploadPaymentReceiptComponent,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private loanService: LoanService,
    loadingComponent: LoadingComponent,
    private messageService: MessageService,
    private overflowErrorsService: OverflowErrorsService,
    private payrollService: PayrollService,
    private router: Router,
  ) {
    this.formPopupUploadPaymentReceiptComponent = formPopupUploadPaymentReceiptComponent;
    this.loadingComponent = loadingComponent;
  }

  salaryLoanAction(): void {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.SALARY_LOAN_CLICK_ANY_FEE,
    );

    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      this.loanService.getPayerName(),
    );

    this.validationWhenIsNewLoanAndPhoneCall();
  }

  async salaryLoanResolve(event?: any) {
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = false;
    if (event === UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS) {
      if (
        this.timeAdvacedUploadFileCount >=
        ApplicationConstantsEnum.TIME_LIMIT_RETRY_UPLOAD_FILE
      ) {
        this.uploadFileProcessEnd(
          this.advisorService.isLoanByPhoneCall()
            ? MessageEnum.END_TIME_INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE
            : MessageEnum.PayrollChecks014,
        );
      } else {
        const customer = this.customerService.getCustomer();
        this.loadingComponent.show = true;

        if (
          !this.advisorService.isLoanByPhoneCall() &&
          customer.urlFile &&
          this.isUploadFiles
        ) {
          await this.sendToTap();
        }
        const payrollCheckId = this.loanService.getPayrollCheckId();
        this.getPayrollCheckPreLoaded(payrollCheckId);
      }
    } else if (event === UploadDocumentsStatesEnum.DOCUMENTS_LOAD_EMPTY) {
      if (this.advisorService.isLoanByPhoneCall()) {
        this.sendLinkForUploadFile();
      }

      this.payrollService.setProvidersId([]);
      this.payrollService.updateProviderId = [];
    }
  }

  private attemptsText() {
    return this.advisorService.isLoanByPresential()
      ? ApplicationConstantsEnum.RETRY +
          ApplicationConstantsEnum.CHARACTER_SPACE +
          this.attemptsCount.toString() +
          "."
      : ApplicationConstantsEnum.CHARACTER_EMPTY;
  }

  private getMessages(messageEnum: MessageEnum, description?: string) {
    const attemptText = description ?? this.attemptsText();
    let message = this.messageService.getMessageById(messageEnum, attemptText);
    if (!message.messageId) {
      message = this.messageService.getMessageById(
        MessageEnum.ERROR_UPLOAD_FILE_TAP_SOLUTION,
        this.attemptsText(),
      );
    }

    return message;
  }

  private getPayrollCheckPreLoaded(payrollCheckId: string) {
    this.payrollService
      .getPayrollCheckPreLoaded(
        payrollCheckId ? payrollCheckId : ApplicationConstantsEnum.UNKNOWN,
      )
      .subscribe(
        (dataPayrollCheck: any) => {
          this.payrollCheckPreLoadedSuccess(dataPayrollCheck);
        },
        (errorPayrollCheck: any) => {
          this.salaryLoanResolveError(errorPayrollCheck);
        },
      );
  }

  private increaseAttempsError() {
    this.isUploadFiles = true;
    if (this.attemptsCount > ApplicationConstantsEnum.NUMBER_ATTEMPTS) {
      this.overflowErrorsService.setOverflowError(
        OverflowErrorsEnum.EXCEEDED_MAXIMUM_NUMBER_ATTEMPTS_CUSTOMER,
      );

      this.router.navigate(["overflowerror"]);
    }

    this.attemptsCount++;
  }

  private initFormPopupUploadPaymentReceipt() {
    this.formPopupUploadPaymentReceiptComponent.initForm();
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = true;
    this.formPopupUploadPaymentReceiptComponent.resetProgress();
  }

  private isPhoneCallAndRetryUploadFileAction(
    messageActionEnum: MessageActionEnum,
  ): boolean {
    return (
      this.advisorService.isLoanByPhoneCall() &&
      messageActionEnum === MessageActionEnum.RETRY_UPLOAD_FILE
    );
  }

  private isPresentialAndRetryUploadFileAction(
    messageActionEnum: MessageActionEnum,
  ): boolean {
    return (
      this.advisorService.isLoanByPresential() &&
      messageActionEnum === MessageActionEnum.RETRY_UPLOAD_FILE
    );
  }

  private payrollCheckPreLoadedSuccess(dataPayrollCheck: any) {
    if (
      ApplicationConstantsEnum.PAYROLL_CHECKS_005 === dataPayrollCheck.code ||
      ApplicationConstantsEnum.PAYROLL_CHECKS_012 === dataPayrollCheck.code
    ) {
      this.loanService.setPayrollCheckId(dataPayrollCheck.id);
      this.setTimeoutSalaryLoanResolve();
      return;
    }

    this.setSalesProcessLoanDecision();

    this.uploadFileProcessEnd(MessageEnum.SUCCESS_UPLOAD_PAYMENT_RECEIPT);
  }

  private refreshLoading() {
    if (this.setTimeOutRetryId) {
      clearTimeout(this.setTimeOutRetryId);
    }

    this.timeAdvacedUploadFileCount +=
      ApplicationConstantsEnum.TIME_RETRY_UPLOAD_FILE;

    this.loadingComponent.loadingCss = ApplicationConstantsEnum.TEXT_WHITE_BOLD;
    this.loadingComponent.loadingDescription =
      "Estamos cargando la documentación";
    this.loadingComponent.loadPercentage = Math.round(
      (this.timeAdvacedUploadFileCount /
        ApplicationConstantsEnum.TIME_LIMIT_RETRY_UPLOAD_FILE) *
        100,
    );
  }

  private salaryLoanResolveError(errorPayrollCheck: any) {
    const responseError = errorPayrollCheck.error.code
      ? errorPayrollCheck.error.code
      : MessageEnum.WITHOUT_CODE;

    if (
      this.advisorService.isLoanByPhoneCall() &&
      this.messageEnumInPhoneCall ===
        MessageEnum.INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE &&
      responseError === MessageEnum.PayrollChecks001
    ) {
      this.setTimeoutSalaryLoanResolve();
      return;
    }

    this.uploadFileProcessEnd(
      responseError,
      errorPayrollCheck.error.statusDesc,
    );
  }

  private sendLinkForUploadFile() {
    this.payrollService.sendLinkForUploadFile().subscribe(
      () => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_SUCCESS,
        );

        this.messageEnumInPhoneCall =
          MessageEnum.INFO_CUSTOMER_HAS_NOT_UPLOAD_FILE;

        this.uploadFileProcessEnd(this.messageEnumInPhoneCall);
      },
      (errorSendLinkUploadFile: any) => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.NEW_LOAN_INIT_PAGE_CLICK_CONTINUE_SEND_LINK_ERROR,
        );

        if (errorSendLinkUploadFile.overFlowError) {
          this.router.navigate(["overflowerror"]);
        } else {
          this.messageEnumInPhoneCall =
            MessageEnum.INFO_CUSTOMER_HAS_UPLOAD_FILE;
          this.uploadFileProcessEnd(this.messageEnumInPhoneCall);
        }
      },
    );
  }

  private async sendToTap() {
    await new Promise((resolve, reject) => {
      this.payrollService.setPayrollFile().subscribe(
        () => resolve(true),
        (error) => reject(error),
      );
    });

    this.isUploadFiles = false;
  }

  private setSalesProcessLoanDecision() {
    if (this.customerService.isNewLoanDecision()) {
      this.loanService.setLoanFlow(LoanFlowEnum.ANYFEE);
      this.loanService.setModalityType(ModalityTypeEnum.ORDINARY);
    }

    this.loanService.setSalesProcessType(
      this.advisorService.isLoanByPhoneCall()
        ? SalesProcessTypeEnum.TELEFONIC_SALE_TC
        : SalesProcessTypeEnum.PRESENTIAL_SALE_TC,
    );
  }

  private setTimeoutSalaryLoanResolve() {
    this.refreshLoading();
    this.setTimeOutRetryId = setTimeout(() => {
      this.salaryLoanResolve(UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS);
    }, ApplicationConstantsEnum.TIME_RETRY_UPLOAD_FILE);
  }

  private showMessagePopup(message: IMessage) {
    if (this.isPhoneCallAndRetryUploadFileAction(message.actionButton)) {
      message.actionButton = MessageActionEnum.CLOSE_MESSAGE;
    } else if (
      this.isPresentialAndRetryUploadFileAction(message.actionButton)
    ) {
      this.increaseAttempsError();
    }

    if (message.actionButton === MessageActionEnum.CLOSE_MESSAGE) {
      this.loanService.setPayrollCheckId(undefined);
    }

    this.formPopupUploadPaymentReceiptComponent.setMessage = message;
    this.formPopupUploadPaymentReceiptComponent.setShowPopupUploadPaymentReceipt = true;
    this.loadingComponent.show = false;
    this.timeAdvacedUploadFileCount = ApplicationConstantsEnum.RESTART_COUNT;
  }

  private uploadFileProcessEnd(messageEnum: MessageEnum, description?: string) {
    const message = this.getMessages(messageEnum, description);
    this.showMessagePopup(message);
  }

  private validityStatus() {
    this.payrollService.isPayrollCheckPreLoaded().subscribe(
      () => {
        this.refreshLoading();
        this.salaryLoanResolve(UploadDocumentsStatesEnum.DOCUMENT_IN_PROCESS);
      },
      () => this.initFormPopupUploadPaymentReceipt(),
    );
  }

  private validationWhenIsNewLoanAndPhoneCall() {
    if (this.advisorService.isLoanByPhoneCall()) {
      this.sendLinkForUploadFile();
      return;
    } else {
      this.validityStatus();
    }
  }
}
